import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { Dialog } from 'primeng/dialog';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { DashboardService } from 'src/app/dashBoards/dashboard/dashboard.service';
import { ReportService } from 'src/app/dynamic-report-table/dynamic-report-service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { VendorTransaction } from '../payment-voucher/payment-voucher.model';
import { PaymentService } from '../payment-voucher/payment-voucher.service';

@Component({
  selector: 'app-vendor-payment-module',
  templateUrl: './vendor-payment-module.component.html',
  styleUrls: ['./vendor-payment-module.component.scss']
})
export class VendorPaymentModuleComponent implements OnInit {
  @ViewChild('dialog') dialog: Dialog;
  itemsPerPage: any = 10;
  filterProps: any;
  searchResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  page: number;
  cols: any;
  userRoles=userRoles;
  roleCode: any;
  vouchercols: any;
  enableStatusVoucher: boolean;
  searchVoucherStatusResult: SearchResult<any> =
  new SearchResult<any>();
  userContextSubscription: Subscription;

  constructor(
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    protected router: Router,
    protected paymentService: PaymentService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    private loginService: SigninService,
    private userRoleService:UserRoleService,
    private notificationService:NotificationService,
    private reportService:ReportService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  message: string;

  ngOnInit(): void {
    if (this.route.snapshot.queryParamMap.get('error')){
      this.notificationService.alertError('Error Occurred: '+this.route.snapshot.queryParamMap.get('error'),'');
    }
    this.loginService.getuserDto().subscribe(
      (res) => {
       this.roleCode = res.body.role.code;
       
    this.cols = [
      {
        field: 'levelMasterName',
        header: 'District',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'voucherNo',
        header: 'Voucher No',
        isFilterable: true,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no'
      },
      {
        field: 'transDate',
        header: 'Voucher Date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'commercialVendorName',
        header: 'Vendor Name',
        jhiTranslate: 'tableHead.vendor.name',
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'bankName',
        header: 'Bank Name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'commercialIfscode',
        header: 'IFSC Code',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'commercialAccountNo',
        header: 'Vendor Account',
        jhiTranslate: 'tableHead.vendor.account',
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'transactionId',
        header: 'Transaction No',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'paymentDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'grossAmount',
        header: 'Bill Value',
        isSelectcolumn: true,
        isFilterable: true,
        type: 'amount'
      },
      {
        field: 'transactionAmount',
        header: 'Total Transction Amount',
        jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        isFilterable: true,
        type: 'amount'
      },
      {
        field: 'paymentMethod',
        header: 'Payment Method',
        isSelectcolumn: true,
        isFilterable: true,
        type:'color'
      },
      {
        field: 'signMessage',
        header: 'Status',
        isSelectcolumn: true,
        isFilterable: true
      }
    ];
    const stateRoleColumn = {
      field: 'implementingAgency',
      header: 'Implementing Agency',
      isFilterable: true,
      isSelectcolumn: true,
      type: 'dropDown'
    };
   if(this.roleCode== userRoles.roleState){
    this.cols.push(stateRoleColumn);
    }
      },
      (onError) => {
        
      } 
     
    );
  }
  
  ngAfterViewInit() {
    this.updateDialogFont();
  }

  openNew() {
    this.paymentService.getVoucher().subscribe(
      (res: HttpResponse<any>) => {
        this.router.navigate(['/VendorPaymentVoucher'], {
          relativeTo: this.route,
          queryParams: {
            VoucherNo: res.body,
          },
        });
      },
      () => {}
    );
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    // this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let query = new filterQuery();
    query.key = 'personalFlag';
    query.operation = 'equals';
    query.value = 'NO';
    this.filterProps = this.filterProps ? this.filterProps : [];
    this.filterProps.push(query);
    query = new filterQuery();
	query.key = 'paymentMethod';
    query.operation = 'contains';
    query.value = 'VENDOR';
	this.filterProps.push(query);
	query = new filterQuery();
	query.key = 'activeFlag';
    query.operation = 'equals';
    query.value = 'YES';
	this.filterProps.push(query);
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.paymentService.filter({ ...Query }, this.filterProps).subscribe(
      (res: HttpResponse<VendorTransaction[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: VendorTransaction[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  onSelectRow($event) {
    if ($event.type === 'select') {
      this.router.navigate(['/VendorPaymentVoucher'], {
        queryParams: {
          id: $event.value.id,
        },
      });
    }
	else{
		this.paymentService
		  .deletedVocher($event.value.id)
		  .subscribe(
			(res: HttpResponse<any>) => {
				this.loadPage(this.page)
			},
			() => {
			  this.onError();
			}
		  );
	}
  }
  getSubTableDetails($event) {
    this.vouchercols = [
      {
        field: 'description',
        header: 'Description',
        isFilterable: false,
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.description'
        
      },
      {
        field: 'quantity',
        header: 'Quantity',
        isFilterable: false,
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.quantity',
        
      },
      {
        field: 'amount',
        header: 'Amount',
        isSortable: true,
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.amount',
        type: 'amount'
      },
      {
        field: 'gstAmount',
        header: 'Gst Amount',
        isSortable: true,
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.gst',
      },
      {
        field: 'grossAmount',
        header: 'Gross Amount',
        isSortable: true,
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.grossTotal',
        type: 'amount'
      },
     
    ];
    this.dashboardService.getVoucherSubtableById('NO',$event).subscribe(
      (res: HttpResponse<any>) => {
        this.enableStatusVoucher = true;
        this.searchVoucherStatusResult.total = Number(
          res.headers.get('X-Total-Count')
        );
        this.searchVoucherStatusResult.items = res.body ?? [];
        this.searchVoucherStatusResult = { ...this.searchVoucherStatusResult };
      },
      () => {
        this.onError();
      }
    );
  }

  blukVoucherApproved(){
    this.router.navigate(['/blukpaymentTable'],  {
      queryParams: {
        type:'Vendor',
      },
    });
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.userContextSubscription) {
      this.userContextSubscription.unsubscribe();
    }
  }
  downloadData() {
    let data="Vendor Payment Report";
    
    this.spinner.show();
    this.reportService.getExcelDownload(data).subscribe((res: HttpResponse<any>) => 
      {
       console.log(res);
       this.notificationService.alertSuccess(res.body, '');
       this.spinner.hide();
     },
      (onError) => {
        this.notificationService.alertError(onError.error.errorMessage, '');
        this.spinner.hide();
      }
    );
  }

  checkStatus() {  
      let reportName='Vendor Payment Report';
    this.spinner.show();
    this.reportService.reportAsyncStatusCheck(reportName).subscribe(
      (res) => {
        console.log('res', res);
        let b: any = res.body;
        this.saveBlobToExcel(b.data,'Vendor Payment Report');
        this.spinner.hide();
      },
      (onError) => {
        this.notificationService.alertError(onError.error.errorMessage, '');
        this.spinner.hide();
      }
    );
  }

  saveBlobToExcel(data, filename) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }
}

