import { LevelMaster } from "../module/level-master/level-master";
import { FinYear } from "../module/limit-translist/limit-translist.module";

export class MaintenanceModule {
  id: number; // MAINTENANCE_MODULE_ID
  workId: string; // WORK_ID
  levelMaster: LevelMaster; // LEVEL_MASTER_ID
  finYear: FinYear; // FIN_YEAR_ID
  workName: string; // WORK_NAME
  administrativeSanctionDate: Date; // ADMINISTRATIVE_SANCTION_DATE
  administrativeSanctionAmount: number; // ADMINISTRATIVE_SANCTION_AMOUNT
  constructionCost: number; // CONSTRUCTION_COST
  totalMaintenanceAmount: number; // TOTAL_MAINTENANCE_AMOUNT
  finalPaymentDate: Date; // FINAL_PAYMENT_DATE
  workCompletionDate: Date; // WORK_COMPLETION_DATE
  fileName: string; // FILE_NAME
  createdOn: Date; // CREATED_ON
  updatedOn: Date; // UPDATED_ON
  createdBy: number; // CREATED_BY
  updatedBy: number; // UPDATED_BY
  activeFlag: string; // ACTIVE_FLAG
  approve: boolean; // APPROVE
  reject: boolean; // REJECT
  rejectReason: string; // REJECT_REASON
  status: string; // STATUS
}

export class maintenanceDetailDescription {
  id: number; // MAINTENANCE_MODULE_DESC_ID
  maintenanceModuleId: number; // MAINTENANCE_MODULE_ID
  billYear: string; // BILL_YEAR
  maintenanceAmount: number; // MAINTENANCE_AMOUNT
  gst: number; // GST
  grossTotal: number; // GROSS_TOTAL
  activeFlag: string; // ACTIVE_FLAG
  lwf: number;
}

export class MaintenanceModuleSaveDto {
  maintenanceModuleDto: MaintenanceModule; // Main module details
  maintenanceDetailDesc: maintenanceDetailDescription[]; // List of descriptions
}