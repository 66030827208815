<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Disputed Transaction List</h5>
                </ng-template>
                <ng-template pTemplate="right">

                </ng-template>
            </p-toolbar>
        </div>
    </div>

    <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
        <p-tabPanel header="Vendor Tranasction">
            <div *ngIf="activeIndex === 0" class="row">
                <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchVendorResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disablePaymentEditIcon]="true" [paginator]="false"></custom-filter-table>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Statutory Tranaction">
            <div class="row">
                <custom-filter-table [columnsValues]="statutorycols" [filterSearchResult]="searchStatutoryResult" [paginator]="false"></custom-filter-table>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Miscellaneous Bulk">
            <div class="row">
                <p-table [value]="searchMisBulkResult.items" [tableStyle]="{ 'min-width': '100%' }" [resizableColumns]="true" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th [ngClass]="fontService.regularClass">District</th>
                            <th [ngClass]="fontService.regularClass">Voucher No</th>
                            <th [ngClass]="fontService.regularClass">Account Number</th>
                            <th [ngClass]="fontService.regularClass">PAN Number</th>
                            <th [ngClass]="fontService.regularClass">GST Number</th>
                            <th [ngClass]="fontService.regularClass">Payment Date</th>
                            <th [ngClass]="fontService.regularClass">Deduction Type</th>
                            <th [ngClass]="fontService.regularClass">Implementing Agency</th>
                            <th [ngClass]="fontService.regularClass">Transaction Amount</th>
                            <th [ngClass]="fontService.regularClass">Transaction Status</th>
                            <th [ngClass]="fontService.regularClass">Status</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-list>
                        <tr>
                            <td>{{list?.levelMasterName}}</td>
                            <td>{{list?.voucherNumber}}</td>
                            <td *ngIf="list?.statutoryBulkTransaction?.paymentType === 'Holding Account'">{{list?.statutoryBulkTransaction?.statutoryAccountNumber}}</td>
                            <td *ngIf="list?.statutoryBulkTransaction?.paymentType !== 'Holding Account'">{{list?.statutoryBulkTransaction?.vendorBankAccNo}}</td>
                            <td>{{list?.statutoryBulkTransaction?.vendorPanNumber}}</td>
                            <td>{{list?.statutoryBulkTransaction?.vendorGstNumber}}</td>
                            <td>{{list?.statutoryBulkTransaction?.paymentDate | date:'dd/MMM/yy'}}</td>
                            <td>{{list?.statutoryBulkTransaction?.deductionName}}</td>
                            <td>{{list?.iaName}}</td>
                            <td>{{list?.statutoryBulkTransaction?.transactionAmount | currency:'INR':'':'1.2-2':'en-IN'}}</td>
                            <td>{{list?.statutoryBulkTransaction?.transactionStatus}}</td>
                            <td>{{list?.signMessage}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Work Maintenance">
            <div *ngIf="activeIndex === 3" class="row">
                <custom-filter-table [columnsValues]="cols" [filterSearchResult]="maintenanceDisputedTransaction" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disablePaymentEditIcon]="true" [paginator]="false"></custom-filter-table>
            </div>
        </p-tabPanel>
    </p-tabView>



</div>