<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Work Maintenance Entry
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label req" for="workId" [ngClass]="fontService.labelClass">Work
                                Id</label>
                            <input type="text" class="form-control" autocomplete="off" maxlength="15" oninput="this.value = this.value.replace(/[^A-Za-z0-9-_]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" [readOnly]="readonly" name="workId" id="workId" [(ngModel)]="maintenceVoucher.maintenanceModuleDto.workId"
                                (change)="changeWorkId()" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row g-0 mt-2">
            <div class="col-lg-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Fin
                                Year</label>
                            <ng-select id="scheme" bindLabel="name" appearance="outline" [searchable]="true" [closeOnSelect]="true" [disabled]="true" [clearable]="false" [items]="finYear" [(ngModel)]="maintenceVoucher.maintenanceModuleDto.finYear" class="form-control dropdown-width">
                            </ng-select>
                        </div>
                        <div class="col-md-3">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">Work Name
                            </label>
                            <input type="text" class="form-control" autocomplete="off" oninput="this.value = this.value.replace(/^[^0-9]+|[^0-9-\/]+/g, '').replace(/(\..*)\./g, '$1');" maxlength="15" [readOnly]="true" [(ngModel)]="maintenceVoucher.maintenanceModuleDto.workName"
                                name="name" id="levelType" />
                        </div>
                        <div class="col-md-3">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">AS Date
                            </label> <br>
                            <p-calendar [disabled]="true" [(ngModel)]="maintenceVoucher.maintenanceModuleDto.administrativeSanctionDate"></p-calendar>
                        </div>
                        <div class="col-md-3">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">AS Amount
                            </label><br>
                            <p-inputNumber id="balancePay" inputId="locale-indian" mode="decimal" locale="en-IN" [disabled]="true" maxlength="15" [(ngModel)]="maintenceVoucher.maintenanceModuleDto.administrativeSanctionAmount"></p-inputNumber>

                        </div>

                    </div>
                    <div class="row mt-3">
                        <div class="col-md-3">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Construction Cost
                            </label>
                            <input type="text" class="form-control" autocomplete="off" oninput="this.value = this.value.replace(/^[^0-9]+|[^0-9-\/]+/g, '').replace(/(\..*)\./g, '$1');" maxlength="15" name="name" [(ngModel)]="maintenceVoucher.maintenanceModuleDto.constructionCost"
                                [readonly]="true" id="levelType" />
                        </div>
                        <div class="col-md-3">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">Maintenance Amount
                            </label><br>
                            <p-inputNumber id="balancePay" inputId="locale-indian" mode="decimal" locale="en-IN" [disabled]="true" maxlength="15" [(ngModel)]="maintenceVoucher.maintenanceModuleDto.totalMaintenanceAmount"></p-inputNumber>
                        </div>
                        <!-- <div class="col-md-4">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">Physical Completion Date
                            </label> <br>
                            <p-calendar></p-calendar>
                        </div> -->
                        <div class="col-md-3">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">Final Payment on 
                            </label> <br>
                            <p-calendar [disabled]="true" [(ngModel)]="maintenceVoucher.maintenanceModuleDto.finalPaymentDate"></p-calendar>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">Work Physical Completion Date
                            </label> <br>
                            <p-calendar styleClass="datepicker" [maxDate]="maxDate" [(ngModel)]="maintenceVoucher.maintenanceModuleDto.workCompletionDate" [ngClass]="{ 'is-invalid': ngSelectInvalid && !maintenceVoucher.maintenanceModuleDto.workCompletionDate}" [disabled]="readonly"></p-calendar>
                        </div>
                        <div class="col-lg-6">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">Upload Work Completion Report
                              </label><br>
                            <small class="mb-2" *ngIf="!readonly" [ngClass]="fontService.regularClass" jhiTranslate="funds.note">Note : Allowed file types are jpg, jpeg, png, pdf and
                                Maximum file size should be 1MB</small>
                            <p-fileUpload *ngIf="!readonly" (onUpload)="onUpload($event)" [customUpload]="true" [disabled]="isFileUploaded" (uploadHandler)="onUpload($event)" auto="true" multiple="false" [showUploadButton]="false" [showCancelButton]="false" chooseLabel="{{'common.upload'|translate}}">
                            </p-fileUpload>
                            <div class="row mt-2" *ngIf="!isFileUploaded && ngSelectInvalid">
                                <small class="text-danger">Upload the Certificate</small>
                            </div>
                            <div class="row mt-2" *ngIf="isFileUploaded">
                                <div class="col">
                                    <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument()">{{uFilename}}</a>
                                    <i class="pi pi-times add-margin" *ngIf="!readonly" (click)="removeDocument()"></i>
                                    <i class="pi pi-download add-margin" (click)="downloadDocument()"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-12">
            <p-table [value]="maintenceVoucher.maintenanceDetailDesc" [resizableColumns]="true" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th [ngClass]="fontService.labelClass">
                            {{ "payments.table.sno" | translate }}
                        </th>
                        <th [ngClass]="fontService.labelClass">
                            Bill Year
                        </th>
                        <th class="req" [ngClass]="fontService.labelClass">Maintence Cost

                        </th>
                        <th [ngClass]="fontService.labelClass">
                            GST 18 %
                        </th>
                        <th [ngClass]="fontService.labelClass">
                            LWF 1 %
                        </th>
                        <th [ngClass]="fontService.labelClass">
                            {{ "payments.table.grossTotal" | translate }}
                        </th>
                        <!-- <th [ngClass]="fontService.labelClass">
                    {{ "payments.table.delete" | translate }}
                  </th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-list let-index="rowIndex">
                    <tr>
                        <td>{{ index + 1 }}</td>
                        <td>
                            <ng-select [appendTo]="'body'" id="scheme" [items]="YearList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [disabled]="true" class="form-control" (change)="checkYear(list)" [(ngModel)]="list.billYear" [clearable]="false" appendTo="body">
                            </ng-select>
                        </td>
                        <td>
                            <p-inputNumber id="lwf" [(ngModel)]="list.maintenanceAmount" mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="2" [disabled]="readonly" (ngModelChange)="calculateTotalAmount(list)"></p-inputNumber>
                        </td>
                        <td>
                            <p-inputNumber id="gst" [disabled]="true" [(ngModel)]="list.gst" mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="2"></p-inputNumber>
                        </td>
                        <td>
                            <p-inputNumber id="gst" [disabled]="true" [(ngModel)]="list.lwf" mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="2"></p-inputNumber>
                        </td>
                        <td>
                            <p-inputNumber type="text" [disabled]="true" inputId="locale-indian" mode="decimal" locale="en-IN" id="field_price" [(ngModel)]="list.grossTotal" [minFractionDigits]="1" [maxFractionDigits]="2"></p-inputNumber>
                        </td>
                        <!-- <td>
                    <button 
                      pButton
                      pRipple
                      type="button"
                      [disabled]="readonly || !isEditable"
                      icon="pi pi-trash"
                      class="p-button-rounded p-button-text p-button-danger"
                      (click)="delete(index)"
                    ></button>
                  </td> -->
                        <td></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <p-toolbar>
            <div class="p-toolbar-group-start"></div>
            <div class="p-toolbar-group-end">
                <!-- <button *ngIf="!approveView" pButton pRipple icon="pi pi-times" class="p-button-raised p-button-danger" (click)="cancel()">
                    <span class="button-label" style="padding-right: inherit;" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton">
                    </span>
                </button> -->
                <button *ngIf="!readonly" pButton pRipple icon="pi pi-check" class="p-button-raised ms-4 p-button-success" (click)="save()">
                    <span class="button-label" style="padding-right: inherit;" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton">
                    </span>
                </button>
                <button *ngIf="approved" pButton pRipple icon="pi pi-check" class="p-button-success custom-button" (click)="approve()">
                    <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.approvebutton"></span>
                  </button>
                <button *ngIf="approved" pButton pRipple icon="pi pi-times" class="p-button-danger custom-button" (click)="reasonDialog = true">
                  <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.rejectbutton"></span>
                </button>
            </div>
        </p-toolbar>

    </div>

    <p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <ng-template pTemplate="body">
            <div style='position: relative; height: 100%;'>
                <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
                <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
            </div>
        </ng-template>
    </p-dialog>

    <p-dialog [(visible)]="reasonDialog" [style]="{ width: '500px', height: '350px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            Reject Dialog:
        </p-header>
        <div class="form-group mb-4">
            <label class="form-control-label req" for="reason" [ngClass]="fontService.labelClass">Reject
          Reason</label><br />
            <textarea id="reason" name="reason" rows="3" [(ngModel)]="rejectionReason" cols="100" maxlength="150" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"></textarea>
            <div *ngIf="requiredError" class="mt-2">
                <small class="text-danger">please mention the reason for reject</small>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple class="p-button-danger">
          <span class="button-label" [ngClass]="fontService.buttonClass" label="Reject"
            (click)="rejectDialog()">Reject</span>
        </button>
        </ng-template>

    </p-dialog>


</div>