import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { DepartmentList } from '../department-list/department-list';
import { DepartmentListService } from '../department-list/department-list.service';
import { FinYear } from '../limit-translist/limit-translist.module';
import { LimitTransListService } from '../limit-translist/limit-translist.service';
import { SchemeList } from '../scheme-list/scheme-list';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { Funds, FundsDto } from './funds';
import { FundsService } from './funds.service';

@Component({
  selector: 'app-funds',
  templateUrl: './funds.component.html',
  styleUrls: ['./funds.component.scss'],
})
export class FundsComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;
  fundsDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Funds> = new SearchResult<Funds>();
  fund?: FundsDto;
  selectedFunds: Funds[] = [];
  submitted?: boolean;
  selectedMapping: string;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any;
  departmentList: DepartmentList[];
  schemeList: SchemeList[];
  finYear: FinYear[];
  allocationTypes = [
    { id: 1, name: 'Allocation' },
    { id: 2, name: 'Additional Allocation' },
    { id: 3, name: 'Interest' }
  ]
  ngSelectInvalid = false;
  finYearError: any;
  viewOnly: boolean;
  viewDoc: boolean;
  vFileUrl: string;
  vFileExt: any;
  isFile: boolean;

  constructor(
    private fundsService: FundsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    protected router: Router,
    private departmentListService: DepartmentListService,
    private schemeListService: SchemeListService,
    private limitTransListService: LimitTransListService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    protected paymentService: PaymentService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.loadPage(1);
    // this.fundsService.getCommercialVendors().then(data => this.funds = data);

    this.cols = [
      {
        field: 'department',
        header: 'Dept Name',
        jhiTranslate: 'tableHead.department.name',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'scheme',
        header: 'Scheme Name',
        jhiTranslate: 'tableHead.scheme.name',
        isSortable: true,
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'finYear',
        header: 'Fin Year',
        jhiTranslate: 'tableHead.fin.year.name',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'budgetAmount',
        header: 'Fin Year',
        jhiTranslate: 'tableHead.budgetAmount',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true
      },
      {
        field: 'transactionDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
        type: 'date',
      },
    ];
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();

    const pageToLoad: number = page ?? this.page ?? 1;
    this.filterProps = this.filterProps ? this.filterProps : [];

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.fundsService.filter({ ...filterQuery }, this.filterProps).subscribe(
      (res: HttpResponse<Funds[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: Funds[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    if (navigate) {
      this.router.navigate(['/funds'], {
        queryParams: {
          page: this.page - 1,
          size: this.itemsPerPage,
        },
      });
    }
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }

  loadValue() {
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<FinYear[]>) => {
        this.finYear = res.body;
      },
      () => { }
    );
  }

  openNew() {
    this.fund = new FundsDto();
    this.submitted = false;
    this.fundsDialog = true;
    this.loadValue();
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  hideDialog() {
    this.fund = new FundsDto();
    this.ngSelectInvalid = false;
    this.isFile = false;
    this.viewOnly = false;
    this.fundsDialog = false;
    this.submitted = false;
    this.finYearError = null;
  }

  checkFinYear() {
    this.finYearError = null;
    let filterQuery = {
      page: 0,
      size: 10,
    };
    const filter = [
      {
        key: 'finYear.id',
        operation: 'equals',
        value: this.fund.finYearDto.id,
      },

    ];
    if (this.fund.id) {
      filter.push({
        key: 'id',
        operation: 'notEquals',
        value: this.fund.id
      })
    };

    this.fundsService.validFilter({ ...filterQuery }, filter).subscribe(
      (res: HttpResponse<Funds[]>) => {
        if (res.body.length != 0) {
          this.finYearError = "Budget Amount for the Chosen Fin Year Already Exists";
          this.fund.budgetAmount = res.body[0].budgetAmount;
        }
        else {
          this.finYearError = null
        }
      },
      () => {
        this.onError();

      }
    );

  }

  removeDocument() {
    this.paymentService.deleteimage(this.fund.fileName).subscribe(
      (res) => {
        this.notificationService.alertSuccess('File Deleted', '');
        this.isFile = false;
      },
      (error) => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }

  onUploadfile1(event: any) {
    const formData = new FormData();
    const i = event.files.length - 1;
    const file = event.files[i];
    const acceptedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (file && !acceptedFormats.includes(file.type)) {
      this.notificationService.alertError('Invalid file format. Please upload a valid file.', '');
      return;
    }
    const fileData = new Blob([event.files[i]], {
      type: event.files[i].type,
    });
    if (event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if (event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if (event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else if (event.files[i].type === 'image/jpg') {
      formData.append('file', fileData, `.jpg`);
    }

    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.notificationService.alertSuccess('File Upload SuccessFully', '');
        this.fund.fileName = res.body.fileName;
        this.isFile = true;
      },
      (onerr) => {
        this.notificationService.alertError('Error, please make sure file size is within 5MB and in supported format', '');
      }
    );

    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }

  viewDocument() {
    this.paymentService
      .viewimage(this.fund.fileName)
      .subscribe((res) => {
        const filename = res.body.fileName;
        this.vFileExt = filename.split('.').pop();
        this.vFileUrl =
          'data:image/' + this.vFileExt + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
        this.viewDoc = true;
      });
  }

  saveData() {
    const isFormValid =
      this.fund.departmentDto &&
      this.fund.schemeDto &&
      this.fund.finYearDto &&
      this.fund.budgetAmount &&
      this.fund.description &&
      this.isFile ;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    if (this.fund.budgetAmount <= 0) {
      this.notificationService.alertError('Budget Amount should be greater than 0', '');
      return;
    }
 
    this.ngSelectInvalid = false;
    this.fundsService.save(this.fund).subscribe(
      (res: HttpResponse<any>) => {
        this.fundsDialog = false;
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      () => {
        this.onError();
        this.notificationService.alertError('Error!', '');
      }
    );


  }
  onSelectRow($event) {
    if ($event.type === 'select' || $event.type === 'view') {
      if ($event.type === 'view') {
        this.viewOnly = true;
      }
      this.fundsService.getFunds($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.fund = res.body;
          this.fundsDialog = true;
          if (this.fund.fileName) {
            this.isFile = true;
          }
          this.loadValue();
        },
        () => {
          this.onError();
        }
      );
    } else {
      this.fundsService.delete($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    }
  }
}
