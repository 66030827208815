import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { LevelMaster } from 'src/app/module/level-master/level-master';
import { environment } from 'src/environments/environment';
import { filterQuery } from 'src/app/shared/common.model';
import { LocalStorageService } from 'ngx-webstorage';
export type EntityArrayResponseType = HttpResponse<LevelMaster[]>;

@Injectable({ providedIn: 'root' })
export class DashboardService {
 
  protected fundsDashboardUrl=environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/fundsDashboard';
  protected fundsTransactionByYearUrl=environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/fundsTransactionByYear';
  protected fundsExpenditureByYearUrl=environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/fundsExpenditureByYear';
  protected fundsSuccessfullTransactionByYearUrl=environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/fundsSuccessfullTransactionByYear';
  protected fundsBalanceByYearUrl=environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/fundsBalanceByYear';
  protected iaLimitAllocateAmountByYearUrl=environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/iaLimitAllocateAmountByYear';
  protected iaLimitExpenditureAmountByYearUrl=environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/iaLimitExpenditureAmountByYear';
  protected iaLimitSuccessTransactionAmountByYearUrl=environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/iaLimitSuccessTransactionAmountByYear';
  protected iaLimitBalanceAmountByYearUrl=environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/iaLimitBalanceAmountByYear';
  protected getProjectSummaryByYearUrl=environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/getProjectSummaryByYear';
  protected getProjectSummaryBylevelMasterUrl=environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/getProjectSummaryBylevelMaster';


  protected VoucherBillDescURL =
    environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getVoucherBillDescByFilter';
  protected VoucherPersonalDescURL =
    environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getPersonalDescByFilter';
  protected VoucherBillDescEmployeeURL =
    environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getVoucherBillDescEmployeeByFilter';
  protected VoucherBillDescEmployeeRepURL =
    environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getVoucherBillDescElectedRepByFilter';
  protected overAllDataUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/overAllData';
  protected voucherCreatedAndToBePaidAmountUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/voucherCreatedAndToBePaidAmount';
  protected voucherCreatedAndToSuccessAmountUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/voucherCreatedAndToSuccessAmount';
  protected loginWisePendingBillsUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/loginWisePendingBills';
  protected finalBillsPaidWorkDataUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/finalBillsPaidWorkData';
  protected zeroBillsWorkDataUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/zeroBillsWorkData';
  protected ThirtyDaysExpenditureUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/dashboard/lastThirtyDaysExpenditure';
  
  filterProps: any;

  constructor(protected http: HttpClient,private localStorageService: LocalStorageService,) {}

  getDashboardCardDetails(levelMasterId,finYearId): Observable<any> {
    return this.http.get<any>(`${this.fundsDashboardUrl}?levelMasterId=${levelMasterId}&finYearId=${finYearId}`, {
      observe: 'response',
    });
  }

  voucherCreatedAndToBePaidAmount(finYearId,levelMasterId?): Observable<any> {
    const params: { [key: string]: number } = {};
    params['finYearId'] = finYearId;
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    return this.http.get<any>(`${this.voucherCreatedAndToBePaidAmountUrl}`, {
      observe: 'response',
      params: params, 
    });
  }

  voucherCreatedAndToSuccessAmount(finYearId,levelMasterId?): Observable<any> {
    const params: { [key: string]: number } = {};
    params['finYearId'] = finYearId;
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    return this.http.get<any>(`${this.voucherCreatedAndToSuccessAmountUrl}`, {
      observe: 'response',
      params: params, 
    });
  }

  loginWisePendingBills(finYearId,levelMasterId?): Observable<any> {
    const params: { [key: string]: number } = {};
    params['finYearId'] = finYearId;
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    return this.http.get<any>(`${this.loginWisePendingBillsUrl}`, {
      observe: 'response',
      params: params, 
    });
  }

  zeroBillsWorkData(finYearId,levelMasterId?): Observable<any> {
    const params: { [key: string]: number } = {};
    params['finYearId'] = finYearId;
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    return this.http.get<any>(`${this.zeroBillsWorkDataUrl}`, {
      observe: 'response',
      params: params, 
    });
  }

  finalBillsPaidWorkData(finYearId,levelMasterId?): Observable<any> {
    const params: { [key: string]: number } = {};
    params['finYearId'] = finYearId;
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    return this.http.get<any>(`${this.finalBillsPaidWorkDataUrl}`, {
      observe: 'response',
      params: params, 
    });
  }

  lastThirtyDaysExpenditure(finYearId,levelMasterId?): Observable<any> {
    const params: { [key: string]: number } = {};
    params['finYearId'] = finYearId;
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    return this.http.get<any>(`${this.ThirtyDaysExpenditureUrl}`, {
      observe: 'response',
      params: params, 
    });
  }

  overAllDataCardDetails(finYearId,levelMasterId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    params['finYearId'] = finYearId;
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    return this.http.get<any>(`${this.overAllDataUrl}`, {
      observe: 'response',
      params: params, 
    });
  }

  fundsTransactionByYear(levelMasterId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    return this.http.get<any>(this.fundsTransactionByYearUrl, {
      observe: 'response',
      params: params, 
    });
  }

  fundsSuccessfullTransactionExpByYear(levelMasterId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    return this.http.get<any>(this.fundsSuccessfullTransactionByYearUrl, {
      observe: 'response',
      params: params, 
    });
  }

  fundsExpenditureByYear(levelMasterId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    return this.http.get<any>(this.fundsExpenditureByYearUrl, {
      observe: 'response',
      params: params, 
    });
  }

  fundsBalanceByYear(levelMasterId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    return this.http.get<any>(this.fundsBalanceByYearUrl, {
      observe: 'response',
      params: params, 
    });
  }

  getProjectSummary(levelMasterId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    return this.http.get<any>(this.getProjectSummaryByYearUrl, {
      observe: 'response',
      params: params, 
    });
  }

  limitFundByYear(finYearId:number,levelMasterId?:number,parentId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    params['finYearId'] = finYearId;
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    if (parentId) {
      params['parentId'] = parentId;
    }
    return this.http.get<any>(this.iaLimitAllocateAmountByYearUrl, {
      observe: 'response',
      params: params, 
    });
  }

  expenditureByYear(finYearId:number,levelMasterId?:number,parentId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    params['finYearId'] = finYearId;
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    if (parentId) {
      params['parentId'] = parentId;
    }
    return this.http.get<any>(this.iaLimitExpenditureAmountByYearUrl, {
      observe: 'response',
      params: params, 
    });
  }

  SuccessTransactionExpenditureByYear(finYearId:number,levelMasterId?:number,parentId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    params['finYearId'] = finYearId;
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    if (parentId) {
      params['parentId'] = parentId;
    }
    return this.http.get<any>(this.iaLimitSuccessTransactionAmountByYearUrl, {
      observe: 'response',
      params: params, 
    });
  }


  balanceByYear(finYearId:number,levelMasterId?:number,parentId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    params['finYearId'] = finYearId;
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    if (parentId) {
      params['parentId'] = parentId;
    }
    return this.http.get<any>(this.iaLimitBalanceAmountByYearUrl, {
      observe: 'response',
      params: params, 
    });
  }

  getProjectSummaryByLevel(finYearId:number,levelMasterId?:number,parentId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    params['finYearId'] = finYearId;
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    if (parentId) {
      params['parentId'] = parentId;
    }
    return this.http.get<any>(this.getProjectSummaryBylevelMasterUrl, {
      observe: 'response',
      params: params, 
    });
  }

  getVoucherSubtableById(personalFlag: string, id: number): Observable<any> {
    let query = new filterQuery();
    switch (personalFlag) {
      case 'NO':
        query.key = 'vendortransactionId';
        query.operation = 'equals';
        query.value = id;
        this.filterProps = [];
        this.filterProps.push(query);
        return this.http.post<any>(this.VoucherBillDescURL, this.filterProps, {
          observe: 'response',
        });
        break;

      case 'YES':
        query.key = 'vendortransactionId';
        query.operation = 'equals';
        query.value = id;
        this.filterProps = [];
        this.filterProps.push(query);
        return this.http.post<any>(
          this.VoucherPersonalDescURL,
          this.filterProps,
          {
            observe: 'response',
          }
        );
        break;
      case 'E':
        query.key = 'vendorTransactionDto';
        query.operation = 'equals';
        query.value = Number(id);
        this.filterProps = [];
        this.filterProps.push(query);
        return this.http.post<any>(
          this.VoucherBillDescEmployeeURL,
          this.filterProps,
          {
            observe: 'response',
          }
        );

      case 'ER':
        query.key = 'vendorTransactionDto';
        query.operation = 'equals';
        query.value = Number(id);
        this.filterProps = [];
        this.filterProps.push(query);
        return this.http.post<any>(
          this.VoucherBillDescEmployeeRepURL,
          this.filterProps,
          {
            observe: 'response',
          }
        );
    }
  }
}
