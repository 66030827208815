import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { DepartmentList } from '../department-list/department-list';
import { DepartmentListService } from '../department-list/department-list.service';
import { FundsService } from '../funds/funds.service';
import { FinYear } from '../limit-translist/limit-translist.module';
import { LimitTransListService } from '../limit-translist/limit-translist.service';
import { OpeningBalanceService } from '../opening-balance/opening-balance.service';
import { SchemeList } from '../scheme-list/scheme-list';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { BudgetAmountDto, FundsTransaction, FundsTransactionDto } from './funds-transaction';
import { FundsTransactionService } from './funds-transaction.service';

@Component({
  selector: 'app-funds-transaction',
  templateUrl: './funds-transaction.component.html',
  styleUrls: ['./funds-transaction.component.scss'],
})
export class FundsTransactionComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;
  fundsTransactionDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<FundsTransaction> =
    new SearchResult<FundsTransaction>();
  fundsTransaction?: FundsTransactionDto;
  selectedFundsTransaction: FundsTransaction[] = [];
  submitted?: boolean;
  selectedMapping: string;
  page: number;
  filterProps: any;
  itemsPerPage: number = 10;
  departmentList: DepartmentList[];
  schemeList: SchemeList[];
  fundSourceList: any = ['TN'];
  selectedSourceList: any;
  finYear: FinYear[];
  isFileTNUploaded:boolean;
  viewDoc:boolean=false;
  vFileExt:string;
  vFileUrl:string;
  fname:string;
  isTNSelected:boolean;

  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public showBMobile = false;
  public aadhaarError = false;
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  today: Date;

  //required fields property
  ngSelectInvalid: boolean = false;
  transactionRefNoError: string;
  viewOnly: boolean;
  noFundError: string;
  totalBudget: number;
  allocatedBudget: number=0;
  balanceBudget: number;

  constructor(
    private FundsTransactionService: FundsTransactionService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    protected router: Router,
    private departmentListService: DepartmentListService,
    private schemeListService: SchemeListService,
    private limitTransListService: LimitTransListService,
    private paymentService: PaymentService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private openingBalanceService: OpeningBalanceService,
    private fundService:FundsService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.loadPage(1);
    this.cols = [
      {
        field: 'department',
        header: 'Dept Name',
        jhiTranslate: 'tableHead.department.name',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'scheme',
        header: 'Scheme Name',
        jhiTranslate: 'tableHead.scheme.name',
        isSortable: true,
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'finYear',
        header: 'Fin Year',
        jhiTranslate: 'tableHead.fin.year.name',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'transactionDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'sanctionAmount',
        header: 'Sanction Amount',
        jhiTranslate: 'tableHead.sanction.amount',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
        type:'amount'
      },
    ];
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();

    const pageToLoad: number = page ?? this.page ?? 1;
    let query = new filterQuery();
    query.key = 'isAllocated';
    query.operation = 'equals';
    query.value = true;
    this.filterProps = this.filterProps ? this.filterProps : [];
    this.filterProps.push(query);
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.FundsTransactionService.filter(
      { ...Query },
      this.filterProps
    ).subscribe(
      (res: HttpResponse<FundsTransaction[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );
  }

  checkRefNo() {
    let filterQuery = {
      page: 0,
      size: 10,
    };
    const filter = [
      {
        key: 'transactionRefNo',
        operation: 'equals',
        value: this.fundsTransaction.transactionRefNo,
      },
    ];
    if (this.fundsTransaction.id) {
      filter.push({
        key: 'id',
        operation: 'notEquals',
        value: String(this.fundsTransaction.id),
      });
    }

    this.FundsTransactionService.vaildFilter(
      { ...filterQuery },
      filter
    ).subscribe(
      (res: HttpResponse<FundsTransaction[]>) => {
        if (res.body.length != 0) {
          this.transactionRefNoError = 'Ref No Already Exists';
        } else {
          this.transactionRefNoError = null;
        }
      },
      () => {
        this.onError();
      }
    );
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  protected onSuccess(
    data: FundsTransaction[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  loadValue() {
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<FinYear[]>) => {
        this.finYear = res.body;
      },
      () => {}
    );
  }

  openNew() {
    this.fundsTransaction = new FundsTransactionDto();
    this.submitted = false;
    this.fundsTransactionDialog = true;
    this.loadValue();
  }

  

  onUploadfile2($event) {
    const formData = new FormData();
    const i=$event.files.length-1;
    const file = $event.files[i];
    const acceptedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (file && !acceptedFormats.includes(file.type)) {
      this.notificationService.alertError('Invalid file format. Please upload a valid file.','');
      return;
    }
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }

    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.notificationService.alertSuccess('File Upload SuccessFully', '');
        this.fundsTransaction.fileNameTwo = res.body.fileName;
        this.isFileTNUploaded=true;
      },
      () => {
        this.notificationService.alertError('Error, please make sure file size is within 5MB and in supported format','');
      }
    );

    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }

  viewDocument(){
    this.paymentService.viewimage(this.fundsTransaction.fileNameTwo)
    .subscribe((res)=>
    {
      const filename = res.body.fileName; 
      this.vFileExt  = filename.split('.').pop();
      this.vFileUrl = "data:image/"+this.vFileExt+";base64,"+res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc=true;
    });
  }

  removeDocument(){
    this.paymentService.deleteimage(this.fundsTransaction.fileNameTwo)
    .subscribe((res)=>{
    this.notificationService.alertSuccess("File Deleted",'');
      this.isFileTNUploaded=false;
      this.fundsTransaction.fileNameTwo=null;
    },
    (error)=>{
    this.notificationService.alertError("Error occured, try again",'');
    });
  }

  hideDialog() {
    this.fundsTransaction = new FundsTransactionDto();
    this.ngSelectInvalid = false;
    this.fundsTransactionDialog = false;
    this.submitted = false;
    this.isFileTNUploaded=false;
    this.viewOnly=false;
    this.totalBudget=null;
  this.allocatedBudget=null;
  this.balanceBudget=null;
  }

  checkLimit(){
    this.balanceBudget=this.totalBudget-this.allocatedBudget;
    if(this.fundsTransaction.sanctionAmount>(this.balanceBudget)){
      this.notificationService.alertError('Amount should be within balance amount: Rs. '+this.balanceBudget,'');
    }
  }

  onFinYearChange(){
  this.totalBudget=0;
  this.allocatedBudget=0;
    this.spinner.show();
    this.noFundError=null;
    let filterQuery = {
      page: 0,
      size: 10,
    };
    const filterProps = [
      {
        key: 'finYear.id',
        operation: 'equals',
        value: this.fundsTransaction.finYearDto.id,
      },
    ];

    this.FundsTransactionService.getAmount(this.fundsTransaction.finYearDto.id, this.fundsTransaction.schemeDto.id
    ).subscribe(
      (res: HttpResponse<BudgetAmountDto>) => {
        if (res.body) {
          this.totalBudget = Number(res.body.budgetAmount);
          this.allocatedBudget = Number(res.body.sanctionAmount);
          this.balanceBudget = this.totalBudget - this.allocatedBudget;
          this.spinner.hide();
          () => {
            this.spinner.hide();
            this.onError();
          }

        }
        else {
          this.noFundError = "No Fund available. Please first add  Budget amount on Budget Allocation Page"
        }
      },
      () => {
        this.spinner.hide();
        this.onError();
      }
    );

  }

  async saveData() {
    const isFormValid =
      this.fundsTransaction.departmentDto &&
      this.fundsTransaction.schemeDto &&
      this.fundsTransaction.fundSource &&
      this.fundsTransaction.sanctionAmount &&
      this.fundsTransaction.transactionDate &&
      this.fundsTransaction.finYearDto &&
      this.isFileTNUploaded &&
      this.fundsTransaction.description;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    if(this.fundsTransaction.sanctionAmount <= 0) {
      this.notificationService.alertError('Amount Received should be greater than 0', '');
      return;
    }


    if(this.fundsTransaction.sanctionAmount>this.balanceBudget){
      this.notificationService.alertError('Amount should be within balance amount: Rs. '+this.balanceBudget,'');
      return;
    }
    // const openingBalance = await this.getOpeningBalance();
    // if (openingBalance) {
      this.ngSelectInvalid = false;
      this.FundsTransactionService.save(this.fundsTransaction).subscribe(
        (res: HttpResponse<any>) => {
          this.fundsTransactionDialog = false;
          this.loadPage(this.page);
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        () => {
          this.onError();
          this.notificationService.alertError('Error!', '');
        }
      );
    }
    // else{
    //   this.notificationService.alertError('Please Add  Budget amount on Budget Allocation Page', '');
    // }
  

  // getOpeningBalance(): Promise<OpeningBalance> {
  //   return new Promise<any>((resolve) => {
  //     let filterQuery = {
  //       page: 0,
  //       size: 5,
  //     };
  //     this.openingBalanceService
  //       .filter({ ...filterQuery },[])
  //     .subscribe(
  //       (res: any) => {
  //         resolve(res.body[0]);
  //       },
  //       (err) => {
  //         this.onError();
  //         resolve(null);
  //       }
  //     );
  //   });
  // }

  onSelectRow($event) {
    if ($event.type === 'select' || $event.type === 'view') {
      if($event.type === 'view'){
        this.viewOnly=true;
      }
      this.FundsTransactionService.getFunds($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.fundsTransaction = res.body;
          this.onFinYearChange();
          this.fundsTransactionDialog = true;
          if(this.fundsTransaction.fileNameTwo){
            this.isFileTNUploaded = true;
          }
          if (this.fundsTransaction.transactionDate) {
            this.fundsTransaction.transactionDate = new Date(
              this.fundsTransaction.transactionDate
            );
          }

          this.loadValue();
        },
        () => {
          this.onError();
        }
      );
    } else {
      this.FundsTransactionService.delete($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    }
  }
  downloadData() {
    let data="Fund Receipt Report";
    
    this.spinner.show();
    this.limitTransListService.getExcelDownload(data).subscribe((res: HttpResponse<any>) => 
      {
       console.log(res);
       this.notificationService.alertSuccess(res.body, '');
       this.spinner.hide();
     },
      (onError) => {
        this.notificationService.alertError(onError.error.errorMessage, '');
        this.spinner.hide();
      }
    );
  }

  checkStatus() {
    let filter = {
      reportName:'Fund Receipt Report',
    };
    this.spinner.show();
    this.limitTransListService.reportAsyncStatusCheck({ ...filter }).subscribe(
      (res) => {
        console.log('res', res);
        let b: any = res.body;
        this.saveBlobToExcel(b.data,'Fund Receipt Report');
        this.spinner.hide();
      },
      (onError) => {
        this.notificationService.alertError(onError.error.errorMessage, '');
        this.spinner.hide();
      }
    );
  }

  saveBlobToExcel(data, filename) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }
}


