<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="payments.work.payment.voucher.name">
                        Maintenance Payment Voucher
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col">
                        <div class="card p-3">
                            <div class="row voucherfont">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="payments.add.voucher.generation.name">
                                    Add Voucher Generation
                                </h6>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.voucher.number.name" | translate }}:<br />{{ voucherGenerationDto?.maintenanceTransactionDto?.voucherNo }}
                                    </p>
                                </div>
                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "date.name" | translate }}:<br />{{ date | date:'dd/MM/yyyy'}}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "implementing.agency.name" | translate }}:<br />{{ implementingAgencyDto.name }}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.gst.number.name" | translate }}:<br />{{ implementingAgencyDto.gstNo }}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.department.name" | translate }}:<br />{{ implementingAgencyDto.departmentDto.name }}
                                    </p>
                                </div>

                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.scheme.name" | translate }}:<br />{{ implementingAgencyDto.schemeDto.name }}
                                    </p>
                                </div>

                            </div>
                            <div class="row voucherfont">
                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.level.type.name" | translate }}: {{ levelMasterDto.name }}
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">

                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label req" for="workId" [ngClass]="fontService.labelClass">Work Id</label>
                            <input type="text" class="form-control" autocomplete="off" maxlength="15" oninput="this.value = this.value.replace(/[^A-Za-z0-9-_]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" [(ngModel)]="voucherGenerationDto.maintenanceTransactionDto.workId"
                                [disabled]="!isEditable" [ngClass]="{'is-invalid':ngSelectInvalid && !voucherGenerationDto.maintenanceTransactionDto.workId}" name="workId" [readonly]="voucherGenerationDto.maintenanceTransactionDto.id" id="workId" (change)="loadProjectData()"
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="voucherGenerationDto.maintenanceTransactionDto.workId" class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <p [ngClass]="fontService.regularBoldClass">
                                Fin Year:<br />{{finYearName?.name }}
                            </p>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <p [ngClass]="fontService.regularBoldClass">
                                Work Name:<br />{{voucherGenerationDto.maintenanceTransactionDto?.workName }}
                            </p>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <p [ngClass]="fontService.regularBoldClass">
                                AS Date:<br />{{voucherGenerationDto.maintenanceTransactionDto?.administrativeSanctionDate | date:'dd/MM/yyyy'}}
                            </p>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <p [ngClass]="fontService.regularBoldClass">
                                AS Amount:<br />{{voucherGenerationDto.maintenanceTransactionDto?.administrativeSanctionAmount | currency:'INR':'':'1.2-2':'en-IN'}}
                            </p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <p [ngClass]="fontService.regularBoldClass">
                                Construction Cost:<br />{{voucherGenerationDto.maintenanceTransactionDto?.constructionCost | currency:'INR':'':'1.2-2':'en-IN'}}
                            </p>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <p [ngClass]="fontService.regularBoldClass">
                                Maintenance Amount:<br />{{voucherGenerationDto.maintenanceTransactionDto?.totalMaintenanceAmount | currency:'INR':'':'1.2-2':'en-IN'}}
                            </p>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <p [ngClass]="fontService.regularBoldClass">
                                Final Payment on:<br />{{voucherGenerationDto.maintenanceTransactionDto?.finalPaymentDate | date:'dd/MM/yyyy'}}
                            </p>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <p [ngClass]="fontService.regularBoldClass">
                                Work Physical Completion Date:<br />{{voucherGenerationDto.maintenanceTransactionDto?.workCompletionDate | date:'dd/MM/yyyy'}}
                            </p>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="voucherGenerationDto.maintenanceTransactionDto.workId" class="row g-0">
            <div class="col-lg-5">
                <div class="card p-3 invoice-card">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.vendor.name">Vendor Name</label>
                            <ng-select id="scheme" [disabled]="readonly || !isEditable|| loadingMore" [(ngModel)]="selectedVendor" [items]="vendorList" bindLabel="name" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="false" class="form-control dropdown-width"
                                [ngClass]="{'is-invalid': ngSelectInvalid && !selectedVendor.name}">
                            </ng-select>
                        </div>
                        <div class="col-md-6">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">Proceedings Number
                            </label>
                            <input type="text" [disabled]="!isEditable" class="form-control" autocomplete="off" oninput="this.value = this.value.replace(/^[^0-9]+|[^0-9-\/]+/g, '').replace(/(\..*)\./g, '$1');" maxlength="15" [(ngModel)]="
                                voucherGenerationDto.maintenanceTransactionDto.proceedingNumber" [ngClass]="{
                                'is-invalid':
                                ngSelectInvalid &&
                                !voucherGenerationDto.maintenanceTransactionDto.proceedingNumber}" name="name" [readonly]="readonly" id="levelType" />
                        </div>

                    </div>
                    <div class="row mt-3">
                        <div class="col-md-3">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{ "payments.vendor.id" | translate }}:<br />{{ selectedVendor.id }}
                            </p>
                        </div>

                        <div class="col-md-3">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{ "payments.vendor.gst" | translate }}:<br />{{ selectedVendor?.gstNo }}
                            </p>
                        </div>
                        <div class="col-md-3">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{ "payments.vendor.pan" | translate }}:<br />{{ selectedVendor?.panNo }}
                            </p>
                        </div>
                        <div class="col-md-3">
                            <p [ngClass]="fontService.regularBoldClass">
                                Vendor Account Number:<br />{{ selectedVendor?.accountNumber }}
                            </p>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-6">
                            <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2 req" for="dateOfBirth" [ngClass]="fontService.labelClass">Proceedings Date</label><br />
                            <p-calendar [(ngModel)]="
                                voucherGenerationDto.maintenanceTransactionDto.proceedingDate
                            " [maxDate]="maxDate" [disabled]="readonly || !isEditable" styleClass="datepicker"></p-calendar>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-7">
                <div class="card p-3 invoice-card">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="schemeDescription" [ngClass]="fontService.labelClass">Description</label><br />
                                    <textarea id="w3review" [disabled]="!isEditable" [readonly]="readonly" name="w3review" rows="3" [(ngModel)]="
                                        voucherGenerationDto.maintenanceTransactionDto.description
                                      " [ngClass]="{
                                        'is-invalid':
                                          ngSelectInvalid &&
                                          !voucherGenerationDto.maintenanceTransactionDto.description
                                      }" cols="100" maxlength="150" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"></textarea>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">Proceedings Upload</label>
                                    <div>
                                        <small class="mb-2" *ngIf="!readonly" [ngClass]="fontService.regularClass">Note : Allowed file types  pdf and
                                            Maximum file size should be 1MB</small>
                                    </div>
                                    <p-fileUpload *ngIf="!readonly" accept="application/pdf" (onUpload)="onUpload($event,'proceedingUpload')" [customUpload]="true" [disabled]="isproceeding" (uploadHandler)="onUpload($event,'proceedingUpload')" auto="true" multiple="false" [showUploadButton]="false"
                                        [showCancelButton]="false" chooseLabel="{{'common.upload'|translate}}">
                                    </p-fileUpload>
                                    <div class="row mt-2" *ngIf="isproceeding">
                                        <div class="col">
                                            <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument('proceedingUpload')">{{voucherGenerationDto?.maintenanceTransactionDto?.proceedingUpload}}</a>
                                            <i class="pi pi-times add-margin" *ngIf="!readonly" (click)="removeDocument('proceedingUpload')"></i>
                                            <i class="pi pi-download add-margin" (click)="downloadDocument('proceedingUpload')"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">Maintenance Work Photo Upload</label>
                                    <div>
                                        <small class="mb-2" *ngIf="!readonly" [ngClass]="fontService.regularClass">Note : Allowed  pdf and
                                            Maximum file size should be 1MB</small>
                                    </div>
                                    <p-fileUpload *ngIf="!readonly" accept="application/pdf" (onUpload)="onUpload($event,'maintenanceUpload')" [customUpload]="true" [disabled]="isMaintenanceUpload" (uploadHandler)="onUpload($event,'maintenanceUpload')" auto="true" multiple="false" [showUploadButton]="false"
                                        [showCancelButton]="false" chooseLabel="{{'common.upload'|translate}}">
                                    </p-fileUpload>
                                    <div class="row mt-2" *ngIf="isMaintenanceUpload">
                                        <div class="col">
                                            <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument('maintenanceUpload')">{{voucherGenerationDto?.maintenanceTransactionDto?.maintenanceUpload}}</a>
                                            <i class="pi pi-times add-margin" *ngIf="!readonly" (click)="removeDocument('maintenanceUpload')"></i>
                                            <i class="pi pi-download add-margin" (click)="downloadDocument('maintenanceUpload')"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">Performance Evaluation Report Upload</label>
                                    <div>
                                        <small class="mb-2" *ngIf="!readonly" [ngClass]="fontService.regularClass">Note : pdf and
                                            Maximum file size should be 1MB</small>
                                    </div>
                                    <p-fileUpload *ngIf="!readonly" accept="application/pdf" (onUpload)="onUpload($event,'reportUpload')" [customUpload]="true" [disabled]="reportUpload" (uploadHandler)="onUpload($event,'reportUpload')" auto="true" multiple="false" [showUploadButton]="false"
                                        [showCancelButton]="false" chooseLabel="{{'common.upload'|translate}}">
                                    </p-fileUpload>
                                    <div class="row mt-2" *ngIf="reportUpload">
                                        <div class="col">
                                            <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument('reportUpload')">{{voucherGenerationDto?.maintenanceTransactionDto?.reportUpload}}</a>
                                            <i class="pi pi-times add-margin" *ngIf="!readonly" (click)="removeDocument('reportUpload')"></i>
                                            <i class="pi pi-download add-margin" (click)="downloadDocument('reportUpload')"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="voucherGenerationDto.maintenanceTransactionDto.workId" class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2>Eligible Maintenance Payment</h2>
                            <p-table [value]="[{}]">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>S.No</th>
                                        <th>Bill Year</th>
                                        <th>{{this.voucherGenerationDto.maintenanceTransactionDescDto?.billYear}} - {{this.voucherGenerationDto.maintenanceTransactionDescDto?.billPart}}</th>
                                        <th>Gst 18%</th>
                                        <th>LWF 1%</th>
                                        <th>Gross Total</th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            {{voucherGenerationDto.maintenanceTransactionDescDto?.billYear}}
                                        </td>
                                        <td>
                                            {{voucherGenerationDto.maintenanceTransactionDto.maintenanceCost | currency:'INR':'':'1.2-2':'en-IN'}}
                                        </td>
                                        <td>
                                            {{voucherGenerationDto.maintenanceTransactionDto.maintenanceGst | currency:'INR':'':'1.2-2':'en-IN'}}
                                        </td>
                                        <td>
                                            {{voucherGenerationDto.maintenanceTransactionDto.maintenanceLwf | currency:'INR':'':'1.2-2':'en-IN'}}
                                        </td>
                                        <td>
                                            {{voucherGenerationDto.maintenanceTransactionDto.maintenanceGrossTotal | currency:'INR':'':'1.2-2':'en-IN'}}
                                        </td>


                                    </tr>

                                </ng-template>
                            </p-table>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <p-table [value]="[{}]">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Maintenance Score <br>{{this.voucherGenerationDto.maintenanceTransactionDescDto.billYear}} - {{this.voucherGenerationDto.maintenanceTransactionDescDto.billPart}}</th>
                                        <th>Calculation</th>
                                        <th>Current Bill Value</th>
                                        <th>SGST 9%</th>
                                        <th>CGST 9%</th>
                                        <th>LWF 1%</th>
                                        <th>Gross Total</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr>
                                        <td>
                                            <p-inputNumber id="voucherGenerationDto.maintenanceTransactionDescDto.maintenanceScore" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="voucherGenerationDto.maintenanceTransactionDescDto.maintenanceScore" (ngModelChange)="calculatetotal()"
                                                [disabled]="readonly || !isEditable" [max]="100"></p-inputNumber>
                                        </td>
                                        <td *ngIf="voucherGenerationDto.maintenanceTransactionDescDto.maintenanceScore">
                                            {{voucherGenerationDto.maintenanceTransactionDto.maintenanceCost}}*({{voucherGenerationDto.maintenanceTransactionDescDto.maintenanceScore/100}})
                                        </td>
                                        <td *ngIf="voucherGenerationDto.maintenanceTransactionDescDto.maintenanceScore">
                                            {{voucherGenerationDto.maintenanceTransactionDescDto.currentBillValue | currency:'INR':'':'1.2-2':'en-IN'}}
                                        </td>
                                        <td *ngIf="voucherGenerationDto.maintenanceTransactionDescDto.maintenanceScore">
                                            {{voucherGenerationDto.maintenanceTransactionDescDto.sgst | currency:'INR':'':'1.2-2':'en-IN'}}
                                        </td>
                                        <td *ngIf="voucherGenerationDto.maintenanceTransactionDescDto.maintenanceScore">
                                            {{voucherGenerationDto.maintenanceTransactionDescDto.cgst | currency:'INR':'':'1.2-2':'en-IN'}}
                                        </td>
                                        <td *ngIf="voucherGenerationDto.maintenanceTransactionDescDto.maintenanceScore">
                                            {{voucherGenerationDto.maintenanceTransactionDescDto.lwf | currency:'INR':'':'1.2-2':'en-IN'}}
                                        </td>
                                        <td *ngIf="voucherGenerationDto.maintenanceTransactionDescDto.maintenanceScore">
                                            {{voucherGenerationDto.maintenanceTransactionDescDto.grossTotal | currency:'INR':'':'1.2-2':'en-IN'}}
                                        </td>


                                    </tr>

                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div *ngIf="selectedVendor.name" class="row mt-3 tds">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-sm-4">
                                    <label class="form-control-label" for="schemeDescription" [ngClass]="fontService.labelClass">{{
                                "payments.amount.select.IT.name" | translate }}</label><br />
                                    <p-radioButton [disabled]="readonly || !isEditable" name="option" [value]="true" [(ngModel)]="tdsApplicable" label="{{ 'payments.amount.yes' | translate }}"></p-radioButton>
                                    <p-radioButton [disabled]="readonly || !isEditable" name="option" [value]="false" [(ngModel)]="tdsApplicable
                              " label="{{ 'payments.amount.no' | translate }}" (onClick)="
                                voucherGenerationDto.maintenanceTransactionDescDto.itPercentage = 0;
                                voucherGenerationDto.maintenanceTransactionDescDto.it = 0;
                                calculateTotalAmount()
                              "></p-radioButton>
                                </div>

                                <div class="col-sm-3" *ngIf="tdsApplicable">
                                    <label class="form-control-label pt-xs-2" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.amount.IT%">IT%</label><br />
                                    <p-inputNumber class="full-width" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" id="field_price" [readonly]="readonly" [min]="0" [max]="28" [(ngModel)]="
                                voucherGenerationDto.maintenanceTransactionDescDto.itPercentage
                              " mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="2" (ngModelChange)="calculateTotalAmount()"></p-inputNumber>
                                </div>
                                <div class="col-sm-5" *ngIf="tdsApplicable">
                                    <label class="form-control-label pt-xs-2" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.amount.IT.amount.name">IT Amount</label><br>
                                    <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="
                              voucherGenerationDto.maintenanceTransactionDescDto.it
                              " name="name" id="levelType" [readonly]="true"></p-inputNumber>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4">
                                    <label class="form-control-label pt-xs-2" for="schemeDescription" [ngClass]="fontService.labelClass">{{ "payments.amount.select.gst.name" | translate }}</label><br />
                                    <p-radioButton name="option" [disabled]="readonly || !isEditable" [value]="true" [(ngModel)]="enableGstOnTds" label="{{ 'payments.amount.yes' | translate }}"></p-radioButton>
                                    <p-radioButton name="option" [disabled]="readonly || !isEditable" [value]="false" [(ngModel)]="enableGstOnTds" label="{{ 'payments.amount.no' | translate }}" (onClick)="voucherGenerationDto.maintenanceTransactionDescDto.tdsOnGstPercentage = 0;calculateTotalAmount()"></p-radioButton>
                                </div>
                                <div class="col-sm-3" *ngIf="enableGstOnTds">
                                    <label class="form-control-label pt-xs-2" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.amount.gstTds%">TDS on GST %</label><br />
                                    <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [min]="0" [max]="29" [(ngModel)]="
                                voucherGenerationDto.maintenanceTransactionDescDto.tdsOnGstPercentage
                              " mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="2" [readonly]="readonly" (ngModelChange)="calculateTotalAmount()" name="name" id="levelType"></p-inputNumber>
                                </div>
                                <div class="col-sm-5" *ngIf="enableGstOnTds">
                                    <label class="form-control-label pt-xs-2" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.amount.gst.amount.name">TDS on GST Amount</label><br>
                                    <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="
                                voucherGenerationDto.maintenanceTransactionDescDto.tdsOnGst
                              " name="name" id="levelType" [readonly]="true"></p-inputNumber>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4">
                                    <label class="form-control-label pt-xs-2" for="schemeDescription" [ngClass]="fontService.labelClass">{{
                                "payments.amount.select.labour.name" | translate
                                }}</label><br />
                                    <p-radioButton name="option" [disabled]="readonly || !isEditable" [value]="true" [(ngModel)]="enableLabourChess" label="{{ 'payments.amount.yes' | translate }}"></p-radioButton>
                                    <p-radioButton name="option" [disabled]="readonly || !isEditable" [value]="false" [(ngModel)]="enableLabourChess" label="{{ 'payments.amount.no' | translate }}" (onClick)="voucherGenerationDto.maintenanceTransactionDescDto.labourCessPercentage = 0;
                                    calculateTotalAmount()"></p-radioButton>
                                </div>

                                <div class="col-sm-3" *ngIf="enableLabourChess">
                                    <label class="form-control-label pt-xs-2" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.amount.labour.percentage.name"></label><br />
                                    <p-inputNumber class="full-width" [(ngModel)]="voucherGenerationDto.maintenanceTransactionDescDto.labourCessPercentage
                               
                              " mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="2" name="name" id="levelType" [max]="28" [disabled]="workPayment" [readonly]="readonly" (ngModelChange)="calculateTotalAmount()"></p-inputNumber>
                                </div>
                                <div class="col-sm-5" *ngIf="enableLabourChess">
                                    <label class="form-control-label pt-xs-2" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.amount.labour.amount.name"></label><br>
                                    <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="
                                voucherGenerationDto.maintenanceTransactionDescDto.labourCess" [readonly]="true" name="name" [disabled]="true" id="levelType"></p-inputNumber>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 pt-lg-0 pt-xs-2 pt-sm-2">
                            <label class="form-control-label req" for="schemeDescription" [ngClass]="fontService.labelClass" jhiTranslate="payments.remarks.name">Remarks</label><br />
                            <textarea id="w3review" name="w3review" rows="3" [disabled]="!isEditable" [(ngModel)]="voucherGenerationDto.maintenanceTransactionDescDto.remark" cols="100" [ngClass]="{
                            'is-invalid':
                              ngSelectInvalid &&
                              !voucherGenerationDto.maintenanceTransactionDescDto.remark
                          }" maxlength="150" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();">
                        </textarea>
                        </div>


                        <div class="col-lg-3 pt-lg-0 pt-xs-2 pt-sm-2">
                            <table class="snippet">
                                <tr *ngIf="
                            this.voucherGenerationDto.maintenanceTransactionDescDto.currentBillValue!== 0
                            ">
                                    <td [ngClass]="fontService.labelClass">
                                        Work Cost
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{ this.voucherGenerationDto.maintenanceTransactionDescDto.currentBillValue | currency:'INR':'':'1.2-2':'en-IN' || "Nill" }}
                                    </td>
                                </tr>
                                <tr *ngIf="
                            voucherGenerationDto.maintenanceTransactionDescDto.grossTotal!== 0
                            ">
                                    <td [ngClass]="fontService.labelClass">
                                        {{ "payments.amount.total.amount.name" | translate }}
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.maintenanceTransactionDescDto.grossTotal | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>
                                <tr *ngIf="
                                voucherGenerationDto.maintenanceTransactionDescDto.it !== 0 && tdsApplicable
                            ">
                                    <td [ngClass]="fontService.labelClass">IT</td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.maintenanceTransactionDescDto.it | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>
                                <tr *ngIf="
                            voucherGenerationDto.maintenanceTransactionDescDto.tdsOnGst !==
                              0 && enableGstOnTds
                            ">
                                    <td [ngClass]="fontService.labelClass">
                                        {{ "payments.amount.gst.amount.name" | translate }}
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.maintenanceTransactionDescDto.tdsOnGst | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>
                                <tr *ngIf="
                            voucherGenerationDto.maintenanceTransactionDescDto.labourCess !== 0 && enableLabourChess
                            ">
                                    <td [ngClass]="fontService.labelClass">
                                        {{ "payments.amount.labour.cess.name" | translate }}
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.maintenanceTransactionDescDto.labourCess | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>
                                <tr *ngIf="
                            totalDeduction !== 0 && totalDeduction
                            ">
                                    <td [ngClass]="fontService.labelClass">
                                        Total Deduction
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{ totalDeduction | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>

                                <tr *ngIf="
                                voucherGenerationDto.maintenanceTransactionDto.transactionAmount !== 0
                            ">
                                    <td [ngClass]="fontService.labelClass">
                                        {{ "payments.amount.transaction.name" | translate }}
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.maintenanceTransactionDto.transactionAmount | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>
                                <!--   <div class="row " *ngIf="!readonly">
                                              <div class="col-sm-3 generate mt-3">
                                              
                                              </div>
                                                  <button pButton pRipple label="Cancel" class="p-button-danger"></button>
                                          </div> -->
                            </table>
                        </div>
                    </div>
                    <p-toolbar>
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <!-- <button *ngIf="enableBackButton" pButton class="back-button" styleClass="button" icon="pi pi-arrow-left" label="Back"
                                (click)="BackButtonClick()"></button> -->
                        </div>
                    </p-toolbar>
                    <p-toolbar *ngIf="selectedVendor.name && !enableRevertButton && isEditable && !enableView">
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <button *ngIf="!enableSignButton && !readonly && isEditable" pButton pRipple class="p-button-success custom-button margin" (click)="genVocher()" styleClass="button">
                            <span class="button-label" [ngClass]="fontService.buttonClass"
                              jhiTranslate="common.generatevoucher"></span>
                          </button>
                            <button *ngIf="enableSignButton && !voucherGenerationDto.maintenanceTransactionDto.signatureChecker2" pButton pRipple class="p-button-success custom-button margin" (click)="submitForm()" styleClass="button">
                            <span class="button-label" [ngClass]="fontService.buttonClass"
                                >{{signButtonName}}</span>
                        </button>
                        </div>
                    </p-toolbar>

                    <p-toolbar *ngIf="selectedVendor.name && enableRevertButton && isEditable && !enableView">
                        <div class="p-toolbar-group-start"></div>
                        <button *ngIf="enableSignButton && !voucherGenerationDto.maintenanceTransactionDto.signatureChecker2" pButton pRipple class="p-button-success custom-button margin" (click)="submitForm()" styleClass="button">
                          <span class="button-label" [ngClass]="fontService.buttonClass"
                              >{{signButtonName}}</span>
                      </button>
                        <div *ngIf="
                              (!enableSignButton &&(
                              !voucherGenerationDto?.maintenanceTransactionDto?.signStatus || !voucherGenerationDto.maintenanceTransactionDto.signatureChecker2) &&  !voucherGenerationDto.maintenanceTransactionDto.signatureChecker2)
                            " class="p-toolbar-group-end">

                            <button *ngIf="!voucherGenerationDto.maintenanceTransactionDto.signStatus  && enableApproved && isEditable" pButton pRipple (click)="genVocher()" class="p-button-success custom-button">
                              <span
                                class="button-label"
                                [ngClass]="fontService.buttonClass"
                                jhiTranslate="common.approvebutton"
                              ></span>
                            </button>
                            <button *ngIf="isEditable" pButton pRipple (click)="reasonDialog=true" class="p-button-danger custom-button">
                              <span
                                class="button-label"
                                [ngClass]="fontService.buttonClass"
                                jhiTranslate="common.rejectbutton"
                              ></span>
                            </button>
                        </div>


                    </p-toolbar>
                    <p-toolbar>
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <button *ngIf="voucherGenerationDto.maintenanceTransactionDto.signatureChecker2 && roleCode.role.code !== userRoles.roleStateApprover" pButton pRipple label="Download Pdf" (click)="getPdf()" class="p-button-success"></button>
                        </div>
                    </p-toolbar>
                </div>

            </div>
        </div>
    </div>



    <p-dialog [(visible)]="paymentSucessDialog" [style]="{ width: '500px', height: '225px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            Payment Alert Box
        </p-header>
        <h1 style="text-align: center;font-size: 18px;">{{ paymentSucessText }}</h1>
        <ng-template pTemplate="footer">
            <button pButton pRipple class="p-button-text custom-button" (click)="paymentSucessDialog = false">
            <span class="button-label" [ngClass]="fontService.buttonClass" label="Ok" (click)="redirect()">Ok</span>
          </button>
        </ng-template>
    </p-dialog>
    <p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <ng-template pTemplate="body">
            <div style='position: relative; height: 100%;'>
                <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
                <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
            </div>
        </ng-template>
    </p-dialog>
    <p-dialog [(visible)]="reasonDialog" [style]="{ width: '500px', height: '350px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            Reject Dialog:
        </p-header>
        <div class="form-group mb-4">
            <label class="form-control-label req" for="reason" [ngClass]="fontService.labelClass">Reject
            Reason</label><br />
            <textarea id="reason" name="reason" rows="3" [(ngModel)]="rejectionReason" cols="100" maxlength="150" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"></textarea>
            <div *ngIf="requiredError" class="mt-2">
                <small class="text-danger">please mention the reason for reject</small>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple class="p-button-danger">
            <span class="button-label" [ngClass]="fontService.buttonClass" label="Reject"
              (click)="rejectDialog()">Reject</span>
          </button>
        </ng-template>

    </p-dialog>
</div>