import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { MaintenanceModuleSaveDto } from './maintenance.model';
import { LocalStorageService } from 'ngx-webstorage';


@Injectable({ providedIn: 'root' })
export class MaintenanceService {
    protected resourceSaveUrl =
        environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/saveMaintenanceModule';
    protected resourceFilterUrl =
        environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getAllMaintenanceDetails';
    protected resourceEditUrl =
        environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getMaintenanceModuleWithMaintenanceDescById';
    protected approveMaintenanceModuleDetailsUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/approveMaintenanceModuleDetails';
    protected rejectMaintenanceModuleDetailsUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/rejectMaintenanceModuleDetails';
    protected getMaintenaceModuleListByIdUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getMaintenaceModuleListById';



    constructor(protected http: HttpClient,private localStorageService:LocalStorageService) { }
    save(payload?: MaintenanceModuleSaveDto) {
        return this.http.post(this.resourceSaveUrl, payload, {
            observe: 'response',
        });
    }
    getMaintenaceModuleListById(workId: string): Observable<HttpResponse<any>> {
        return this.http.get<any>(`${this.getMaintenaceModuleListByIdUrl}?workId=${workId}`, {
            observe: 'response',
        });
    }



    getMaintenanceModule(id: number): Observable<HttpResponse<MaintenanceModuleSaveDto>> {
        return this.http.get<MaintenanceModuleSaveDto>(`${this.resourceEditUrl}?id=${id}`, {
            observe: 'response',
        });
    }

    filter(req?: any, filter?: any): Observable<any> {
        if (filter === null) {
            filter = []
        }
        const options = createRequestOption(req);
        return this.http
            .post(this.resourceFilterUrl, filter, { params: options, observe: 'response' })
    }

    approve(req): Observable<any> {
        const options = createRequestOption(req);
        return this.http.post(this.approveMaintenanceModuleDetailsUrl,[],{ params: options, responseType: 'text' });
    }
    reject(req): Observable<any> {
        const options = createRequestOption(req);
        return this.http.post(this.rejectMaintenanceModuleDetailsUrl,[],{ params: options, responseType: 'text' });
    }
}
