import { NotificationService } from 'src/app/alert/notification.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SigninService } from './signin.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Captcha, Login } from './login.model';
import { HttpResponse } from '@angular/common/http';
import { NavbarService } from 'src/app/layout/navbar/navbar.service';
import { UserContext, UserRoleService } from 'src/app/shared/service/user-role.service';
import { Subscription } from 'rxjs';
import { filterQuery } from 'src/app/shared/common.model';
import { Profile } from 'src/app/module/profile/profile';
import { ProfileService } from 'src/app/module/profile/profie.service';
import { EncryptService } from 'src/app/shared/encrypt-service.service';
import { userRoles } from 'src/app/shared/models/user-role-code';

@Component({
  selector: 'ib-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit, AfterViewInit {
  @ViewChild('username', { static: false })
  username!: ElementRef;

  showOtp = false;
  inValidCaptcha = false;
  inValidUser:boolean=false;
  publicKey:string;
  loginForm = this.fb.group({
    username: [null, [Validators.required]],
    password: [null, [Validators.required]],
    captcha: [null, [Validators.required]],
    rememberMe: [false],
  });

  otpForm = this.fb.group({
    otp: [null, [Validators.required]],
  });
  image: any;
  captchaText: any;
  otpTimer: number;
  timer: any;
  enableResendButton: boolean;
  disableButton: boolean = false;
  otpError: string;
  passwordReset:string;
  profileStatus:string;
  uname:string;
  pwd:string;
  hide:boolean=true;
  captchaDetails:Captcha;
  captchaExpireError:string;
  spinner:boolean=false;
  reSendCount:number=1;
  userContextSubscription: Subscription;
  userContext: UserContext;
  userDetail: any;
  filterProps: any;
  mobileNo:string;
  enableSigninButton: boolean=false;
  credentials: Login;
  otpId: number;
  btnSpinner:boolean;
  roleCode: string;
  resetDate: any;

  constructor(
    private loginService: SigninService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private navbarService:NavbarService,
    protected notificationService: NotificationService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private encryptService: EncryptService,
    private userRoleService:UserRoleService,
    private ProfileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.localStorageService.clear();
    this.sessionStorageService.clear();
    this.loadCaptcha();
    // if already authenticated then navigate to home page
  }
  loadCaptcha(){
    this.spinner=true;
    this.loginForm.controls['captcha'].setValue('');
    this.loginService.getCapthcaImage().subscribe(
      (res: any) => {
        this.spinner=false;
        this.captchaDetails=JSON.parse(res);
        let objectURL = 'data:image/jpeg;base64,' + this.captchaDetails.captchaImage;
        this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      },
      (onError) => {
        this.spinner=false;
      }
    );
  }
  startTimer() {
    clearInterval(this.timer);
    this.otpTimer = 60; 
    this.timer = setInterval(() => {
      this.otpTimer--; 

      if (this.otpTimer === 0) {
        this.stopTimer();
      }
    }, 1000);
  }

  stopTimer() {
    if(this.reSendCount < 6){
      this.enableResendButton = true;
      this.reSendCount =this.reSendCount +1;
    }
    else{
      this.logout();
      this.showOtp = false;
      this.loginForm.reset();
      this.otpError=null;
      this.reSendCount = 1;
      this.otpForm.controls['otp'].setValue('');
    }
    clearInterval(this.timer);
  }

  ngAfterViewInit(): void {
    // this.username.nativeElement.focus();
  }
  navToForgotPassword() {
    if(this.loginForm.get('username').value)
    {
      this.router.navigate(['/ForgotPassword'], {
        queryParams: { username: btoa(this.loginForm.get('username')!.value) },
      });
    }
    else{
      this.router.navigate(['/ForgotPassword']);
    }
  }
  navToResetPassword() {
    if(this.loginForm.get('username').value)
    {
      this.router.navigate(['/ResetPassword'], {
        queryParams: { username: btoa(this.loginForm.get('username')!.value) },
      });
    }
    else{
      this.router.navigate(['/ResetPassword']);
    }
  }

  validateOtp(otpValue: string) {
    if (otpValue.length === 6) {
        this.enableSigninButton=true;
    }
    else{
      this.enableSigninButton=false;
    }
}

  loginOtp(): void {
    if (!this.otpForm.get('otp')!.value) {
      this.otpError = 'Invalid Otp';
      return;
    }
    const payload = {"otp":this.otpForm.get('otp')!.value,"id":this.otpId};
    this.loginService.otpvalidate(payload).subscribe(
      (res) => {
        this.localStorageService.store('auth', true);
        const currentDate = new Date();
        const resetDay = new Date(currentDate);
        resetDay.setDate(currentDate.getDate() - 90);
        const date = new Date(this.resetDate);
        console.log('logggd',resetDay);
        console.log('date',date);
        if(this.passwordReset=="Yes" || date < resetDay){
          this.navToResetPassword();
        }
            else{
              if(this.roleCode !== userRoles.roleStateMaker && this.roleCode !== userRoles.roleStateChecker && this.roleCode !== userRoles.roleStateApprover && this.roleCode !== userRoles.roleStateMonitor) {
                this.router.navigateByUrl('dashBoard-Report');
              }
              else {
                this.router.navigateByUrl('district-dashboard');
              }
             
                      
            }
          },
      (onError) => {
        this.otpError = 'Invalid Otp';
        this.localStorageService.store('auth', false);
      }
    );
  }
   login(): void {
    this.inValidCaptcha = false;
    this.inValidUser=false;
    this.captchaExpireError=null;
    if(!this.loginForm.get('username')!.value || !this.loginForm.get('password')!.value){
      this.inValidUser=true;
      return;
    }
    if (!this.loginForm.get('captcha')!.value) {
      this.inValidCaptcha = true;
      return;
    }
    if(this.captchaDetails)
    {
    this.btnSpinner=true;
  this.credentials={
            userName:this.loginForm.get('username')!.value,
            password:this.loginForm.get('password')!.value,
            rememberMe: true,
            captchId: this.captchaDetails.captchaId,
            captchaText: this.loginForm.get('captcha')!.value,
          }
          let payload={credentials:this.encryptService.encryptData(JSON.stringify(this.credentials))}
            this.loginService
            .login(payload,this.credentials)
        .subscribe(
          (res) => {
            this.btnSpinner=false;
            this.passwordReset=res.resetPassword;
            this.profileStatus=res.profileActive;
            this.roleCode = res.userRoleCode;
            this.otpId= res.otpId;
            this.resetDate = res?.passowrdResetDate;
            this.userRoleService.fetchAndSetuserContext();
          if(this.profileStatus=='Yes' && this.passwordReset=="No" && this.roleCode!= 'DM' && this.roleCode!='BM' && this.roleCode!='SM'){
            this.mobileNo='******'+res.userMobileNumber;
            this.localStorageService.store('auth', false);
            this.startTimer();
            this.showOtp=true;
            }
		  else if(this.profileStatus=='Yes' && this.passwordReset=="Yes" ){
            this.btnSpinner=false;
            this.showOtp=false;
            this.localStorageService.store('auth', true);
            this.navToResetPassword();
          }
          else {
            this.localStorageService.store('auth', true);
            this.btnSpinner=false;
          if(this.roleCode=='SA'){
                this.router.navigateByUrl('state-dashboard');
            } else if(this.roleCode!='SA'){
                this.router.navigateByUrl('district-dashboard');
            }

          }
              },
              (onError) => {
              this.btnSpinner=false;
              console.log('error',onError);
                if(onError.error.errorCode==="CAPTCHA_EXPIRED"){
                  this.captchaExpireError=onError.error.errorMessage;
                }
                else if(onError.error.errorCode==="CAPTCHA_INVALID"){
                this.inValidCaptcha = true;
                }
                else if(onError.error.errorCode==="UNAUTHORIZED_USER"){
                  this.inValidUser = true;
                  this.loginForm.reset()
                }
                else if(onError.status == 400) {
                  this.notificationService.alertError(onError.error.errorMessage,'');
                  this.inValidUser = true;
                }
              }
            );  
    }
  }

  getUserOtp(resend?) {
    this.loginService.getOtp({ isReinitiate: false }).subscribe(
      (res) => {
        this.btnSpinner=false;
        this.otpId=res;
        if(resend){
          this.enableResendButton = false;
          this.startTimer();
        }
        else{
        this.showOtp = true;
        }
      },
      (onError) => {
        this.btnSpinner=false;
        if (onError.status == 403){
        this.notificationService.alertError('Error! '+onError.error,'');
        }
      }
    );
  }


  navToLogin() {
    this.logout();
    this.showOtp=false;
    this.otpForm.reset();
    this.otpError=null;
    this.loginForm.reset();
  }
  logout(): void {
    if (this.userContextSubscription) {
      this.userContextSubscription.unsubscribe();
    }
        this.loginService.stopRefreshTokenTimer();
    this.navbarService.logout().subscribe(
      (res: HttpResponse<any>) => {
        this.userRoleService.clearuserContext();
        this.localStorageService.clear();
    this.sessionStorageService.clear();
      },
      (err) => {}
    );
  }
  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.userContextSubscription) {
      this.userContextSubscription.unsubscribe();
    }
  }

  

  //   async encrypt(value: string){
  //     // const publicKeyText = await this.loginService.loadPemFile("/assets/public_key.pem");
  //     // const publicKey = forge.pki.publicKeyFromPem(publicKeyText);
  //     // const encryptedValue = publicKey.encrypt("hello", 'RSA-OAEP');
  //     // const encryptedValueBase64 = forge.util.encode64(encryptedValue);

  //     const encryptRsa = new EncryptRsa();
  // const publicKeyText = await this.loginService.loadPemFile('/assets/public_key.pem');
  // const encryptedText = encryptRsa.encryptStringWithRsaPublicKey({
  //   text: 'hello world',
  //   publicKey,
  // });

  //   }

  // async loadPemFile() {
  //   const pemContent = await this.loginService.loadPemFile("/assets/mypublickey.der");
  //   const data = 'my secret data';
  //   const ciphertext = CryptoJS.AES.encrypt('my secret data', pemContent, {
  //     mode: CryptoJS.mode.ECB,
  //     padding: CryptoJS.pad.Pkcs7
  //   }).toString();
  //   // const encrypted = CryptoJS.AES.encrypt(data, pemContent);
  //   // const rsa = Forge.pki.publicKeyFromPem(pemContent);
  //   // const publicKey = window.btoa(rsa.encrypt('stateadmin1'));
  // }
  //   encryptData(data) {
  //     this.loadPemFile()

  //     // const plaintext = 'This is my plaintext data';
  //     // const paddedPlaintext = pad.pkcs7.encrypt(enc.Utf8.parse(plaintext), {
  //     //   blockSize: 16,
  //     // });
  //     // const encrypted = AES.encrypt(paddedPlaintext, environment.encryption_Key, {
  //     //   iv: iv,
  //     // });
  //     // const ciphertext = encrypted.ciphertext.toString(enc.Base64);
  //   }
  // encrypt(text: string){

  //   const message = 'Hello, world!';
  //   const key = 'secret key 123';
  //   const encryptedMessage = CryptoJS.AES.encrypt(message, environment.encryption_Key).toString();

  // }
}
