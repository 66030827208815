import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Login } from './login.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

import { createRequestOption } from '../request/request-util';
import { Router } from '@angular/router';

type JwtToken = {
  passowrdResetDate: any;
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  profileActive: string;
  resetPassword: string;
  userRoleCode: string;
  userMobileNumber: string;
  otpId: number;
};
@Injectable({
  providedIn: 'root',
})
export class SigninService {
  private refreshTokenTimeout;
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    protected router: Router
  ) {}
  protected resourceUrl = environment.SERVER_API_URL + 'api/mgsmt/authenticate';
  protected getUserDetails = environment.SERVER_API_URL + 'api/mgsmt/getAdminUserDto';
  // protected getCaptchaUrl = environment.SERVER_API_URL + 'api/getCaptcha';
  protected getCaptchaUrl = environment.SERVER_API_URL + 'api/mgsmt/generateCaptcha';
  protected validateCapchaUrl = environment.SERVER_API_URL + 'api/mgsmt/verifyCaptcha';
  protected getOtpUrl = environment.SERVER_API_URL + 'api/mgsmt/otp'
  protected getOtpValidUrl = environment.SERVER_API_URL + 'api/mgsmt/validateOtp';
  protected logOutUrl = environment.SERVER_API_URL + 'api/mgsmt/logOut';
  protected refreshTokenUrl = environment.SERVER_API_URL + 'api/mgsmt/refreshToken';
  login(req, credentials: Login): Observable<JwtToken> {
    const options = createRequestOption(req);
    return this.http
      .get<JwtToken>(this.resourceUrl, { params: options })
      .pipe(map(response => this.authenticateSuccess(response, credentials.rememberMe)));
  }

  getCapthcaImage(): any {
    return this.http.get(this.getCaptchaUrl, { responseType: 'text' });
  }

  getOtp(param?): any {
    const options = createRequestOption(param);
    return this.http.get(this.getOtpUrl, { params: options ,responseType: 'text' });
  }

  Capthcavalidate(capthca: string, id: number): any {
    return this.http.get(`${this.validateCapchaUrl}?captchaText=${capthca}&id=${id}`, { responseType: 'text' });
  }

  otpvalidate(parms): any {
    const options = createRequestOption(parms);
    return this.http.get(this.getOtpValidUrl, { params: options,responseType: 'text' });
  }

  getuserDto(): any {
    return this.http.get(this.getUserDetails, { observe: 'response' });
  }

  public loadPemFile(filePath: string): Promise<string> {
    return this.http.get(filePath, { responseType: 'text' }).toPromise();
  }

  private authenticateSuccess(response: JwtToken, rememberMe: boolean, refresh = false) {
    const jwt = response.access_token;
    const idToken = response.id_token;
    const refreshToken = response.refresh_token;

    /** TBD temporary fix */
    //  this.localStorageService.clear('authenticationToken');
    //  this.sessionStorageService.clear('authenticationToken');

    //  this.localStorageService.clear('refreshToken');
    //   this.sessionStorageService.clear('refreshToken');
    /** TBD temporary fix */

    if (rememberMe) {
      this.localStorageService.store('authenticationToken', jwt);
      this.sessionStorageService.clear('authenticationToken');
      this.localStorageService.store('refreshToken', refreshToken);
      this.sessionStorageService.clear('refreshToken');
    } else {
      this.sessionStorageService.store('authenticationToken', jwt);
      this.localStorageService.clear('authenticationToken');
      this.sessionStorageService.store('refreshToken', refreshToken);
      this.localStorageService.clear('refreshToken');
    }
    this.timeOutFunction(response);
    return response;
  }

  refreshToken(): Observable<any> {
    let data = {
      refreshToken: this.localStorageService.retrieve('refreshToken') ?? this.sessionStorageService.retrieve('refreshToken'),
    };
    return this.http.post<JwtToken>(this.refreshTokenUrl, data).pipe(map(response => this.authenticateSuccess(response, true, true)));
  }
  timeOutFunction(token: JwtToken): void {
    this.refreshTokenTimeout = setTimeout(() => {
      this.refreshToken().subscribe();
    }, token.expires_in * 1000);
  }
  stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
