import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountStatementService {

  constructor( protected http: HttpClient,private localStorageService:LocalStorageService ) { }
 protected accountStatementUrl= environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/accountStatement'
 protected accountStatementtableUrl= environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getStatement'
 protected downloadaccountstatementUrl=  environment.SERVER_API_URL +  'api/'+ this.localStorageService.retrieve('scheme') + '/downloadstatementPdf'
 protected downloadaccountstatementexcelUrl=  environment.SERVER_API_URL +  'api/'+ this.localStorageService.retrieve('scheme') + '/downloadstatementExcel'
 protected checkBankaacountStatusUrl=  environment.SERVER_API_URL +  'api/'+ this.localStorageService.retrieve('scheme') + '/checkBankStatementStatus'
 protected accountBalanceUrl=  environment.SERVER_API_URL +  'api/'+ this.localStorageService.retrieve('scheme') + '/balanceEnquiry'
 protected getLevelMasterUrl= environment.SERVER_API_URL +'api/'+ this.localStorageService.retrieve('scheme') + '/getLevelMaster'
 

 getAccountStatement(levelMasterId){
  return this.http.get(`${this.accountStatementUrl}`, { observe: 'response' })
 }
 getAccountStatementTable(data):Observable<HttpResponse<any>>{
  if(data.fromDate){
    console.log(data,"fr")
    return this.http.get<any>(`${this.accountStatementtableUrl}?fromDate=${data.fromDate}&toDate=${data.toDate}&page=${data.page}&size=${data.size}`, { observe: 'response' })
  }else{
    console.log(data)
    return this.http.get<any>(`${this.accountStatementtableUrl}?page=${data.page}&size=${data.size}`, { observe: 'response' })
  }
 
 }
 downloadaccountStatement(data){

return this.http.get(`${this.downloadaccountstatementUrl}?fromDate=${data.fromDate}&toDate=${data.toDate}`,{ observe: 'response',responseType: 'arraybuffer' })

}
 downloadaccountStatementExcel(data){
  const httpOptions = {
    Accept : 'application/pdf',
    responseType: 'blob' as 'json'
  };
  return this.http.get<any>(`${this.downloadaccountstatementexcelUrl}?fromDate=${data.fromDate}&toDate=${data.toDate}`, httpOptions)
   }
   getStatus(levelMasterId){
      return this.http.get(`${this.checkBankaacountStatusUrl}`,{ observe: 'response', })
   }
   getAccountBalance(levelMasterId){
    return this.http.get<any>(`${this.accountBalanceUrl}`,{observe:'response'})
   }
   getLevelMasterById(data){
    return this.http.get<any>(`${this.getLevelMasterUrl}?id=${data}`,{observe:'response'})
   }
}
