import { Component, OnInit } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { TableColumn } from 'src/app/shared/models/table-column';
import { StatutoryAccountDetail, StatutoryDeductionType } from './statutory-account-model';
import { SearchResult } from 'src/app/shared/models/search-result';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { filterQuery } from 'src/app/shared/common.model';
import { StatutoryAccountService } from './statutory-account.service';
import { environment } from 'src/environments/environment';
import { CommercialVendorService } from '../commercial-vendors/commercial-vendors.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { ValidationResponse } from '../commercial-vendors/commercial-vendors';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { Subscription } from 'rxjs';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { LevelMaster } from '../level-master/level-master';

@Component({
  selector: 'app-statutory-account-config',
  templateUrl: './statutory-account-config.component.html',
  styleUrls: ['./statutory-account-config.component.scss']
})
export class StatutoryAccountConfigComponent implements OnInit {
  configureDialog: boolean;
  cols: any;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  searchResult: SearchResult<StatutoryAccountDetail> =
    new SearchResult<StatutoryAccountDetail>();
  showGstError: string;
  showaccountNoError: string;
  bankList: any;
  bankBranchList: any = [];
  viewOnly: boolean;
  allowEdit: boolean = false;
  enableApprove: boolean;
  isChecker: boolean;
  approveView: boolean;
  statusButton: boolean = false;
  currentEnvironment: string;
  bankName: any;
  accountInvalidError: any;
  public verifyClicked: boolean;
  ngSelectInvalid: boolean = false;
  readonly: boolean;
  validationRes: ValidationResponse;
  stautoryAccount: StatutoryAccountDetail;
  bankBranch: any;
  public ifscError = false;
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public panError = false;
  showPanError: string;
  statutoryDeductionList: StatutoryDeductionType[];
  userContextSubscription: Subscription;
  levelMasterId: number;
  requiredError: boolean;
  reasonDialog: boolean;
  rejectionReason: string;
  mandateTan: boolean;
  mandateGst: boolean;

  constructor(public fontService: FontService,
    private spinner: NgxSpinnerService,
    public statutoryAccountService: StatutoryAccountService,
    public commercialVendorservice: CommercialVendorService,
    public validationService: ValidationService,
    public notificationService: NotificationService,
    public userRoleService: UserRoleService) { }

  ngOnInit(): void {
    this.currentEnvironment = environment.environment;
    this.cols = [
      {
        field: 'deductionType',
        header: 'Deduction Type',
        width: 13,
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },
      {
        field: 'panNumber',
        header: 'PAN No',
        jhiTranslate: 'tableHead.panno',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'gstNumber',
        header: 'GST No',
        jhiTranslate: 'tableHead.gstNo',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'bankName',
        header: 'Bank Name',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'bankBranchName',
        header: 'Branch',
        jhiTranslate: 'tableHead.branch.name',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'bankAccountNumber',
        header: 'Account No',
        jhiTranslate: 'tableHead.account.number',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'status',
        header: 'Status',
        isSortable: true,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'tinNumber',
        header: 'TIN Number',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
      },
      {
        field: 'tanNumber',
        header: 'TAN Number',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
      },
    ];
    this.userContextSubscription = this.userRoleService.getuserContext().subscribe(
      (res) => {
        this.levelMasterId = res.levelMaster.id;
        if (res.role.code == userRoles.roleState || res.role.code == userRoles.roleStateMaker || res.role.code == userRoles.roleMaker) {
          this.allowEdit = true;
        } else if (res.role.code == userRoles.roleChecker || res.role.code == userRoles.roleStateChecker) {
          this.enableApprove = true;
        }
        if (res.role.code == userRoles.roleChecker ||
          res.role.code == userRoles.roleApprover ||
          res.role.code == userRoles.roleStateChecker ||
          res.role.code == userRoles.roleStateApprover ||
          res.role.code == userRoles.roleDistrictMonitor ||
          res.role.code == userRoles.roleStateMonitor) {
          this.isChecker = true;
        }
      },
      () => {
        this.onError();
      })
    this.loadDeductionType();
    this.commercialVendorservice.getBankList().subscribe(
      (res: HttpResponse<[]>) => {
        this.bankList = res.body;
      },
      () => { }
    );
  }



  loadDeductionType() {
    let query = {
      page: 0,
      size: 10,
    };
    let filterProps = [];
    let filquery = new filterQuery();
    filquery.key = 'isMiscellaneous';
    filquery.operation = 'equals';
    filquery.value = false;
    filterProps.push(filquery);
    filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    filterProps.push(filquery);
    this.statutoryAccountService.getAllDeductionTypesByFilter(query, filterProps).subscribe(
      (res) => {
        this.statutoryDeductionList = res.body;
      },
      () => { }
    )
  }

  accountValidationStatus() {
    this.verifyClicked = true;
    this.spinner.show();
    this.commercialVendorservice
      .getaccountValidations({
        bankAccountNo: this.stautoryAccount.bankAccountNumber,
        bankIfsc: this.stautoryAccount.bankIfscCode
      })
      .subscribe(
        (res: any) => {
          this.readonly = true;
          this.spinner.hide();
          this.notificationService.alertSuccess('Account Verified Successfully', '');
          this.statusButton = false;
        },
        (error) => {
          this.accountInvalidError = "Please input valid account number and bank details";
          this.notificationService.alertError('Account Verification Failed', '');
          this.verifyClicked = false;
          this.readonly = false;
          // this.statusButton=true;
          this.spinner.hide()
        }
      );
  }

  getBankBranchList(event?) {
    this.bankBranch = null;
    let filter = [];
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.stautoryAccount.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };
    if (event) {
      filter.push({
        key: 'bankBranch',
        operation: 'startswith',
        value: event.term.toUpperCase(),
      });
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => { }
        );
    } else {
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => { }
        );
    }
  }

  getBankBranchdetais() {
    let filter = [];
    filter.push({
      key: 'bankIfsc',
      operation: 'equals',
      value: this.stautoryAccount.bankIfscCode.toUpperCase(),
    });
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.stautoryAccount.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };

    this.commercialVendorservice
      .getBankBranchList(filter, filterQuery)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.bankBranch = res.body[0];
        },
        () => { }
      );
  }

  accountValidation() {
    this.accountInvalidError = null;
    if (this.currentEnvironment == 'uat' || this.currentEnvironment == 'production') {
      if (!this.stautoryAccount.bankIfscCode || !this.stautoryAccount.bankAccountNumber) {
        this.accountInvalidError = "Please input valid account number and bank details";
        return;
      }
      this.verifyClicked = true;
      this.spinner.show()
      this.commercialVendorservice
        .getaccountValidation(
          this.stautoryAccount.bankAccountNumber,
          this.stautoryAccount.bankIfscCode
        )
        .subscribe(
          (res: any) => {
            this.validationRes = JSON.parse(res.body);
            if (this.validationRes.bankTxnStatus === true) {
              this.accountInvalidError = null;
              this.stautoryAccount.bankAccountName = this.validationRes.accountName;
              this.notificationService.alertSuccess(
                'Account Verfied Sucessfully', '');
              this.readonly = true;
            }
            else {
              this.accountInvalidError = 'Account Verification Failed due to ' + this.validationRes.errorDescription;
              this.notificationService.alertError(
                'Account Verification Failed due to ' + this.validationRes.errorDescription,
                ''
              );
              this.readonly = false;
              this.verifyClicked = false;
            }
            this.spinner.hide()

          },
          (error) => {
            error = JSON.parse(error);
            if (error.status === 400) {
              this.accountInvalidError =
                'Account Verification Failed. ' + error.error.excepMetaData;
              this.notificationService.alertError(
                'Account Verification Failed. ' + error.error.excepMetaData,
                ''
              );
            }
            else {
              this.accountInvalidError = "Please input valid account number and bank details";
              this.notificationService.alertError(
                'Account Verification Failed', '');
            }

            this.spinner.hide()
          }
        );
    }
    else {
      if (!this.stautoryAccount.bankIfscCode || !this.stautoryAccount.bankAccountNumber || !this.stautoryAccount.bankAccountName) {
        this.accountInvalidError = "Please input valid account number and bank details";
        return;
      }
      this.verifyClicked = true;
      this.readonly = true;

      // this.spinner.show();
      // this.commercialVendorservice
      //   .getaccountValidations({bankAccountNo: this.stautoryAccount.bankAccountNumber,
      //     bankIfsc: this.stautoryAccount.bankIfscCode})
      //   .subscribe(
      //     (res:any) => {
      //       this.readonly=true;
      //       this.spinner.hide();
      //       this.notificationService.alertSuccess('Account Verified Successfully','');
      //       this.statusButton=false;
      //     },
      //     (error) => {
      //       // this.accountInvalidError="Please input valid account number and branch details";
      //       // this.notificationService.alertError('Account Verification Failed','');
      //       // this.verifyClicked=false;
      //       // this.readonly=false;
      //       this.readonly=true;
      //       this.statusButton=true;
      //       this.spinner.hide()
      //     }
      //   );
    }
  }


  accountDetailsChanged() {
    this.verifyClicked = false;
    if (!this.ifscError) {
      this.spinner.show();
      this.commercialVendorservice.bankBranchDetails(this.stautoryAccount.bankIfscCode)
        .subscribe(
          (res) => {
            this.spinner.hide();
            this.stautoryAccount.bankBranchName = res.body.FetchBankDetails_Response.Body.Payload.Branch_Name;
            this.stautoryAccount.bankName = res.body.FetchBankDetails_Response.Body.Payload.Bank_Name;
          },
          () => {
            this.spinner.hide();
            this.notificationService.alertError('Error Occured', '');
          }
        );
    }

  }


  getBankIfsc() {
    this.stautoryAccount.bankBranchName = this.bankBranch.bankBranch;
    this.commercialVendorservice
      .getBankIfscCode(
        this.stautoryAccount.bankName,
        this.stautoryAccount.bankBranchName
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.stautoryAccount.bankIfscCode = res.body[0];
        },
        () => { }
      );
  }

  validatePan() {
    if (this.stautoryAccount.panNumber) {
      this.panError = !this.validationService.isPanValid(
        this.stautoryAccount.panNumber.toUpperCase()
      );
    }
    return !this.panError;
  }

  validateTan() {
    if (this.stautoryAccount.tanNumber) {
      this.tanError = !this.validationService.isTanValid(
        this.stautoryAccount.tanNumber
      );
    }
    return !this.tanError;
  }

  validateTin() {
    if (this.stautoryAccount.tinNumber) {
      this.tinError = !this.validationService.isTinValid(
        this.stautoryAccount.tinNumber
      );
    }
    return !this.tinError;
  }


  validateIfsc() {
    this.ifscError = !this.validationService.isIfscValid(
      this.stautoryAccount.bankIfscCode
    );
    !this.stautoryAccount.bankIfscCode ? (this.ifscError = false) : true;
    return !this.ifscError && !!this.stautoryAccount.bankIfscCode;
  }

  validateGst() {
    if (this.stautoryAccount.gstNumber) {
      this.gstError = !this.validationService.isGstValid(
        this.stautoryAccount.gstNumber
      );
    }
    return !this.gstError;
  }

  openNew() {
    this.configureDialog = true;
    this.readonly = false;
    this.stautoryAccount = new StatutoryAccountDetail();
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.filterProps = this.filterProps ?? [];
    this.filterProps = this.filterProps ? this.filterProps : [];
    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);
    filquery = new filterQuery();
    filquery.key = 'levelMaster.id';
    filquery.operation = 'equals';
    filquery.value = this.levelMasterId;
    this.filterProps.push(filquery);
    this.statutoryAccountService.getAllStatutoryDetailsfilter({ ...query }, this.filterProps)
      .subscribe(
        (res: HttpResponse<StatutoryAccountDetail[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
          this.onError();
        }
      );
  }
  protected onSuccess(
    data: StatutoryAccountDetail[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  hideDialog() {
    this.configureDialog = false;
    this.verifyClicked = false;
    this.panError = false;
    this.tanError = false;
    this.tinError = false;
    this.gstError = false;
    this.ifscError = false;
    this.ngSelectInvalid = false;
    this.stautoryAccount = new StatutoryAccountDetail();
    this.accountInvalidError = false;
    this.bankBranch = null;
    this.viewOnly = false;
    this.approveView = false;
    this.statusButton = false;
    this.mandateGst = false;
    this.mandateTan = false;
  }

  saveData() {
    this.ngSelectInvalid = false;
    const isFormValid =
      this.validatePan() &&
      this.validateTin() &&
      this.validateTan() &&
      this.validateGst() &&
      this.validateIfsc() &&
      this.stautoryAccount.deductionType &&
      this.stautoryAccount.bankAccountName &&
      this.stautoryAccount.bankAccountNumber &&
      this.stautoryAccount.bankBranchName &&
      this.stautoryAccount.bankIfscCode &&
      this.stautoryAccount.bankName &&
      !this.accountInvalidError &&
      this.verifyClicked

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      this.notificationService.alertError("Please fill all the mandotory fields marked with *", '');
      return;
    }

    if (this.stautoryAccount.deductionType.code == 'TDS' || this.stautoryAccount.deductionType.code == 'LWF') {
      if (!this.stautoryAccount.tanNumber) {
        this.ngSelectInvalid = true;
        this.notificationService.alertError("TAN Number is required for the chosen Deduction Type", '');
        return;
      }
    } else if (this.stautoryAccount.deductionType.code == 'TG') {
      if (!this.stautoryAccount.gstNumber) {
        this.ngSelectInvalid = true;
        this.notificationService.alertError("GST Number is required for the chosen Deduction Type", '');
        return;
      }
    }

    this.spinner.show();
    this.stautoryAccount.levelMaster = new LevelMaster();
    this.stautoryAccount.levelMaster.id = this.levelMasterId;
    this.statutoryAccountService.save(this.stautoryAccount).subscribe(
      (res) => {
        this.spinner.hide();
        this.notificationService.alertSuccess('Saved Successfully, you can use it after approval', '');
        this.hideDialog();
        this.loadPage(this.page);
      },
      (onError) => {
        this.spinner.hide();
        if (onError.status == 400) {
          this.notificationService.alertError(
            onError.error.title, '');
        } else {
          this.notificationService.alertError('Error!', '');
        }
      }
    );
  }

  onSelectRow($event) {
    if ($event.type === 'select' || $event.type === 'view' || $event.type === 'approve') {
      if ($event.type === 'view') {
        this.viewOnly = true;
      }
      if ($event.type === 'approve') {
        this.viewOnly = true;
        this.approveView = true;
      }
      this.statutoryAccountService
        .getStatutoryDetailById($event.value.id)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.readonly = true;
            this.stautoryAccount = res.body;
            this.configureDialog = true;
            this.verifyClicked = true;
            if (this.stautoryAccount.bankName) {
              this.getBankBranchList()
            }
            this.getBankBranchdetais()
          },
          () => {
            this.onError();
          }
        );
    }
    else {
      this.statutoryAccountService.reject($event.value.id, false).subscribe(
        (res: HttpResponse<any>) => {
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    }
  }

  approve() {
    this.statutoryAccountService.approve(this.stautoryAccount.id).subscribe(
      (res: HttpResponse<any>) => {
        this.loadPage(this.page);
        this.configureDialog = false;
      },
      (error) => {
        this.onError();
      }
    );
  }

  reject() {
    this.statutoryAccountService.reject(this.stautoryAccount.id, true, this.rejectionReason).subscribe(
      (res: HttpResponse<any>) => {
        this.loadPage(this.page);
        this.configureDialog = false;
      },
      () => {
        this.onError();
      }
    );
  }

  rejectDialog() {
    if (!this.rejectionReason) {
      this.requiredError = true;
      return;
    }
    else {
      this.reasonDialog = false;
      this.reject();
    }
  }

  onDeductionTypeChange() {
    if (this.stautoryAccount.deductionType.code == 'TDS' || this.stautoryAccount.deductionType.code == 'LWF') {
      this.mandateTan = true;
      this.mandateGst = false;
    } else if (this.stautoryAccount.deductionType.code == 'TG') {
      this.mandateGst = true;
      this.mandateTan = false;
    }
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.userContextSubscription) {
      this.userContextSubscription.unsubscribe();
    }
  }
}
