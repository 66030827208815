import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StatutoryTransaction, StatutoryVoucherGeneration } from '../statutory-payment-voucher/statutory-payment-voucher.model';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'ngx-webstorage';


export type EntityArrayResponseType = HttpResponse<StatutoryTransaction[]>;
@Injectable({
  providedIn: 'root'
})
export class StatutoryPaymentVoucherService {
  
  protected getStatutoryDeductionListUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getStatutoryDeductionListByDeductionId');
  protected generateStatutoryPaymentUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/voucher/generateStatutoryPayment');
  protected getAllStatutoryPaymentsByFilter = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getAllStatutoryPayments');
  protected getStatutoryPaymentWithStatutoryDescById = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getStatutoryPaymentWithStatutoryDescById');
  protected statutoryCdacRequestUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/statutoryCdacRequest');
  protected rejectStatutoryTransactionUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/rejectStatutoryTransaction')
  protected revertStatutoryTransactionUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/revertStatutoryTransaction') ;
  protected getMiscellaneousDeductionListByIdUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getMiscellaneousDeductionListById');

  constructor(protected http: HttpClient,private localStorageService:LocalStorageService) { }

  filter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
    if(filter === null){
      filter = []
    }
    const options = createRequestOption(req);
      return this.http
      .post<StatutoryTransaction[]>(this.getAllStatutoryPaymentsByFilter,filter, { params:options, observe: 'response' })
  }

  getStatutoryDeductionList(deductionCode,fromDate,toDate){
    return this.http.get<any>(`${this.getStatutoryDeductionListUrl}?deductionCode=${deductionCode}&fromDate=${fromDate}&toDate=${toDate}`, {observe: 'response' })
  }
  getMiscellaneousDeductionList(deductionCode,workId){
    return this.http.get<any>(`${this.getMiscellaneousDeductionListByIdUrl}?deductionCode=${deductionCode}&workId=${workId}`, {observe: 'response' })
  }

  generateVoucher(statutoryVoucherGeneration){
    return this.http.post(this.generateStatutoryPaymentUrl,statutoryVoucherGeneration, {observe: 'response'})
  }

  statutoryCdacRequest(fileName:string ,paymentType?): Observable<any> {
    if(paymentType) {
      return this.http.get(`${this.statutoryCdacRequestUrl}?fileName=${fileName}&paymentType=${paymentType}`, { observe: 'response' });
    }
    else { 
      return this.http.get(`${this.statutoryCdacRequestUrl}?fileName=${fileName}`, { observe: 'response' });
     }
    
  } 

  getStatutoryPayment(id:number){
    return this.http.get<StatutoryVoucherGeneration>(`${this.getStatutoryPaymentWithStatutoryDescById}?id=${id}`,{observe: 'response'})
  }

 

  revertTranaction(id: number) {
    return this.http.get<any>(`${this.revertStatutoryTransactionUrl}?id=${id}`, {
      observe: "response",
    });
  }

  rejectTranaction(id: number,rejectReason?:string) {
    if(rejectReason)
    return this.http.get(`${this.rejectStatutoryTransactionUrl}?id=${id}&rejectReason=${rejectReason}`, { observe: 'response' });
    else 
    return this.http.get(`${this.rejectStatutoryTransactionUrl}?id=${id}`, { observe: 'response' });
  }

  getBulkPayment(id:number,voucherType){
    return this.http.get<StatutoryVoucherGeneration>(`${this.getStatutoryPaymentWithStatutoryDescById}?id=${id}&voucherType=${voucherType}`,{observe: 'response'})
  }

}
