<div class="row">
    <div class="col-md-12">
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <h5>
                    Account Statement
                </h5>
            </ng-template>
        </p-toolbar>
    </div>
    <div class="btn_class">

        <button pButton pRipple class="p-button-secondary mx-2 custom-button new-button" [disabled]="fetchbtnDisable" (click)="getAccountStatementData()">Fetch Latest Statement</button>
        <button *ngIf="isTable" pButton pRipple class="download_btn mx-2 p-button-secondary custom-button new-button" (click)="opendownloadPdf()">Download PDF</button>

        <button *ngIf="isTable" pButton pRipple class="download_btn mx-2 p-button-secondary custom-button new-button" (click)="opendownloadExcel()">Download Excel</button>
    </div>
</div>
<div *ngIf="showStatus" class="container-fluid pb-3">
    <div class="status_card">
        <div class="row">
            <h1>{{this.status}}</h1>
        </div>

    </div>
</div>

<div *ngIf="isTable" class="flex_display mt-2">
    <div class="mx-2">
        <label class="req">From Date</label><br>
        <p-calendar id="fromDate" placeholder="{{'dashBoard.dropdown.fromDate'|translate}}" [(ngModel)]="fromDate" [showIcon]="true" dateFormat="dd/mm/yy" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
    </div>
    <div class="mx-2 ">
        <label class="req">To Date</label><br>
        <p-calendar id="toDate" placeholder="{{'dashBoard.dropdown.toDate'|translate}}" [(ngModel)]="toDate" [showIcon]="true" dateFormat="dd/mm/yy" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
    </div>

    <div class="btn_class">
        <button pButton pRipple class="search_btn mx-2 p-button-secondary custom-button new-button" (click)="filterData()">Search</button>
        <button pButton pRipple class="clear_btn mx-2 p-button-secondary custom-button new-button" (click)="clearTable()">Clear </button>
    </div>
</div>
<div *ngIf="isTable" class="row mt-2">
    <div>
        <p *ngIf="accountBalance" class="balance_field">The available balance in this account : {{accountBalance}}</p>
    </div>
    <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" [showRedirectButton]="false"></custom-filter-table>
</div>
<p-dialog #dialog [(visible)]="isDownlaodPdf" [modal]="true" styleClass="ib-dialog" [style]="{ width: '650px' ,height:'600px' }" (onHide)="hideDialog()">
    <p class="heading_font">Select the From Date and ToDate to download the pdf</p>
    <div class="flex_display">
        <div class="mx-2">
            <label class="req">From Date</label><br>
            <p-calendar id="fromDate" placeholder="{{'dashBoard.dropdown.fromDate'|translate}}" dateFormat="dd/mm/yy" [(ngModel)]="downloadfromDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
        </div>
        <div class="mx-4">
            <label class="req">To Date</label><br>
            <p-calendar id="toDate" placeholder="{{'dashBoard.dropdown.toDate'|translate}}" dateFormat="dd/mm/yy" [(ngModel)]="downloadtoDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="btn_part">
            <button pButton pRipple class="mx-2" (click)="hideDialog()">Cancel</button>
            <button pButton pRipple class="mx-2" (click)="downloadPdfData()">Proceed</button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog #dialog [(visible)]="isDownlaodExcel" [modal]="true" styleClass="ib-dialog" [style]="{ width: '650px' ,height:'600px' }" (onHide)="hideDialog()">
    <p class="heading_font">Select the From Date and ToDate to download the excel</p>
    <div class="flex_display">
        <div class="mx-2">
            <label class="req">From Date</label><br>
            <p-calendar id="fromDate" placeholder="{{'dashBoard.dropdown.fromDate'|translate}}" [(ngModel)]="downloadfromDate" [showIcon]="true" styleClass="datepicker" dateFormat="dd/mm/yy" [maxDate]="maxDate"></p-calendar>
        </div>
        <div class="mx-4">
            <label class="req">To Date</label><br>
            <p-calendar id="toDate" placeholder="{{'dashBoard.dropdown.toDate'|translate}}" [(ngModel)]="downloadtoDate" [showIcon]="true" styleClass="datepicker" dateFormat="dd/mm/yy" [maxDate]="maxDate"></p-calendar>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="btn_part">
            <button pButton pRipple class="mx-2" (click)="hideDialog()">Cancel</button>
            <button pButton pRipple class="mx-2" (click)="excelData()">Proceed</button>
        </div>
    </ng-template>

</p-dialog>