import { LocalStorageService } from 'ngx-webstorage';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { createRequestOption } from '../auth/request/request-util';

export type EntityListResponseType = HttpResponse<any>;

@Injectable({ providedIn: 'root' })
export class ReportService {
 
  protected reportAsyncDownloadUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/reportAsyncDownload');
  protected reportAsyncStatusCheckUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/reportAsyncStatusCheck');

  constructor(protected http: HttpClient,private localStorageService:LocalStorageService) {}
  
  getData(url,parms,payload): Observable<any> {
    if(payload === null){
        payload = []
      }
      const options = createRequestOption(parms);
        return this.http
        .get<any[]>(environment.SERVER_API_URL+ 'api/' +  this.localStorageService.retrieve('scheme') +'/'+url, { params:options, observe: 'response' })
  }
  getExcelDownload(data){
    return this.http.get(`${this.reportAsyncDownloadUrl}?reportName=${data}`,{observe:'response',responseType: 'text'})
  }
  reportAsyncStatusCheck(req:any){
    return this.http.get(`${this.reportAsyncStatusCheckUrl}?reportName=${req}`,{observe:'response'})

  }
 
}