import { CommercialVendorsDto } from "src/app/module/commercial-vendors/commercial-vendors";
import { LevelMaster } from "src/app/module/level-master/level-master";
import { FinYear } from "src/app/module/limit-translist/limit-translist.module";
import { StatutoryAccountDetail, StatutoryDeductionType } from "src/app/module/statutory-account-config/statutory-account-model";
import { Project } from "src/app/project/project.model";
import { ImplementingAgencyDto } from "../payment-voucher/payment-voucher.model";

export class StatutoryVoucherGeneration {
  statutoryTransactionDto: StatutoryTransaction;
  statutoryBillDescription: StatutoryBillDescription[];
  statutoryBulkTransaction: StatutoryBulkTransaction[];
}



export class StatutoryTransactionNew {
  levelMaster: ''
  voucherNumber: ''
  implementingAgency: ''
  remarks: ''
  voucherType: ''
}

// {
//   workId: ''
//   statutoryAccountDetail: ''
//   vendor: ''
//   paymentType: ''
//   miscellaneousType: ''
//   deductionType: ''
//   totalAmount: ''
//   transactionAmount: ''
//   paidAmount: ''
// }


export class StatutoryTransaction {
  id: number;
  transactionAmount: number;
  paidAmount: number;
  totalAmount: number;
  deductionMonth: string;
  deductionType: StatutoryDeductionType;
  // statutoryAccountDetail: StatutoryAccountDetail;
  implementingAgency: ImplementingAgencyDto;
  levelMaster: LevelMaster;
  finYear: FinYear;
  levelOneSign: string;
  levelTwoSign: string;
  transactionStatus: string;
  transactionDate: Date;
  voucherNumber: string;
  year: number;
  signCount: number;
  signMessage: string;
  signStatus: boolean;
  rejectReason: string;
  utrNo: string;
  remarks: string;
  journalNumber: string;
  activeFlag: string;
  isEditable: boolean;
  createdBy: number;
  updatedBy: number;
  createdOn: Date;
  updatedOn: Date;
  checkTransactionStatus: boolean;
  reInitialize: boolean;
  workId: string;
  paymentType: string;
  vendor: CommercialVendorsDto;
  miscellaneousType: string;
  voucherType: string;
  statutoryBulkTransaction: StatutoryBillDescription[];
}

export class StatutoryBillDescription {
  isDefault: boolean;
  id: number;
  statutoryTransactionId: number;
  statutoryDeductionType: StatutoryDeductionType;
  deductionAmount: number;
  deductionPercentage: number;
  voucherNumber: string;
  vendorName: string;
  vendorGstNumber: string;
  vendorPanNumber: string;
  activeFlag: string;
  workId: string;
  work: Project;
  createdBy: number;
  updatedBy: number;
  createdOn: Date;
  updatedOn: Date;
}
export class StatutoryDeductionDto {
  statutoryDeductionListDtos: StatutoryDeductionList;
  totalAmount: number;
}

export class StatutoryDeductionList {
  vendorName: string;
  vendorGstNumber: string;
  vendorPanNumber: string;
  voucherNumber: string;
  deductionPercentage: number;
  deductionAmount: number;
}

export class StatutoryBulkTransaction {
  id?: number;
  statutoryTransactionid?: number;
  transactionAmount?: number;
  deductionId?: number;
  deductionCode?: string;
  deductionName?: string;
  statutoryAccountDetailId?: number;
  statutoryAccountNumber?: string;
  statutoryBankName?: string;
  statutoryBankIfscCode?: string;
  statutoryBankBranch?: string;
  commercialVendorId?: number;
  transactionStatus?: string;
  voucherNumber?: string;
  workId?: string;
  paymentType?: string;
  utrNo?: string;
  journalNumber?: string;
  activeFlag?: string;
  totalAmount?: number;
  paidAmount?: number;
  miscellaneousType?: string;
  vendorName?: string;
  vendorGstNumber?: string;
  vendorPanNumber?: string;
  paymentDate?: Date;
  updatedOn?: Date;
  vendorBankName?: string;
  vendorBankBranch?: string;
  vendorBankAccNo?: string;
  vendorBankIfscCode?: string;
  isDefault: boolean;
  vendorBankAccountName?:string;
  statutoryBankAccountName?:string;
  deductionAmount: any;
}
