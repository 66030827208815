import { AdminUserDtoCreatedby } from './../limit-set/limit-set';
import { SchemeList } from './../scheme-list/scheme-list';
import { DepartmentList } from './../department-list/department-list';
export class CommercialVendors {
  name?: string;
  companyName?: string;
  panNo?: string;
  accountNo?: string;
  commercial?: commercial;
  designation?: string;
  department?: DepartmentList;
  scheme?: SchemeList;
  staffId?: string;
  aadhaar?: string;
  mobileNo?: number;
  email?: string;
  doorNo?: string;
  street?: string;
  landmark?: string;
  city?: string;
  district?: string;
  state?: string;
  pinCode?: string;
  bankName?: string;
  branchName?: string;
  bankAccountNo?: string;
  bankAccountName?: string;
  ifsc?: string;
  micr?: string;

  vendorType?: string;
  registerationNo?: string;
  gstNo?: string;
  tinNo?: string;
  tanNo?: string;
  agencyName?: string;
  schemeCode?: string;
  deptCode?: string;
  levelName?: string;
  levelMasterName?: string;
  bankId?: string;
  branchMobileNo?: string;
  branchEmail?: string;
  branchAddress?: string;
  approve:string;
  id: number;
}

export class Vendor {
  id: number;
  name: string;
  companyName: string;
  registrationNo: string;
  aadhaarNo: number;
  micr: string;
  panNo: string;
  gstNo: string;
  department: DepartmentList;
  scheme: SchemeList;
  mobile: string;
  email: string;
  commercial: commercial;
  tanNo: string;
  tinNo: string;
  state: string;
  district: string;
  city: string;
  doorNo: string;
  street: string;
  area: string;
  companyState: string;
  companyDistrict: string;
  companyCity: string;
  companyDoorNo: string;
  companyStreet: string;
  companyArea: string;
  companyPinCode: string;
  bankAccName: string;
  bankId: string;
  bankName: string;
  bankBranch: string;
  pinCode: string;
  ifscCode: string;
  accountNumber: string;
}
export class commercial {
  id: number;
  name: string;
}

export class CommercialVendorsDto {
  id: number;
  name: string;
  companyName: string;
  aadhaarNo: string;
  registrationNo: string;
  micr: any;
  panNo: string;
  gstNo: string;
  mobile: string;
  email: string;
  commercialDto: commercial;
  tanNo: any;
  tinNo: any;
  state: string;
  district: string;
  city: string;
  doorNo: string;
  street: string;
  area: any;
  pinCode: string;
  companyState: string;
  companyDistrict: string;
  companyCity: string;
  companyDoorNo: string;
  companyStreet: string;
  companyArea: any;
  companyPinCode: string;
  bankAccName: any;
  bankId: string;
  bankName: string;
  bankBranch: string;
  accountNumber: string;
  activeFlag: string;
  ifscCode: string;
  createdOn: string;
  updatedOn: string;
  adminUserDtoCreatedBy: number;
  adminUserDtoUpdatedBy: number;
  departmentDto: DepartmentList;
  schemeDto: SchemeList;
  check: any;
  approve : string;
  reject : string;
  status : string;
  displayApprove : boolean;
}

export class ValidationResponse{
  accountNo:string;
  accountName:string;
  bankTxnStatus:boolean;
  ifsc:string;
  bankResponse:string;
  errorDescription: string;
}
