import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { createRequestOption } from '../auth/request/request-util';
import { IALimitAllocationAndAuthorization, IALimitUtilizedFundDto, VoucherTranacstionDto, countReportModel, limitReport } from './drillDown.model';
import { LocalStorageService } from 'ngx-webstorage';

export type EntityListResponseType = HttpResponse<countReportModel[]>;
export type TranactionEntityListResponseType = HttpResponse<VoucherTranacstionDto[]>;
export type limitReportResponseType = HttpResponse<limitReport[]>;
export type IALimitAllocationAndAuthorizationResponseType = HttpResponse<IALimitAllocationAndAuthorization[]>;
export type IALimitUtilizedFundDtoResponseType = HttpResponse<IALimitUtilizedFundDto[]>;
@Injectable({ providedIn: 'root' })
export class DrillDownReportService {
  constructor(protected http: HttpClient,protected localStorageService:LocalStorageService) {}
  protected getDistrictPaymentCountUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/drillDown/getDistrictPaymentStatusCount';
  protected getSuccessfullTransactions = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/report/getSuccessfullTransactions';
  protected getFailedTranactions = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/report/getFailureTransactions';
  protected getRejectTransaction = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/report/getRejectTransactions';
  protected getVoucherStatusCount = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/drillDown/getDistrictSignPendingCount';
  protected getVouchersign1Pending = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/report/getVoucherSignFirstlevelPending';
  protected getVouchersign2Pending = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/report/getVoucherSignSecondLevelPending';
  protected getVouchersign3Pending = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/report/getVoucherSignThirdLevelPending';
  protected getVendorCount = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/drillDown/getVendorRegistrationStatusCount';
  protected getVendorRegistrationStatusReportUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/drillDown/getVendorRegistrationStatusReport';
  protected limitAmountUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/drillDown/getIaLimitReport';
  protected IALimitAllocationAndAuthorizationUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/drillDown/getIaAllocationAndAuthorizationFundReport';
  protected getIaUtilizedFundReportUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/drillDown/getIaUtilizedFundReport';

  getDistrictPaymentCount(req?: any): Observable<EntityListResponseType> {
    const options = createRequestOption(req);
    return this.http.get<countReportModel[]>(this.getDistrictPaymentCountUrl, { params: options, observe: 'response' });
  }

  getDistrictLimitAmount(req?: any): Observable<limitReportResponseType> {
    const options = createRequestOption(req);
    return this.http.get<limitReport[]>(this.limitAmountUrl, { params: options, observe: 'response' });
  }

  getDistrictVoucherCount(req?: any): Observable<EntityListResponseType> {
    const options = createRequestOption(req);
    return this.http.get<countReportModel[]>(this.getVoucherStatusCount, { params: options, observe: 'response' });
  }

  getDistrictVendorCount(req?: any): Observable<EntityListResponseType> {
    const options = createRequestOption(req);
    return this.http.get<countReportModel[]>(this.getVendorCount, { params: options, observe: 'response' });
  }

  getVendorCountWise(req?: any): Observable<EntityListResponseType> {
    const options = createRequestOption(req);
    return this.http.get<countReportModel[]>(this.getVendorRegistrationStatusReportUrl, { params: options, observe: 'response' });
  }

  getDistrictPaymentSuccessList(req?: any): Observable<TranactionEntityListResponseType> {
    const options = createRequestOption(req);
    return this.http.get<VoucherTranacstionDto[]>(this.getSuccessfullTransactions, { params: options, observe: 'response' });
  }

  getDistrictVouchersign1PendingList(req?: any): Observable<TranactionEntityListResponseType> {
    const options = createRequestOption(req);
    return this.http.get<VoucherTranacstionDto[]>(this.getVouchersign1Pending, { params: options, observe: 'response' });
  }

  getDistrictVouchersign2PendingList(req?: any): Observable<TranactionEntityListResponseType> {
    const options = createRequestOption(req);
    return this.http.get<VoucherTranacstionDto[]>(this.getVouchersign2Pending, { params: options, observe: 'response' });
  }

  getDistrictVouchersign3PendingList(req?: any): Observable<TranactionEntityListResponseType> {
    const options = createRequestOption(req);
    return this.http.get<VoucherTranacstionDto[]>(this.getVouchersign3Pending, { params: options, observe: 'response' });
  }

  getDistrictPaymentFailedList(req?: any): Observable<TranactionEntityListResponseType> {
    const options = createRequestOption(req);
    return this.http.get<VoucherTranacstionDto[]>(this.getFailedTranactions, { params: options, observe: 'response' });
  }

  getDistrictPaymentRejectList(req?: any): Observable<TranactionEntityListResponseType> {
    const options = createRequestOption(req);
    return this.http.get<VoucherTranacstionDto[]>(this.getRejectTransaction, { params: options, observe: 'response' });
  }

  getIALimitAllocationAndAuthorizationDistrictWise(req?: any): Observable<IALimitAllocationAndAuthorizationResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IALimitAllocationAndAuthorization[]>(this.IALimitAllocationAndAuthorizationUrl, { params: options, observe: 'response' });
  }

  IaUtilizedFundReportDistrictWise(req?: any): Observable<IALimitUtilizedFundDtoResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IALimitUtilizedFundDto[]>(this.getIaUtilizedFundReportUrl, { params: options, observe: 'response' });
  }
}
