import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { LimitSet } from './limit-set';
import { LocalStorageService } from 'ngx-webstorage';
export type EntityArrayResponseType = HttpResponse<LimitSet[]>;

@Injectable()
export class LimitSetService {
    
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getIAComponentLimitByFilter');
    protected reportAsyncDownloadUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/reportAsyncDownload');
    protected reportAsyncStatusCheckUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/reportAsyncStatusCheck');
    // protected resourceSaveUrl = environment.SERVER_API_URL+('api/saveProfile');
    // protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deleteProfile');
    constructor(protected http: HttpClient,private localStorageService:LocalStorageService) {}
    filter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<LimitSet[]>(this.resourceFliterUrl,filter, { params:options, observe: 'response' })
    }
    // save(profile?:Profile): Observable<EntityArrayResponseType> {
    //     return this.http
    //     .post<Profile[]>(this.resourceSaveUrl,profile, {observe: 'response' })
    // }
    // delete(id: number): Observable<HttpResponse<{}>> {
    //   return this.http.get(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
    // }

    getExcelDownload(data){
      return this.http.get(`${this.reportAsyncDownloadUrl}?reportName=${data}`,{observe:'response',responseType: 'text'})
    }
    reportAsyncStatusCheck(req:any){
      const options = createRequestOption(req);
      return this.http.get(this.reportAsyncStatusCheckUrl,{
        params: options,
        observe: 'response',
      });
    }
  
}