import { HttpResponse } from '@angular/common/http';

import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from "primeng/api";
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Router } from "@angular/router";



@Component({
  selector: 'app-select-scheme',
  templateUrl: './select-scheme.component.html',
  styleUrls: ['./select-scheme.component.scss']
})
export class SelectSchemeComponent implements OnInit {
  selectedScheme: any;
  schemeList = [{name:'MGSMT',value:'mgsmt'},{name:'NABARD',value:'nabard'}];
  constructor(
    private confirmationService: ConfirmationService,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) {
    
   }

  ngOnInit(): void {
   
  }

  selectScheme(scheme){
    this.selectedScheme = scheme;
    this.confirmationService.confirm({
      message:
        "Are you sure you want to continue with this Scheme " +
        scheme.name,
      icon: "pi pi-unlock",
      accept: () => {
        this.localStorageService.store("scheme",scheme.value);
        window.location.reload();
      },
      reject: () => {},
    });
  }

}
