import { LevelMasterDto } from "../module/level-master/level-master";
import { FinYearDto, SchemeDto } from "../module/limit-set/limit-set";

export class Project {
    id: number;
    projectName: string;
    workId: string;
    scheme: SchemeDto;
    villageMaster: LevelMasterDto;
    districtMaster: LevelMasterDto;
    blockMaster: LevelMasterDto;
    finYear: FinYearDto;
    administrativeSanctionNo: string;
    administrativeSanctionDate: Date;
    technicalSanctionNo: string;
    technicalSanctionDate: Date;
    agreementDate: Date;
    fileUpload:string;
    publicContribution: string;
    sanctionImageCopy: string;
    totalCost: number;
    utilizedTotalCost:number;
    publicContributionAmount: number;
    administrativeSanctionAmount: number;
    technicalSanctionAmount:number;
    agreementNo:string;
    agreementAmount:number;
    contributionPercentage: number;
    activeFlag: string;
    finalPayment:string;
    createdOn: Date;
    updatedOn: Date;
    createdBy: number;
    updatedBy: number;
    constructionCost: number;
  }
  