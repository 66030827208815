import { Component, OnInit } from '@angular/core';
import { Subscriber, Subscription } from 'rxjs';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { FailedTransaction, VendorTransaction, vendorTransactionDto } from '../payment-voucher/payment-voucher.model';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { SearchResult } from 'src/app/shared/models/search-result';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/alert/notification.service';
import { StatutoryTransaction } from '../statutory-payment-voucher/statutory-payment-voucher.model';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { userRoles } from 'src/app/shared/models/user-role-code';

@Component({
  selector: 'app-failure-transaction',
  templateUrl: './failure-transaction.component.html',
  styleUrls: ['./failure-transaction.component.scss'],
})
export class FailureTransactionComponent implements OnInit {
  userContextSubscription: Subscription;
  itemsPerPage: any = 10;
  filterProps: any;
  searchVendorResult: SearchResult<VendorTransaction> = new SearchResult<VendorTransaction>();
  searchStatutoryResult: SearchResult<StatutoryTransaction> = new SearchResult<StatutoryTransaction>();
  maintenanceFailedTransaction: SearchResult<any> = new SearchResult<any>();
  searchMisBulkResult: SearchResult<any> = new SearchResult<any>();
  page: number;
  user: any;
  cols: any;
  timer: any;
  otpTimer: number;
  otpBox: boolean;
  otpError: string;
  otp: string;
  personalFlag: string = 'No';
  activeIndex = 0;
  otpId: any;
  enableResendButton: boolean;
  showOtp: boolean;
  enableVerifyButton: boolean = false;
  currentRecordId: any;
  paymentSucessText: any;
  paymentSucessDialog: boolean;
  userRoles = userRoles;
  statutorycols: any;
  miscellaneousBulk: any[];
  bulkSubList: any;

  constructor(
    private userRoleService: UserRoleService,
    private paymentService: PaymentService,
    public fontService: FontService,
    private spinner: NgxSpinnerService,
    protected notificationService: NotificationService,
    private loginService: SigninService
  ) { }

  ngOnInit(): void {
    this.miscellaneousBulk = [
      {
        field: 'levelMasterName',
        header: 'District',
        isSelectcolumn: true,
        isFilterable: false,
      },
      {
        field: 'voucherNumber',
        header: 'Voucher No',
        isFilterable: false,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no',
      },

      {
        field: 'statutoryAccountDetail',
        header: 'Account Number',
        isFilterable: false,
        isSelectcolumn: true,
        type: 'dropDown_with_other_inner_field',
        Dfield: 'statutoryBulkTransaction',
        innerField: 'paidAmount',
      },
      {
        field: 'statutoryAccountDetail',
        header: 'PAN Number',
        isFilterable: false,
        isSelectcolumn: true,
        type: 'dropDown_with_other_field',
        Dfield: 'panNumber',
      },
      {
        field: 'statutoryAccountDetail',
        header: 'GST Number',
        isFilterable: false,
        isSelectcolumn: true,
        type: 'dropDown_with_other_field',
        Dfield: 'gstNumber',
      },
      {
        field: 'transactionDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'deductionType',
        header: 'Deduction Type',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'dropDown',
      },
      {
        field: 'implementingAgency',
        header: 'Implementing Agency',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'dropDown',
      },
      {
        field: 'transactionAmount',
        header: 'Total Transction Amount',
        jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'transactionStatus',
        header: 'Transaction Status',
        isSortable: true,
        isSelectcolumn: true,
      },
      {
        field: 'signMessage',
        header: 'Status',
        isSelectcolumn: true,
        isFilterable: false,
      },
    ]
    this.statutorycols = [
      {
        field: 'levelMaster',
        header: 'District',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'dropDown',
      },
      {
        field: 'voucherNumber',
        header: 'Voucher No',
        isFilterable: false,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no',
      },
      {
        field: 'deductionMonth',
        header: 'Deduction Month',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'statutoryAccountDetail',
        header: 'Account Number',
        isFilterable: false,
        isSelectcolumn: true,
        type: 'dropDown_with_other_field',
        Dfield: 'bankAccountNumber',
      },
      {
        field: 'statutoryAccountDetail',
        header: 'PAN Number',
        isFilterable: false,
        isSelectcolumn: true,
        type: 'dropDown_with_other_field',
        Dfield: 'panNumber',
      },
      {
        field: 'statutoryAccountDetail',
        header: 'GST Number',
        isFilterable: false,
        isSelectcolumn: true,
        type: 'dropDown_with_other_field',
        Dfield: 'gstNumber',
      },
      {
        field: 'transactionDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'deductionType',
        header: 'Deduction Type',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'dropDown',
      },
      {
        field: 'implementingAgency',
        header: 'Implementing Agency',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'dropDown',
      },
      {
        field: 'transactionAmount',
        header: 'Total Transction Amount',
        jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'transactionStatus',
        header: 'Transaction Status',
        isSortable: true,
        isSelectcolumn: true,
      },
      {
        field: 'signMessage',
        header: 'Status',
        isSelectcolumn: true,
        isFilterable: false,
      },
    ];
    this.cols = [
      {
        field: 'voucherNo',
        header: 'Voucher No',
        isFilterable: false,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no',
      },
      {
        field: 'commercialVendorName',
        header: 'Vendor Name',
        jhiTranslate: 'tableHead.vendor.name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'commercialAccountNo',
        header: 'Vendor Account',
        jhiTranslate: 'tableHead.vendor.account',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'grossAmount',
        header: 'Rate',
        jhiTranslate: 'tableHead.rate',
        isSelectcolumn: true,
        isFilterable: true,
        type: 'amount',
      },
      {
        field: 'transactionAmount',
        header: 'Total Transction Amount',
        jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        isFilterable: true,
        type: 'amount',
      },
      {
        field: 'transactionStatus',
        header: 'Transaction Status',
        isSortable: true,
        isSelectcolumn: true,
      },
      {
        field: 'signMessage',
        header: 'Status',
        isSelectcolumn: true,
        isFilterable: true,
      },
    ];
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      levelMasterId: this.user?.levelMaster?.id,
    };
    if(this.activeIndex === 3) {
      this.activeIndex = 2;
    }
    if (this.activeIndex === 2) {
      this.paymentService.failedBulkTransactionStatus({ ...Query }).subscribe(
        (res: HttpResponse<FailedTransaction>) => {

          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          this.spinner.hide();
        },
        () => {
          this.onError();
          this.spinner.hide();
        }
      );
    }
    else {
      if (this.personalFlag && this.activeIndex === 0) {
        Query['personalFlag'] = 'No';
      }
      else {
        if(this.activeIndex === 4) {
          Query['paymentType'] = 'Maintenance';
        }
        
      }
      this.paymentService.failtureTransaction({ ...Query }).subscribe(
        (res: HttpResponse<FailedTransaction>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          this.spinner.hide();
        },
        () => {
          this.onError();
          this.spinner.hide();
        }
      );
    }
   
  }

  protected onSuccess(data: any | null, headers: HttpHeaders, page: number, navigate: boolean): void {
    console.log('this.activeIndex',this.activeIndex);
      console.log('data',data);
   
    if (this.personalFlag) {
      this.searchStatutoryResult.total = Number(headers.get('X-Total-Count'));
      this.searchVendorResult.items = data.vendorFailedTransaction ?? [];
    }
    else if (this.activeIndex === 2) {

      this.searchMisBulkResult.total = Number(headers.get('X-Total-Count'));
      this.searchMisBulkResult.items = data ?? [];

    }
    else {
      this.searchStatutoryResult.total = Number(headers.get('X-Total-Count'));
      this.searchStatutoryResult.items = data.statutoryFailedTransaction ?? [];
    }
    if(this.activeIndex === 4) {
      
      this.maintenanceFailedTransaction.total = Number(headers.get('X-Total-Count'));
      this.maintenanceFailedTransaction.items = data.maintenanceFailedTransaction?? [];
      console.log('this.activeIndex',this.maintenanceFailedTransaction);
      console.log('data',data);
    }
    this.searchVendorResult.items?.forEach(element => {
      const date = new Date(element.updatedOn);
      const currentDate = new Date();
      const yesterday = new Date(currentDate);
      yesterday.setHours(currentDate.getHours() - 6);
      if (date < yesterday && this.user?.role?.code === userRoles.roleApprover && element.transactionStatus === 'failed') {
        element.reInitialize = true;
      }
    });

    this.searchStatutoryResult.items?.forEach(element => {
      const date = new Date(element.updatedOn);
      const currentDate = new Date();
      const yesterday = new Date(currentDate);
      yesterday.setHours(currentDate.getHours() - 6);
      if (date < yesterday && this.user?.role?.code === userRoles.roleApprover && element.transactionStatus === 'failed') {
        element.reInitialize = true;
      }
    });

    this.maintenanceFailedTransaction.items?.forEach(element => {
      const date = new Date(element.updatedOn);
      const currentDate = new Date();
      const yesterday = new Date(currentDate);
      yesterday.setHours(currentDate.getHours() - 6);
      console.log('date',date);
      console.log('yesterday',yesterday);
      if (date < yesterday) {
        element.reInitialize = true;
      }
    });
    
    this.searchStatutoryResult = { ...this.searchStatutoryResult };
    this.searchVendorResult = { ...this.searchVendorResult };
    this.searchMisBulkResult = { ... this.searchMisBulkResult };
    this.maintenanceFailedTransaction = {... this.maintenanceFailedTransaction};
    console.log('this.maintenanceFailedTransaction ',this.maintenanceFailedTransaction )
  }

  protected onError(): void { }

  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    this.userContextSubscription = this.userRoleService.getuserContext().subscribe(
      res => {
        this.user = res;
        this.loadPage(event.page);
      },
      () => { }
    );
  }

  onSelectRow($event) {
    if ($event.type === 'checkTranaction') {
      $event.value.checkTransactionStatus = false;
      const payload = {};
      if (this.personalFlag) {
        payload['vendorTransactionId'] = $event.value.id;
      } else {
        payload['statutoryTransactionId'] = $event.value.id;
      }
      this.paymentService.failedTransactionStatus(payload).subscribe(
        (res: any) => {
          if (res.body.transactionStatus.TRANSTATUS === 'Failure') {
            this.notificationService.alertError('Transaction Failed for Reference No:' + res.body.transactionStatus.JRNL_NO, '');
            // this.disablepaymentButton = false;
            $event.value.reInitialize = true;
            $event.value.checkTransactionStatus = false;
          } else if (res.body.transactionStatus.TRANSTATUS === 'Success') {
            this.notificationService.alertSuccess('Transaction Success for Reference No:' + res.body.transactionStatus.JRNL_NO, '');
            this.loadPage();
          }
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
          $event.value.showReject = false;
          $event.value.checkTransactionStatus = true;
        }
      );
    } else if ($event.type === 'reinitialize') {
      this.currentRecordId = $event.value.id;
      $event.value.reInitialize = false;
      this.spinner.show();
      this.getUserOtp();
    } else {
      let payload = {};
      payload['status'] = false;
      if ($event.type === 'true') {
        payload['status'] = true;
      }

      if (this.personalFlag && this.activeIndex === 0) {
        payload['vendorTransactionId'] = $event.value.id;
      }
      else if(this.activeIndex === 4) {
        payload['maintenanceTransactionId'] = $event.value.id;
      }  
      else {
        payload['statutoryTransactionId'] = $event.value.id;
      }
      this.paymentService.paymentUserConfirmation(payload).subscribe(
        (res: any) => {
          console.log('res', res);
          if (payload['status']) {
            this.notificationService.alertSuccess('Payment Confirmed', '');
          } else {
            this.notificationService.alertSuccess('Payment Disputed', '');
          }
          this.loadPage();
        },
        err => {
          console.log('err', err);
        }
      );
    }
  }

  getUserOtp(resend?) {
    this.loginService.getOtp({ isReinitiate: true }).subscribe(
      res => {
        this.otpId = res;
        this.otpBox = true;
        if (resend) {
          this.enableResendButton = false;
        }
        this.startTimer();
        this.spinner.hide();
      },
      onError => {
        if (onError.status == 403) {
          this.notificationService.alertError('Error! ' + onError.error, '');
        }
      }
    );
  }

  startTimer() {
    clearInterval(this.timer);
    this.otpTimer = 60;
    this.timer = setInterval(() => {
      this.otpTimer--;
      if (this.otpTimer === 0) {
      }
    }, 1000);
  }
  onTabChange(event: any): void {
    switch (event.index) {
      case 0:
        this.personalFlag = 'No';
        this.loadPage();
        break;
      case 1:
        this.personalFlag = null;
        this.loadPage();
        break;
      case 2:
        this.personalFlag = null;
        this.loadPage();
        break;
      case 4:
        this.personalFlag = null;
        this.loadPage();
        break;
    }
  }

  validateOtp() {
    if (this.otp.length === 6) {
      this.enableVerifyButton = true;
    } else {
      this.enableVerifyButton = false;
    }
  }

  verifyOtp() {
    this.spinner.show();
    this.enableVerifyButton = false;
    this.otpBox = false;
    console.log('otp', this.otp);
    const payload = { otp: this.otp, id: this.otpId };
    if (this.personalFlag && this.activeIndex === 1) {
      payload['vendorTransactionId'] = this.currentRecordId;
    }
    else if(this.activeIndex === 3) {
      payload['statutoryBulkTransactionId'] = this.currentRecordId;
    }
    else if(this.activeIndex === 4) {
      payload['maintenanceTransactionId'] = this.currentRecordId;
    } 
    else {
      payload['statutoryTransactionId'] = this.currentRecordId;
    }
    this.loginService.otpvalidate(payload).subscribe(
      res => {
        this.otp = null;
        this.paymentSucessText = res;
        this.paymentSucessDialog = true;
        this.spinner.hide();
      },
      onError => {
        this.spinner.hide();
        this.otpError = 'Invalid Otp';
        this.otp = null;
      }
    );
  }

  reinitializeBulk(item) {
    this.currentRecordId = item.id;
    item.reInitialize = false;
    this.spinner.show();
    this.getUserOtp();
  }
  pendingBulkTransaction (type,item) {
    let payload = {};
    payload['status'] = false;
    if (type === 'true') {
      payload['status'] = true;
    }
    payload['statutoryBulkTransactionId'] = item.id;
    this.paymentService.paymentUserConfirmation(payload).subscribe(
      (res: any) => {
        console.log('res', res);
        this.bulkSubList = null;
        if (payload['status']) {
          this.notificationService.alertSuccess('Payment Confirmed', '');
        } else {
          this.notificationService.alertSuccess('Payment Disputed', '');
        }
        this.loadPage();
      },
      err => {
        console.log('err', err);
      }
    );
  }
 

  getSubBulkList (list) {
    this.spinner.show();
    let Query = {
      statutoryTransactionId:list.id,
      levelMasterId: this.user?.levelMaster?.id,
    };
    this.paymentService.failtureTransaction({ ...Query }).subscribe(
      (res: HttpResponse<any>) => {
        this.spinner.hide();
        this.activeIndex = 3;
        res?.body?.statutoryBulkFailedTransaction.forEach(element => {
          if (element.updatedOn) {
            const date = new Date(element.updatedOn);
            const currentDate = new Date();
            const yesterday = new Date(currentDate);
            yesterday.setHours(currentDate.getHours() - 6);
            if (date < yesterday && this.user?.role?.code === userRoles.roleApprover && element.transactionStatus === 'failed') {
              element.reInitialize = true;
            }
          }
        });
        this.bulkSubList = res?.body?.statutoryBulkFailedTransaction;
      },
      () => {
        this.onError();
        this.spinner.hide();
      }
    );
    console.log('list',list);

  }
}
