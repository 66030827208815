import { Component, OnInit } from '@angular/core';
import { Subscriber, Subscription } from 'rxjs';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { DisputedTransaction, FailedTransaction, VendorTransaction, vendorTransactionDto } from '../payment-voucher/payment-voucher.model';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { SearchResult } from 'src/app/shared/models/search-result';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/alert/notification.service';
import { StatutoryTransaction } from '../statutory-payment-voucher/statutory-payment-voucher.model';
import { SigninService } from 'src/app/auth/signin/signin.service';

@Component({
  selector: 'app-disputed-payment-list',
  templateUrl: './disputed-payment-list.component.html',
  styleUrls: ['./disputed-payment-list.component.scss'],
})
export class DisputedPaymentListComponent implements OnInit {
  userContextSubscription: Subscription;
  itemsPerPage: any = 10;
  filterProps: any;
  searchVendorResult: SearchResult<VendorTransaction> = new SearchResult<VendorTransaction>();
  searchStatutoryResult: SearchResult<StatutoryTransaction> = new SearchResult<StatutoryTransaction>();
  page: number;
  user: any;
  cols: any;
  timer: any;
  otpTimer: number;
  otpBox: boolean;
  otpError: string;
  otp: string;
  personalFlag: string = 'No';
  activeIndex = 0;
  otpId: any;
  enableResendButton: boolean;
  showOtp: boolean;
  enableSigninButton: boolean = true;
  currentRecordId: any;
  paymentSucessText: any;
  paymentSucessDialog: boolean;
  statutorycols: any;
  searchMisBulkResult: SearchResult<any> = new SearchResult<any>();

  constructor(
    private userRoleService: UserRoleService,
    private paymentService: PaymentService,
    public fontService: FontService,
    private spinner: NgxSpinnerService,
    protected notificationService: NotificationService,
    private loginService: SigninService
  ) {}

  ngOnInit(): void {
    this.statutorycols = [
      {
        field: 'levelMaster',
        header: 'District',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'dropDown',
      },
      {
        field: 'voucherNumber',
        header: 'Voucher No',
        isFilterable: false,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no',
      },
      {
        field: 'deductionMonth',
        header: 'Deduction Month',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'statutoryAccountDetail',
        header: 'Account Number',
        isFilterable: false,
        isSelectcolumn: true,
        type: 'dropDown_with_other_field',
        Dfield: 'bankAccountNumber',
      },
      {
        field: 'statutoryAccountDetail',
        header: 'PAN Number',
        isFilterable: false,
        isSelectcolumn: true,
        type: 'dropDown_with_other_field',
        Dfield: 'panNumber',
      },
      {
        field: 'statutoryAccountDetail',
        header: 'GST Number',
        isFilterable: false,
        isSelectcolumn: true,
        type: 'dropDown_with_other_field',
        Dfield: 'gstNumber',
      },
      {
        field: 'transactionDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'deductionType',
        header: 'Deduction Type',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'dropDown',
      },
      {
        field: 'implementingAgency',
        header: 'Implementing Agency',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'dropDown',
      },
      {
        field: 'transactionAmount',
        header: 'Total Transction Amount',
        jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'transactionStatus',
        header: 'Transaction Status',
        isSortable: true,
        isSelectcolumn: true,
      },
      {
        field: 'signMessage',
        header: 'Status',
        isSelectcolumn: true,
        isFilterable: false,
      },
    ];
    this.cols = [
      {
        field: 'voucherNo',
        header: 'Voucher No',
        isFilterable: false,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no',
      },
      {
        field: 'commercialVendorName',
        header: 'Vendor Name',
        jhiTranslate: 'tableHead.vendor.name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'commercialAccountNo',
        header: 'Vendor Account',
        jhiTranslate: 'tableHead.vendor.account',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'grossAmount',
        header: 'Rate',
        jhiTranslate: 'tableHead.rate',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'transactionAmount',
        header: 'Total Transction Amount',
        jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'transactionStatus',
        header: 'Transaction Status',
        isSortable: true,
        isSelectcolumn: true,
      },
      {
        field: 'signMessage',
        header: 'Status',
        isSelectcolumn: true,
        isFilterable: false,
      },
    ];
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      levelMasterId: this.user?.levelMaster?.id,
    };
    if (this.personalFlag) {
      Query['personalFlag'] = 'No';
    }
    if(this.activeIndex === 2) {
      Query['voucherType'] = 'BULK'
    }
    this.paymentService.disputedTransaction({ ...Query }).subscribe(
      (res: HttpResponse<DisputedTransaction>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        this.spinner.hide();
      },
      () => {
        this.onError();
        this.spinner.hide();
      }
    );
  }

  protected onSuccess(data: any | null, headers: HttpHeaders, page: number, navigate: boolean): void {
    if (this.personalFlag) {
      this.searchVendorResult.total = Number(headers.get('X-Total-Count'));
      this.searchVendorResult.items = data.vendorDisputedTransaction ?? [];
    }
    else if(this.activeIndex === 2) {
      this.searchMisBulkResult.total = Number(headers.get('X-Total-Count'));
      this.searchMisBulkResult.items = data.statutoryBulkDisputedTransaction ?? [];
    }

     else {
      this.searchVendorResult.total = Number(headers.get('X-Total-Count'));
      this.searchStatutoryResult.items = data.statutoryDisputedTransaction ?? [];
    }
    this.searchVendorResult = { ...this.searchVendorResult };
    this.searchStatutoryResult = { ...this.searchStatutoryResult };
  }

  protected onError(): void {}

  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    this.userContextSubscription = this.userRoleService.getuserContext().subscribe(
      res => {
        this.user = res;
        this.loadPage(event.page);
      },
      () => {}
    );
  }

  onTabChange(event: any): void {
    switch (event.index) {
      case 0:
        this.personalFlag = 'No';
        this.loadPage();
        break;
      case 1:
        this.personalFlag = null;
        this.loadPage();
        break;
      case 2:
        this.personalFlag = null;
        this.loadPage();
        break;
    }
  }
}
