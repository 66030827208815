import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { environment } from 'src/environments/environment';
import { LimitTransListService } from '../limit-translist/limit-translist.service';
import {
  commercial,
  CommercialVendors,
  CommercialVendorsDto,
  ValidationResponse,
} from './commercial-vendors';
import { CommercialVendorService } from './commercial-vendors.service';

@Component({
  selector: 'app-commercial-vendors',
  templateUrl: './commercial-vendors.component.html',
  styleUrls: ['./commercial-vendors.component.scss'],
})
export class CommercialVendorsComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;

  commercialVendorsDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<CommercialVendors> =
    new SearchResult<CommercialVendors>();
  checked: boolean;
  commercialVendor?: CommercialVendorsDto;
  selectedcommercialVendors: CommercialVendors[] = [];
  submitted?: boolean;
  selectedMapping: string;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any;
  commercialList: commercial[];
  bankBranch: any;
  public verifyClicked: boolean;
  validationRes: ValidationResponse;
  readonly: boolean;
  mandateGst: boolean;
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public cPinError = false;
  public showBMobile = false;
  public aadhaarError = false;
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  today: Date;
  bankName: any;
  accountInvalidError: any;
  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;
  showCompanyNameError: string;
  showPanError: string;
  showeEmailError: string;
  showAadhaarNoError: string;
  showGstError: string;
  showaccountNoError: string;
  bankList: any;
  bankBranchList: any = [];
  viewOnly: boolean;
  allowEdit: boolean = false;
  enableApprove: boolean;
  isChecker: boolean;
  approveView: boolean;
  statusButton: boolean = false;
  currentEnvironment: string;
  userContextSubscription: Subscription;
  levelMasterId: number;
  userCode: string;
 

  constructor(
    private commercialVendorservice: CommercialVendorService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    protected router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private userRoleService: UserRoleService,
    private limitTransListService: LimitTransListService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.currentEnvironment = environment.environment;
    this.userContextSubscription = this.userRoleService
      .getuserContext()
      .subscribe(
        (res) => {
          this.levelMasterId = res.levelMaster.id;
          this.userCode = res.role.code;
          if (
            res.role.code == userRoles.roleStateMaker ||
            res.role.code == userRoles.roleMaker ||
            res.role.code == userRoles.roleState
          ) {
            this.allowEdit = true;
          } else if (
            res.role.code == userRoles.roleChecker ||
            res.role.code == userRoles.roleStateChecker
          ) {
            this.enableApprove = true;
          }
          if (
            res.role.code == userRoles.roleChecker ||
            res.role.code == userRoles.roleApprover ||
            res.role.code == userRoles.roleStateChecker ||
            res.role.code == userRoles.roleStateApprover ||
            res.role.code == userRoles.roleState ||
            res.role.code == userRoles.roleDistrictMonitor ||
            res.role.code == userRoles.roleStateMonitor
          ) {
            this.isChecker = true;
          }
        },
        () => {
          this.onError();
        }
      );
    this.cols = [
      {
        field: 'name',
        header: 'Firm Name/Company Name',
        isSortable: true,
        isFilterable: true,
        width: 10,
        isSelectcolumn: true,
      },
      {
        field: 'levelMaster',
        header: 'District',
        jhiTranslate: 'tableHead.district.name',
        isSortable: false,
        isFilterable: true,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'mobile',
        header: 'Mobile No',
        jhiTranslate: 'tableHead.mobile.number',
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'panNo',
        header: 'Pan No',
        jhiTranslate: 'tableHead.panno',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'gstNo',
        header: 'GST No',
        jhiTranslate: 'tableHead.gstNo',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'bankName',
        header: 'Bank Name',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'bankBranch',
        header: 'Branch',
        jhiTranslate: 'tableHead.branch.name',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'accountNumber',
        header: 'Account No',
        jhiTranslate: 'tableHead.account.number',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'status',
        header: 'Status',
        isSortable: true,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
    ];
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  onVendorTypeChange() {
    if (this.commercialVendor.commercialDto.name == 'SOLE PROPRIETOR') {
      this.mandateGst = false;
    } else {
      this.mandateGst = true;
    }
  }

  loadValue() {
    this.commercialVendorservice.getCommerical().subscribe(
      (res: HttpResponse<commercial[]>) => {
        this.commercialList = res.body;
      },
      () => {}
    );
    this.commercialVendorservice.getBankList().subscribe(
      (res: HttpResponse<[]>) => {
        this.bankList = res.body;
      },
      () => {}
    );
  }

  openNew() {
    this.commercialVendor = new CommercialVendorsDto();
    this.loadValue();
    this.submitted = false;
    this.commercialVendorsDialog = true;
    this.readonly = false;
  }

  addresschange(check?) {
    if (this.checked) {
      this.commercialVendor.companyDoorNo = this.commercialVendor.doorNo;
      this.commercialVendor.companyStreet = this.commercialVendor.street;
      this.commercialVendor.companyArea = this.commercialVendor.area;
      this.commercialVendor.companyCity = this.commercialVendor.city;
      this.commercialVendor.companyDistrict = this.commercialVendor.district;
      this.commercialVendor.companyState = this.commercialVendor.state;
      this.commercialVendor.companyPinCode = this.commercialVendor.pinCode;
    } else {
      if (!check) {
        this.commercialVendor.companyDoorNo = null;
        this.commercialVendor.companyStreet = null;
        this.commercialVendor.companyArea = null;
        this.commercialVendor.companyCity = null;
        this.commercialVendor.companyDistrict = null;
        this.commercialVendor.companyState = null;
        this.commercialVendor.companyPinCode = null;
      }
    }
  }

  removeExtraSpaces(text: string): string {
    return text.replace(/\s+/g, ' ').trim();
  }
  hideDialog() {
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.panError = false;
    this.pinError = false;
    this.showBMobile = false;
    this.aadhaarError = false;
    this.tanError = false;
    this.tinError = false;
    this.gstError = false;
    this.ifscError = false;
    this.ngSelectInvalid = false;
    this.commercialVendor = new CommercialVendorsDto();
    this.commercialVendorsDialog = false;
    this.submitted = false;
    this.accountInvalidError = false;
    this.verifyClicked = true;
    this.bankBranch = null;
    this.viewOnly = false;
    this.approveView = false;
    this.statusButton = false;
    this.mandateGst = false;
  }

  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(
      this.commercialVendor.mobile
    );
    !this.commercialVendor.mobile ? (this.showMobileError = false) : true;
    return !this.showMobileError && !!this.commercialVendor.mobile;
  }

  validateEmail() {
    this.emailError = !this.validationService.isEmailValid(
      this.commercialVendor.email
    );
    return !this.emailError && !!this.commercialVendor.email;
  }

  validatePan() {
    this.panError = !this.validationService.isPanValid(
      this.commercialVendor.panNo.toUpperCase()
    );
    !this.commercialVendor.panNo ? (this.panError = false) : true;

    return !this.panError && !!this.commercialVendor.panNo;
  }

  validateTan() {
    this.tanError = !this.validationService.isTanValid(
      this.commercialVendor.tanNo
    );
    !this.commercialVendor.tanNo ? (this.tanError = false) : true;
    return !this.tanError && !!this.commercialVendor.tanNo;
  }

  validateTin() {
    this.tinError = !this.validationService.isTinValid(
      this.commercialVendor.tinNo
    );
    !this.commercialVendor.tinNo ? (this.tinError = false) : true;
    return !this.tinError && !!this.commercialVendor.tinNo;
  }

  validatePin() {
    this.pinError = !this.validationService.isPincodeValid(
      this.commercialVendor.pinCode
    );
    !this.commercialVendor.pinCode ? (this.pinError = false) : true;
    return !this.pinError && !!this.commercialVendor.pinCode;
  }

  accountDetailsChanged() {
    this.verifyClicked = false;
    if(!this.ifscError){
      this.spinner.show();
      this.commercialVendorservice.bankBranchDetails(this.commercialVendor.ifscCode)
      .subscribe(
        (res)=>{
          this.spinner.hide();
          this.commercialVendor.bankBranch=res.body.FetchBankDetails_Response.Body.Payload.Branch_Name;
          this.commercialVendor.bankName=res.body.FetchBankDetails_Response.Body.Payload.Bank_Name;
        },
        ()=>{
          this.spinner.hide();
          this.notificationService.alertError('Error Occured','');
        }
      );
      }

  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
      ];
      if (this.commercialVendor.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.commercialVendor.id,
        });
      }
      this.commercialVendorservice
        .validFilter({ ...filterQuery }, filter)
        .subscribe(
          (res: HttpResponse<CommercialVendors[]>) => {
            resolve(res.body);
          },
          () => {
            this.onError();
            resolve(null);
          }
        );
    });
  }

  validateCPin() {
    this.cPinError = !this.validationService.isPincodeValid(
      this.commercialVendor.companyPinCode
    );
    !this.commercialVendor.companyPinCode ? (this.cPinError = false) : true;

    return !this.cPinError && !!this.commercialVendor.companyPinCode;
  }

  validateAadhaar() {
    this.aadhaarError = !this.validationService.isAadhaarValid(
      this.commercialVendor.aadhaarNo
    );
    !this.commercialVendor.aadhaarNo ? (this.aadhaarError = false) : true;
    return !this.aadhaarError && !!this.commercialVendor.aadhaarNo;
  }
  validateGst() {
    if (this.commercialVendor.gstNo) {
      this.gstError = !this.validationService.isGstValid(
        this.commercialVendor.gstNo
      );
    }
    return !this.gstError;
  }

  checkAccountNo() {
    let filterQuery = {
      page: 0,
      size: 10,
    };
    this.commercialVendorservice
      .filter({ ...filterQuery }, [
        {
          key: 'accountNumber',
          operation: 'equals',
          value: this.commercialVendor.accountNumber,
        },
      ])
      .subscribe(
        (res: HttpResponse<CommercialVendors[]>) => {
          if (res.body.length !== 0) {
            this.showaccountNoError = 'Account No Already Exists';
          } else {
            this.showaccountNoError = null;
          }
        },
        () => {
          this.onError();
        }
      );
  }

  validateIfsc() {
    this.ifscError = !this.validationService.isIfscValid(
      this.commercialVendor.ifscCode
    );
    !this.commercialVendor.ifscCode ? (this.ifscError = false) : true;
    return !this.ifscError && !!this.commercialVendor.ifscCode;
  }

  saveData() {
    const isFormValid =
      this.validateMobile() &&
      this.validateEmail() &&
      this.validatePan() &&
      this.validatePin() &&
      this.validateAadhaar() &&
      this.validateGst() &&
      this.validateIfsc() &&
      this.commercialVendor.mobile &&
      this.commercialVendor.email &&
      this.commercialVendor.aadhaarNo &&
      this.commercialVendor.panNo &&
      this.commercialVendor.doorNo &&
      this.commercialVendor.street &&
      this.commercialVendor.city &&
      this.commercialVendor.district &&
      this.commercialVendor.state &&
      this.commercialVendor.pinCode &&
      this.commercialVendor.companyDoorNo &&
      this.commercialVendor.companyStreet &&
      this.commercialVendor.companyCity &&
      this.commercialVendor.companyDistrict &&
      this.commercialVendor.companyState &&
      this.commercialVendor.companyPinCode &&
      this.commercialVendor.bankBranch &&
      this.commercialVendor.bankName &&
      this.commercialVendor.accountNumber &&
      this.commercialVendor.bankAccName &&
      this.commercialVendor.ifscCode &&
      !this.accountInvalidError &&
      this.verifyClicked;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    this.ngSelectInvalid = false;
    if (this.commercialVendor.commercialDto.name !== 'SOLE PROPRIETOR') {
      if (!this.commercialVendor.gstNo) {
        this.ngSelectInvalid = true;
        this.notificationService.alertError(
          'GST Number is required for the chosen Vendor Type',
          ''
        );
        return;
      }
    }
    this.commercialVendorservice.save(this.commercialVendor).subscribe(
      (res: HttpResponse<any>) => {
        this.commercialVendorsDialog = false;
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      (onError) => {
        this.onError();
        if (onError.status == 400) {
          this.notificationService.alertError(onError.error.errorKey, '');
        } else {
          this.notificationService.alertError('Error!', '');
        }
      }
    );
  }

  approveVendor(id) {
    this.commercialVendorservice.approveVendor(id).subscribe(
      (res: HttpResponse<any>) => {
        this.loadPage(this.page);
        this.commercialVendorsDialog = false;
      },
      (error) => {
        this.onError();
      }
    );
  }

  rejectVendor(id) {
    this.commercialVendorservice.rejectVendor(id).subscribe(
      (res: HttpResponse<any>) => {
        this.loadPage(this.page);
        this.commercialVendorsDialog = false;
      },
      () => {
        this.onError();
      }
    );
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();

    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      sort: ['createdOn,asc'],
    };
    this.filterProps = this.filterProps ? this.filterProps : [];
    this.filterProps.push({
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    });
    this.commercialVendorservice
      .filter({ ...filterQuery }, this.filterProps)
      .subscribe(
        (res: HttpResponse<CommercialVendors[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
          this.spinner.hide();
        }
      );
  }

  protected onSuccess(
    data: CommercialVendors[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    if (navigate) {
      this.router.navigate(['/commercial-vendors'], {
        queryParams: {
          page: this.page - 1,
          size: this.itemsPerPage,
        },
      });
    }
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  getBankBranchList(event?) {
    this.bankBranch = null;
    let filter = [];
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.commercialVendor.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };
    if (event) {
      filter.push({
        key: 'bankBranch',
        operation: 'startswith',
        value: event.term.toUpperCase(),
      });
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => {}
        );
    } else {
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => {}
        );
    }
  }

  getBankBranchdetais() {
    let filter = [];
    filter.push({
      key: 'bankIfsc',
      operation: 'equals',
      value: this.commercialVendor.ifscCode.toUpperCase(),
    });
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.commercialVendor.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };

    this.commercialVendorservice
      .getBankBranchList(filter, filterQuery)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.bankBranch = res.body[0];
        },
        () => {}
      );
  }

  getBankIfsc() {
    this.commercialVendor.bankBranch = this.bankBranch.bankBranch;
    this.commercialVendorservice
      .getBankIfscCode(
        this.commercialVendor.bankName,
        this.commercialVendor.bankBranch
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.commercialVendor.ifscCode = res.body[0];
        },
        () => {}
      );
  }

  accountValidationStatus() {
    this.verifyClicked = true;
    this.spinner.show();
    this.commercialVendorservice
      .getaccountValidations({
        bankAccountNo: this.commercialVendor.accountNumber,
        bankIfsc: this.commercialVendor.ifscCode,
      })
      .subscribe(
        (res: any) => {
          this.readonly = true;
          this.spinner.hide();
          this.notificationService.alertSuccess(
            'Account Verified Successfully',
            ''
          );
          this.statusButton = false;
        },
        (error) => {
          this.accountInvalidError =
            'Please input valid account number and bank details';
          this.notificationService.alertError(
            'Account Verification Failed',
            ''
          );
          this.verifyClicked = false;
          this.readonly = false;
          // this.statusButton=true;
          this.spinner.hide();
        }
      );
  }

  accountValidation() {
    if (
      this.currentEnvironment == 'uat' ||
      this.currentEnvironment == 'production'
    ) {
      if (
        !this.commercialVendor.ifscCode ||
        !this.commercialVendor.accountNumber ||
        !this.commercialVendor.bankBranch ||
        !this.commercialVendor.bankName
      ) {
        this.accountInvalidError =
          'Please input valid account number and bank details';
        return;
      }
      this.verifyClicked = true;
      this.spinner.show();
      this.commercialVendorservice
        .getaccountValidation(
          this.commercialVendor.accountNumber,
          this.commercialVendor.ifscCode
        )
        .subscribe(
          (res: any) => {
            this.validationRes = JSON.parse(res.body);
            if (this.validationRes.bankTxnStatus === true) {
              this.accountInvalidError = null;
              this.commercialVendor.bankAccName = this.validationRes.accountName;
              this.notificationService.alertSuccess(
                'Account Verfied Sucessfully',
                ''
              );
              this.readonly = true;
            } else {
              this.accountInvalidError =
                'Account Verification Failed due to ' +
                this.validationRes.errorDescription;
              this.notificationService.alertError(
                'Account Verification Failed due to ' +
                  this.validationRes.errorDescription,
                ''
              );
             
              this.readonly = false;
              this.verifyClicked = false;
            }
            this.spinner.hide();
          },
          (error) => {
            error = JSON.parse(error);
            if (error.status === 400) {
              this.accountInvalidError =
                'Account Verification Failed. ' + error.error.excepMetaData;
              this.notificationService.alertError(
                'Account Verification Failed. ' + error.error.excepMetaData,
                ''
              );
            } else
            {
              this.accountInvalidError =
              'Please input valid account number and bank details';
            this.notificationService.alertError(
              'Account Verification Failed',
              ''
            );
            }
            
            this.spinner.hide();
          }
        );
    } else {
      if (
        !this.commercialVendor.ifscCode ||
        !this.commercialVendor.accountNumber ||
        !this.commercialVendor.bankAccName || !this.commercialVendor.bankBranch ||
        !this.commercialVendor.bankName
      ) {
        this.accountInvalidError =
          'Please input valid account number and bank details';
        return;
      }
      this.verifyClicked = true;
      // this.spinner.show();
      // this.commercialVendorservice
      //   .getaccountValidations({bankAccountNo: this.commercialVendor.accountNumber,
      //     bankIfsc: this.commercialVendor.ifscCode})
      //   .subscribe(
      //     (res:any) => {
      //       this.readonly=true;
      //       this.spinner.hide();
      //       this.notificationService.alertSuccess('Account Verified Successfully','');
      //       this.statusButton=false;
      //     },
      //     (error) => {
      //       // this.accountInvalidError="Please input valid account number and branch details";
      //       // this.notificationService.alertError('Account Verification Failed','');
      //       // this.verifyClicked=false;
      //       // this.readonly=false;
      //       this.readonly=true;
      //       this.statusButton=true;
      //       this.spinner.hide()
      //     }
      //   );
    }
  }

  protected onError(): void {}

  onSelectRow($event) {
    if (
      $event.type === 'select' ||
      $event.type === 'view' ||
      $event.type === 'approve'
    ) {
      if ($event.type === 'view') {
        this.viewOnly = true;
      }
      if ($event.type === 'approve') {
        this.viewOnly = true;
        this.approveView = true;
      }
      this.commercialVendorservice
        .getcommercialVendor($event.value.id)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.readonly = true;
            this.commercialVendor = res.body;
            this.commercialVendorsDialog = true;
            this.loadValue();
            this.verifyClicked = true;
            if (this.commercialVendor.bankName) {
              this.getBankBranchList();
            }
            this.getBankBranchdetais();
          },
          () => {
            this.onError();
          }
        );
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure want to Delete this ' + $event.value.name,
        header: 'Confirmation',

        accept: () => {
          this.commercialVendorservice.delete($event.value.id).subscribe(
            (res: HttpResponse<any>) => {
              this.loadPage(this.page);
            },
            () => {
              this.onError();
            }
          );
        },
        reject: () => {},
      });
    }
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.userContextSubscription) {
      this.userContextSubscription.unsubscribe();
    }
  }
  downloadData() {
    let data="Vendor Registration Report";
    
    this.spinner.show();
    this.limitTransListService.getExcelDownload(data).subscribe((res: HttpResponse<any>) => 
      {
       console.log(res);
       this.notificationService.alertSuccess(res.body, '');
       this.spinner.hide();
     },
      (onError) => {
        this.notificationService.alertError(onError.error.errorMessage, '');
        this.spinner.hide();
      }
    );
  }

  checkStatus() {
    let filter = {
      reportName:'Vendor Registration Report',
    };
    this.spinner.show();
    this.limitTransListService.reportAsyncStatusCheck({ ...filter }).subscribe(
      (res) => {
        console.log('res', res);
        let b: any = res.body;
        this.saveBlobToExcel(b.data,'Vendor Registration Report');
        this.spinner.hide();
      },
      (onError) => {
        this.notificationService.alertError(onError.error.errorMessage, '');
        this.spinner.hide();
      }
    );
  }

  saveBlobToExcel(data, filename) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }
}
