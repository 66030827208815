import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { Project } from './project.model';
import { LocalStorageService } from 'ngx-webstorage';


@Injectable({ providedIn: 'root' })
export class ProjectService {
   
    protected calculatePercentageUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/calculatePercentage');
    protected saveProjectDtoUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/saveProjectDto')
    protected filterUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getProjectByFilter')
   
    constructor(protected http: HttpClient,protected localStorageService:LocalStorageService) {}
    getAmount(percentage:any,amount:any){
        return this.http.get(`${this.calculatePercentageUrl}?amount=${amount}&state=${percentage}`,{observe: 'response'})
    }

    saveProject(projectDto:Project){
        return this.http
      .post<Project>(this.saveProjectDtoUrl,projectDto, {observe: 'response' })
    }

    filter(req?: any, filter?: any): Observable<any> {
        if (filter === null) {
          filter = [];
        }
        const options = createRequestOption(req);
        return this.http.post<any[]>(this.filterUrl, filter, {
          params: options,
          observe: 'response',
        });
      }
   

}