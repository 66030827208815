import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchResult } from 'src/app/shared/models/search-result';
import { DepartmentList } from '../department-list/department-list';
import { DepartmentListService } from '../department-list/department-list.service';
import { LevelMasterService } from '../level-master/level-master.service';
import { LevelType } from '../level-type/level-type';
import { LevelTypeService } from '../level-type/level-type.service';
import {
  FinYear,
  limitTransDto,
  limitTransList,
  limitTransListDto,
} from '../limit-translist/limit-translist.module';
import { LimitTransListService } from '../limit-translist/limit-translist.service';
import { SchemeList } from '../scheme-list/scheme-list';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { LevelMasterDto } from './../level-master/level-master';

import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';

@Component({
  selector: 'app-limit-allocation',
  templateUrl: './limit-allocation.component.html',
  styleUrls: ['./limit-allocation.component.scss']
})
export class LimitAllocationComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  formulaitemsPerPage: any = 10;
  formulapage: number;
  formulafilterProps: any;
  searchResult: SearchResult<limitTransList> =
    new SearchResult<limitTransList>();
  cols: any;
  formulaCol: any;
  submitted: boolean;
  limitSetDialog: boolean;
  limitTransDto: limitTransDto;
  levelTypeList: LevelType[];
  limitTranslist: limitTransListDto;
  levelMasterList: any;
  agency: any;
  ComponentType: any;
  finYear: FinYear[];
  enableForm: any;
  departmentList: DepartmentList[];
  schemeList: SchemeList[];
  departmentDto: any;
  schemeDto: any;
  showText: boolean;
  enableTable: boolean;
  iaCompList: any;
  readonly:boolean=false;
  enableLbc:boolean=false;
  lbcPer:number;
  lbcAmount:number;
  districtLevelList: LevelMasterDto[];
  districtListDto: any;
  blockLevelList: LevelMasterDto[];
  BlockLevelDto: any;
  villageLevelList: LevelMasterDto[];
  villageLevelDto: any;
  parentLimitTransDto: limitTransDto;
  //required fields property
  ngSelectInvalid: boolean = false;
  iaError: any;
  limitAmount: number;
  amount:number;
  viewOnly: boolean;
  viewDoc: boolean;
  vFileExt: string;
  vFileUrl: string;
  isFile: boolean;
  districtExist: boolean;
  districtAdminExist: boolean;
  existingFundAuth: number;
  existingAdminFundAuth: number;
  edit: boolean;
  limitUpdateList = ['Credit', 'Debit'];
  existingLimit: number;
  existingProgrammeLimit: number;
  existingAdminLimit: number;
  limitExist: boolean;
  filterTimeout: any;

  constructor(
    private limitTransListService: LimitTransListService,
    private spinner: NgxSpinnerService,
    private levelTypeService: LevelTypeService,
    private levelMasterService: LevelMasterService,
    private departmentListService: DepartmentListService,
    private schemeListService: SchemeListService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    protected paymentService:PaymentService

  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit(): void {
    this.cols = [
      {
        field: 'levelType',
        header: 'Level Type',
        jhiTranslate: 'tableHead.levelType',
        width: 13,
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },
      {
        field: 'levelMaster',
        header: 'Level Master',
        jhiTranslate: 'tableHead.levelMaster',
        width: 25,
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },
      {
        field: 'implementingAgency',
        header: 'IA Name',
        jhiTranslate: 'tableHead.iA.name',
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },
      {
        field: 'transactionDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        width: 13,
        type:'date',
        isSelectcolumn: true,
        isFilterable: false,
      },
      {
        field: 'districtAllocationAmount',
        header: 'District Allocation Amount',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
        type:'amount'
      },
      {
        field: 'programmeAllocationAmount',
        header: 'Programme Allocation Amount',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
        type:'amount'
      },
      {
        field: 'adminAllocationAmount',
        header: 'Admin Allocation Amount',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
        type:'amount'
      },
      {
        field: 'allocationType',
        header: 'Allocation Type',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false
      },
    ];
    this.loadPage(1);
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.filterProps = this.filterProps ?? [];
    this.limitTransListService
      .filter({ ...filterQuery }, this.filterProps)
      .subscribe(
        (res: HttpResponse<limitTransList[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
          this.onError();
        }
      );
  }
  protected onSuccess(
    data: limitTransList[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  onLevelTypeChangeTrans($event?) {
    this.districtListDto = null;
    this.BlockLevelDto = null;
    this.villageLevelDto = null;
    this.districtExist=false;
    this.districtAdminExist=false;
    this.limitExist=false;
    this.amount=null;
    this.existingLimit=null;
    this.existingAdminLimit=null;
    this.existingProgrammeLimit=null;
    this.limitTransDto.lbcPercentage=10.25;
    if(this.limitTransDto.levelTypeDto.name =='State'){
        let filterQuery = {
          page: 0,
          size: 1,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'id',
              operation: 'equals',
              value: 1,
            },
          ])
          .subscribe(
            (res: HttpResponse<any>) => {
              this.limitTransDto.levelMasterDto = res.body[0];
            },
            () => {}
          );
          this.limitTransListService.getAmountAndLBC({
            levelMasterId: 1,
            schemeId: this.limitTransDto.schemeDto.id,
            finYearId: this.limitTransDto.finYearDto.id,
          })
          .subscribe(
            (res: HttpResponse<limitTransDto>) => {
              if(res.body.districtAllocationAmount){
                this.existingLimit=res.body.districtAllocationAmount;
                this.limitExist=true;
                if(!this.edit){
                  this.notificationService.alertError('Allocation already exist for the chosen leveltype,Use edit/Update button to update','');
                  return;
                }
              }
              if(res.body.programmeAllocationAmount){
                this.existingProgrammeLimit=res.body.programmeAllocationAmount;
                this.existingFundAuth=res.body.programmeFundAuthorizationAmount;
                this.districtExist=true;
              }
              if(res.body.adminAllocationAmount){
                this.existingAdminLimit=res.body.adminAllocationAmount;
                this.existingAdminFundAuth= res.body.adminFundAuthorizationAmount;
                this.districtAdminExist=true;
              }
              this.parentLimitTransDto=res.body;
            },
            () => {}
          );
      }
    else{
      if (this.filterTimeout) {
        clearTimeout(this.filterTimeout);
      }
    if ($event) {
      if($event.term){
        this.filterTimeout = setTimeout(() => {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
      }, 2000);
    }
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
    }
  }

  onDistrictLevelChange($event?) {
    this.districtExist=false;
    this.districtAdminExist=false;
    this.limitExist=false;
    this.amount=null;
    this.existingLimit=null;
    this.existingAdminLimit=null;
    this.existingProgrammeLimit=null;
    this.limitTransDto.lbcPercentage=10.25;
    if(this.limitTransDto.levelTypeDto?.name=='District'){
      this.limitTransDto.levelMasterDto=this.districtListDto;
      this.limitTransListService.getAmountAndLBC({
        levelMasterId: this.districtListDto.id,
        schemeId: this.limitTransDto.schemeDto.id,
        finYearId: this.limitTransDto.finYearDto.id,
      })
      .subscribe(
        (res: HttpResponse<limitTransDto>) => {
          if(res.body.districtAllocationAmount){
            this.existingLimit=res.body.districtAllocationAmount;
            this.limitExist=true;
            if(!this.edit){
              this.notificationService.alertError('Allocation already exist for the chosen leveltype,Use edit/Update button to update','');
              return;
            }
          }
          if(res.body.programmeAllocationAmount){
            this.existingProgrammeLimit=res.body.programmeAllocationAmount;
            this.existingFundAuth=res.body.programmeFundAuthorizationAmount;
            this.districtExist=true;
          }
          if(res.body.adminAllocationAmount){
            this.existingAdminLimit=res.body.adminAllocationAmount;
            this.existingAdminFundAuth= res.body.adminFundAuthorizationAmount;
            this.districtAdminExist=true;
          }
          this.parentLimitTransDto=res.body;
          // if(this.parentLimitTransDto.lbcPercentage)
          // this.limitTransDto.lbcPercentage=this.parentLimitTransDto.lbcPercentage;
        },
        () => {}
      );
    }
  }

  onBlockLevelChange($event?) {
    this.amount=null;
    if(this.limitTransDto.levelTypeDto?.name=='Block'){
      this.limitTransDto.levelMasterDto=this.BlockLevelDto;
      this.limitTransListService.getAmountAndLBC({
        levelMasterId: this.districtListDto.id,
        schemeId: this.limitTransDto.schemeDto.id,
        finYearId: this.limitTransDto.finYearDto.id,
      })
      .subscribe(
        (res: HttpResponse<limitTransDto>) => {
          this.parentLimitTransDto=res.body;
          if(this.parentLimitTransDto.lbcPercentage || !this.parentLimitTransDto?.lbcFlag)
          this.limitTransDto.lbcPercentage=this.parentLimitTransDto.lbcPercentage;
        },
        () => {}
      );
    }
      this.villageLevelDto = null;
      let payload={
        levelMasterId: this.BlockLevelDto.id,
        schemeId: this.limitTransDto.schemeDto.id,
        finYearId: this.limitTransDto.finYearDto.id,
      }
      if ($event) {
        this.levelMasterService
          .villageListByScheme(payload)
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.villageLevelList = res.body;
            },
            () => {}
          );
      } else {
        this.levelMasterService
          .villageListByScheme(payload)
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.villageLevelList = res.body;
            },
            () => {}
          );
      }
    }

  loadIA(id:number) {
    if(this.limitTransDto.levelTypeDto?.name=='Village'){
    this.amount=null;
      this.limitTransDto.levelMasterDto=this.villageLevelDto;
      this.limitTransListService.getAmountAndLBC({
        levelMasterId: this.BlockLevelDto.id,
        schemeId: this.limitTransDto.schemeDto.id,
        finYearId: this.limitTransDto.finYearDto.id,
      })
      .subscribe(
        (res: HttpResponse<limitTransDto>) => {
          this.parentLimitTransDto=res.body;
          if(this.parentLimitTransDto.lbcPercentage || !this.parentLimitTransDto?.lbcFlag)
          this.limitTransDto.lbcPercentage=this.parentLimitTransDto.lbcPercentage;
        },
        () => {}
      );
    }
    this.limitTransDto.implementingAgencyDto = null;
    this.limitTransListService
      .getImplementingAgencyByLevelMaster(id)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.iaCompList = res.body;
        },
        () => { }
      );
  }

  onlimitUpdate(){

  }

  async openNew() {
    this.submitted = false;
    this.limitSetDialog = true;
    this.limitTranslist = new limitTransListDto();
    this.enableForm = false;
    this.showText = false;
    this.limitTransDto = new limitTransDto();
    this.loadValue();
  }

  openEdit(){
    this.edit=true;
    this.submitted = false;
    this.limitSetDialog = true;
    this.limitTranslist = new limitTransListDto();
    this.enableForm = false;
    this.showText = false;
    this.limitTransDto = new limitTransDto();
    this.loadValue();
  }

  loadValue() {
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<FinYear[]>) => {
        this.finYear = res.body;
      },
      () => { }
    );
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        // this.levelTypeList = res.body;
        this.levelTypeList = res.body.filter(item => item.name === 'State' || item.name === 'District' );

      },
      () => { }
    );
  }

  hideDialog() {
    this.limitTranslist = new limitTransListDto();
    this.limitTransDto = new limitTransDto();
    this.ngSelectInvalid = false;
    this.amount=null;
    this.existingLimit=null;
    this.existingAdminLimit=null;
    this.existingProgrammeLimit=null;
    this.existingFundAuth=null;
    this.existingAdminFundAuth=null;
    this.limitSetDialog = false;
    this.submitted = false;
    this.viewOnly=false;
    this.isFile=false;
    this.edit=false;
  }

  calcLbc() {
    if(this.amount && this.limitTransDto.lbcPercentage )
    this.limitTransDto.lbcAmount=Number(((Number(this.amount) * this.limitTransDto.lbcPercentage)/100).toFixed(2));
  }

  saveData() {
    const isFormValid =
      this.limitTransDto.departmentDto &&
      this.limitTransDto.schemeDto &&
      this.limitTransDto.levelTypeDto &&
      this.limitTransDto.levelMasterDto &&
      this.limitTransDto.implementingAgencyDto &&
      this.limitTransDto.districtAllocationAmount &&
      this.limitTransDto.finYearDto &&
      this.limitTransDto.programmeAllocationAmount &&
      this.limitTransDto.adminAllocationAmount &&
      this.isFile &&
      this.limitTransDto.description
    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    this.ngSelectInvalid = false;
    // if(this.limitTransDto.levelTypeDto.name!='State' && !this.limitTransDto.levelMasterDto)
    // {
    //   this.ngSelectInvalid = true;
    //   return;
    // }
    if(!this.edit && this.limitExist){
      this.notificationService.alertError('Allocation already exist for the chosen leveltype,Use edit/Update button to update','');
      this.ngSelectInvalid=true;
      return;
    }
    this.limitTransDto.lbcFlag=this.enableLbc?'Yes':'No';
    // this.enableLbc?this.limitTransDto.programmeFundAuthorizationAmount=Number(this.amount) - Number(this.limitTransDto.lbcAmount):
    this.limitTransDto.lbcAmount=0;
    this.limitTransDto.lbcPercentage=0;
    this.limitTransDto.allocation='Yes';
    if(this.edit && !this.limitTransDto.allocationType){
      this.ngSelectInvalid = true;
      this.notificationService.alertError("Choose Allocation Type",'');
      return;
    }
    if(!this.edit){
      this.limitTransDto.allocationType='Credit';
    }
    if(Number(this.limitTransDto.programmeAllocationAmount)+Number(this.limitTransDto.adminAllocationAmount)>
    Number(this.limitTransDto.districtAllocationAmount) && !this.edit){
      this.notificationService.alertError("Programme Fund Allocation Amount and Admin Fund Allocation amount should not exceed Distict Allocation Amount ",'');
      this.ngSelectInvalid = true;
      return;
    }
    if(this.edit && (Number(this.limitTransDto.programmeAllocationAmount)+Number(this.limitTransDto.adminAllocationAmount) 
    + this.existingProgrammeLimit?Number(this.existingProgrammeLimit):0+this.existingAdminLimit?Number(this.existingAdminLimit):0)>
    (Number(this.limitTransDto.districtAllocationAmount) + Number(this.existingLimit))){
      this.notificationService.alertError("Programme Fund Allocation Amount and Admin Fund Allocation amount should not exceed Distict Allocation Amount ",'');
      this.ngSelectInvalid = true;
      return;
    }
    if(this.districtExist  && this.existingFundAuth){
      switch(this.limitTransDto.allocationType){
        case "Credit":{
          if(Number(this.existingProgrammeLimit)+Number(this.limitTransDto.programmeAllocationAmount)<Number(this.existingFundAuth)){
          this.notificationService.alertError("New Programme Fund Allocation Amount should not be lesser than  the existing Authorisation Amount "+this.existingFundAuth,'');
          this.ngSelectInvalid = true;
          return;
          }
          break;
        }
        case "Debit":{
          if(Number(this.existingProgrammeLimit)-Number(this.limitTransDto.programmeAllocationAmount)<Number(this.existingFundAuth)){
            this.notificationService.alertError("New Programme Fund Allocation Amount should not be lesser than  the existing Authorisation Amount "+this.existingFundAuth,'');
            this.ngSelectInvalid = true;
            return;
          }
          break;
        }
      }
    }
    if(this.districtAdminExist && this.existingAdminFundAuth){
      switch(this.limitTransDto.allocationType){
        case "Credit":{
          if(Number(this.existingAdminLimit)+Number(this.limitTransDto.adminAllocationAmount)<Number(this.existingAdminFundAuth)){
          this.notificationService.alertError("New Admin Fund Allocation Amount should not be lesser than  the existing Authorisation Amount "+this.existingAdminFundAuth,'');
          this.ngSelectInvalid = true;
          return;
          }
          break;
        }
        case "Debit":{
          if(Number(this.existingAdminLimit)-Number(this.limitTransDto.adminAllocationAmount)<Number(this.existingAdminFundAuth)){
            this.notificationService.alertError("New Admin Fund Allocation Amount should not be lesser than  the existing Authorisation Amount "+this.existingAdminFundAuth,'');
            this.ngSelectInvalid = true;
            return;
            }
          break;
        }
      }
    }
    this.spinner.show();
    this.limitTransListService
      .save(this.limitTransDto)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.limitSetDialog = false;
          this.spinner.hide();
          this.loadPage(this.page);
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        (onError) => {
          this.onError();
          this.spinner.hide();
          this.notificationService.alertError(onError.error.errorKey+': '+onError.error.title, '');
        }
      );
  }

  onSelectRow($event) {
    if ($event.type === 'select' || $event.type === 'view') {
      if($event.type === 'view'){
        this.viewOnly=true;
      }
      this.limitTransListService.getIAComponentLimitTransById($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.limitTransDto = res.body;    
            this.amount=this.limitTransDto.programmeFundAuthorizationAmount;
            if(this.limitTransDto.levelTypeDto?.name=='District'){
              this.districtListDto=this.limitTransDto.levelMasterDto;
            } else if(this.limitTransDto.levelTypeDto?.name=='Block'){
              this.BlockLevelDto=this.limitTransDto.levelMasterDto;
            } else if(this.limitTransDto.levelTypeDto?.name=='Village'){
              this.villageLevelDto=this.limitTransDto.levelMasterDto;
            }
          if(this.limitTransDto.fileName){
            this.isFile=true;
          }
          this.limitSetDialog = true;
          
        },
        () => {
          this.onError();
        }
      );
    } 
  }

  
  removeDocument() {
    this.paymentService.deleteimage(this.limitTransDto.fileName).subscribe(
      (res) => {
        this.notificationService.alertSuccess('File Deleted', '');
        this.isFile = false;
      },
      (error) => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }

  onUploadfile1($event) {
    const formData = new FormData();
    const i = $event.files.length - 1;
    const file = $event.files[i];
    const acceptedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (file && !acceptedFormats.includes(file.type)) {
      this.notificationService.alertError('Invalid file format. Please upload a valid file.','');
      return;
    }
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }

    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.notificationService.alertSuccess('File Upload SuccessFully', '');
        this.limitTransDto.fileName = res.body.fileName;
        this.isFile = true;
      },
      (onerr) => {
        this.notificationService.alertError('Error, please make sure file size is within 5MB and in supported format','');
      }
    );
  }

  viewDocument() {
    this.paymentService
      .viewimage(this.limitTransDto.fileName)
      .subscribe((res) => {
        const filename = res.body.fileName;
        this.vFileExt = filename.split('.').pop();
        this.vFileUrl =
          'data:image/' + this.vFileExt + ';base64,' + res.body.fileType;
        this.viewDoc = true;
      });
  }
  downloadData() {
    let data="District Allocation Report";
    
    this.spinner.show();
    this.limitTransListService.getExcelDownload(data).subscribe((res: HttpResponse<any>) => 
      {
       console.log(res);
       this.notificationService.alertSuccess(res.body, '');
       this.spinner.hide();
     },
      (onError) => {
        this.notificationService.alertError(onError.error.errorMessage, '');
        this.spinner.hide();
      }
    );
  }

  checkStatus() {
    let filter = {
      reportName:'District Allocation Report',
    };
    this.spinner.show();
    this.limitTransListService.reportAsyncStatusCheck({ ...filter }).subscribe(
      (res) => {
        console.log('res', res);
        let b: any = res.body;
        this.saveBlobToExcel(b.data,'District Allocation Report');
        this.spinner.hide();
      },
      (onError) => {
        this.notificationService.alertError(onError.error.errorMessage, '');
        this.spinner.hide();
      }
    );
  }

  saveBlobToExcel(data, filename) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }
}

