<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Failed Transaction List</h5>
                </ng-template>
                <ng-template pTemplate="right">

                </ng-template>
            </p-toolbar>
        </div>
    </div>

    <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
        <p-tabPanel header="Vendor Tranasction">
            <div *ngIf="activeIndex === 0" class="row">
                <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchVendorResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disablePaymentEditIcon]="true" [paginator]="false" (emitRow)="onSelectRow($event)" [enableCheckTransaction]="user?.role?.code === userRoles.roleApprover"></custom-filter-table>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Statutory Tranaction">
            <div class="row">
                <div #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="statutorycols" [filterSearchResult]="searchStatutoryResult" (emitRow)="onSelectRow($event)" [enableCheckTransaction]="user?.role?.code === userRoles.roleApprover" [paginator]="false"></custom-filter-table>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Miscellaneous Bulk">
            <div class="row">
                <p-table [value]="searchMisBulkResult.items" [tableStyle]="{ 'min-width': '100%' }" [resizableColumns]="true" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th [ngClass]="fontService.regularClass">District</th>
                            <th [ngClass]="fontService.regularClass">Voucher No</th>
                            <th [ngClass]="fontService.regularClass">Payment Date</th>
                            <th [ngClass]="fontService.regularClass">Implementing Agency</th>
                            <th [ngClass]="fontService.regularClass">Transaction Amount</th>
                            <th [ngClass]="fontService.regularClass">Transaction Status</th>
                            <th [ngClass]="fontService.regularClass">Status</th>
                            <th></th>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-list>
                        <tr>
                            <td>{{list?.levelMaster?.name}}</td>
                            <td>{{list?.voucherNumber}}</td>
                            <td>{{list?.paymentDate | date:'dd/MMM/yy'}}</td>
                            <td>{{list?.implementingAgency?.name}}</td>
                            <td>{{list?.transactionAmount | currency:'INR':'':'1.2-2':'en-IN'}}</td>
                            <td>{{list ?.transactionStatus}}</td>
                            <td>{{list?.signMessage}}</td>
                            <td><button pButton pRipple type="button" icon="pi pi-arrow-circle-right" class="p-button-rounded p-button-text" (click)="getSubBulkList(list)">
                            </button></td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Miscellaneous Bulk Sub List">
            <div class="row">
                <p-table [value]="bulkSubList" [tableStyle]="{ 'min-width': '100%' }" [resizableColumns]="true" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>

                            <th [ngClass]="fontService.regularClass">Voucher No</th>
                            <th [ngClass]="fontService.regularClass">Account Number</th>
                            <th [ngClass]="fontService.regularClass">Bank Name</th>
                            <th [ngClass]="fontService.regularClass">Bank Ifsc Code</th>
                            <th [ngClass]="fontService.regularClass">Payment Date</th>
                            <th [ngClass]="fontService.regularClass">Deduction Type</th>
                            <th [ngClass]="fontService.regularClass">Transaction Amount</th>
                            <th [ngClass]="fontService.regularClass">Transaction Status</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-list>
                        <tr>
                            <td>{{list?.voucherNumber}}</td>
                            <td *ngIf="list?.paymentType === 'Holding Account'">{{list?.statutoryAccountNumber}}</td>
                            <td *ngIf="list?.paymentType !== 'Holding Account'">{{list?.vendorBankAccNo}}</td>
                            <td *ngIf="list?.paymentType === 'Holding Account'">{{list?.statutoryBankName}}</td>
                            <td *ngIf="list?.paymentType !== 'Holding Account'">{{list?.vendorBankName}}</td>
                            <td *ngIf="list?.paymentType === 'Holding Account'">{{list?.statutoryBankIfscCode}}</td>
                            <td *ngIf="list?.paymentType !== 'Holding Account'">{{list?.vendorBankIfscCode}}</td>
                            <td>{{list?.paymentDate | date:'dd/MMM/yy'}}</td>
                            <td>{{list?.deductionName}}</td>
                            <td>{{list?.transactionAmount | currency:'INR':'':'1.2-2':'en-IN'}}</td>
                            <td>{{list ?.transactionStatus}}</td>
                            <td>
                                <button *ngIf="list?.transactionStatus === 'Pending'" pButton pRipple type="button" label="Confirm" class="p-button p-button-success" (click)="pendingBulkTransaction('true',list)">
                                </button>
                                <button *ngIf="list?.transactionStatus === 'Pending'" pButton pRipple type="button" label="Dispute" class="p-button p-button-danger" (click)="pendingBulkTransaction('false',list)">
                                </button>
                                <button *ngIf="list?.reInitialize" pButton pRipple type="button" label="Reinitiate Payment" class="p-button-text" (click)="reinitializeBulk(list)">
                                </button>
                            </td>

                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Work Maintenance">
            <div *ngIf="activeIndex === 4" class="row">
                <custom-filter-table [columnsValues]="cols" [filterSearchResult]="maintenanceFailedTransaction" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disablePaymentEditIcon]="true" [paginator]="false" (emitRow)="onSelectRow($event)" [enableCheckTransaction]="user?.role?.code === userRoles.roleApprover"></custom-filter-table>
            </div>
        </p-tabPanel>
    </p-tabView>



</div>

<p-dialog [(visible)]="otpBox" [style]="{ width: '500px', height: '350px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
    <p-header [ngClass]="fontService.headingClass">
        OTP page:
    </p-header>
    <div class="row">
        <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass">Enter Otp</label
      >
        <input
          type="text" 
          class="form-control"
          autocomplete="off"
          maxlength="6"
          [(ngModel)]="otp"
          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
          name="name"
          id="otp"
          (input)="validateOtp()"
        />
       
        <div *ngIf="otpError" class="mt-2">
            <small style="font-size: 30px;" class="text-danger">{{otpError}}</small>
        </div>
    </div>
    <div class="mb-4 mt-3">
        Resend OTP in: {{ otpTimer }} seconds
    </div>
    <div class="row">
        <div class="col">
            <button *ngIf="enableVerifyButton" (click)="verifyOtp()" class="btn btn-secondary">Verify Otp</button>
        </div>
        <div class="col text-end">
            <button type="button" [disabled]="!enableResendButton" (click)="getUserOtp('reSend')" class="btn btn-secondary">Resend OTP</button>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <!-- <button pButton pRipple class="p-button-danger">
            <span class="button-label" [ngClass]="fontService.buttonClass" label="Reject"
                (click)="rejectDialog()">Reject</span>
        </button> -->
    </ng-template>

</p-dialog>

<p-dialog [(visible)]="paymentSucessDialog" [style]="{ width: '500px', height: '225px' }" [modal]="true" [baseZIndex]="1000" (onhide)="loadPage()" styleClass="ib-dialog">
    <p-header [ngClass]="fontService.headingClass">
        Payment Alert Box
    </p-header>
    <h1 style="text-align: center;font-size: 18px;">{{ paymentSucessText }}</h1>
    <ng-template pTemplate="footer">
        <button pButton pRipple class="p-button-text custom-button" (click)="paymentSucessDialog = false;this.bulkSubList = null;this.loadPage()">
        <span
          class="button-label"
          [ngClass]="fontService.buttonClass"
          label="Ok"
          (click)="paymentSucessDialog = false;this.loadPage()"
          >Ok</span
        >
      </button>
    </ng-template>
</p-dialog>