import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { Funds, FundsDto } from './funds';
import { LocalStorageService } from 'ngx-webstorage';
export type EntityArrayResponseType = HttpResponse<Funds[]>;
export type EntityResponseType = HttpResponse<Funds>;

@Injectable()
export class FundsService {
  protected resourceFliterUrl =
    environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getFundsByFilter';
  protected resourceValidUrl =
    environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getFundsValidByFilter';
  protected resourceSaveUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/SaveFund';
  protected resourceDeleteUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/deleteFund';
  protected getFundsUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getFund';
  constructor(protected http: HttpClient,private localStorageService: LocalStorageService,) {}
  filter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<Funds[]>(this.resourceFliterUrl, filter, {
      params: options,
      observe: 'response',
    });
  }
  validFilter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<Funds[]>(this.resourceFliterUrl, filter, {
      params: options,
      observe: 'response',
    });
  }
  save(fund?: FundsDto): Observable<EntityArrayResponseType> {
    return this.http.post<any>(this.resourceSaveUrl, fund, {
      observe: 'response',
    });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.get(`${this.resourceDeleteUrl}?id=${id}`, {
      observe: 'response',
    });
  }

  getFunds(id: number): Observable<HttpResponse<any>> {
    return this.http.get<FundsDto>(`${this.getFundsUrl}?id=${id}`, {
      observe: 'response',
    });
  }
}
