<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="breadcrumb.commercial.name">
                        Commercial Vendors
                    </h5>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>
                <ng-template pTemplate="right">
                    <div class="p-toolbar-group-end">
                        <div class="row download_field">
                            <div class="col-sm">
                                <button pButton pRipple label="Download" (click)="downloadData()"
                                    class="p-button-success download-btn" styleClass="button"><br />
                                    <span class="button-label"></span>
                                </button>
                            </div>
                            <div class="col-sm">
                                <button pButton pRipple label="Check Download Status" (click)="checkStatus()"
                                    class="p-button-success download-status-btn" styleClass="button"><br />
                                    <span class="button-label"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <button *ngIf="!isChecker" pButton pRipple icon="pi pi-plus"
                        class="p-button-secondary custom-button  new-button" (click)="openNew()" styleClass="button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.newbutton"></span>
                    </button>
                    <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [fileName]="'Vendor'" [levelMasterId]="levelMasterId" [columnsValues]="cols"
                [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true"
                [fileName]="'Vendor'" [disableEditIcon]="true" [disableVendorEdit]="allowEdit?false:true"
                [disableVendorDelete]="allowEdit?false:true" [enableApproveButton]="enableApprove"
                (emitRow)="onSelectRow($event)" [enableViewIcon]="true" [currentRole]="userCode"></custom-filter-table>
        </div>
    </div>
    <p-dialog #dialog [(visible)]="commercialVendorsDialog" [style]="{ width: '850px' }" header="User Details"
        [modal]="true" styleClass="ib-dialog" (onHide)="hideDialog()">
        <p-header [ngClass]="fontService.headingClass">
            {{ "vendor.commercial.add" | translate }}
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row gy-2">
                        <div class="col-12">
                            <h6 [ngClass]="fontService.headingClass" jhiTranslate="vendor.commercial.details">
                                Commercial Vendor Details
                            </h6>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="vendorType" [ngClass]="fontService.labelClass"
                                jhiTranslate="nav.vendor.vendortype">Vendor Type</label>
                            <ng-select [appendTo]="'.p-dialog'" id="vendorType"
                                [(ngModel)]="commercialVendor.commercialDto" [items]="commercialList"
                                (change)="onVendorTypeChange()" bindLabel="name" appearance="outline"
                                [closeOnSelect]="true" [clearable]="true" class="form-control" [disabled]="viewOnly"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.commercialDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4"
                            *ngIf="(commercialVendor?.commercialDto && commercialVendor?.commercialDto?.name=='SOLE PROPRIETOR') || (commercialVendor?.commercialDto && commercialVendor?.commercialDto?.name=='PARTNERSHIP')">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for=" name">Firm
                                Name</label>
                            <input type="text" class="form-control" [(ngModel)]="commercialVendor.name" name="name"
                                autocomplete="off" maxlength="60"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                                uppercase [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.name }"
                                [disabled]="viewOnly" id="name" />
                            <div *ngIf="showNameError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.showNameError">{{
                                    showNameError }}</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4"
                            *ngIf="(commercialVendor?.commercialDto && commercialVendor?.commercialDto?.name=='PRIVATE LIMITED') || (commercialVendor?.commercialDto && commercialVendor?.commercialDto?.name=='PUBLIC LIMITED')">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.companyName" for=" companyName">Company Name</label>
                            <input type="text" class="form-control" name="companyName" autocomplete="off"
                                [(ngModel)]="commercialVendor.companyName" id="companyName" maxlength="60"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" uppercase
                                [disabled]="viewOnly"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.companyName }" />
                            <div *ngIf="showCompanyNameError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.showNameError">{{
                                    showCompanyNameError }}</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4"
                            *ngIf="(commercialVendor?.commercialDto && commercialVendor?.commercialDto?.name==='PRIVATE LIMITED') || (commercialVendor?.commercialDto && commercialVendor?.commercialDto?.name==='PUBLIC LIMITED')">
                            <label class="form-control-label req" for="registrationNo"
                                [ngClass]="fontService.labelClass" jhiTranslate="nav.vendor.registrationNo">Registration
                                No</label>
                            <input type="text" class="form-control" autocomplete="off"
                                [(ngModel)]="commercialVendor.registrationNo" maxlength="15" [disabled]="viewOnly"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" uppercase
                                name="registrationNo" id="registrationNo"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.registrationNo }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="mobileNo" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.mobile">Mobile No</label>
                            <input class="form-control" [(ngModel)]="commercialVendor.mobile" (input)="validateMobile()"
                                autocomplete="off" [disabled]="viewOnly"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.mobile }" maxlength="10"
                                name="mobileNo" id="mobileNo" />
                            <div *ngIf="showMobileError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validmobile">Please enter
                                    a valid Mobile
                                    Number.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="email" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.email">E-mail</label>
                            <input type="text" class="form-control" [(ngModel)]="commercialVendor.email" name="email"
                                autocomplete="off" id="email" maxlength="60" onblur="this.value =this.value.trim();"
                                (input)="validateEmail()"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.email }"
                                [disabled]="viewOnly" />
                            <div *ngIf="showeEmailError">
                                <small [ngClass]="fontService.smallClass">{{ showeEmailError }}</small>
                            </div>
                            <div *ngIf="emailError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validemail">Please enter a
                                    valid Email
                                    Address.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="aadhaar" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.aadhaarno">Aadhaar Number</label>
                            <input type="text" class="form-control" [(ngModel)]="commercialVendor.aadhaarNo"
                                name="aadhaar" autocomplete="off" id="aadhaar"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                onblur="this.value =this.value.trim();" (input)="validateAadhaar()"
                                [disabled]="viewOnly"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.aadhaarNo }"
                                maxlength="12" />
                            <div *ngIf="showAadhaarNoError">
                                <small [ngClass]="fontService.smallClass">
                                    {{ showAadhaarNoError }}

                                </small>
                            </div>

                            <div *ngIf="aadhaarError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validaadhar">Please enter
                                    a valid
                                    Aadhaar Number.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="panNo" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.panno">PAN No</label>
                            <input type="text" class="form-control" name="panNo" autocomplete="off"
                                [(ngModel)]="commercialVendor.panNo" id="panNo" [disabled]="viewOnly"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" uppercase (input)="validatePan()"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.panNo }"
                                maxlength="10" />
                            <div *ngIf="showPanError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.panNoUniError">
                                    {{ showPanError }}
                                </small>
                            </div>
                            <div *ngIf="panError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validpan">Please enter a
                                    valid PAN
                                    Number.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" jhiTranslate="user.gstNo" for=" gstNo" [ngClass]="{
                                'req': mandateGst,
                                'fontService.labelClass': true
                              }" jhiTranslate="user.gstNo">GST No</label>
                            <input type="text" class="form-control" name="gstNo" autocomplete="off"
                                [(ngModel)]="commercialVendor.gstNo" id="gstNo"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" uppercase (input)="validateGst()" maxlength="15"
                                [disabled]="viewOnly"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.gstNo && mandateGst  }" />
                            <div *ngIf="showGstError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.gstUniError">
                                    {{ showGstError }}
                                </small>
                            </div>
                            <div *ngIf="gstError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validgst">Please enter a
                                    valid GST
                                    Number.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="tanNo" [ngClass]="fontService.labelClass"
                                jhiTranslate="user.tanNo">TAN No</label>
                            <input type="text" name="tanNo" autocomplete="off" class="form-control"
                                [(ngModel)]="commercialVendor.tanNo"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" uppercase id="tanNo" (input)="validateTan()"
                                maxlength="10" [disabled]="viewOnly" />
                            <div *ngIf="tanError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validtan">Please enter a
                                    valid TAN
                                    Number.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="tinNo" [ngClass]="fontService.labelClass"
                                jhiTranslate="user.tinNo">TIN No</label>
                            <input type="text" class="form-control" name="tinNo" autocomplete="off"
                                [(ngModel)]="commercialVendor.tinNo"
                                oninput="this.value = this.value.replace(/[^0-9-]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" uppercase id="tinNo" [disabled]="viewOnly"
                                (input)="validateTin()" maxlength="11" />
                            <div *ngIf="tinError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validtin">Please enter a
                                    valid TIN
                                    Number.</small>
                            </div>
                        </div>
                    </div>
                    <div class="row gy-2 mt-3">
                        <div class="col-12">
                            <h6 [ngClass]="fontService.headingClass" jhiTranslate="dashboard.parmenent">
                                Permenent Address
                            </h6>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="doorNo" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.buildingno">Building No/Door No</label>
                            <input type="text" class="form-control" name="doorNo" autocomplete="off" id="doorNo"
                                [(ngModel)]="commercialVendor.doorNo" (change)="addresschange('check')" maxlength="20"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" uppercase
                                [disabled]="viewOnly"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.doorNo }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="street" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.street">Street</label>
                            <input type="text" class="form-control" name="street" autocomplete="off" id="street"
                                [(ngModel)]="commercialVendor.street" (change)="addresschange('check')" maxlength="50"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,.-]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" uppercase
                                [disabled]="viewOnly"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.street }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="landmark" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.landmark">Landmark</label>
                            <input type="text" class="form-control" (change)="addresschange('check')" name="landmark"
                                autocomplete="off" maxlength="50" [disabled]="viewOnly"
                                oninput="this.value = this.value.replace(/[^A-Za-z ,.-]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" uppercase
                                id="landmark" [(ngModel)]="commercialVendor.area" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="city" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.city">City</label>
                            <input type="text" class="form-control" name="city" autocomplete="off"
                                [(ngModel)]="commercialVendor.city" id="city" maxlength="20" [disabled]="viewOnly"
                                oninput="this.value = this.value.replace(/[^A-Za-z ,.-]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" uppercase
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.city }"
                                (change)="addresschange('check')" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="district" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.district">District</label>
                            <input type="text" class="form-control" name="district" autocomplete="off"
                                [(ngModel)]="commercialVendor.district" id="district" maxlength="20"
                                oninput="this.value = this.value.replace(/[^A-Za-z ,.-]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" uppercase
                                (change)="addresschange('check')" [disabled]="viewOnly"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.district }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="state" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.state">State</label>
                            <input type="text" class="form-control" name="state" autocomplete="off"
                                [(ngModel)]="commercialVendor.state" (change)="addresschange('check')" id="state"
                                maxlength="50"
                                oninput="this.value = this.value.replace(/[^A-Za-z ,.-]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" uppercase
                                [disabled]="viewOnly"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.state }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="pinCode" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.pincode">Pincode</label>
                            <input type="text" class="form-control" name="pinCode" autocomplete="off" id="pinCode"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                [(ngModel)]="commercialVendor.pinCode" (input)="validatePin()" [disabled]="viewOnly"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.pinCode }"
                                (change)="addresschange('check')" maxlength="6" />
                            <div *ngIf="pinError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validpin">Please enter a
                                    valid
                                    Pincode.</small>
                            </div>
                        </div>
                    </div>
                    <div class="row gy-2 mt-3">
                        <div class="col-12">
                            <h6 [ngClass]="fontService.headingClass" jhiTranslate="dashboard.commercial">
                                Commercial Address
                            </h6>
                        </div>
                        <div class="col-12">
                            <span>
                                <p-checkbox (onChange)="addresschange()" [(ngModel)]="checked"
                                    binary="true"></p-checkbox>
                                <label style="margin-left: 15px; font-size: 14px" [ngClass]="fontService.labelClass"
                                    jhiTranslate="error.sameAs">Same as Permanent Address</label>
                            </span>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for=" doorNo" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.buildingno">Building No/Door No</label>
                            <input type="text" class="form-control" name="doorNo" autocomplete="off"
                                [(ngModel)]="commercialVendor.companyDoorNo" id="doorNo" maxlength="50"
                                [disabled]="viewOnly"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,.-]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" uppercase
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.companyDoorNo }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.street" for=" street">Street</label>
                            <input type="text" class="form-control" name="street" autocomplete="off" id="street"
                                maxlength="50"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,.-]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" uppercase
                                [(ngModel)]="commercialVendor.companyStreet" [disabled]="viewOnly"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.companyStreet }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.landmark" for=" landmark">Landmark</label>
                            <input type="text" class="form-control" maxlength="50" [disabled]="viewOnly"
                                oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" name="landmark"
                                autocomplete="off" id="landmark" uppercase [(ngModel)]="commercialVendor.companyArea" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.city" for=" city">City</label>
                            <input type="text" class="form-control" name="city" autocomplete="off" maxlength="50"
                                oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" id="city"
                                [(ngModel)]="commercialVendor.companyCity" [disabled]="viewOnly" uppercase
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.companyCity }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.district" for=" district">District</label>
                            <input type="text" class="form-control" name="district" autocomplete="off" id="district"
                                maxlength="20"
                                oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                [(ngModel)]="commercialVendor.companyDistrict" uppercase [disabled]="viewOnly"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.companyDistrict }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for=" state" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.state">State</label>
                            <input type="text" class="form-control" name="state" autocomplete="off"
                                [(ngModel)]="commercialVendor.companyState" [disabled]="viewOnly"
                                oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" id="state"
                                uppercase
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.companyState }"
                                maxlength="20" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.pincode" for=" pinCode">Pincode</label>
                            <input type="text" class="form-control" name="pinCode" autocomplete="off"
                                [(ngModel)]="commercialVendor.companyPinCode" id="pinCode" [disabled]="viewOnly"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.companyPinCode }"
                                maxlength="6" (input)="validateCPin()" />
                            <div *ngIf="cPinError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validpin">Please enter a
                                    valid
                                    Pincode.</small>
                            </div>
                        </div>
                    </div>
                    <div class="row gy-2 mt-3">
                        <div class="col-12">
                            <h6 [ngClass]="fontService.headingClass" jhiTranslate="agency.account.detail.name">
                                Vendor Account Details
                            </h6>
                        </div>

                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.ifscode" for=" ifsc">IFSC Code</label>
                            <input type="text" class="form-control" name="ifsc" autocomplete="off" maxlength="11"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" uppercase [readonly]="readonly"
                                [(ngModel)]="commercialVendor.ifscCode" id="ifsc" [disabled]="viewOnly"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.ifscCode }"
                                maxlength="11" (input)="validateIfsc()" (change)="accountDetailsChanged()" />
                            <div *ngIf="ifscError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a
                                    valid
                                    IFSCode.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.bank.name" for=" bankName">Bank Name</label>
                            <h4 [ngClass]="fontService.regularClass" class="mb-0">{{commercialVendor.bankName}}</h4>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="agency.branch.name" for=" branchName">Branch Name</label>
                            <h4 [ngClass]="fontService.regularClass" class="mb-0">{{commercialVendor.bankBranch}}</h4>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.accountno" for=" accountNo">Account Number</label>
                            <input type="text" class="form-control" name="accountNo" autocomplete="off" maxlength="25"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                [readonly]="readonly" [(ngModel)]="commercialVendor.accountNumber" uppercase
                                id="accountNo"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.accountNumber }"
                                [disabled]="viewOnly" />
                            <div *ngIf="showaccountNoError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.accountNoUniError">{{
                                    showaccountNoError }}</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.bank.account.name" for=" bankAccountName">Bank Account Name As per
                                Passbook</label>
                            <input type="text" class="form-control" name="bankAccountName" autocomplete="off"
                                maxlength="60"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                [readonly]="readonly" uppercase [(ngModel)]="commercialVendor.bankAccName"
                                id="bankAccountName" [disabled]="viewOnly"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.bankAccName }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.micr" for=" micr">MICR</label>
                            <input type="text" class="form-control" name="micr" autocomplete="off" [readonly]="readonly"
                                [(ngModel)]="commercialVendor.micr" maxlength="9" id="micr" [disabled]="viewOnly" />
                        </div>
                        <div class="col-12 justify-content-center"
                            *ngIf="currentEnvironment==='uat' || currentEnvironment==='production'">
                            <button pButton pRipple class="p-button-success custom-button" (click)="accountValidation()"
                                styleClass="button" [disabled]="readonly">
                                <span class="button-label" [ngClass]="fontService.buttonClass"
                                    jhiTranslate="common.verifyaccount">Verify Account</span>
                            </button>
                            <div *ngIf="accountInvalidError" class="text-danger">
                                <small [ngClass]="fontService.smallClass">{{"error.accountinvaliderror" |
                                    translate}}</small>
                            </div>
                            <div *ngIf="ngSelectInvalid && !verifyClicked" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.verifyaccount"> Please
                                    verify the account</small>
                            </div>
                        </div>
                        <div class="col-12 justify-content-center"
                            *ngIf="currentEnvironment!=='uat' && currentEnvironment!=='production'">
                            <button pButton pRipple class="p-button-success custom-button" (click)="accountValidation()"
                                styleClass="button" [disabled]="readonly || viewOnly">
                                <span class="button-label" [ngClass]="fontService.buttonClass"
                                    jhiTranslate="common.verifyaccount">Verify Account</span>
                            </button>
                            <div *ngIf="accountInvalidError" class="text-danger">
                                <small [ngClass]="fontService.smallClass">{{"error.accountinvaliderror" |
                                    translate}}</small>
                            </div>
                            <div *ngIf="ngSelectInvalid && !verifyClicked" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.verifyaccount"> Please
                                    verify the account</small>
                            </div>
                        </div>
                        <div *ngIf="statusButton && currentEnvironment!=='uat' && currentEnvironment!=='production'"
                            class="col-12 justify-content-center">
                            <button pButton pRipple class="p-button-success custom-button"
                                (click)="accountValidationStatus()" styleClass="button" [disabled]="viewOnly">
                                <span class="button-label" [ngClass]="fontService.buttonClass">Check Account
                                    Status</span>
                            </button>
                            <div *ngIf="accountInvalidError" class="text-danger">
                                <small [ngClass]="fontService.smallClass">{{"error.accountinvaliderror" |
                                    translate}}</small>
                            </div>
                            <div *ngIf="ngSelectInvalid && !verifyClicked" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.verifyaccount"> Please
                                    verify the account</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
                <span class="button-label" [ngClass]="fontService.buttonClass" custom-button
                    jhiTranslate="common.cancelbutton"></span>
            </button>
            <button *ngIf="!commercialVendor.id && !viewOnly" pButton pRipple icon="pi pi-check"
                class="p-button-text custom-button" (click)="saveData()">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
            </button>
            <button *ngIf="commercialVendor.id && !viewOnly" pButton pRipple icon="pi pi-check"
                class="p-button-text custom-button" (click)="saveData()">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
            </button>
            <button *ngIf="approveView" pButton pRipple icon="pi pi-check" class="p-button-success custom-button"
                (click)="approveVendor(commercialVendor.id)">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.approvebutton"></span>
            </button>
            <button *ngIf="approveView" pButton pRipple icon="pi pi-times" class="p-button-danger custom-button"
                (click)="rejectVendor(commercialVendor.id)">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.rejectbutton"></span>
            </button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>