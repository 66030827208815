import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';

import { DepartmentList } from './department-list';
import { LocalStorageService } from 'ngx-webstorage';
export type EntityArrayResponseType = HttpResponse<DepartmentList[]>;
export type EntityResponseType = HttpResponse<DepartmentList>;

@Injectable()
export class DepartmentListService {
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/departmentList');
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/saveDepartment');
    protected resourseGetUrl = environment.SERVER_API_URL+('api/')
    constructor(protected http: HttpClient,private localStorageService:LocalStorageService) {}
    filter(): Observable<EntityArrayResponseType> {
      
        return this.http
        .get<DepartmentList[]>(this.resourceFliterUrl,{ observe: 'response' })
    }
    save(departmentList?:DepartmentList): Observable<EntityResponseType> {
        return this.http
        .post<DepartmentList>(this.resourceSaveUrl,departmentList, {observe: 'response' })
    }
}