import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { AccountSna, AccountSnaDto } from './account-sna';
import { LocalStorageService } from 'ngx-webstorage';
export type EntityArrayResponseType = HttpResponse<AccountSna[]>;

@Injectable()
export class AccountSnaService {

    constructor(private http: HttpClient,private localStorageService: LocalStorageService,) { }

    

    protected resourceFliterUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getSnaByFilter');
    protected getAllSna = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/snaList');
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/saveSna');
    protected resourceDeleteUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/deleteSna');
    protected getSnaUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getSna');

    filter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<AccountSna[]>(this.resourceFliterUrl,filter, { params:options, observe: 'response' })
    }

    getAllSnaList(): Observable<any>{
      return this.http
        .get<AccountSnaDto[]>(this.getAllSna, {observe: 'response' })
    }

    save(sna?:AccountSnaDto): Observable<any> {
      return this.http
      .post<any>(this.resourceSaveUrl,sna, {observe: 'response' })
    }

    delete(id: number): Observable<HttpResponse<{}>> {
      return this.http.get(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
    }

    getSna(id: number): Observable<HttpResponse<any>> {
      return this.http.get<AccountSnaDto>(`${this.getSnaUrl}?id=${id}`, { observe: 'response' });
    }




}