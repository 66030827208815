import { vendorTransactionDto } from './../payment-voucher/payment-voucher.model';
import { LevelMaster } from './../../module/level-master/level-master';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  CommercialVendorsDto,
  Vendor,
} from 'src/app/module/commercial-vendors/commercial-vendors';
import { CommercialVendorService } from 'src/app/module/commercial-vendors/commercial-vendors.service';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { DepartmentList } from 'src/app/module/department-list/department-list';
import { DepartmentListService } from 'src/app/module/department-list/department-list.service';
import { LevelTypeService } from 'src/app/module/level-type/level-type.service';
import { SchemeList } from 'src/app/module/scheme-list/scheme-list';
import { SchemeListService } from 'src/app/module/scheme-list/scheme-list.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import {
  ComponentDto,
  ComponentType,
} from './../../module/component-type/component-type';
import { LevelType } from './../../module/level-type/level-type';
import { filterQuery } from './../../shared/common.model';


import { registerLocaleData } from '@angular/common';
import enIN from '@angular/common/locales/en-IN';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Dialog } from 'primeng/dialog';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { NavbarComponent } from 'src/app/layout/navbar/navbar.component';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { UserService } from 'src/app/module/user/user.service';
import { TenderPageService } from 'src/app/module/vendor-mapping-page/tendor-page.service';
import { Project } from 'src/app/project/project.model';
import { ProjectService } from 'src/app/project/project.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { environment } from 'src/environments/environment';
import { MaintenanceGeneration, MaintenancePayment, MaintenanceTransaction } from './work-maintenance-payment.model';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { StatutoryPaymentVoucherService } from '../statutory-payment-voucher/statutory-payment-voucher.service';

@Component({
  selector: 'app-work-maintenance-payment-voucher',
  templateUrl: './work-maintenance-payment-voucher.component.html',
  styleUrl: './work-maintenance-payment-voucher.component.scss'
})
export class WorkMaintenancePaymentVoucherComponent {
  @ViewChild('dialog') dialog: Dialog;
  @ViewChild('formData') formData;
  navbarComponent!: NavbarComponent;
  enableStateGst: boolean;
  enableIGst: boolean;
  departmentList: DepartmentList[];
  pdfString: any;
  schemeList: SchemeList[];
  selectedVendor: CommercialVendorsDto;
  levelType: LevelType[];
  grantLevel: ComponentType;
  grantlevel: any;
  componentMasterList: any;
  levelMasterDto: LevelType;
  voucherGenerationDto: MaintenanceGeneration;
  date: Date;
  voucherNo: string;
  vendorList: Vendor[];
  projectList: Project[];
  selectedProject: any;
  selectedscheme: any;
  profileDialog: boolean = false;
  searchResult: SearchResult<any> =
    new SearchResult<MaintenanceGeneration>();
  totalDeduction: number;
  dropDownLabel: any;
  maxDate = new Date();
  amount: number;
  description: string;
  signButtonName: any;
  noRecord: boolean;
  isFileUploaded: boolean;
  uFilename: string;
  viewDoc: boolean = false;
  vFileExt: string;
  vFileUrl: string;
  finYear: FinYear[];
  paymentTypeList = ['Part', 'Final'];
  currentPage: number = 0;
  loadingMore: boolean = false;
  userContextSubscription: Subscription;
  requiredError: boolean;
  reasonDialog: boolean;
  rejectionReason: string;
  enableBackButton: boolean = true;
  vendorFilterQuery = [
    {
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    },
    {
      key: 'approve',
      operation: 'equals',
      value: 'YES',
    },

  ];
  cols = [
    {
      field: 'levelMasterName',
      header: 'District',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'workId',
      header: 'Work Id',
      isFilterable: true,
      isSelectcolumn: true,
    },
    {
      field: 'voucherNo',
      header: 'Voucher No',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'commercialVendorName',
      header: 'Vendor Name',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'bankName',
      header: 'Bank Name',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'commercialIfscode',
      header: 'IFSC Code',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'commercialAccountNo',
      header: 'Vendor Account',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'transDate',
      header: 'Transaction Date',
      isSortable: true,
      isSelectcolumn: true,
      type: 'date',
    },
    {
      field: 'grossAmount',
      header: 'Rate',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
      isFilterable: false,
    },
    {
      field: 'transactionAmount',
      header: 'Total Transction Amount',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
      isFilterable: false,
    },
  ];

  // validation fields properties
  public ifscError = false;
  public panError = false;
  public hsnError = false;

  //required fields property
  ngSelectInvalid: boolean = false;
  filterProps: any;
  securityDeposit: number;
  enableGstOnTds: boolean;
  gstOnTdsAmount: number;
  uploadedFiles: any;
  enableLabourChess: boolean;
  enableSecurityDeposit: boolean;
  enableMaterialSupply: boolean;
  enableMiscellaneous: boolean;
  enableMiscellaneousAdditional: boolean;
  roleCode: any;
  readonly: boolean;
  enableRevertButton: boolean;
  param1: any;
  param2: any;
  param3: any;
  enableSignButton: boolean;
  downloadButton: boolean;
  paymentSucessDialog: boolean;
  paymentSucessText: any;
  enablesign: boolean = false;
  enableRePaymentButton: boolean;
  disablepaymentButton: boolean;
  buttonText: string;
  checkstatusButton: boolean;
  interactionId: any;
  checkAmount: boolean;
  zonalList: LevelType;
  selectedZonalUser: any;
  enableZonalButton: boolean;
  enableApproved: boolean;
  finy: any;
  limitAmount: number;
  projectLimitAmount: number;
  isEditable: boolean = true;
  amountPaid: number = 0;
  balancePay: number = 0;
  disableAmount: boolean;
  partialCount: string;
  isPartial: boolean;
  totalWithheldAmount: number = 0;
  partCount: string;
  WithheldAmount: number = 0;
  totWithheldAmount: number;
  totalBillValue: number = 0;
  totBillValue: number;
  workPayment: boolean;
  noPendingVoucher: boolean;
  workPaymentList: any;
  finalPayment: string;
  filterTimeout: any;
  enableView: boolean;
  userRoles = userRoles;
  implementingAgencyDto: any;
  finYearName: FinYear;
  isproceeding: boolean;
  isMaintenanceUpload: boolean;
  reportUpload: boolean;
  tdsApplicable: boolean;



  constructor(
    protected activatedRoute: ActivatedRoute,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private componentTypeService: ComponentTypeService,
    private commercialVendorService: CommercialVendorService,
    private paymentService: PaymentService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private userService: UserService,
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private limitTransListService: LimitTransListService,
    private projectService: ProjectService,
    private userRoleService: UserRoleService,
    private statutoryPaymentVoucherService: StatutoryPaymentVoucherService,
  ) {
    if (this.formData) {
      this.formData.valueChanges.subscribe((event) => { });
    }
    registerLocaleData(enIN);
    this.translateService.onLangChange.subscribe((event) => {
      const lang = this.translateService.currentLang;
      this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
    });
  }

  async ngOnInit(): Promise<void> {

    this.componentTypeService.filter().subscribe(
      (res: HttpResponse<any>) => {
        this.grantLevel = res.body.filter(item => item.name === 'Programme Fund');
        this.grantlevel = this.grantLevel[0];
      },
      (error) => { }
    );

    await this.getRole();
    this.paymentService
      .getByImplemeting(this.roleCode.implementingAgencyId)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.implementingAgencyDto =
            res.body;

          this.levelTypeService
            .getbyId(
              res.body.leveltype
            )
            .subscribe(
              (res: HttpResponse<LevelType>) => {
                this.levelMasterDto = res.body;
              },
              () => { }
            );
        },
        () => { }
      );
    if (
      this.roleCode.role.code === userRoles.roleMaker ||
      this.roleCode.role.code === 'VMAKER' ||
      this.roleCode.role.code === 'BMAKER'
    ) {
      if (this.roleCode.role.code === 'BMAKER') {
        this.paymentService
          .getZonalCheckers(this.roleCode.levelMaster.id)
          .subscribe(
            (res: HttpResponse<LevelType>) => {
              this.zonalList = res.body;
            },
            () => { }
          );
      }
      this.signButtonName = 'Perform e-Sign Administrative Officer';
    } else if (

      this.roleCode.role.code === userRoles.roleChecker ||
      this.roleCode.role.code === 'BC1' ||
      this.roleCode.role.code === 'VC1' ||
      this.roleCode.role.code === 'ZC1'
    ) {
      this.enableApproved = true;
      if (this.roleCode.role.code === 'BC1' || this.roleCode.role.code === 'ZC1') {
        this.checkAmount = true;
      }
      this.signButtonName = 'Perform e-Sign Project Director';
      this.enableRevertButton = true;
      this.readonly = true;
    } else if (
      this.roleCode.role.code === userRoles.roleApprover ||
      this.roleCode.role.code === 'BC2' ||
      this.roleCode.role.code === 'VC2'
    ) {
      this.enableApproved = true;
      this.signButtonName = 'Perform e-Sign District Collector';
      this.enableRevertButton = true;
      this.downloadButton = true;
      this.readonly = true;
    }
    if (
      this.roleCode.role.code === 'BMAKER' ||
      this.roleCode.role.code === 'BC1' ||
      this.roleCode.role.code === 'BC2' ||
      this.roleCode.role.code === 'ZC1'
    ) {
      this.enableZonalButton = true;
    }

    if (this.activatedRoute.snapshot.queryParamMap.get('response')) {
      this.paymentSucessDialog = true;
      this.paymentSucessText = this.activatedRoute.snapshot.queryParamMap.get('response');
      this.disablepaymentButton = true;
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('error')) {
      this.notificationService.alertError('Error Occurred: ' + this.activatedRoute.snapshot.queryParamMap.get('error'), '');
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
      if (this.activatedRoute.snapshot.queryParamMap.get('view')) {
        this.enableView = true;
      }
      this.spinner.show();
      this.paymentService
        .getMaintenacePayment(
          Number((this.activatedRoute.snapshot.queryParamMap.get('id')))
        )
        .subscribe(
          async (res: HttpResponse<MaintenanceGeneration>) => {
            this.voucherGenerationDto = res.body;
            this.isEditable = this.voucherGenerationDto.maintenanceTransactionDto.isEditable == true ? true : false;

            if (this.voucherGenerationDto.maintenanceTransactionDto.proceedingUpload) {
              this.isproceeding = true;
            }
            if (this.voucherGenerationDto.maintenanceTransactionDto.reportUpload) {
              this.reportUpload = true;
            }
            if (this.voucherGenerationDto.maintenanceTransactionDto.maintenanceUpload) {
              this.isMaintenanceUpload = true;
            }
            this.date = new Date(this.voucherGenerationDto.maintenanceTransactionDto.createdOn);

            this.spinner.hide();

            if (this.voucherGenerationDto.maintenanceTransactionDto.signStatus) {
              this.readonly = true;
              this.enableSignButton = true;
            }

            if (this.voucherGenerationDto.maintenanceTransactionDescDto.labourCess) {
              this.enableLabourChess = true;
            } else {
              this.enableLabourChess = false;
            }
            if (this.voucherGenerationDto.maintenanceTransactionDescDto.itPercentage) {
              this.tdsApplicable = true;
            } else {
              this.tdsApplicable = false;
            }
            if (this.voucherGenerationDto.maintenanceTransactionDescDto.tdsOnGst) {
              this.enableGstOnTds = true;
            } else {
              this.enableGstOnTds = false;
            }
            this.calculateTotalAmount();
            await this.getVendor(this.voucherGenerationDto.maintenanceTransactionDto.commercialVendor)


            if (this.voucherGenerationDto.maintenanceTransactionDto.proceedingDate) {
              this.voucherGenerationDto.maintenanceTransactionDto.proceedingDate =
                new Date(
                  this.voucherGenerationDto.maintenanceTransactionDto.proceedingDate
                );
            }
            this.grantlevel =
              this.voucherGenerationDto.maintenanceTransactionDto.componentType;
            this.finYearName = this.voucherGenerationDto.maintenanceTransactionDto.finYear;
          },
          () => { }
        );
      if (
        this.roleCode.role.code === userRoles.roleApprover ||
        this.roleCode.role.code === 'BC2' ||
        this.roleCode.role.code === 'VC2'
      ) {
        if (
          this.voucherGenerationDto.maintenanceTransactionDto.signatureChecker2 !==
          null
        ) {
          this.enablesign = true;
        }
      }
    }
    if (!this.activatedRoute.snapshot.queryParamMap.get('id')) {
      this.voucherGenerationDto = new MaintenanceGeneration();
      this.voucherGenerationDto.maintenanceTransactionDto =
        new MaintenanceTransaction();
      this.enableGstOnTds = false;
      this.enableLabourChess = false;
      this.tdsApplicable = false;
      this.enableSecurityDeposit = false;
      this.enableMiscellaneous = false;
      this.enableMiscellaneousAdditional = false;
      this.enableMaterialSupply = false;
      this.selectedVendor = new CommercialVendorsDto();


      this.voucherGenerationDto.maintenanceTransactionDto.voucherNo =
        this.activatedRoute.snapshot.queryParamMap.get('VoucherNo');
      this.date = new Date();
    }
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => { }
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => { }
    );
  }

  ngAfterViewInit() {
    if (this.formData) {
      this.formData.valueChanges.subscribe((event) => { });
    }
  }

  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res) => {
          this.roleCode = res.body;
          resolve(this.roleCode)
        },
        (onError) => {
          resolve(null);
        }
      );
      this.limitTransListService.getFinYear().subscribe(
        (res: HttpResponse<FinYear[]>) => {
          this.finYear = res.body;
        },
        () => { }
      );
    });
  }

  getVendor(id: number): Promise<any> {
    return new Promise<any>((resolve) => {
      this.commercialVendorService.getcommercialVendor(id).subscribe(
        (res) => {
          resolve(res.body);
          this.selectedVendor = res.body;
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }



  getfile() {
    // this.paymentService
    //   .getfile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
    //   .subscribe(
    //     (res: HttpResponse<any>) => {
    //       this.param1 = res.body.paramOne;
    //       this.param2 = res.body.paramTwo;
    //       this.param3 = res.body.paramThree;
    //       this.enableSignButton = true;
    //       if(!this.voucherGenerationDto.vendorTransactionDto.id){
    //         this.router.navigate(['/PaymentVoucher'], {
    //           queryParams: {
    //             id: res.body.paramFour
    //           },
    //         });
    //       }
    //       // add more parameters as needed
    //     },
    //     () => {}
    //   );
  }

  submitForm() {
    this.spinner.show();
    this.statutoryPaymentVoucherService
      .statutoryCdacRequest(this.voucherGenerationDto.maintenanceTransactionDto.voucherNo, 'Maintenance')
      .subscribe(
        (res: HttpResponse<any>) => {
          console.log('ress', res.body);
          this.spinner.hide();
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          if (!this.voucherGenerationDto.maintenanceTransactionDto.id) {
            this.router.navigate(['/PaymentVoucher'], {
              queryParams: {
                id: res.body.paramFour
              },
            });
          }
          this.localStorageService.store('refresh', 'Yes');
          const formElement = document.createElement('form');
          formElement.method = 'POST';
          formElement.action = environment.CDAC_URL;
          let inputElement = document.createElement('input');
          inputElement.type = 'hidden';
          inputElement.name = "eSignRequest";
          inputElement.value = res.body.paramOne;
          formElement.appendChild(inputElement);
          inputElement = document.createElement('input');
          inputElement.type = 'hidden';
          inputElement.name = "aspTxnID";
          inputElement.value = res.body.paramTwo;
          formElement.appendChild(inputElement);
          inputElement = document.createElement('input');
          inputElement.type = 'hidden';
          inputElement.name = "Content-Type";
          inputElement.value = res.body.paramThree;
          formElement.appendChild(inputElement);
          document.body.appendChild(formElement);
          formElement.submit();
        },
        (error) => {
          this.spinner.hide();

        }
      );

  }

  getPdf() {
    this.paymentService
      .getPdfFile(this.voucherGenerationDto.maintenanceTransactionDto.voucherNo, false)
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          window.open(url, '_blank');
          link.click();
          URL.revokeObjectURL(url);
          // this.spinner.hide();
          // this.router.navigate(['payment']);
          // this.getfile();
          // this.notificationService.alertSuccess('Saved Successfully', '');
        },
        (err) => {
          // this.spinner.hide();
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  revert() {
    this.spinner.show();
    this.paymentService
      .revertTranaction(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/WorkPayment'], {});
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  rejectDialog() {
    if (!this.rejectionReason) {
      this.requiredError = true;
      return;
    }
    else {
      this.reasonDialog = false;
      this.reject();
    }
  }

  reject() {
    this.spinner.show();
    this.paymentService.rejectMaintenanceTransaction(
      Number(this.activatedRoute.snapshot.queryParamMap.get('id')), this.rejectionReason)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/maintenance-payment'], {});
        },
        () => {
          this.spinner.hide();
          this.notificationService.alertError('Unexpected error occured!!', '');
        }
      );
  }

  submit() {
  }


  paymentStatus() {
    // this.paymentService
    //   .getPaymentStatus(this.voucherGenerationDto.vendorTransactionDto.id)
    //   .subscribe(
    //     (res: any) => {
    //       if (res.body.transactionStatus === 'O.K.') {
    //         this.disablepaymentButton = true;
    //       } 
    //       else if (res.body.transactionStatus !== null) {
    //         this.buttonText = 'Re-Initiate payment';
    //         this.checkstatusButton = true;
    //         this.interactionId = res.body.apiInteractionId;
    //       }
    //     },
    //     (err) => {}
    //   );
  }

  checkPaymentStatus() {
    this.spinner.show();
    this.paymentService.paymentStatus(this.interactionId).subscribe(
      (res: any) => {
        if (res.body.transactionStatus.TRANSTATUS === "Failure") {
          this.notificationService.alertError("Transaction Failed for Reference No:" + res.body.transactionStatus.JRNL_NO, '')
          this.buttonText = 'Re-Initiate payment';
          this.checkstatusButton = false;
          this.disablepaymentButton = false;
          this.spinner.hide();
        }
        else if (res.body.transactionStatus.TRANSTATUS === "Success") {
          this.checkstatusButton = false;
          this.paymentSucessDialog = true;
          this.paymentSucessText = "Transaction Success for Reference No:", res.body.transactionStatus.JRNL_NO;
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  redirect() {
    this.router.navigate(['/maintenance-payment-voucher'], {
      queryParams: {
        id: String(this.voucherGenerationDto.maintenanceTransactionDto.id),
      },
    });
  }



  PaymentSucess() {
    this.spinner.show();
    // this.paymentService.paymentSuccess(this.voucherGenerationDto).subscribe(
    //   (res: any) => {
    //     this.spinner.hide();
    //     this.paymentSucessDialog = true;
    //     this.paymentSucessText = res.body;
    //     this.disablepaymentButton = true;
    //   },
    //   (err) => {
    //     if (String(err.status).startsWith('5')) {
    //       this.checkstatusButton = true;
    //     }
    //     this.spinner.hide();
    //   }
    // );
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.userContextSubscription) {
      this.userContextSubscription.unsubscribe();
    }
  }
  BackButtonClick() {
    this.router.navigate(['/WorkPayment']);
  }

  loadProjectData() {
    this.spinner.show();
    this.paymentService.getMaintenaceModuleDetails(this.voucherGenerationDto.maintenanceTransactionDto.workId).subscribe(
      (res: any) => {
        this.spinner.hide();
        console.log('res', res.body)
        this.finYearName = this.finYear.filter(_ => _.name === res.body.finYear)[0];
        this.voucherGenerationDto.maintenanceTransactionDto.finYear = this.finYearName;
        this.voucherGenerationDto.maintenanceTransactionDto.workName = res.body.projectName;
        this.voucherGenerationDto.maintenanceTransactionDto.administrativeSanctionDate = new Date(res.body.administrativeSanctionDate);
        this.voucherGenerationDto.maintenanceTransactionDto.administrativeSanctionAmount = res.body.administrativeSanctionAmount;
        this.voucherGenerationDto.maintenanceTransactionDto.constructionCost = res.body.constructionCost;
        this.voucherGenerationDto.maintenanceTransactionDto.totalMaintenanceAmount = res.body.maintenanceAmount;
        this.voucherGenerationDto.maintenanceTransactionDto.finalPaymentDate = new Date(res.body.finalPaymentDate);
        this.voucherGenerationDto.maintenanceTransactionDto.workCompletionDate = new Date(res.body.workCompletionDate);
        this.voucherGenerationDto.maintenanceTransactionDto.maintenanceCost = res.body.maintenanceCost;
        this.voucherGenerationDto.maintenanceTransactionDto.maintenanceGst = res.body.gst;
        this.voucherGenerationDto.maintenanceTransactionDto.maintenanceLwf = res.body.lwf;
        this.voucherGenerationDto.maintenanceTransactionDto.maintenanceGrossTotal = res.body.grossTotal;
        this.voucherGenerationDto.maintenanceTransactionDescDto = new MaintenancePayment();
        this.voucherGenerationDto.maintenanceTransactionDescDto.billYear = res.body.billYear;
        this.voucherGenerationDto.maintenanceTransactionDescDto.billPart = res.body.billPart;
        this.voucherGenerationDto.maintenanceTransactionDescDto.maintenanceDetailDescId = res.body.id
      },
      (error) => {
        this.voucherGenerationDto.maintenanceTransactionDto.workId = null;
        this.spinner.hide();
        if (error.status == 400) {
          this.notificationService.alertError(error.error.message, '');

        } else {
          this.notificationService.alertError('An unexpected error occurred.', '');
        }

      }
    );
    this.paymentService.getVendorMappingByWorkId(this.voucherGenerationDto.maintenanceTransactionDto.workId).subscribe(
      (res: HttpResponse<any>) => {
        this.vendorList = [];
        res.body.forEach(element => {
          this.vendorList.push(element.commercialVendor);
        });
      },
      () => { }
    );
  }

  viewDocument(fileType) {
    let file = '';
    if (fileType === 'proceedingUpload') {
      file = this.voucherGenerationDto.maintenanceTransactionDto.proceedingUpload;
    } else if (fileType === 'maintenanceUpload') {
      file = this.voucherGenerationDto.maintenanceTransactionDto.maintenanceUpload;
    }
    else if (fileType === 'reportUpload') {
      file = this.voucherGenerationDto.maintenanceTransactionDto.reportUpload;
    }
    this.paymentService.viewimage(file).subscribe((res) => {
      const filename = res.body.fileName;
      this.vFileExt = filename.split('.').pop();
      this.vFileUrl =
        'data:image/' + this.vFileExt + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc = true;
    });
  }
  downloadImage(base64Data: string, filename: string) {
    const link = document.createElement('a');
    link.href = base64Data;
    link.download = filename;
    link.click();
  }
  downloadDocument(fileType) {
    let file = '';
    if (fileType === 'proceedingUpload') {
      file = this.voucherGenerationDto.maintenanceTransactionDto.proceedingUpload;
    } else if (fileType === 'maintenanceUpload') {
      file = this.voucherGenerationDto.maintenanceTransactionDto.maintenanceUpload;
    }
    else if (fileType === 'reportUpload') {
      file = this.voucherGenerationDto.maintenanceTransactionDto.reportUpload;
    }
    this.paymentService.getimage(file).subscribe(
      (res) => {
        const filename = res.body.fileName;
        const fileExtension = filename.split('.').pop();

        if (fileExtension == 'pdf') {
          const base64Data = 'data:application/pdf;base64,' + res.body.fileType; // Replace with your Base64-encoded image string
          this.downloadImage(base64Data, filename);
        } else {
          const base64Data =
            'data:image/' + fileExtension + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
          this.downloadImage(base64Data, filename);
        }
      },
      (onError) => { }
    );
  }
  removeDocument(fileType) {
    let file = '';
    if (fileType === 'proceedingUpload') {
      file = this.voucherGenerationDto.maintenanceTransactionDto.proceedingUpload;
    } else if (fileType === 'maintenanceUpload') {
      file = this.voucherGenerationDto.maintenanceTransactionDto.maintenanceUpload;
    }
    else if (fileType === 'reportUpload') {
      file = this.voucherGenerationDto.maintenanceTransactionDto.reportUpload;
    }
    this.paymentService.deleteimage(file).subscribe(
      (res) => {
        this.notificationService.alertSuccess('File Deleted', '');
        if (fileType === 'proceedingUpload') {
          this.isproceeding = false;
          this.voucherGenerationDto.maintenanceTransactionDto.proceedingUpload = null;
        } else if (fileType === 'maintenanceUpload') {
          this.isMaintenanceUpload = false;
          this.voucherGenerationDto.maintenanceTransactionDto.maintenanceUpload = null;
        }
        else if (fileType === 'reportUpload') {
          this.reportUpload = false;
          this.voucherGenerationDto.maintenanceTransactionDto.reportUpload = null;
        }
      },
      (error) => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }

  calculatetotal() {
    this.voucherGenerationDto.maintenanceTransactionDescDto.currentBillValue = Math.floor((this.voucherGenerationDto.maintenanceTransactionDto.maintenanceCost * (this.voucherGenerationDto.maintenanceTransactionDescDto.maintenanceScore / 100)));
    this.voucherGenerationDto.maintenanceTransactionDescDto.sgst = Math.floor((9 / 100) * (this.voucherGenerationDto.maintenanceTransactionDescDto.currentBillValue));
    this.voucherGenerationDto.maintenanceTransactionDescDto.cgst = Math.floor(((9 / 100) * this.voucherGenerationDto.maintenanceTransactionDescDto.currentBillValue));
    this.voucherGenerationDto.maintenanceTransactionDescDto.lwf = Math.floor(((1 / 100) * this.voucherGenerationDto.maintenanceTransactionDescDto.currentBillValue));
    this.voucherGenerationDto.maintenanceTransactionDescDto.grossTotal = this.voucherGenerationDto.maintenanceTransactionDescDto.currentBillValue + this.voucherGenerationDto.maintenanceTransactionDescDto.sgst + this.voucherGenerationDto.maintenanceTransactionDescDto.cgst + this.voucherGenerationDto.maintenanceTransactionDescDto.lwf;
    this.voucherGenerationDto.maintenanceTransactionDto.transactionAmount = Math.round(this.voucherGenerationDto.maintenanceTransactionDescDto.grossTotal);
    this.calculateTotalAmount();
  }

  onUpload($event, fileType) {
    const formData = new FormData();
    const i = $event.files.length - 1;
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }

    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        if (fileType === 'proceedingUpload') {
          this.isproceeding = true;
          this.voucherGenerationDto.maintenanceTransactionDto.proceedingUpload = res.body.fileName;
        } else if (fileType === 'maintenanceUpload') {
          this.isMaintenanceUpload = true;
          this.voucherGenerationDto.maintenanceTransactionDto.maintenanceUpload = res.body.fileName;
        }
        else if (fileType === 'reportUpload') {
          this.reportUpload = true;
          this.voucherGenerationDto.maintenanceTransactionDto.reportUpload = res.body.fileName;
        }
        this.notificationService.alertSuccess('Uploaded Successfully', '');
      },
      () => {
        this.notificationService.alertError('Error!', '');
      }
    );



    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }

  convertToEvenNumber(num: number): number {
    if (num % 2 !== 0) {
      num++;
    }
    return num;
  }
  calculateTotalAmount() {

    this.voucherGenerationDto.maintenanceTransactionDescDto.labourCess = 0;
    this.voucherGenerationDto.maintenanceTransactionDescDto.tdsOnGst = 0;
    this.voucherGenerationDto.maintenanceTransactionDescDto.it = 0;
    if (
      this.voucherGenerationDto.maintenanceTransactionDescDto.currentBillValue &&
      this.enableGstOnTds
    ) {
      this.voucherGenerationDto.maintenanceTransactionDescDto.tdsOnGst = Math.ceil(
        (
          (this.voucherGenerationDto.maintenanceTransactionDescDto.tdsOnGstPercentage / 100) *
          this.voucherGenerationDto.maintenanceTransactionDescDto.currentBillValue
        )
      );
      this.voucherGenerationDto.maintenanceTransactionDescDto.tdsOnGst = this.convertToEvenNumber(this.voucherGenerationDto.maintenanceTransactionDescDto.tdsOnGst);
    }
    if (this.enableLabourChess && this.voucherGenerationDto.maintenanceTransactionDescDto.currentBillValue) {
      this.voucherGenerationDto.maintenanceTransactionDescDto.labourCess = Math.ceil(
        (
          (this.voucherGenerationDto.maintenanceTransactionDescDto
            .labourCessPercentage /
            100) *
          this.voucherGenerationDto.maintenanceTransactionDescDto.currentBillValue
        )
      );
    }
    if (this.tdsApplicable && this.voucherGenerationDto.maintenanceTransactionDescDto.currentBillValue) {
      this.voucherGenerationDto.maintenanceTransactionDescDto.it = Math.ceil(
        (
          (this.voucherGenerationDto.maintenanceTransactionDescDto
            .itPercentage /
            100) *
          this.voucherGenerationDto.maintenanceTransactionDescDto.currentBillValue
        )
      );
    }
    this.totalDeduction =
      this.voucherGenerationDto.maintenanceTransactionDescDto.it + this.voucherGenerationDto.maintenanceTransactionDescDto.tdsOnGst + this.voucherGenerationDto.maintenanceTransactionDescDto.labourCess;
    this.voucherGenerationDto.maintenanceTransactionDto.transactionAmount =
      this.voucherGenerationDto.maintenanceTransactionDescDto.grossTotal -
      this.totalDeduction;
    this.voucherGenerationDto.maintenanceTransactionDto.transactionAmount = Math.round(this.voucherGenerationDto.maintenanceTransactionDto.transactionAmount);

  }

  genVocher() {
    this.voucherGenerationDto.maintenanceTransactionDto.levelMasterId = this.roleCode.levelMaster.id;
    this.voucherGenerationDto.maintenanceTransactionDto.levelMasterName = this.roleCode.levelMaster.name;
    this.voucherGenerationDto.maintenanceTransactionDto.commercialAccountNo = this.selectedVendor.accountNumber;
    this.voucherGenerationDto.maintenanceTransactionDto.commercialCompanyName = this.selectedVendor.companyName;
    this.voucherGenerationDto.maintenanceTransactionDto.commercialIfscode = this.selectedVendor.ifscCode;
    this.voucherGenerationDto.maintenanceTransactionDto.commercialVendor = this.selectedVendor.id;
    this.voucherGenerationDto.maintenanceTransactionDto.commercialGst = this.selectedVendor.gstNo;
    this.voucherGenerationDto.maintenanceTransactionDto.commercialPanNo = this.selectedVendor.panNo;
    this.voucherGenerationDto.maintenanceTransactionDto.commercialVendorName = this.selectedVendor.name
    this.voucherGenerationDto.maintenanceTransactionDto.grossAmount = this.voucherGenerationDto.maintenanceTransactionDescDto.grossTotal;
    this.voucherGenerationDto.maintenanceTransactionDto.componentType = this.grantlevel;
    this.voucherGenerationDto.maintenanceTransactionDto.componentName = 'Maintenance';
    this.voucherGenerationDto.maintenanceTransactionDto.bankAccName = this.selectedVendor.bankAccName;
    this.voucherGenerationDto.maintenanceTransactionDto.bankName = this.selectedVendor.bankName;
    this.voucherGenerationDto.maintenanceTransactionDto.implementingAgency = this.implementingAgencyDto;
    this.voucherGenerationDto.maintenanceTransactionDto.department = this.implementingAgencyDto.departmentDto;
    this.voucherGenerationDto.maintenanceTransactionDto.scheme = this.implementingAgencyDto.schemeDto;

    if (this.voucherGenerationDto.maintenanceTransactionDescDto.maintenanceScore < 80) {
      this.notificationService.alertError('Maintenance Score is less than 80, So You are not eligible to generate Voucher', '');
      return;
    }
    if (!this.voucherGenerationDto.maintenanceTransactionDto.proceedingUpload || !this.voucherGenerationDto.maintenanceTransactionDto.maintenanceUpload || !this.voucherGenerationDto.maintenanceTransactionDto.reportUpload) {
      this.notificationService.alertError('please Upload Documents', '');
      return;
    }

    this.ngSelectInvalid = false;
    const isFormValid =
      this.levelMasterDto &&
      this.grantlevel &&
      this.selectedVendor.name &&
      this.selectedVendor.id &&
      this.selectedVendor.panNo &&
      this.voucherGenerationDto.maintenanceTransactionDescDto.remark &&
      this.voucherGenerationDto.maintenanceTransactionDto.transactionAmount &&
      this.voucherGenerationDto.maintenanceTransactionDto.proceedingNumber &&
      this.voucherGenerationDto.maintenanceTransactionDto.proceedingDate;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      this.notificationService.alertError("Please fill all the mandotory fields marked with *", '');
      return;
    }


    this.spinner.show();
    this.paymentService.genMaintenancePaymentVocher(this.voucherGenerationDto).subscribe(
      (response: any) => {
        this.readonly = true;
        this.spinner.hide();
        this.enableSignButton = true;
        // this.router.navigate(['/payment']);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      (error) => {
        this.spinner.hide();
        if (error.status == 400) {
          this.notificationService.alertError(error.error.message, '');

        } else {
          this.notificationService.alertError('An unexpected error occurred.', '');
        }
      }
    );
  }

}
