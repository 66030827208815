import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { SigninComponent } from './auth/signin/signin.component';
import { UserRouteAccessService } from './auth/user-route-access.service';
import { DashBoardReportComponent } from './dashBoards/dash-board-report/dash-board-report.component';
import { DashboardComponent } from './dashBoards/dashboard/dashboard.component';
import { DistrictDashboardComponent } from './dashBoards/dashboard/district-dashboard/district-dashboard.component';
import { DeductionsComponent } from './deductions/deductions.component';
import { VendorDeductionsComponent } from './deductions/vendor-deductions/vendor-deductions.component';
import { LimitReportComponent } from './drillDown-report/limit-report/limit-report.component';
import { PaymentStatusComponent } from './drillDown-report/payment-status/payment-status.component';
import { VendorReportComponent } from './drillDown-report/vendor-report/vendor-report.component';
import { VoucherStatusComponent } from './drillDown-report/voucher-status/voucher-status.component';
import { DynamicReportTableComponent } from './dynamic-report-table/dynamic-report-table.component';
import { AccountSnaComponent } from './module/account-sna/account-sna.component';
import { AgencyComponent } from './module/agency/agency.component';
import { CommercialVendorsComponent } from './module/commercial-vendors/commercial-vendors.component';
import { ComponentTypeComponent } from './module/component-type/component-type.component';
import { DepartmentListComponent } from './module/department-list/department-list.component';
import { ElectedRepresentativeComponent } from './module/elected-representative/elected-representative.component';
import { EmployeeComponent } from './module/employee/employee.component';
import { FundsTransactionComponent } from './module/funds-transaction/funds-transaction.component';
import { FundsComponent } from './module/funds/funds.component';
import { LevelMasterComponent } from './module/level-master/level-master.component';
import { LevelTypeComponent } from './module/level-type/level-type.component';
import { LimitAllocationComponent } from './module/limit-allocation/limit-allocation.component';
import { LimitSetComponent } from './module/limit-set/limit-set.component';
import { LimitTranslistComponent } from './module/limit-translist/limit-translist.component';
import { LocalBodyContributionComponent } from './module/local-body-contribution/local-body-contribution.component';
import { MajorComponentComponent } from './module/major-component/major-component.component';
import { MinorComponentComponent } from './module/minor-component/minor-component.component';
import { PersonalVendorsComponent } from './module/personal-vendors/personal-vendors.component';
import { ProfileComponent } from './module/profile/profile.component';
import { SchemeListComponent } from './module/scheme-list/scheme-list.component';
import { StatutoryAccountConfigComponent } from './module/statutory-account-config/statutory-account-config.component';
import { SubComponentComponent } from './module/sub-component/sub-component.component';
import { UserComponent } from './module/user/user.component';
import { VendorMappingPageComponent } from './module/vendor-mapping-page/vendor-mapping-page.component';
import { VendorMappingTableComponent } from './module/vendor-mapping-table/vendor-mapping-table.component';
import { BlukPaymentTableComponent } from './payments/bluk-payment-table/bluk-payment-table.component';
import { BlukPaymentVoucherComponent } from './payments/bluk-payment-voucher/bluk-payment-voucher.component';
import { DisputedPaymentListComponent } from './payments/disputed-payment-list/disputed-payment-list.component';
import { FailureTransactionComponent } from './payments/failure-transaction/failure-transaction.component';
import { MigratePaymentModuleComponent } from './payments/migrate-payment-module/migrate-payment-module.component';
import { MigratePaymentComponent } from './payments/migrate-payment/migrate-payment.component';
import { MigrateVendorPaymentModuleComponent } from './payments/migrate-vendor-payment-module/migrate-vendor-payment-module.component';
import { MigrateVendorPaymentVoucherComponent } from './payments/migrate-vendor-payment-voucher/migrate-vendor-payment-voucher.component';
import { MiscellaneousBulkpaymentTableComponent } from './payments/miscellaneous-bulkpayment-table/miscellaneous-bulkpayment-table.component';
import { MiscellaneousBulkpaymentVoucherComponent } from './payments/miscellaneous-bulkpayment-voucher/miscellaneous-bulkpayment-voucher.component';
import { MiscellaneousPaymentTableComponent } from './payments/miscellaneous-payment-table/miscellaneous-payment-table.component';
import { MiscellaneousPaymentVoucherComponent } from './payments/miscellaneous-payment-voucher/miscellaneous-payment-voucher.component';
import { PaymentModuleComponent } from './payments/payment-module/payment-module.component';
import { PaymentVoucherComponent } from './payments/payment-voucher/payment-voucher.component';
import { StatutoryPaymentModuleComponent } from './payments/statutory-payment-module/statutory-payment-module.component';
import { StatutoryPaymentVoucherComponent } from './payments/statutory-payment-voucher/statutory-payment-voucher.component';
import { StatutoryPaymentComponent } from './payments/statutory-payment/statutory-payment.component';
import { VendorPaymentModuleComponent } from './payments/vendor-payment-module/vendor-payment-module.component';
import { VendorPaymentVoucherComponent } from './payments/vendor-payment-voucher/vendor-payment-voucher.component';
import { WrongTransactionComponent } from './payments/wrong-transaction/wrong-transaction.component';
import { ProjectComponent } from './project/project.component';
import { ErrorComponent } from './shared/error/error.component';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: 'login',
          component: SigninComponent,
        },
        {
          path: '',
          redirectTo: 'login',
          pathMatch: 'full',
        },
        {
          path: 'ForgotPassword',
          component: ForgotPasswordComponent,
        },
        {
          path: 'ResetPassword',
          component: ResetPasswordComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'state-dashboard',
          component: DashboardComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'district-dashboard',
          component: DistrictDashboardComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'profile',
          component: ProfileComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'user',
          component: UserComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'agency',
          component: AgencyComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'personal-vendors',
          component: PersonalVendorsComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'commercial-vendors',
          component: CommercialVendorsComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'employee',
          component: EmployeeComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'electedRepresentativeComponent',
          component: ElectedRepresentativeComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'account-sna',
          component: AccountSnaComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'department-list',
          component: DepartmentListComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'scheme-list',
          component: SchemeListComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'funds',
          component: FundsComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'funds-transaction',
          component: FundsTransactionComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'limit-set',
          component: LimitSetComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'limit-translist',
          component: LimitTranslistComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'limit-allocation',
          component: LimitAllocationComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'local-body-contribution',
          component: LocalBodyContributionComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'project',
          component: ProjectComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'level-type',
          component: LevelTypeComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'level-master',
          component: LevelMasterComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'component-type',
          component: ComponentTypeComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'major-component',
          component: MajorComponentComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'minor-component',
          component: MinorComponentComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'sub-component',
          component: SubComponentComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'MigratePayment',
          component: MigratePaymentModuleComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'MigratePaymentVoucher',
          component: MigratePaymentComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'MigrateVendorPayment',
          component: MigrateVendorPaymentModuleComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'MigrateVendorPaymentVoucher',
          component: MigrateVendorPaymentVoucherComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'WorkPayment',
          component: PaymentModuleComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'PaymentVoucher',
          component: PaymentVoucherComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'VendorPaymentVoucher',
          component: VendorPaymentVoucherComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'VendorPayment',
          component: VendorPaymentModuleComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'dynamicReport',
          component: DynamicReportTableComponent,
          canActivate: [UserRouteAccessService],
          pathMatch: 'prefix',
        },
        {
          path: 'failureTransaction',
          component: FailureTransactionComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'DisputedPaymentList',
          component: DisputedPaymentListComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'StatutoryPayment',
          component: StatutoryPaymentComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'StatutoryPaymentList',
          component: StatutoryPaymentModuleComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'StatutoryPaymentVoucher',
          component: StatutoryPaymentVoucherComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'StatutoryAccount',
          component: StatutoryAccountConfigComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'WorkDeductions',
          component: DeductionsComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'VendorDeductions',
          component: VendorDeductionsComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'drilldownpaymentstatus',
          component: PaymentStatusComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'drilldownvoucherstatus',
          component: VoucherStatusComponent,
          canActivate: [UserRouteAccessService],
        },

        {
          path: 'drilldownvendorstatus',
          component: VendorReportComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'drilldownlimitReport',
          component: LimitReportComponent,
          canActivate: [UserRouteAccessService],
        },

        {
          path: 'blukpaymentTable',
          component: BlukPaymentTableComponent,
          canActivate: [UserRouteAccessService],
        },

        {
          path: 'blukpaymentVoucher',
          component: BlukPaymentVoucherComponent,
          canActivate: [UserRouteAccessService],
        },

        {
          path: 'tender-table',
          component: VendorMappingTableComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'tender-page',
          component: VendorMappingPageComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'miscellaneous-payment-table',
          component: MiscellaneousPaymentTableComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'miscellaneous-payment-page',
          component: MiscellaneousPaymentVoucherComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'miscellaneous-bulkpayment-table',
          component: MiscellaneousBulkpaymentTableComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'miscellaneous-bulkpayment-page',
          component: MiscellaneousBulkpaymentVoucherComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'wrong-transaction-page',
          component: WrongTransactionComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'dashBoard-Report',
          component: DashBoardReportComponent,
          canActivate: [UserRouteAccessService],
        },

        

        { path: '**', redirectTo: '/error', pathMatch: 'full' },
        {
          path: 'error',
          component: ErrorComponent,
        },
      ],
      { enableTracing: false }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
