<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        <p>Miscellaneous BulkPayment Voucher</p>
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col">
                    <div class="card p-3">
                        <div class="row">
                            <h6 [ngClass]="fontService.headingClass">
                                Generate Voucher
                            </h6>
                        </div>
                        <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    {{ "payments.voucher.number.name" | translate }}:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.voucherNumber }}
                                </p>
                            </div>
                            <div class="col-lg-1 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    {{ "date.name" | translate }}:<br />{{ date | date:'dd/MM/yyyy'}}
                                </p>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    {{ "implementing.agency.name" | translate }}:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.implementingAgency?.name }}
                                </p>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    {{ "payments.gst.number.name" | translate }}:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.implementingAgency?.gstNo }}
                                </p>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    {{ "payments.level.type.name" | translate }}:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.levelMaster?.name }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!statutoryVoucherGeneration?.statutoryTransactionDto.id">
                <div class="card mt-3">
                    <table class="table p-2">
                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Work Id</th>
                                <th scope="col">Miscellaneous Type</th>
                                <th scope="col">Deduction Type</th>
                                <th scope="col">Payment Type</th>
                                <th scope="col">Total Amount</th>
                                <th scope="col">Release Amount</th>
                                <th scope="col">Transaction Amount</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of tempArrForTransaction;let i=index">
                                <td>{{i+1}}</td>
                                <td>{{data.workId}}</td>
                                <td>{{data.miscellaneousType}}</td>
                                <td>{{data.deductionName}}</td>
                                <td>{{data.paymentType}}</td>
                                <td>{{data.totalAmount}}</td>
                                <td>{{data.paidAmount}}</td>
                                <td>{{data.transactionAmount}}</td>
                                <td>
                                    <i *ngIf="!disableRemarks" class="fa fa-trash text-danger" (click)="trash(data,i)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-center" *ngIf="tempArrForTransaction.length == 0">
                        No Records Found!!!
                    </div>
                </div>
                <button pButton type="button" icon="pi pi-plus" label="Add Record" class="mt-3" (click)="showDialog()"></button>
            </div>
            <p-dialog header="Add Statutory Transaction" [(visible)]="displayDialog" [modal]="true" [closable]="true" [style]="{width: '50vw'}">
                <div class="row">
                    <div class="col">
                        <div class="card p-3">
                            <div class="row mt-2">
                                <div class="col-md-3 col-sm-4">
                                    <label class="form-control-label req" for="workId" [ngClass]="fontService.labelClass">Work Id</label>
                                    <input type="text" class="form-control" autocomplete="off" maxlength="15" (change)="clearData()" oninput="this.value = this.value.replace(/[^A-Za-z0-9-_]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" [(ngModel)]="workId" [disabled]="!isEditable"
                                        id="workId" />
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3 col-sm-4">
                                    <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Miscellaneous Type</label>
                                    <ng-select id="scheme" [disabled]="readonly || !isEditable" [(ngModel)]="miscellaneousTypelist" [items]="miscellaneousType" appearance="outline" [searchable]="true" [closeOnSelect]="true" class="form-control dropdown-width">
                                    </ng-select>
                                </div>
                                <div class="col-md-3 col-sm-4">
                                    <label class="form-control-label req" [ngClass]="fontService.labelClass" for=" statutoryType">Deduction Type</label>
                                    <ng-select id="statutoryType" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width" [items]="statutoryDeductionList" [(ngModel)]="deductionType" (change)="loadAccountType()">
                                    </ng-select>
                                </div>
                                <div class="col-md-3 col-sm-4">
                                    <label class="form-control-label req" [ngClass]="fontService.labelClass" for=" statutoryType">Payment Type</label>
                                    <ng-select id="statutoryType" appearance="outline" (change)="loadAccountDetails()" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width" [items]="accountTypeList" [(ngModel)]="paymentType">
                                    </ng-select>
                                </div>
                                <div *ngIf="paymentType !== 'Holding Account' && paymentType" class="col-md-3 col-sm-4">
                                    <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.vendor.name">Vendor
                                        Name</label>
                                    <ng-select id="scheme" [disabled]="readonly || !isEditable || loadingMore" [(ngModel)]="VendorType" [items]="vendorList" bindLabel="name" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="false" (search)="vendorFilter($event)"
                                        class="form-control dropdown-width" (change)="loadstatutoryBulkTransaction()" (scrollToEnd)="onScrollToEnd()" [ngClass]="{
                      'is-invalid': ngSelectInvalid && !statutoryVoucherGeneration?.statutoryTransactionDto.vendor.name
                    }">
                                    </ng-select>
                                </div>

                            </div>
                            <div class="row mt-4" *ngIf="this.statutoryAccountDetails?.bankAccountNumber">
                                <div class="col-md-12">
                                    <h2 [ngClass]="fontService.regularBoldClass">
                                        Account Details
                                    </h2>
                                </div>
                                <div class="col-md-2 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        Account Number:<br />{{ this.statutoryAccountDetails?.bankAccountNumber }}
                                    </p>
                                </div>
                                <div class="col-md-2 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        Account Name:<br />{{ this.statutoryAccountDetails?.bankAccountName }}
                                    </p>
                                </div>
                                <div class="col-md-2 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        Bank Name:<br />{{ this.statutoryAccountDetails?.bankName }}
                                    </p>
                                </div>
                                <div class="col-md-2 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        Bank IFSC:<br />{{ this.statutoryAccountDetails?.bankIfscCode }}
                                    </p>
                                </div>
                                <div class="col-md-2 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        Bank Branch:<br />{{ this.statutoryAccountDetails?.bankBranchName }}
                                    </p>
                                </div>

                            </div>
                            <div class="row mt-4" *ngIf="this.statutoryVoucherGeneration?.statutoryTransactionDto?.vendor?.name">
                                <div class="col-md-12">
                                    <h2 [ngClass]="fontService.regularBoldClass">
                                        Account Details
                                    </h2>
                                </div>
                                <div class="col-md-2 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        Account Number:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.vendor?.accountNumber }}
                                    </p>
                                </div>
                                <div class="col-md-2 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        Account Name:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.vendor?.bankAccName }}
                                    </p>
                                </div>
                                <div class="col-md-2 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        Bank Name:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.vendor?.bankName }}
                                    </p>
                                </div>
                                <div class="col-md-2 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        Bank IFSC:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.vendor?.ifscCode}}
                                    </p>
                                </div>
                                <div class="col-md-2 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        Bank Branch:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.vendor?.bankBranch }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="statutoryDeductionListDtos.length >=1">
                    <div class="col">
                        <div class="card p-3">
                            <div class="row">
                                <div class="col-md-12 mt-2">
                                    <p-table [value]="statutoryDeductionListDtos" [paginator]="true" [rows]="10" styleClass="p-datatable-gridlines" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>S.No</th>
                                                <th></th>
                                                <th>Voucher Number</th>
                                                <th>Work Id</th>
                                                <th>Vendor Name</th>
                                                <th>GST Number</th>
                                                <th>PAN Number</th>
                                                <th>Deduction Percentage</th>
                                                <th>Deduction Amount</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-index="rowIndex" let-list>
                                            <tr>
                                                <td>{{index+1}}</td>
                                                <td>
                                                    <p-checkbox (onChange)="VoucherCheckStatus(list)" [disabled]="enableSignButton" [(ngModel)]="list.isDefault" binary="true"></p-checkbox>
                                                </td>
                                                <td>{{ list.voucherNumber }}</td>
                                                <td>{{ list.workId }}</td>
                                                <td>{{ list.vendorName }}</td>
                                                <td>{{ list.vendorGstNumber }}</td>
                                                <td>{{ list.vendorPanNumber }}</td>
                                                <td>{{ list.deductionPercentage}}</td>
                                                <td>{{ list.deductionAmount }}</td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <!-- <div class="col-6">
                                    <label class="form-control-label req" for="schemeDescription"
                                        [ngClass]="fontService.labelClass"
                                        jhiTranslate="payments.remarks.name">Remarks</label><br />
                                    <textarea id="w3review" name="w3review" rows="3" [disabled]="!isEditable"
                                        [(ngModel)]="statutoryVoucherGeneration?.statutoryTransactionDto.remarks"
                                        cols="100" maxlength="150" [ngClass]="{'is-invalid':ngSelectInvalid &&
                                    !statutoryVoucherGeneration?.statutoryTransactionDto.remarks}"
                                        oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                        onblur="this.value =this.value.trim();">
                                </textarea>
                                </div> -->
                                <div class="col-2" *ngIf="this.totalAmount">
                                    <div class="col"><b>Total Amount :
                                            {{this.totalAmount}}
                                        </b></div>
                                </div>
                                <div class="col-2" *ngIf="this.totalAmount">
                                    <div class="col"><b>Already Release Amount :
                                            {{this.paidAmount}}
                                        </b></div>
                                </div>
                                <div class="col-2" *ngIf="this.totalAmount">
                                    <div class="col"><b>Balance Amount :
                                        {{this.totalAmount - this.paidAmount}}
                                    </b></div>
                                </div>
                                <div class="col-2">
                                    <div class="col"><b><label class="form-control-label req" for="transactionAmount"
                                                [ngClass]="fontService.labelClass">Transaction Amount</label></b>
                                        <input type="number" class="form-control" autocomplete="off" maxlength="15" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" [(ngModel)]="transactionAmount" [disabled]="!isEditable" [ngClass]="{'is-invalid':ngSelectInvalid && !transactionAmount}"
                                            name="workId" min [readonly]="statutoryVoucherGeneration?.statutoryTransactionDto.id" id="transactionAmount" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-3">
                    <button pButton type="button" style="margin-right: 10px;" class="p-mr-2" label="Save" icon="pi pi-check" (click)="saveTransaction()"></button>
                    <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="displayDialog=false"></button>
                </div>
            </p-dialog>
            <div class="row" *ngIf="statutoryVoucherGeneration?.statutoryTransactionDto.id">
                <div class="col">
                    <div class="card p-3">
                        <div class="row">
                            <div class="col-md-12 mt-2">
                                <p-table [value]="statutoryVoucherGeneration?.statutoryBulkTransaction" [paginator]="true" [rows]="10" styleClass="p-datatable-gridlines" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>S.No</th>
                                            <th>Voucher Number</th>
                                            <th>Work Id</th>
                                            <th>Vendor Name</th>
                                            <th>GST Number</th>
                                            <th>PAN Number</th>
                                            <th>Miscellaneous Type</th>
                                            <th>Deduction Type</th>
                                            <th>Payment Type</th>
                                            <th>Transaction Amount</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-index="rowIndex" let-list>
                                        <tr>
                                            <td style="padding: 16px; text-align: center;">{{index+1}}</td>
                                            <td style="padding: 16px; text-align: center;">{{ list.voucherNumber }}</td>
                                            <td style="padding: 16px; text-align: center;">{{ list.workId }}</td>
                                            <td style="padding: 16px; text-align: center;">{{ list.vendorName }}</td>
                                            <td style="padding: 16px; text-align: center;">{{ list.vendorGstNumber }}</td>
                                            <td style="padding: 16px; text-align: center;">{{ list.vendorPanNumber }}</td>
                                            <td style="padding: 16px; text-align: center;">{{ list.miscellaneousType }}</td>
                                            <td style="padding: 16px; text-align: center;">{{ list.deductionName }}</td>
                                            <td style="padding: 16px; text-align: center;">{{ list.paymentType }}</td>
                                            <td style="padding: 16px; text-align: center;">{{ list.transactionAmount }}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-toolbar>
        <div class="col-6">
            <label class="form-control-label req" for="schemeDescription" [ngClass]="fontService.labelClass" jhiTranslate="payments.remarks.name">Remarks</label><br />
            <textarea id="w3review" name="w3review" rows="3" [disabled]="disableRemarks" [(ngModel)]="statutoryVoucherGeneration?.statutoryTransactionDto.remarks" cols="100" maxlength="150" [ngClass]="{'is-invalid':ngSelectInvalid &&
                !statutoryVoucherGeneration?.statutoryTransactionDto.remarks}" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();">
            </textarea>
        </div>
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-end">
            <button *ngIf="enableBackButton" pButton class="back-button" styleClass="button" icon="pi pi-arrow-left" label="Back" (click)="BackButtonClick()"></button>
        </div>
    </p-toolbar>
    <p-toolbar *ngIf="statutoryVoucherGeneration?.statutoryBulkTransaction && !enableRevertButton && isEditable">
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-end">
            <button *ngIf="!enableSignButton && !readonly && isEditable" pButton pRipple class="p-button-success custom-button margin" (click)="saveVoucher()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.generatevoucher"></span>
            </button>
            <button *ngIf="enableSignButton" pButton pRipple class="p-button-success custom-button margin" (click)="submitForm()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass">{{signButtonName}}</span>
            </button>
        </div>
    </p-toolbar>
    <p-toolbar *ngIf="statutoryVoucherGeneration?.statutoryBulkTransaction && enableRevertButton && isEditable">
        <div class="p-toolbar-group-start"></div>
        <button *ngIf="enableSignButton && !statutoryVoucherGeneration.statutoryTransactionDto.levelTwoSign" pButton pRipple class="p-button-success custom-button margin" (click)="submitForm()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass">{{signButtonName}}</span>
        </button>

        <div *ngIf="
                  (!enableSignButton &&(
                  !statutoryVoucherGeneration?.statutoryTransactionDto?.signStatus || !statutoryVoucherGeneration.statutoryTransactionDto.levelTwoSign) &&  !statutoryVoucherGeneration.statutoryTransactionDto.levelTwoSign)
                " class="p-toolbar-group-end">
            <!-- <button pButton pRipple (click)="revert()" class="p-button-warning custom-button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.revertbutton"></span>
            </button> -->
            <button *ngIf="!statutoryVoucherGeneration.statutoryTransactionDto.signStatus && !statutoryVoucherGeneration?.statutoryTransactionDto?.revertStatus && enableApproved && isEditable" pButton pRipple (click)="saveVoucher()" class="p-button-success custom-button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.approvebutton"></span>
            </button>
            <button *ngIf="isEditable" pButton pRipple (click)="reasonDialog=true" class="p-button-danger custom-button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.rejectbutton"></span>
            </button>
        </div>
        <p *ngIf="checkstatusButton && isEditable" style="font-size: 18px;color:red;font-weight:700;">Please Check Transaction Status Before Re-Initiate Payment</p>
    </p-toolbar>
    <p-toolbar>
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-end">
            <button *ngIf="statutoryVoucherGeneration?.statutoryTransactionDto.levelTwoSign" pButton pRipple label="Download Pdf" (click)="getPdf()" class="p-button-success"></button>
        </div>
    </p-toolbar>
</div>

<p-dialog [(visible)]="reasonDialog" [style]="{ width: '500px', height: '350px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
    <p-header [ngClass]="fontService.headingClass">
        Reject Dialog:
    </p-header>
    <div class="form-group mb-4">
        <label class="form-control-label req" for="reason" [ngClass]="fontService.labelClass">Reject
            Reason</label><br />
        <textarea id="reason" name="reason" rows="3" [(ngModel)]="rejectionReason" cols="100" maxlength="150" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"></textarea>
        <div *ngIf="requiredError" class="mt-2">
            <small class="text-danger">please mention the reason for reject</small>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple class="p-button-danger">
            <span class="button-label" [ngClass]="fontService.buttonClass" label="Reject"
                (click)="rejectDialog()">Reject</span>
        </button>
    </ng-template>

</p-dialog>
<p-dialog [(visible)]="paymentSucessDialog" [style]="{ width: '500px', height: '225px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
    <p-header [ngClass]="fontService.headingClass">
        Payment Alert Box
    </p-header>
    <h1 style="text-align: center;font-size: 18px;">{{ paymentSucessText }}</h1>
    <ng-template pTemplate="footer">
        <button pButton pRipple class="p-button-text custom-button" (click)="paymentSucessDialog = false">
            <span class="button-label" [ngClass]="fontService.buttonClass" label="Ok" (click)="redirect()">Ok</span>
        </button>
    </ng-template>
</p-dialog>