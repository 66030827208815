import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Report } from './report-model';
import { LocalStorageService } from 'ngx-webstorage';
export type EntityListResponseType = HttpResponse<any>;

@Injectable({ providedIn: 'root' })
export class NavbarService {
  
  protected getMenuUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getMenu';
  protected logOutUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/logOut';
  protected getReportMenuUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getReportList'

  constructor(protected http: HttpClient,private localStorageService: LocalStorageService,) {}
  getMenu(): Observable<EntityListResponseType> {
    return this.http.get<any>(this.getMenuUrl, { observe: 'response' });
  }
  logout(): Observable<any> {
    return this.http.get(this.logOutUrl,{observe: 'response',responseType:'text'});
  }

  getReportMenu(): Observable<HttpResponse<Report[]>> {
    return this.http.get<Report[]>(this.getReportMenuUrl, { observe: 'response' });
  }
 
}
