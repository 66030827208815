import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  CommercialVendorsDto,
  Vendor,
} from 'src/app/module/commercial-vendors/commercial-vendors';
import { CommercialVendorService } from 'src/app/module/commercial-vendors/commercial-vendors.service';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { DepartmentList } from 'src/app/module/department-list/department-list';
import { DepartmentListService } from 'src/app/module/department-list/department-list.service';
import { LevelTypeService } from 'src/app/module/level-type/level-type.service';
import { SchemeList } from 'src/app/module/scheme-list/scheme-list';
import { SchemeListService } from 'src/app/module/scheme-list/scheme-list.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import {
  ComponentDto,
  ComponentType,
} from '../module/component-type/component-type';
import { LevelType } from '../module/level-type/level-type';
import { filterQuery } from '../shared/common.model';


import { registerLocaleData } from '@angular/common';
import enIN from '@angular/common/locales/en-IN';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Dialog } from 'primeng/dialog';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { NavbarComponent } from 'src/app/layout/navbar/navbar.component';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { UserService } from 'src/app/module/user/user.service';
import { TenderPageService } from 'src/app/module/vendor-mapping-page/tendor-page.service';
import { Project } from 'src/app/project/project.model';
import { ProjectService } from 'src/app/project/project.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { environment } from 'src/environments/environment';
import { FormArray, FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { MaintenanceModule, maintenanceDetailDescription, MaintenanceModuleSaveDto } from './maintenance.model';
import { MaintenanceService } from './maintenance.service';

@Component({
  selector: 'app-maintenance-view',
  templateUrl: './maintenance-view.component.html',
  styleUrl: './maintenance-view.component.scss'
})
export class MaintenanceViewComponent {
  formData: any;
  dropDownLabel: string;
  roleCode: any;
  dialog: any;
  levelType: LevelType[];
  loadingMore: any;
  currentPage: any;
  vendorList: any;
  panError: boolean;
  selectedVendor: any;
  description: string;
  rejectionReason: any;
  requiredError: boolean;
  reasonDialog: boolean;
  maintenceVoucher: MaintenanceModuleSaveDto;
  readonly: boolean;
  ngSelectInvalid: boolean;
  finYear: FinYear[];
  maintenceAmount: any;
  isFileUploaded: boolean;
  vFileExt: any;
  vFileUrl: string;
  viewDoc: boolean;
  uFilename: string;
  YearList = ['Ist Year', '2nd Year', '3rd Year', '4th Year', '5th Year'];
  defaultYear: string[];
  isFile: boolean;
  approved: boolean;
  maxDate: Date;






  constructor(
    protected activatedRoute: ActivatedRoute,
    private levelTypeService: LevelTypeService,
    private commercialVendorService: CommercialVendorService,
    private router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private localStorageService: LocalStorageService,
    private maintenanceService: MaintenanceService,
    private limitTransListService: LimitTransListService,
    private paymentService: PaymentService,
    private spinner: NgxSpinnerService
  ) {
    if (this.formData) {
      this.formData.valueChanges.subscribe(() => { });
    }
    registerLocaleData(enIN);
    this.translateService.onLangChange.subscribe(() => {
      const lang = this.translateService.currentLang;
      this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
    });
  }



  async ngOnInit(): Promise<void> {
    this.defaultYear = this.YearList;
    await this.getRole();

    if (this.activatedRoute.snapshot.queryParamMap.get('id')) {

      this.spinner.show();
      this.maintenanceService
        .getMaintenanceModule(
          Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
        )
        .subscribe((res: HttpResponse<MaintenanceModuleSaveDto>) => {
          this.maintenceVoucher = res.body;
          if (this.maintenceVoucher?.maintenanceModuleDto?.fileName) {
            this.isFileUploaded = true;
            this.isFile = true;
            this.uFilename = this.maintenceVoucher.maintenanceModuleDto.fileName;
          }
          this.maintenceVoucher.maintenanceModuleDto.finalPaymentDate = new Date(
            this.maintenceVoucher.maintenanceModuleDto.finalPaymentDate
          );
          this.maintenceVoucher.maintenanceDetailDesc = res.body.maintenanceDetailDesc.sort((a, b) => {
            const getYearNumber = (billYear: string): number => parseInt(billYear.split(" ")[0]);
            return getYearNumber(a.billYear) - getYearNumber(b.billYear);
          });
          this.maintenceVoucher.maintenanceModuleDto.workCompletionDate = new Date(
            this.maintenceVoucher.maintenanceModuleDto.workCompletionDate
          );
          this.maintenceVoucher.maintenanceModuleDto.administrativeSanctionDate = new Date(
            this.maintenceVoucher.maintenanceModuleDto.administrativeSanctionDate
          );
          if (this.roleCode.role.code === userRoles.roleChecker) {
            this.approved = true;
            this.readonly = true;
          }
          if(this.maintenceVoucher.maintenanceModuleDto.status === 'Approved') {
            this.readonly = true;
            this.approved = false;
          }
          this.spinner.hide();
        });
    } else {
      this.maintenceVoucher = new MaintenanceModuleSaveDto();
      this.maintenceVoucher.maintenanceModuleDto = new MaintenanceModule();
      this.maintenceVoucher.maintenanceDetailDesc = [];
    }
  }

  ngAfterViewInit() {
    this.updateDialogFont();
    if (this.formData) {
      this.formData.valueChanges.subscribe(() => { });
    }
  }

  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res) => {
          this.roleCode = res.body;
          resolve(this.roleCode)
        },
        () => {
          resolve(null);
        }
      );
      this.limitTransListService.getFinYear().subscribe(
        (res: HttpResponse<FinYear[]>) => {
          this.finYear = res.body;
        },
        () => { }
      );
    });
  }

  changeWorkId() {
    this.maintenanceService.getMaintenaceModuleListById(this.maintenceVoucher.maintenanceModuleDto.workId).subscribe(
      (res) => {
        console.log('res', this.finYear.find(_ => _.name === res.body.finYear));
        this.maintenceVoucher.maintenanceModuleDto.finYear = this.finYear.find(_ => _.name === res.body.finYear);
        this.maintenceVoucher.maintenanceModuleDto.constructionCost = res.body.constructionCost;
        this.maintenceVoucher.maintenanceModuleDto.administrativeSanctionAmount = res.body.administrativeSanctionAmount;
        this.maintenceVoucher.maintenanceModuleDto.administrativeSanctionDate = new Date(res.body.administrativeSanctionDate);
        this.maintenceVoucher.maintenanceModuleDto.finalPaymentDate = new Date(res.body.finalPaymentDate);
        this.maxDate = new Date(res.body.finalPaymentDate);
        this.maintenceVoucher.maintenanceModuleDto.workName = res.body.projectName;
        this.maintenceVoucher.maintenanceModuleDto.totalMaintenanceAmount = res.body.maintenanceAmount;
        for (let i = 0; i <= 4; i++) {
          let desc = new maintenanceDetailDescription;
          switch (i) {
            case 0:
              desc.billYear = '1st Year';
              break;
            case 1:
              desc.billYear = '2nd Year';
              break;
            case 2:
              desc.billYear = '3rd Year';
              break;
            case 3:
              desc.billYear = '4th Year';
              break;
            case 4:
              desc.billYear = '5th Year';
              break;
          }
          this.maintenceVoucher.maintenanceDetailDesc.push(desc);
        }
        console.log('res', this.maintenceVoucher);
      },
      (err) => {
        this.notificationService.alertError(err.error.entityName, '');
        this.maintenceVoucher.maintenanceModuleDto.finYear = null;
        this.maintenceVoucher.maintenanceModuleDto.constructionCost = null;
        this.maintenceVoucher.maintenanceModuleDto.administrativeSanctionAmount = null;
        this.maintenceVoucher.maintenanceModuleDto.administrativeSanctionDate = null;
        this.maintenceVoucher.maintenanceModuleDto.finalPaymentDate = null;
        this.maintenceVoucher.maintenanceModuleDto.workName = null;
        this.maintenceVoucher.maintenanceModuleDto.totalMaintenanceAmount = null;
        this.maintenceVoucher.maintenanceModuleDto.workId = null;
        this.maintenceVoucher.maintenanceDetailDesc = [];
        console.log('err', err.error.entityName);
      }
    );
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }


  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelType = res.body;
      },
      () => { }
    );
  }

  calculateTotalAmount(list: maintenanceDetailDescription) {
    console.log('list', list)
    list.gst = ((18 / 100) * list.maintenanceAmount);
    list.lwf = ((1 / 100) * list.maintenanceAmount);
    list.grossTotal = list.maintenanceAmount + list.gst + list.lwf;
  }


  viewDocument() {
    this.paymentService.viewimage(this.maintenceVoucher.maintenanceModuleDto.fileName)
      .subscribe((res) => {
        const filename = res.body.fileName;
        this.vFileExt = filename.split('.').pop();
        this.vFileUrl = "data:image/" + this.vFileExt + ";base64," + res.body.fileType; // Replace with your Base64-encoded image string
        this.viewDoc = true;
      });
  }
  downloadImage(base64Data: string, filename: string) {
    const link = document.createElement('a');
    link.href = base64Data;
    link.download = filename;
    link.click();
  }
  downloadDocument() {
    this.paymentService.getimage(this.maintenceVoucher.maintenanceModuleDto.fileName)
      .subscribe((res) => {
        const filename = res.body.fileName;
        const fileExtension = filename.split('.').pop();
        if (fileExtension == 'pdf') {
          const base64Data = "data:application/pdf;base64," + res.body.fileType; // Replace with your Base64-encoded image string
          this.downloadImage(base64Data, filename);
        }
        else {
          const base64Data = "data:image/" + fileExtension + ";base64," + res.body.fileType; // Replace with your Base64-encoded image string
          this.downloadImage(base64Data, filename);
        }
      },
        (onError) => {

        })
  }
  removeDocument() {
    this.paymentService.deleteimage(this.maintenceVoucher.maintenanceModuleDto.fileName)
      .subscribe((res) => {
        this.notificationService.alertSuccess("File Deleted", '');
        this.isFileUploaded = false;
        this.maintenceVoucher.maintenanceModuleDto.fileName = null;
      },
        (error) => {
          this.notificationService.alertError("Error occured, try again", '');
        });
  }

  onUpload($event) {
    const formData = new FormData();
    const i = $event.files.length - 1;
    const file = $event.files[i];
    const acceptedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (file && !acceptedFormats.includes(file.type)) {
      this.notificationService.alertError('Invalid file format. Please upload a valid file.', '');
      return;
    }
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }

    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.maintenceVoucher.maintenanceModuleDto.fileName =
          res.body.fileName;
        this.uFilename = this.maintenceVoucher.maintenanceModuleDto.fileName;
        this.isFileUploaded = true;
        this.notificationService.alertSuccess('File Uploaded Successfully', '');
      },
      () => {
        this.notificationService.alertError('Error, please make sure file size is within 5MB and in supported format', '');
      }
    );

    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }


  getActionUrl() {
    this.localStorageService.store('refresh', 'Yes');
    return environment.CDAC_URL;
  }



  loadMoreItems(page: number) {
    const paginationOptions = { size: 10, page };
    return this.commercialVendorService.filter(paginationOptions, this.vendorFilterQuery);
  }
  vendorFilterQuery(paginationOptions: { size: number; page: number; }, vendorFilterQuery: any) {
    throw new Error('Method not implemented.');
  }

  onScrollToEnd() {
    if (!this.loadingMore) {
      this.loadingMore = true;
      this.currentPage++;

      this.loadMoreItems(this.currentPage).subscribe(
        (res: HttpResponse<any>) => {
          const additionalItems = res.body;
          this.vendorList = this.vendorList.concat(additionalItems);
          this.loadingMore = false;
        },
        () => {
          this.loadingMore = false;
        }
      );
    }
  }






  convertToEvenNumber(num: number): number {
    if (num % 2 !== 0) {
      num++;
    }
    return num;
  }

  validatePan() {
    this.panError = !this.validationService.isPanValid(
      this.selectedVendor.panNo
    );
  }

  addDescritption(list: string) {
    this.description = list;
  }

  save() {
    this.ngSelectInvalid = false;
    // if (this.voucherGenerationDto.vendorTransactionDto.transactionAmount <= 0) {
    //   this.notificationService.alertError('Transaction Amount should be greater than 0', '');
    //   return;
    // }

    // if (this.roleCode?.role?.code === 'DMAKER') {
    //   if (this.voucherGenerationDto.vendorTransactionDto.netAmount > this.balancePay) {
    //     this.notificationService.alertError('Limit amount exceeded, Gross Total should be within project unpaid amount: Rs. ' + this.balancePay, '')
    //     return;
    //   }
    //   if (this.voucherGenerationDto.vendorTransactionDto.transactionAmount > this.balancePay) {
    //     this.notificationService.alertError('Limit amount exceeded, Transaction Amount should be within project unpaid amount: Rs. ' + this.balancePay, '')
    //     return;
    //   }
    //   if (
    //     this.voucherGenerationDto.vendorTransactionDto.netAmount >
    //     this.limitAmount
    //   ) {
    //     this.notificationService.alertError(
    //       'Due to insufficient limit, You are not eligible to generate Voucher',
    //       ''
    //     );
    //     return;
    //   }
    // }
    const isFormValid =
      this.maintenceVoucher.maintenanceModuleDto.workCompletionDate &&
      this.maintenceVoucher.maintenanceModuleDto.fileName
    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    this.maintenceVoucher.maintenanceDetailDesc.forEach((element) => {
      let isVal;
      isVal = element.grossTotal && element.billYear;
      if (!isVal) {
        this.ngSelectInvalid = true;
        this, this.notificationService.alertError('please Fill Amount', '');
      }
    });

    if (this.ngSelectInvalid) {
      return;
    }
    this.ngSelectInvalid = false;

    this.maintenceVoucher.maintenanceModuleDto.levelMaster = this.roleCode.levelMaster;
    this.spinner.show();
    this.maintenanceService.save(this.maintenceVoucher).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.readonly = true;
        this.router.navigate(['/work-maintenance']);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      (error) => {
        this.spinner.hide();
        if (error.status == 400) {

          this.notificationService.alertError(error.error.message, '');

        } else {
          this.notificationService.alertError('An unexpected error occurred.', '');
        }
      }
    );
  }



  rejectDialog() {
    if (!this.rejectionReason) {
      this.requiredError = true;
      return;
    }
    else {
      this.reasonDialog = false;
      this.reject();
    }
  }



  submit() {
  }
  BackButtonClick() {
    this.router.navigate(['/work-maintenance'])
  }

  checkYear(list: maintenanceDetailDescription) {
    this.YearList = this.defaultYear.filter(_ => _ !== list.billYear);
  }

  approve() {
    let query = {
      id: this.maintenceVoucher.maintenanceModuleDto.id
    };
    this.spinner.show();
    this.maintenanceService.approve(query).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.readonly = true;
        this.router.navigate(['/work-maintenance']);
        this.notificationService.alertSuccess('Approved Successfully', '');
      },
      (error) => {
        this.spinner.hide();
        if (error.status == 400) {
          this.notificationService.alertError(error.error.message, '');
        } else {
          this.notificationService.alertError('An unexpected error occurred.', '');
        }
      }
    );
  }

  reject() {
    let query = {
      id: this.maintenceVoucher.maintenanceModuleDto.id,
      isReject:true,
      rejectReason:this.rejectionReason
    };
    this.spinner.show();
    this.maintenanceService.reject(query).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.readonly = true;
        this.router.navigate(['/work-maintenance']);
        this.notificationService.alertSuccess('Reject Successfully', '');
      },
      (error) => {
        this.spinner.hide();
        if (error.status == 400) {
          this.notificationService.alertError(error.error.message, '');
        } else {
          this.notificationService.alertError('An unexpected error occurred.', '');
        }
      }
    );

  }
}

