import { FinYearDto } from 'src/app/shared/common.model';
import { AdminUserDtoCreatedby, AdminUserDtoUpdatedBy } from '../limit-set/limit-set';
import { DepartmentList } from './../department-list/department-list';
import { SchemeList } from './../scheme-list/scheme-list';
export class Funds {
    id: number
    department:DepartmentList
    scheme: SchemeList
    finYear: FinYearDto
    budgetAmount: number
    budgetAmountAsString: string
    sanctionAmountAsString: string
    utilizedAmountAsString: string
           
}

export class FundsDto {
    id: number
    departmentDto: DepartmentList
    schemeDto: SchemeList
    finYearDto: FinYearDto
    budgetAmount: number
    description: string
    fileName:string
    budgetAmountAsString: string
    sanctionAmountAsString: any
    utilizedAmountAsString: any
    createdOn: string
    updatedOn: string
    transactionDate:string
    adminUserDtoCreatedby: AdminUserDtoCreatedby
    adminUserDtoUpdatedBy: AdminUserDtoUpdatedBy
    allocationType: string
  }