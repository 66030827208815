import { FinYear } from "src/app/module/limit-translist/limit-translist.module";
import { ComponentTypeDto, DepartmentDto, ImplementingAgencyDto, SchemeDto } from "../payment-voucher/payment-voucher.model";

export class MaintenanceTransaction {
    id?: number;
    departmentId?: number;
    schemeId?: number;
    implementingAgencyId?: number;
    commercialVendor?: number;
    levelMasterId?: number;
    commercialVendorName?: string;
    levelMasterName?: string;
    commercialCompanyName?: string;
    commercialPanNo?: string;
    commercialGst?: string;
    commercialIfscode?: string;
    commercialAccountNo?: string;
    levelTypeId?: number;
    workName?: string;
    workId?: string;
    signatureMaker?: string;
    signMessage?: string;
    signatureChecker1?: string;
    signatureChecker2?: string;
    grossAmount?: number;
    transactionAmount?: number;
    status?: string;
    count?: number;
    voucherNo?: string;
    activeFlag?: string;
    signStatus?: boolean;
    transactionStatus?: string;
    utrNo?: string;
    journalNumber?: string;
    paymentDate?: Date;
    rejectionReason?: string;
    createdOn?: Date;
    updatedOn?: Date;
    createdBy?: number;
    updatedBy?: number;
    finYearId?: number;
    administrativeSanctionDate?: Date;
    administrativeSanctionAmount?: number;
    constructionCost?: number;
    totalMaintenanceAmount?: number;
    finalPaymentDate?: Date;
    workCompletionDate?: Date;
    maintenanceCost?: number;
    maintenanceGst?: number;
    maintenanceLwf?: number;
    maintenanceGrossTotal?: number;
    proceedingNumber?: string;
    proceedingDate?: Date;
    proceedingUpload?: string;
    maintenanceUpload?: string;
    reportUpload?: string;
    description?: string;
    isEditable:boolean;
    scheme: SchemeDto;
    implementingAgency: ImplementingAgencyDto;
    finYear: FinYear;
    componentType: ComponentTypeDto;
    department: DepartmentDto;
    componentName:string;
    bankAccName:string;
    bankName:string;
}

export class MaintenancePayment {
    id: number;
    maintenanceTransactionId: number;
    billYear: string;
    partAmount: number;
    maintenanceScore: number;
    currentBillValue: number;
    sgst: number;
    cgst: number;
    lwf: number;
    grossTotal: number;
    activeFlag: string;
    it: number;
    tdsOnGst: number;
    labourCess: number;
    remark: string;
    itPercentage: number;
    tdsOnGstPercentage: number;
    labourCessPercentage: number;
    billPart:string;
    maintenanceDetailDescId:number;
}

export class MaintenanceGeneration {
    maintenanceTransactionDto: MaintenanceTransaction;
    maintenanceTransactionDescDto: MaintenancePayment;
}