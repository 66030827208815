import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Deduction } from './deductions.model';
import { createRequestOption } from '../auth/request/request-util';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';

export type EntityArrayResponseType = HttpResponse<Deduction[]>;

@Injectable({
  providedIn: 'root'
})
export class DeductionsService {

  protected getDeductionListUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/report/getDeductionList');
   
  constructor(protected http: HttpClient,private localStorageService:LocalStorageService) {}
  
  getDeductionList(req: any,paymentMethod:string,fromDate:string,toDate:string):Observable<EntityArrayResponseType> {   
    const options = createRequestOption(req);
      return this.http
      .get<Deduction[]>(`${this.getDeductionListUrl}?fromDate=${fromDate}&toDate=${toDate}&paymentMethod=${paymentMethod}`, { params:options, observe: 'response' })
  }
}
