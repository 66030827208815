<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        <p>Entry For Enable Payments</p>
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col">
                    <div class="card p-3">
                        <div class="row">
                            <div class="col-sm-4 col-md-4">
                                <label class="form-control-label" [ngClass]="fontService.labelClass" for="name">Work Id
                                </label>
                                <input type="text" class="form-control" [disabled]="view" [(ngModel)]="workId" (change)="getMaintenancePayment()" name="name" autocomplete="off" maxlength="60" onblur="this.value =this.value.trim();" id="name" />
                            </div>
                            <div *ngIf="workEnableList" class="col-sm-4 col-md-4">
                                <label class="form-control-label" [ngClass]="fontService.labelClass" for="name">Select Maintenance Payment
                                </label>
                                <label *ngFor="let maintenance of workEnableList" class="checkbox-label">
                                    <input *ngIf="workEnableList && !view" type="checkbox" name="maintenance" [(ngModel)]="maintenance.isReenable" [value]="true" class="custom-checkbox" />
                                    <span class="bullet">•</span>
                                    {{ maintenance?.billYear }} - {{ maintenance?.billPart }}
                                </label>
                            </div>
                            <div *ngIf="workEnableList && !view" class="col-lg-4">
                                <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">Upload District Request Letter
                                  </label>
                                <div>
                                    <small class="mb-2" [ngClass]="fontService.regularClass">Note : Allowed file types are pdf and
                                    Maximum file size should be 1MB</small>
                                </div>
                                <p-fileUpload (onUpload)="onUpload($event)" accept="application/pdf" [customUpload]="true" [disabled]="isFileUploaded" (uploadHandler)="onUpload($event)" auto="true" multiple="false" [showUploadButton]="false" [showCancelButton]="false" chooseLabel="{{'common.upload'|translate}}">
                                </p-fileUpload>
                                <div class="row mt-2" *ngIf="isFileUploaded">
                                    <div class="col">
                                        <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument()">{{uFilename}}</a>
                                        <i class="pi pi-times add-margin" (click)="removeDocument()"></i>
                                        <i class="pi pi-download add-margin" (click)="downloadDocument()"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p-toolbar>
                            <div class="p-toolbar-group-start"></div>
                            <div class="p-toolbar-group-end">
                                <button *ngIf="workEnableList && !view" pButton pRipple icon="pi pi-check" class="p-button-success custom-button" (click)="save()">
                                    <span class="button-label" [ngClass]="fontService.buttonClass">Submit</span>
                                  </button>
                                <button pButton pRipple icon="pi pi-times" class="p-button-danger custom-button" (click)="cancel()">
                                    <span class="button-label" [ngClass]="fontService.buttonClass">cancel</span>
                                  </button>
                            </div>
                        </p-toolbar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
    <ng-template pTemplate="body">
        <div style='position: relative; height: 100%;'>
            <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
            <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
        </div>
    </ng-template>
</p-dialog>