import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { Statutory } from './statutory-payment.model';
import { LocalStorageService } from 'ngx-webstorage';


@Injectable({ providedIn: 'root' })
export class StatutoryPaymentService {
   
    protected getStatutoryPaymentUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getStatutoryPayment');
   
    constructor(protected http: HttpClient,protected localStorageService:LocalStorageService) {}
   

    getStatutoryPayment(fromDate,toDate){
        return this.http.post<Statutory>(`${this.getStatutoryPaymentUrl}?fromDate=${fromDate}&toDate=${toDate}`,null, {observe: 'response' })
    }
    
   

}