import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  CommercialVendorsDto,
  Vendor,
} from 'src/app/module/commercial-vendors/commercial-vendors';
import { CommercialVendorService } from 'src/app/module/commercial-vendors/commercial-vendors.service';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { DepartmentList } from 'src/app/module/department-list/department-list';
import { DepartmentListService } from 'src/app/module/department-list/department-list.service';
import { LevelTypeService } from 'src/app/module/level-type/level-type.service';
import { SchemeList } from 'src/app/module/scheme-list/scheme-list';
import { SchemeListService } from 'src/app/module/scheme-list/scheme-list.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import {
  ComponentDto,
  ComponentType,
} from './../../module/component-type/component-type';
import { LevelType } from './../../module/level-type/level-type';
import { filterQuery } from './../../shared/common.model';
import {
  ComponentMasterDto,
  paymentVoucherGen,
  VendorTransaction,
  vendorTransactionDto,
  VoucherBillDescDto,
} from './payment-voucher.model';
import { PaymentService } from './payment-voucher.service';

import { registerLocaleData } from '@angular/common';
import enIN from '@angular/common/locales/en-IN';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Dialog } from 'primeng/dialog';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { NavbarComponent } from 'src/app/layout/navbar/navbar.component';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { UserService } from 'src/app/module/user/user.service';
import { TenderPageService } from 'src/app/module/vendor-mapping-page/tendor-page.service';
import { Project } from 'src/app/project/project.model';
import { ProjectService } from 'src/app/project/project.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-voucher',
  templateUrl: './payment-voucher.component.html',
  styleUrls: ['./payment-voucher.component.scss'],
})
export class PaymentVoucherComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;
  @ViewChild('formData') formData;
  navbarComponent!: NavbarComponent;
  enableStateGst: boolean;
  enableIGst: boolean;
  departmentList: DepartmentList[];
  pdfString: any;
  schemeList: SchemeList[];
  selectedVendor: CommercialVendorsDto;
  levelType: LevelType[];
  grantLevel: ComponentType;
  grantlevel: any;
  componentMaster: ComponentMasterDto;
  componentMasterList: any;
  levelMasterDto: LevelType;
  voucherGenerationDto: paymentVoucherGen;
  date: Date;
  voucherNo: string;
  vendorList: Vendor[];
  projectList:Project[];
  selectedProject:any;
  selectedscheme: any;
  profileDialog: boolean = false;
  searchResult: SearchResult<any> =
    new SearchResult<VendorTransaction>();
  totalDeduction: number;
  dropDownLabel: any;
  maxDate = new Date();
  amount: number;
  description: string;
  signButtonName: any;
  noRecord: boolean;
  isFileUploaded:boolean;
  uFilename:string;
  viewDoc:boolean=false;
  vFileExt:string;
  vFileUrl:string;
  finYear: FinYear[];
  paymentTypeList = ['Part', 'Final'];
  currentPage: number = 0;
  loadingMore: boolean = false;
  userContextSubscription: Subscription;
  requiredError:boolean;
  reasonDialog:boolean;
  rejectionReason:string;
  enableBackButton:boolean =true;
  vendorFilterQuery = [
    {
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    },
    {
      key: 'approve',
      operation: 'equals',
      value: 'YES',
    },
   
  ];
  cols = [
    {
      field: 'levelMasterName',
      header: 'District',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'workId',
      header: 'Work Id',
      isFilterable: true,
      isSelectcolumn: true,
    },
    {
      field: 'voucherNo',
      header: 'Voucher No',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'commercialVendorName',
      header: 'Vendor Name',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'bankName',
      header: 'Bank Name',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'commercialIfscode',
      header: 'IFSC Code',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'commercialAccountNo',
      header: 'Vendor Account',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'transDate',
      header: 'Transaction Date',
      isSortable: true,
      isSelectcolumn: true,
      type: 'date',
    },
    {
      field: 'grossAmount',
      header: 'Rate',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
      isFilterable: false,
    },
    {
      field: 'transactionAmount',
      header: 'Total Transction Amount',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
      isFilterable: false,
    },
  ];

  // validation fields properties
  public ifscError = false;
  public panError = false;
  public hsnError = false;

  //required fields property
  ngSelectInvalid: boolean = false;
  filterProps: any;
  securityDeposit: number;
  enableGstOnTds: boolean;
  gstOnTdsAmount:number;
  uploadedFiles: any;
  enableLabourChess: boolean;
  enableSecurityDeposit: boolean;
  enableMaterialSupply:boolean;
  enableMiscellaneous:boolean;
  enableMiscellaneousAdditional:boolean;
  roleCode: any;
  readonly: boolean;
  enableRevertButton: boolean;
  param1: any;
  param2: any;
  param3: any;
  enableSignButton: boolean;
  downloadButton: boolean;
  paymentSucessDialog: boolean;
  paymentSucessText: any;
  enablesign: boolean = false;
  enableRePaymentButton: boolean;
  disablepaymentButton: boolean;
  buttonText: string;
  checkstatusButton: boolean;
  interactionId: any;
  checkAmount: boolean;
  zonalList: LevelType;
  selectedZonalUser: any;
  enableZonalButton: boolean;
  enableApproved: boolean;
  finy: any;
  limitAmount: number;
  projectLimitAmount: number;
  isEditable: boolean = true;
  amountPaid:number=0;
  balancePay:number=0;
  disableAmount: boolean;
  partialCount: string;
  isPartial: boolean;
  totalWithheldAmount: number=0;
  partCount: string;
  WithheldAmount: number=0;
  totWithheldAmount: number;
  totalBillValue: number=0;
  totBillValue: number;
  workPayment: boolean;
  noPendingVoucher: boolean;
  workPaymentList: any;
  finalPayment: string;
  filterTimeout: any;
  enableView: boolean;
  userRoles = userRoles;


  constructor(
    protected activatedRoute: ActivatedRoute,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private componentTypeService: ComponentTypeService,
    private commercialVendorService: CommercialVendorService,
    private paymentService: PaymentService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private userService: UserService,
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private limitTransListService:LimitTransListService,
    private projectService:ProjectService,
    private userRoleService:UserRoleService,
    private tenderPageService: TenderPageService,
  ) {
    if (this.formData) {
      this.formData.valueChanges.subscribe((event) => {});
    }
    registerLocaleData(enIN);
    this.translateService.onLangChange.subscribe((event) => {
      const lang = this.translateService.currentLang;
      this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getRole();
        this.paymentService
          .getByImplemeting(this.roleCode.implementingAgencyId)
          .subscribe(
            (res: HttpResponse<any>) => {
              this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto =
                res.body;

              this.levelTypeService
                .getbyId(
                  this.voucherGenerationDto.vendorTransactionDto
                    .implementingAgencyDto.leveltype
                )
                .subscribe(
                  (res: HttpResponse<LevelType>) => {
                    this.levelMasterDto = res.body;
                  },
                  () => {}
                );
            },
            () => {}
          );
        if (
          this.roleCode.role.code === userRoles.roleMaker ||
          this.roleCode.role.code === 'VMAKER' ||
          this.roleCode.role.code === 'BMAKER'
        ) {
          if (this.roleCode.role.code === 'BMAKER') {
            this.paymentService
              .getZonalCheckers(this.roleCode.levelMaster.id)
              .subscribe(
                (res: HttpResponse<LevelType>) => {
                  this.zonalList = res.body;
                },
                () => {}
              );
          }
          this.signButtonName = 'Perform e-Sign Administrative Officer';
        } else if (
          
          this.roleCode.role.code === userRoles.roleChecker ||
          this.roleCode.role.code === 'BC1' ||
          this.roleCode.role.code === 'VC1' ||
          this.roleCode.role.code === 'ZC1'
        ) {
          this.enableApproved=true;
          if (this.roleCode.role.code === 'BC1' || this.roleCode.role.code === 'ZC1') {
            this.checkAmount = true;
          }
          this.signButtonName = 'Perform e-Sign Project Director';
          this.enableRevertButton = true;
          this.readonly = true;
        } else if (
          this.roleCode.role.code === userRoles.roleApprover ||
          this.roleCode.role.code === 'BC2' ||
          this.roleCode.role.code === 'VC2'
        ) {
          this.enableApproved=true;
          this.signButtonName = 'Perform e-Sign District Collector';
          this.enableRevertButton = true;
          this.downloadButton = true;
          this.readonly = true;
        }
        if (
          this.roleCode.role.code === 'BMAKER' ||
          this.roleCode.role.code === 'BC1' ||
          this.roleCode.role.code === 'BC2' ||
          this.roleCode.role.code === 'ZC1'
        ) {
          this.enableZonalButton = true;
        }
     
    if (this.activatedRoute.snapshot.queryParamMap.get('response')){
      this.paymentSucessDialog = true;
      this.paymentSucessText = this.activatedRoute.snapshot.queryParamMap.get('response');
      this.disablepaymentButton = true;
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('error')){
      this.notificationService.alertError('Error Occurred: '+this.activatedRoute.snapshot.queryParamMap.get('error'),'');
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
      if(this.activatedRoute.snapshot.queryParamMap.get('view')){
        this.enableView = true;
      }
      this.spinner.show();
      this.paymentService
        .updatedVocher(
          Number((this.activatedRoute.snapshot.queryParamMap.get('id')))
        )
        .subscribe(
          (res: HttpResponse<paymentVoucherGen>) => {
            this.voucherGenerationDto = res.body;
            this.isEditable=this.voucherGenerationDto.vendorTransactionDto.isEditable=="True"?true:false;
            const getWorkPaymentPartCountPromise = new Promise((resolve, reject) => {
              this.getWorkPaymentPartCount(resolve, reject);
          });
            if(this.voucherGenerationDto.vendorTransactionDto.invoiceImage)
            {
              this.uFilename=this.voucherGenerationDto.vendorTransactionDto.invoiceImage;
              this.isFileUploaded=true;
            }
            this.finalPayment=this.voucherGenerationDto.vendorTransactionDto.project.finalPayment;
            this.balancePay=Number(this.voucherGenerationDto.vendorTransactionDto.project.totalCost)-Number(this.voucherGenerationDto.vendorTransactionDto.workExpenditure);
            if(this.voucherGenerationDto.voucherBillDescDtoList[0].paymentType=='Part'){
              this.partialCount=this.voucherGenerationDto.voucherBillDescDtoList[0].partialCount;
            this.isPartial=true;}
            this.spinner.hide();
            if (
              this.checkAmount &&
              res.body.vendorTransactionDto.transactionAmount <= 500000 &&
              res.body.vendorTransactionDto.signatureVicePresident
            ) {
              res.body.vendorTransactionDto.signaturePresident = 'signed';
            }
            if (res.body?.vendorTransactionDto?.zonalChecker) {
              this.userService
              .getUser(res.body?.vendorTransactionDto?.zonalChecker)
              .subscribe(
                (res: HttpResponse<any>) => {
                  this.selectedZonalUser = res.body;
                },
                () => {}
              );
            }
            
            this.paymentStatus();
            if (this.voucherGenerationDto.vendorTransactionDto)
              if (this.voucherGenerationDto.vendorTransactionDto.signStatus) {
                this.readonly = true;
                this.enableSignButton = true;
              }
            this.date = new Date(
              this.voucherGenerationDto.vendorTransactionDto.transDate
            );
            this.securityDeposit =
              this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount;
            if (this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount) {
              this.enableGstOnTds = true;
            } else {
              this.enableGstOnTds = false;
            }
            if (
              this.voucherGenerationDto.vendorTransactionDto
                .securityDepositAmount
            ) {
              this.enableSecurityDeposit = true;
            } else {
              this.enableSecurityDeposit = false;
            }
            if (this.voucherGenerationDto.vendorTransactionDto.labourCess) {
              this.enableLabourChess = true;
            } else {
              this.enableLabourChess = false;
            }
            if (
              this.voucherGenerationDto.vendorTransactionDto
                .miscellaneousAmount
            ) {
              this.enableMiscellaneous = true;
            } else {
              this.enableMiscellaneous = false;
            } if (
              this.voucherGenerationDto.vendorTransactionDto
                .materialSupplyAmount
            ) {
              this.enableMaterialSupply = true;
            } else {
              this.enableMaterialSupply = false;
            }
            if (
              this.voucherGenerationDto.vendorTransactionDto
                .miscellaneousAdditionAmount
            ) {
              this.enableMiscellaneousAdditional = true;
            } else {
              this.enableMiscellaneousAdditional = false;
            }
            this.totalDeduction =
              this.voucherGenerationDto.vendorTransactionDto.totlaInRupees;
            this.selectedVendor =
              this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto;
            if (this.selectedVendor?.gstNo) {
              this.enableStateGst = true;
              this.enableIGst = false;
            } else {
              this.enableStateGst = false;
            }
            if (this.voucherGenerationDto.vendorTransactionDto.tdsAmount) {
              this.voucherGenerationDto.vendorTransactionDto.tdsApplicable =
                true;
            } else {
              this.voucherGenerationDto.vendorTransactionDto.tdsApplicable =
                false;
            }
            if (this.voucherGenerationDto.vendorTransactionDto.invoiceDate) {
              this.voucherGenerationDto.vendorTransactionDto.invoiceDate =
                new Date(
                  this.voucherGenerationDto.vendorTransactionDto.invoiceDate
                );
            }
            this.grantlevel =
              this.voucherGenerationDto.vendorTransactionDto.componentTypeDto;
              this.componentMaster=this.voucherGenerationDto.vendorTransactionDto.componentMaster;
            if(this.componentMaster.name === 'Work payment'){
              this.workPayment=true;
            } else {
              this.workPayment=false;
            }
            getWorkPaymentPartCountPromise.then(() => {
              this.vendorFilter();
              this.totWithheldAmount=0;
              this.totWithheldAmount=this.WithheldAmount;
            });
            this.levelMasterDto =
              this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto.levelTypeDto;
              this.limitAmount= this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto.programmeFundAuthorizationAmount-
              this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto.programmeUtilizedAmount;
              this.projectLimitAmount=this.voucherGenerationDto.vendorTransactionDto.project.totalCost-
              this.voucherGenerationDto.vendorTransactionDto.project.utilizedTotalCost;
            this.levelTypeService
              .getbyId(
                this.voucherGenerationDto.vendorTransactionDto
                  .implementingAgencyDto.leveltype
              )
              .subscribe(
                (res: HttpResponse<LevelType>) => {
                  this.levelMasterDto = res.body;
                },
                () => {}
              );
              this.componentTypeService.filter().subscribe(
                (res: HttpResponse<any>) => {
                  this.grantLevel = res.body.filter(item=>item.name==='Programme Fund');
                  this.grantlevel=this.grantLevel[0];
                  this.ongrantlevel();
                    },
                    () => {}
                  );

            
           
              this.levelTypeService
              .getbyId(
                this.voucherGenerationDto.vendorTransactionDto
                  .implementingAgencyDto.leveltype
              )
              .subscribe(
                (res: HttpResponse<LevelType>) => {
                  this.levelMasterDto = res.body;
                  
                  this.onLevelType();
                },
                () => {}
                
              );
          },
          () => {}
        );
      if (
        this.roleCode.role.code === userRoles.roleApprover ||
        this.roleCode.role.code === 'BC2' ||
        this.roleCode.role.code === 'VC2'
      ) {
        if (
          this.voucherGenerationDto.vendorTransactionDto.signaturePresident !==
          null
        ) {
          this.enablesign = true;
        }
      }
    }
    if (!this.activatedRoute.snapshot.queryParamMap.get('id')) {
      this.voucherGenerationDto = new paymentVoucherGen();
      this.voucherGenerationDto.vendorTransactionDto =
        new vendorTransactionDto();
      this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto =
        new CommercialVendorsDto();
      this.enableGstOnTds = true;
      this.enableLabourChess = false;
      this.enableSecurityDeposit = false;
      this.enableMiscellaneous = false;
      this.enableMiscellaneousAdditional=false;
      this.enableMaterialSupply = false;
      this.selectedVendor = new CommercialVendorsDto();
      this.voucherGenerationDto.vendorTransactionDto =
        new vendorTransactionDto();
      this.voucherGenerationDto.vendorTransactionDto.tdsApplicable = true;
      this.voucherGenerationDto.vendorTransactionDto.componentTypeDto =
        new ComponentDto();
      this.voucherGenerationDto.vendorTransactionDto.voucherNo =
        this.activatedRoute.snapshot.queryParamMap.get('VoucherNo');
      this.date = new Date();
    }
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => {}
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => {}
    );
  }

  ngAfterViewInit() {
    this.updateDialogFont();
    if (this.formData) {
      this.formData.valueChanges.subscribe((event) => {});
    }
  }

  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res)=>{
          this.roleCode = res.body;
          resolve(this.roleCode)
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }

  loadProjectData(){
    if(this.voucherGenerationDto.vendorTransactionDto.workId){
      this.noPendingVoucher=false;
      const getWorkPaymentPartCountPromise = new Promise((resolve, reject) => {
        this.getWorkPaymentPartCount(resolve, reject);
      });
      this.tenderPageService.filter({workId:this.voucherGenerationDto.vendorTransactionDto.workId,page:0,size:1,levelMasterId:this.roleCode?.levelMaster?.id,approve:"Yes",isPayment:true}).subscribe(
        (res:any) => {
                this.voucherGenerationDto.vendorTransactionDto.project = res.body[0].work;
                this.finalPayment=res.body[0].work.finalPayment;
                this.projectLimitAmount = this.voucherGenerationDto.vendorTransactionDto.project.totalCost-this.voucherGenerationDto.vendorTransactionDto.project.utilizedTotalCost;
                this.balancePay=Number(this.voucherGenerationDto.vendorTransactionDto.project.totalCost)-Number(this.voucherGenerationDto.vendorTransactionDto.id?this.voucherGenerationDto.vendorTransactionDto.workExpenditure:this.voucherGenerationDto.vendorTransactionDto.project.utilizedTotalCost);
                this.voucherGenerationDto.vendorTransactionDto.finYearDto=this.voucherGenerationDto.vendorTransactionDto.project.finYear;
                this.limitTransListService.getAmountAndLBC({
                  levelMasterId: this.roleCode.levelMaster.id,
                  schemeId: this.voucherGenerationDto.vendorTransactionDto.project.scheme.id,
                  finYearId: this.voucherGenerationDto.vendorTransactionDto.project.finYear.id,
                })
                .subscribe(
                  (res: HttpResponse<any>) => {
                    this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto =res.body;
                    this.limitAmount= this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto.programmeFundAuthorizationAmount-
                    this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto.programmeUtilizedAmount;
                  },
                  () => {}
                );
            },
            (error) => {
              this.notificationService.alertError(error.error.errorKey,'');
              this.voucherGenerationDto.vendorTransactionDto.workId=null;
            }
          );
          getWorkPaymentPartCountPromise.then(() => {
          this.paymentService.getcheckPendingVoucher(this.voucherGenerationDto.vendorTransactionDto.workId).subscribe(
            (res)=>{
              this.noPendingVoucher=true;
              this.componentTypeService.filter().subscribe(
                (res: HttpResponse<any>) => {
                        this.grantLevel = res.body.filter(item=>item.name==='Programme Fund');
                        this.grantlevel=this.grantLevel[0];
                        this.ongrantlevel();
                    },
                    (error) => {}
                  );
                },
            (error)=>{
              this.noPendingVoucher=false;
              this.notificationService.alertError('There is voucher pending for this work,you cannot proceed further','');
              this.voucherGenerationDto.vendorTransactionDto.project=null;

            }
          );
          }).catch(error => {
            
        });
      }
  }

  ongrantlevel(){
    this.componentMaster=null;
    if(this.voucherGenerationDto.vendorTransactionDto.componentMaster){
    this.componentMaster=this.voucherGenerationDto.vendorTransactionDto.componentMaster;
    }
    this.componentTypeService.findbyMasterId(this.grantlevel.id).subscribe(
      (res: HttpResponse<any>) => {
        if(this.finalPayment== 'Yes'){
        this.componentMasterList = res.body.filter(item=>!item.name.startsWith('Statutory') && !item.name.startsWith('Work'));
        } else {
        this.componentMasterList = res.body.filter(item=>!item.name.startsWith('Statutory'));
        }
      },
      () => {}
    );
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  onPaymentTypeChange(list){
    this.disableAmount=false; 
    this.isPartial=false;
    if(list.paymentType=="Final"){
       list.amount=this.balancePay;
      this.disableAmount=true; 
      this.partialCount=null;
      this.totalWithheldAmount=this.WithheldAmount;
      this.voucherGenerationDto.vendorTransactionDto.securityDeposit=2.5;
      this.calculatetotal(list);
    } else{
      this.partialCount=this.partCount;
      this.totalWithheldAmount=0;
      this.voucherGenerationDto.vendorTransactionDto.securityDeposit=5;
      this.isPartial=true;
      list.amount=0;
      this.calculatetotal(list);
    }
  }

  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelType = res.body;
      },
      () => {}
    );
  }

  onLevelType() {
    this.componentTypeService.filter().subscribe(
      (res: HttpResponse<any>) => {
        this.grantLevel = res.body.filter(item=>item.name==='Programme Fund');
        this.grantlevel=this.grantLevel[0];
        this.ongrantlevel();
          },
          () => {}
        );
  }
  viewDocument(){
    this.paymentService.viewimage(this.voucherGenerationDto.vendorTransactionDto.invoiceImage)
    .subscribe((res)=>
    {
      const filename = res.body.fileName; 
      this.vFileExt  = filename.split('.').pop();
      this.vFileUrl = "data:image/"+this.vFileExt+";base64,"+res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc=true;
  });
}
  downloadImage(base64Data: string, filename: string) {
    const link = document.createElement('a');
    link.href = base64Data;
    link.download = filename;
    link.click();
  }
  downloadDocument(){
  this.paymentService.getimage(this.voucherGenerationDto.vendorTransactionDto.invoiceImage)
  .subscribe((res)=>
  {
    const filename = res.body.fileName; 
    const fileExtension = filename.split('.').pop();

    if(fileExtension=='pdf')
    {
    const base64Data = "data:application/pdf;base64,"+res.body.fileType; // Replace with your Base64-encoded image string
    this.downloadImage(base64Data, filename);
  }
    else{
    const base64Data = "data:image/"+fileExtension+";base64,"+res.body.fileType; // Replace with your Base64-encoded image string
    this.downloadImage(base64Data, filename);
    }
  },
  (onError)=>
  {

  })
  }
  removeDocument(){
    this.paymentService.deleteimage(this.voucherGenerationDto.vendorTransactionDto.invoiceImage)
    .subscribe((res)=>{
    this.notificationService.alertSuccess("File Deleted",'');
    this.isFileUploaded=false;
    this.voucherGenerationDto.vendorTransactionDto.invoiceImage=null;
    },
    (error)=>{
    this.notificationService.alertError("Error occured, try again",'');
    });
  }

  onUpload($event) {
    const formData = new FormData();
    const i=$event.files.length-1;
    const file = $event.files[i];
    const acceptedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (file && !acceptedFormats.includes(file.type)) {
      this.notificationService.alertError('Invalid file format. Please upload a valid file.','');
      return;
    }
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }

    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.voucherGenerationDto.vendorTransactionDto.invoiceImage =
          res.body.fileName;
          this.uFilename=this.voucherGenerationDto.vendorTransactionDto.invoiceImage;
          this.isFileUploaded=true;
        this.notificationService.alertSuccess('File Uploaded Successfully', '');
      },
      () => {
        this.notificationService.alertError('Error, please make sure file size is within 5MB and in supported format','');
      }
    );

    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }
  

  getActionUrl(){
    this.localStorageService.store('refresh', 'Yes');
    return environment.CDAC_URL;
  }

  vendorFilter($event?) {
    if(this.componentMaster.name === 'Work payment'){
      this.workPayment=true;
      if (this.workPaymentList?.length > 0) {
        this.WithheldAmount=0;
        this.totalBillValue=0;
        if (this.workPayment)
            this.partCount = 'Part ' + (this.workPaymentList.length + 1);
        this.workPaymentList.forEach((element) => {
          if(element.paymentType!=="Final"){
          this.WithheldAmount += element.securityDepositAmount;
          }
        });
        this.workPaymentList.forEach((element)=>{
          this.totalBillValue+=element.grossAmount;
        });
    } else {
        this.partCount = 'Part ' + 1;
        this.WithheldAmount = 0;
    }
    if(!this.voucherGenerationDto.vendorTransactionDto.id) {
      this.enableLabourChess = true;
      this.enableSecurityDeposit = true;
      this.voucherGenerationDto.vendorTransactionDto.labourCessPercentage = 1;
    }
        if(this.voucherGenerationDto.voucherBillDescDtoList){
          if(this.voucherGenerationDto?.voucherBillDescDtoList[0].paymentType=='Part'){
            this.voucherGenerationDto.vendorTransactionDto.securityDeposit=5;
            }else if(this.voucherGenerationDto?.voucherBillDescDtoList[0].paymentType=='Final'){
            this.voucherGenerationDto.vendorTransactionDto.securityDeposit=2.5;
            }
        }
        
    } else {
      this.workPayment=false;
      if(!this.voucherGenerationDto.vendorTransactionDto.id)
      {
        this.enableLabourChess = false;
        this.enableSecurityDeposit = false;
        this.voucherGenerationDto.vendorTransactionDto.labourCessPercentage = 0;
        this.voucherGenerationDto.vendorTransactionDto.labourCess = 0;
        this.voucherGenerationDto.vendorTransactionDto.securityDeposit=0;
        this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount=0;
      }
      this.voucherGenerationDto.vendorTransactionDto.miscellaneousAmount=0;
      this.voucherGenerationDto.vendorTransactionDto.materialSupplyAmount=0;
      this.voucherGenerationDto.vendorTransactionDto.cumulativePercentage=0;
      this.voucherGenerationDto.vendorTransactionDto.cumulativeAmount=0;
      if(this.voucherGenerationDto.voucherBillDescDtoList){
        this.voucherGenerationDto.voucherBillDescDtoList[0].lwfPercentage= 0;
        this.voucherGenerationDto.voucherBillDescDtoList[0].paymentType=undefined;
        this.calculateTotalAmount();
        this.calculatetotal(this.voucherGenerationDto.voucherBillDescDtoList[0]);
      }
    }
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    
      this.paymentService.getVendorMappingByWorkId(this.voucherGenerationDto.vendorTransactionDto.workId).subscribe(
        (res: HttpResponse<any>) => {
          this.vendorList = [];
          res.body.forEach(element => {
            this.vendorList.push(element.commercialVendor);
          });
        },
        () => {}
      );
    
  }

loadMoreItems(page: number) {
  const paginationOptions = { size: 10, page };
  return this.commercialVendorService.filter(paginationOptions, this.vendorFilterQuery);
}

  onScrollToEnd(){
    if (!this.loadingMore) {
      this.loadingMore = true;
      this.currentPage++;
  
      this.loadMoreItems(this.currentPage).subscribe(
        (res: HttpResponse<any>) => {
          const additionalItems = res.body;
          this.vendorList = this.vendorList.concat(additionalItems);
          this.loadingMore = false;
        },
        () => {
          this.loadingMore = false;
        }
      );
    }
  }

  popupValue() {
    this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = 0;
    this.voucherGenerationDto.vendorTransactionDto.gstOnTds = 2;
    this.voucherGenerationDto.vendorTransactionDto.labourCess = 0;
    let voucherList = new VoucherBillDescDto();
    if (this.voucherGenerationDto.vendorTransactionDto.id) {
      const id = this.voucherGenerationDto.voucherBillDescDtoList[0].id;
      this.voucherGenerationDto.voucherBillDescDtoList = [];
      voucherList.id = id;
    } else {
      this.voucherGenerationDto.voucherBillDescDtoList = [];
    }
    voucherList.amount = 0;
    if (this.selectedVendor.gstNo === null) {
    voucherList.gstPercentage = 0;
    }else{
    voucherList.gstPercentage = 18;
    }
    this.voucherGenerationDto.voucherBillDescDtoList.push(voucherList);
    this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto =
      new CommercialVendorsDto();
    this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto.id =
      this.selectedVendor.id;
    this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto.gstNo =
      this.selectedVendor.gstNo;

    this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto.panNo =
      this.selectedVendor.panNo;
    if (this.selectedVendor.gstNo === null && !this.voucherGenerationDto.vendorTransactionDto.id) {
      this.enableStateGst = false;
      this.enableIGst = false;
      this.enableGstOnTds = false;
    }
    if (
      this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto.gstNo
    ) {
      this.enableStateGst=true;
      this.voucherGenerationDto.vendorTransactionDto.gstOnTds = 2;
    }
    this.getSercurityDesposit();
    this.calculateTotalAmount();
  }

  calculatetotal(gst: VoucherBillDescDto) {
    if (gst.amount === null) {
      gst.amount = 0;
    }
    this.amount = gst.amount;

    gst.grossAmount =  gst.amount;
    let totalAmount =  gst.amount;

    let totalvalue = Number(((gst.gstPercentage / 100) * totalAmount).toFixed());
    gst.gstAmount = totalvalue;
    // if (this.enableStateGst) {
    //   gst.cgstPercentage = Number((gst.gstPercentage / 2).toFixed(2));
    //   gst.sgstPercentage = Number((gst.gstPercentage / 2).toFixed(2));
    //   gst.cgstAmount = Number((totalvalue / 2).toFixed(2));
    //   gst.sgstAmount = Number((totalvalue / 2).toFixed(2));
    // } 
    // else {
    //   gst.igstPercentage = gst.gstPercentage;
    //   gst.igstAmount = totalvalue;
    // }
    gst.grossAmount = totalAmount + totalvalue;
    if(this.workPayment && this.enableLabourChess){
      gst.lwfPercentage = 1;
      let lwfValue=Number(((gst.lwfPercentage / 100) * totalAmount).toFixed());
      gst.lwfAmount = lwfValue;
      gst.grossAmount = totalAmount + totalvalue +lwfValue;
    }
    else {
      gst.lwfPercentage = 0;
      gst.lwfAmount = 0;
      gst.grossAmount = totalAmount + totalvalue;
    }

    this.calculateTotalAmount();
    this.calculateTds();
  }
  delete(index: number) {
    this.voucherGenerationDto.voucherBillDescDtoList.splice(index, 1);
    if (this.voucherGenerationDto.voucherBillDescDtoList.length === 0) {
      this.voucherGenerationDto.vendorTransactionDto.grossAmount = null;
      this.voucherGenerationDto.vendorTransactionDto.totalCgst = null;
      this.voucherGenerationDto.vendorTransactionDto.totalSgst = null;
      this.voucherGenerationDto.vendorTransactionDto.totalIgst = null;
      this.voucherGenerationDto.vendorTransactionDto.netAmount = null;
      this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = 0;
      this.totalDeduction = null;
      this.voucherGenerationDto.vendorTransactionDto.tdsAmount = 0;
      this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = 0;
      this.voucherGenerationDto.vendorTransactionDto.labourCess = 0;
      this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount = 0;

      this.voucherGenerationDto.vendorTransactionDto.transactionAmount = null;
    } else {
      this.calculateTotalAmount();
      this.calculateTds();
    }
  }

  getSercurityDesposit() {
    let query = new filterQuery();
    query.key = 'commercialVendor';
    query.operation = 'equals';
    query.value = this.selectedVendor.id.toString();
    this.filterProps = this.filterProps ? this.filterProps : [];
    this.filterProps.push(query);
    query = new filterQuery();
    query.key = 'activeFlag';
    query.operation = 'equals';
    query.value = 'YES';
    this.filterProps.push(query);
    let Query = {
      page: 0,
      size: 1,
    };
    this.paymentService.filter({ ...Query }, this.filterProps).subscribe(
      (res: HttpResponse<VendorTransaction[]>) => {
        if (res.body) {
          // this.voucherGenerationDto.vendorTransactionDto.securityDeposit = 2;
        } else {
          // this.voucherGenerationDto.vendorTransactionDto.securityDeposit = 0;
        }
      },
      () => {}
    );
  }

  calculateTds() {
    this.voucherGenerationDto.vendorTransactionDto.tdsAmount = Number(
      (
        (this.voucherGenerationDto.vendorTransactionDto.tdsPercentage / 100) *
        this.voucherGenerationDto.vendorTransactionDto.grossAmount
      ).toFixed()
    );
    this.gstOnTdsAmount = Number(
      (
        (this.voucherGenerationDto.vendorTransactionDto.gstOnTds / 100) *
        this.voucherGenerationDto.vendorTransactionDto.grossAmount
      ).toFixed()
    );
    this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = this.convertToEvenNumber(this.gstOnTdsAmount);
    this.calculateTotalAmount();
  }

  convertToEvenNumber(num: number): number {
    if (num % 2 !== 0) {
        num++;
    }
    return num;
}

  validatePan() {
    this.panError = !this.validationService.isPanValid(
      this.selectedVendor.panNo
    );
  }
  
  addDescritption(list: string) {
    this.description = list;
  }

  genVocher() {
    // if(!this.noPendingVoucher)
    // {
    //   this.notificationService.alertError('There is voucher pending for this work,you cannot proceed further','');
    //   return;
    // }
    this.ngSelectInvalid = false;
    const isFormValid =
      this.levelMasterDto &&
      this.grantlevel &&
      this.componentMaster &&
      this.selectedVendor.name &&
      this.selectedVendor.id &&
      this.selectedVendor.panNo &&
      this.voucherGenerationDto.vendorTransactionDto
        .paymentApprovalNumber &&
      this.voucherGenerationDto.vendorTransactionDto.nameOfWork &&
      this.voucherGenerationDto.vendorTransactionDto.remark &&
      this.isFileUploaded &&
      this.voucherGenerationDto.vendorTransactionDto.transactionAmount;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      this.notificationService.alertError("Please fill all the mandotory fields marked with *",'');
      return;
    }
    if(this.roleCode?.role?.code === 'BMAKER' && !this.selectedZonalUser){
      this.notificationService.alertError('Please Select the Zonal User','')
      return;
    }
    if(this.voucherGenerationDto.vendorTransactionDto.transactionAmount <= 0){
      this.notificationService.alertError('Transaction Amount should be greater than 0','');
      return;
    }

    if(this.roleCode?.role?.code === 'DMAKER'){
      if(this.voucherGenerationDto.vendorTransactionDto.netAmount>this.balancePay){
        this.notificationService.alertError('Limit amount exceeded, Gross Total should be within project unpaid amount: Rs. '+this.balancePay,'')
        return;
      }
      if(this.voucherGenerationDto.vendorTransactionDto.transactionAmount>this.balancePay && this.voucherGenerationDto?.voucherBillDescDtoList[0].paymentType=='Part'){
        this.notificationService.alertError('Limit amount exceeded, Transaction Amount should be within project unpaid amount: Rs. '+this.balancePay,'')
        return;
      }
      if (
        this.voucherGenerationDto.vendorTransactionDto.netAmount >
        this.limitAmount
      ) {
        this.notificationService.alertError(
          'Due to insufficient limit, You are not eligible to generate Voucher',
          ''
        );
        return;
      }
    }
    this.voucherGenerationDto.voucherBillDescDtoList.forEach((element)=>{
      let isVal;
      if(this.workPayment){
       isVal= element.amount && element.paymentType ;
     } else {
       isVal= element.amount;
     }
      if (!isVal) {
        this.ngSelectInvalid = true;     
      }
      });
      if(this.ngSelectInvalid){
        return;
      }
    this.ngSelectInvalid = false;
    if (this.selectedZonalUser?.id) {
      this.voucherGenerationDto.vendorTransactionDto.zonalChecker =
        this.selectedZonalUser?.id;
    }
    this.spinner.show();
    this.voucherGenerationDto.voucherBillDescDtoList[0].partialCount=this.partialCount;
    this.voucherGenerationDto.vendorTransactionDto.paymentMethod="Work";
    
    if (
      this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount ===
        null ||
      this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount === 0
    ) {
      this.voucherGenerationDto.vendorTransactionDto.securityDeposit = 0;
    }
    if (
      this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount === null ||
      this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount === 0
    ) {
      this.voucherGenerationDto.vendorTransactionDto.gstOnTds = 0;
    }
    this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto =
      this.selectedVendor;
    this.voucherGenerationDto.vendorTransactionDto.componentTypeDto =
      this.grantlevel;
      this.voucherGenerationDto.vendorTransactionDto.componentMaster=this.componentMaster;
    this.voucherGenerationDto.vendorTransactionDto.invoiceAmount =
      this.voucherGenerationDto.vendorTransactionDto.netAmount;
    this.voucherGenerationDto.vendorTransactionDto.personalFlag = 'No';
    this.voucherGenerationDto.vendorTransactionDto.levelTypeId =
      this.levelMasterDto.id;

    this.paymentService.genVocher(this.voucherGenerationDto).subscribe(
      (response: any) => {
        this.readonly = true;
        this.spinner.hide();
        this.enableSignButton = true;
        // this.router.navigate(['/payment']);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      (error) => {
        this.spinner.hide();
        if (error.status == 400)
        {
          this.notificationService.alertError('Due to insufficient limit, You are not eligible to generate Voucher','');

        } else {
          this.notificationService.alertError('An unexpected error occurred.','');
        }
      }
    );
  }

  getfile() {
    this.paymentService
      .getfile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          this.enableSignButton = true;
          if(!this.voucherGenerationDto.vendorTransactionDto.id){
            this.router.navigate(['/PaymentVoucher'], {
              queryParams: {
                id: res.body.paramFour
              },
            });
          }
          // add more parameters as needed
        },
        () => {}
      );
  }

  submitForm(){
    this.spinner.show();
    this.paymentService
    .getfile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
    .subscribe(
      (res: HttpResponse<any>) => {
        console.log('ress',res.body);
        this.spinner.hide();
        this.param1 = res.body.paramOne;
        this.param2 = res.body.paramTwo;
        this.param3 = res.body.paramThree;
        if(!this.voucherGenerationDto.vendorTransactionDto.id){
          this.router.navigate(['/PaymentVoucher'], {
            queryParams: {
              id: res.body.paramFour
            },
          });
        }
        this.localStorageService.store('refresh', 'Yes');
        const formElement = document.createElement('form');
        formElement.method = 'POST';
        formElement.action = environment.CDAC_URL;
        let inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.name = "eSignRequest";
        inputElement.value = res.body.paramOne;
        formElement.appendChild(inputElement);
        inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.name = "aspTxnID";
        inputElement.value = res.body.paramTwo;
        formElement.appendChild(inputElement);
        inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.name = "Content-Type";
        inputElement.value = res.body.paramThree;
        formElement.appendChild(inputElement);
        document.body.appendChild(formElement);
        formElement.submit();
      },
      (error) => {
        this.spinner.hide();
        
      }
    );
    
  }

  getPdf() {
    this.paymentService
      .getPdfFile(this.voucherGenerationDto.vendorTransactionDto.voucherNo,false)
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          window.open(url, '_blank');
          link.click();
          URL.revokeObjectURL(url);
          // this.spinner.hide();
          // this.router.navigate(['payment']);
          // this.getfile();
          // this.notificationService.alertSuccess('Saved Successfully', '');
        },
        (err) => {
          // this.spinner.hide();
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  revert() {
    this.spinner.show();
    this.paymentService
      .revertTranaction(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/WorkPayment'], {});
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  rejectDialog(){
    if(!this.rejectionReason){
      this.requiredError=true;
      return;
    }
    else{
      this.reasonDialog=false;
      this.reject();
    }
  }

  reject() {
    this.spinner.show();
    this.paymentService.deletedVocher(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id')),this.rejectionReason)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/WorkPayment'], {});
        },
        () => {
          this.spinner.hide();
          this.notificationService.alertError('Unexpected error occured!!','');
        }
      );
  }

  submit(){
  }

  calculateTotalAmount() { 
    if (this.voucherGenerationDto?.voucherBillDescDtoList.length !== 0) {
      if (!this.voucherGenerationDto.vendorTransactionDto.tdsAmount) {
        this.voucherGenerationDto.vendorTransactionDto.tdsAmount = 0;
      }
      if (!this.voucherGenerationDto.vendorTransactionDto.miscellaneousAmount) {
        this.voucherGenerationDto.vendorTransactionDto.miscellaneousAmount = 0;
      }
      if (!this.voucherGenerationDto.vendorTransactionDto.materialSupplyAmount) {
        this.voucherGenerationDto.vendorTransactionDto.materialSupplyAmount = 0;
      }
      if (!this.voucherGenerationDto.vendorTransactionDto.miscellaneousAdditionAmount) {
        this.voucherGenerationDto.vendorTransactionDto.miscellaneousAdditionAmount = 0;
      }
      if (!this.totWithheldAmount) {
        this.totWithheldAmount = 0;
      }

      let initaiTotal = 0;
      // let intitalCgst = 0;
      // let intitalSgst = 0;
      let intitalTotalWithTax = 0;
      // let initialIgst = 0;
      this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount = 0;
      this.voucherGenerationDto.vendorTransactionDto.labourCess = 0;
      this.voucherGenerationDto?.voucherBillDescDtoList.forEach((element) => {
        initaiTotal += element.amount;
        // if (element.sgstPercentage) {
        //   intitalCgst += element.cgstAmount;
        //   intitalSgst += element.sgstAmount;
        // }
        // if (element.igstPercentage) {
        //   initialIgst += element.igstAmount;
        // }
        intitalTotalWithTax += element.grossAmount;
      });
      this.voucherGenerationDto.vendorTransactionDto.grossAmount = initaiTotal;
      // this.voucherGenerationDto.vendorTransactionDto.totalCgst = intitalCgst;
      // this.voucherGenerationDto.vendorTransactionDto.totalSgst = intitalSgst;
      // this.voucherGenerationDto.vendorTransactionDto.totalIgst = initialIgst;
      this.voucherGenerationDto.vendorTransactionDto.netAmount =
        intitalTotalWithTax;
      this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = 0;
      if (
        this.voucherGenerationDto.vendorTransactionDto.grossAmount &&
        this.enableGstOnTds
      ) {
        this.gstOnTdsAmount = Number(
          (
            (this.voucherGenerationDto.vendorTransactionDto.gstOnTds / 100) *
            this.voucherGenerationDto.vendorTransactionDto.grossAmount
          ).toFixed()
        );
        this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = this.convertToEvenNumber(this.gstOnTdsAmount);
      }
      if(this.voucherGenerationDto?.voucherBillDescDtoList[0].paymentType=='Part'){
      this.voucherGenerationDto.vendorTransactionDto.securityDeposit=5;
      }else if(this.voucherGenerationDto?.voucherBillDescDtoList[0].paymentType=='Final'){
      this.voucherGenerationDto.vendorTransactionDto.securityDeposit=2.5;
      }

      if (this.enableSecurityDeposit && this.voucherGenerationDto.voucherBillDescDtoList
        && this.voucherGenerationDto.voucherBillDescDtoList.length > 0
        && this.voucherGenerationDto.voucherBillDescDtoList[0].amount 
        ) {
        this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount =
          Number(
            (
              (this.voucherGenerationDto.vendorTransactionDto.securityDeposit /
                100) *
              (this.voucherGenerationDto.voucherBillDescDtoList[0].amount +
                this.voucherGenerationDto.voucherBillDescDtoList[0].gstAmount)
            ).toFixed()
          );
      }
      this.totWithheldAmount=0;
      this.totWithheldAmount=this.totalWithheldAmount;
      this.totBillValue=0;
      this.totBillValue=this.totalBillValue+this.voucherGenerationDto.voucherBillDescDtoList[0].amount+this.voucherGenerationDto.voucherBillDescDtoList[0].gstAmount;
      if(this.voucherGenerationDto.voucherBillDescDtoList[0].paymentType=='Part'){
        this.voucherGenerationDto.vendorTransactionDto.cumulativePercentage=0;
        this.voucherGenerationDto.vendorTransactionDto.cumulativeAmount=0;
      } else if(this.voucherGenerationDto.voucherBillDescDtoList[0].paymentType=='Final'){
      this.voucherGenerationDto.vendorTransactionDto.cumulativePercentage=2.5;
      this.voucherGenerationDto.vendorTransactionDto.cumulativeAmount=Number((this.totBillValue*(2.5/100)).toFixed());
      }
      if(this.workPayment && this.enableLabourChess){
        this.voucherGenerationDto.vendorTransactionDto.labourCessPercentage = 1;
      }

      if (this.enableLabourChess && this.voucherGenerationDto.vendorTransactionDto.grossAmount) {
        this.voucherGenerationDto.vendorTransactionDto.labourCess = Number(
          (
            (this.voucherGenerationDto.vendorTransactionDto
              .labourCessPercentage /
              100) *
            this.voucherGenerationDto.vendorTransactionDto.grossAmount
          ).toFixed()
        );
      }
      
      if(this.workPayment && this.voucherGenerationDto.voucherBillDescDtoList[0].paymentType=='Final'){
        this.totalDeduction =
        this.voucherGenerationDto.vendorTransactionDto?.tdsAmount +
        this.voucherGenerationDto.vendorTransactionDto.labourCess +
        this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount+
        this.voucherGenerationDto.vendorTransactionDto.miscellaneousAmount+
        this.voucherGenerationDto.vendorTransactionDto.materialSupplyAmount+
        this.voucherGenerationDto.vendorTransactionDto.cumulativeAmount;
      }
      else{
        this.totalDeduction =
        this.voucherGenerationDto.vendorTransactionDto?.tdsAmount +
        this.voucherGenerationDto.vendorTransactionDto.labourCess +
        this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount +
        this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount+
        this.voucherGenerationDto.vendorTransactionDto.miscellaneousAmount+
        this.voucherGenerationDto.vendorTransactionDto.materialSupplyAmount+
        this.voucherGenerationDto.vendorTransactionDto.cumulativeAmount;
      }
      this.voucherGenerationDto.vendorTransactionDto.transactionAmount =
        this.voucherGenerationDto.vendorTransactionDto.netAmount - this.totalDeduction 
        + this.voucherGenerationDto.vendorTransactionDto.miscellaneousAdditionAmount
        + this.totWithheldAmount;
    }
  }

  viewClick(){
    this.getvendorTrans();
  }
  
  getvendorTrans() {
    this.paymentService.getTransactionByProject(this.voucherGenerationDto.vendorTransactionDto.workId)
    .subscribe(
      (res) => {
        this.noRecord = true;
        this.searchResult.items = res ?? [];
        if (this.searchResult.items.length > 0) {
          this.noRecord = false;
        }
        this.searchResult = { ...this.searchResult };
        this.profileDialog = true;
      },
      () => {
      }
    );
  }

  getWorkPaymentPartCount(resolve, reject) {
    this.paymentService.getWorkPaymentPartCount(this.voucherGenerationDto.vendorTransactionDto.workId)
        .subscribe(
            (res: any) => {
              this.workPaymentList=res;
                // Resolve the Promise to indicate completion of the API call
                resolve();
            },
            (error) => {
                // Reject the Promise if there's an error
                reject(error);
            }
        );
}
  paymentStatus() {
    this.paymentService
      .getPaymentStatus(this.voucherGenerationDto.vendorTransactionDto.id)
      .subscribe(
        (res: any) => {
          if (res.body.transactionStatus === 'O.K.') {
            this.disablepaymentButton = true;
          } 
          else if (res.body.transactionStatus !== null) {
            this.buttonText = 'Re-Initiate payment';
            this.checkstatusButton = true;
            this.interactionId = res.body.apiInteractionId;
          }
        },
        (err) => {}
      );
  }

  checkPaymentStatus() {
    this.spinner.show();
    this.paymentService.paymentStatus(this.interactionId).subscribe(
      (res: any) => {
        if (res.body.transactionStatus.TRANSTATUS === "Failure") {
          this.notificationService.alertError("Transaction Failed for Reference No:"+res.body.transactionStatus.JRNL_NO,'')
          this.buttonText = 'Re-Initiate payment';
          this.checkstatusButton = false; 
          this.disablepaymentButton = false; 
          this.spinner.hide();
          }
          else if (res.body.transactionStatus.TRANSTATUS === "Success"){
            this.checkstatusButton = false;
            this.paymentSucessDialog=true;
            this.paymentSucessText="Transaction Success for Reference No:",res.body.transactionStatus.JRNL_NO;
            this.spinner.hide();
          }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  redirect() {
    this.router.navigate(['/PaymentVoucher'], {
      queryParams: {
        id:String(this.voucherGenerationDto.vendorTransactionDto.id),
      },
    });
  }

  

  PaymentSucess() {
    this.spinner.show();
    this.paymentService.paymentSuccess(this.voucherGenerationDto).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.paymentSucessDialog = true;
        this.paymentSucessText = res.body;
        this.disablepaymentButton = true;
      },
      (err) => {
        if (String(err.status).startsWith('5')) {
          this.checkstatusButton = true;
        }
        this.spinner.hide();
      }
    );
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.userContextSubscription) {
      this.userContextSubscription.unsubscribe();
    }
  }
  BackButtonClick() {
    this.router.navigate(['/WorkPayment']); 
  }
}
