import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { SchemeList } from './scheme-list';
import { LocalStorageService } from 'ngx-webstorage';
export type EntityArrayResponseType = HttpResponse<SchemeList[]>;
export type EntityResponseType = HttpResponse<SchemeList>;

@Injectable()
export class SchemeListService {
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/schemeList');
    protected resourceVaildFliterUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getSchemeValidByFilter');
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/saveScheme');
    constructor(protected http: HttpClient,private localStorageService:LocalStorageService) {}
    filter(): Observable<EntityArrayResponseType> {
        return this.http
        .get<SchemeList[]>(this.resourceFliterUrl,{ observe: 'response' })
    }
    save(schemeList?:SchemeList): Observable<EntityResponseType> {
        return this.http
        .post<SchemeList>(this.resourceSaveUrl,schemeList, {observe: 'response' })
    }
    validFilter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
        if(filter === null){
          filter = []
        }
        const options = createRequestOption(req);
          return this.http
          .post<SchemeList[]>(this.resourceVaildFliterUrl,filter, { params:options, observe: 'response' })
      }
}