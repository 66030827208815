import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import {
  commercial,
  CommercialVendors,
  CommercialVendorsDto,
} from './commercial-vendors';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class CommercialVendorService {
  protected resourceFliterUrl =
    environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getCommercialVendorByFilter';
  protected resourceSaveUrl =
    environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/saveCommercialVendor';
  protected resourceDeleteUrl =
    environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/deleteCommercialVendor';
  protected CommercialListUrl =
    environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/commercialList';
  protected commercialGetUrl =
    environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getCommercialVendor';
  protected resourceValidFliterUrl =
    environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getCommercialVendorValidByFilter';
  protected getBankUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getBankList';
  protected getBankBranchUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getBankBranchByFilter';
  protected getIfscByBankBranchUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getIfscByBankBranch';
  protected accountValidationUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/accountValidation';
  protected accountValidationsUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/accountValidations';
  protected approveVendorUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/approveVendor';
  protected rejectVendorUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/rejectVendor';
  protected bankBranchDetailsUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/bankBranchDetails';

  constructor(protected http: HttpClient,private localStorageService: LocalStorageService,) {}
  filter(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<any[]>(this.resourceFliterUrl, filter, {
      params: options,
      observe: 'response',
    });
  }
  validFilter(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<any[]>(this.resourceValidFliterUrl, filter, {
      params: options,
      observe: 'response',
    });
  }
  save(profile?: CommercialVendorsDto): Observable<any> {
    return this.http.post<CommercialVendorsDto>(this.resourceSaveUrl, profile, {
      observe: 'response',
    });
  }
  getcommercialVendor(id: number): Observable<HttpResponse<any>> {
    return this.http.get<CommercialVendorsDto>(
      `${this.commercialGetUrl}?id=${id}`,
      { observe: 'response' }
    );
  }
  getCommerical(): Observable<any> {
    return this.http.get<commercial[]>(this.CommercialListUrl, {
      observe: 'response',
    });
  }

  getBankList(): Observable<any> {
    return this.http.get<any[]>(this.getBankUrl, {
      observe: 'response',
    });
  }


  getBankBranchList(filter,req): Observable<any> {
      if (filter === null) {
        filter = [];
      }
      const options = createRequestOption(req);
      return this.http.post<any[]>(this.getBankBranchUrl, filter, {
        params: options,
        observe: 'response',
      });
    
  }

  getBankIfscCode(bank:string,branch?:string): Observable<any> {
    return this.http.get<any[]>(`${this.getIfscByBankBranchUrl}?bank=${bank}&branch=${branch}&page=0&size=10`, {
      observe: 'response',
    });
  }
  getaccountValidation(account:string,ifscCode?:string): Observable<any> {
    return this.http.get(`${this.accountValidationUrl}?accountNo=${account}&ifscode=${ifscCode}`,{ observe: 'response', responseType: 'text' });
  }
  getaccountValidations(payload): Observable<any> {
    return this.http.post(`${this.accountValidationsUrl}`,payload,{ observe: 'response', responseType: 'text' });
  }
  delete(id: number): Observable<any> {
    return this.http.get(`${this.resourceDeleteUrl}?id=${id}`, {
      observe: 'response',
    });
  }

  approveVendor(id:number): Observable<any> {
    return this.http.post(`${this.approveVendorUrl}?id=${id}`,null,{responseType: 'text'});
  }
  rejectVendor(id:number): Observable<any> {
    return this.http.post(`${this.rejectVendorUrl}?id=${id}`,null,{responseType: 'text'});
  }
  bankBranchDetails(ifscCode?:string): Observable<any> {
    return this.http.get(`${this.bankBranchDetailsUrl}?ifscCode=${ifscCode}`,{ observe: 'response' });
  }
}
