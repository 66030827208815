import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { AgencyDto } from './../agency/agency';
import { MasterComponent } from './../major-component/major-component';
import { FinYear, FormulaTabelModel, limitTransDto, limitTransList } from './limit-translist.module';
import { LocalStorageService } from 'ngx-webstorage';


export type EntityArrayResponseType = HttpResponse<limitTransList[]>;

@Injectable({ providedIn: 'root' })
export class LimitTransListService {
    
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getIAComponentLimitTransByFilter');
    protected resourceValidFliterUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getIAComponentLimitTransValidByFilter');
    protected getLevelMaster= environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getLevelMasterByLevelTypeId');
    protected getComponentbyLevelById = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getComponentTypeByLevelId')
    protected getComponentbyLevelByIdGf = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getComponentTypeByLevelIdGf')
    protected getImplementingAgencyByLevelMasterId = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getImplementingAgencyByLevelMasterId')
    protected getfinYearList = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/finYearList')
    protected calcFormula = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/formulaCalculation')
    protected formulaTableFilter = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getIAComponentLimitFormulaTempGFByFilter')
    protected getStatusUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/statusCheck')
    protected schedulerMethod = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/schedulerMethod')
    protected savelimittransUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/SaveIALimit')
    protected getAmountAndLBCUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getAmountByLevelMasterAndScheme')
    protected getIAComponentLimitTransUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/getIAComponentLimitTrans')
    protected reportAsyncDownloadUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/reportAsyncDownload');
    protected reportAsyncStatusCheckUrl = environment.SERVER_API_URL+('api/'+ this.localStorageService.retrieve('scheme') + '/reportAsyncStatusCheck');

    
    
    // protected resourceSaveUrl = environment.SERVER_API_URL+('api/saveProfile');
    // protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deleteProfile');
    constructor(protected http: HttpClient,private localStorageService: LocalStorageService,) {}
    filter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<limitTransList[]>(this.resourceFliterUrl,filter, { params:options, observe: 'response' })
    }
    vaildFilter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<limitTransList[]>(this.resourceValidFliterUrl,filter, { params:options, observe: 'response' })
    }
    getLevelMasterById(id:any): Observable<EntityArrayResponseType> {
      return this.http
      .get<any>(this.getLevelMaster,{params:{id:id},observe: 'response' })
    }

    getComponentbyLevel(id:any): Observable<any> {
      return this.http
      .get<MasterComponent[]>(this.getComponentbyLevelById,{params:{id:id},observe: 'response' })
    }

    getComponentbyLevelGf(id:any): Observable<any> {
      return this.http
      .get<MasterComponent[]>(this.getComponentbyLevelByIdGf,{params:{id:id},observe: 'response' })
    }

    getImplementingAgencyByLevelMaster(id:any): Observable<any> {
      return this.http
      .get<AgencyDto[]>(this.getImplementingAgencyByLevelMasterId,{params:{id:id},observe: 'response' })
    }

    getFinYear(): Observable<any> {
      return this.http
      .get<FinYear[]>(this.getfinYearList,{observe: 'response' })
    }

    calcAmountByFormula(limitTransListDto): Observable<any> {
      return this.http.post<any>(this.calcFormula, limitTransListDto,{ observe: 'response' });
    }

    tableFormulaFilter(req?: any,filter?:any): Observable<any> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<FormulaTabelModel[]>(this.formulaTableFilter,filter, { params:options, observe: 'response' })
    }

    getStatus(levelTypeDto:any): any {
      return this.http
      .post<any>(this.getStatusUrl,levelTypeDto,{})
    }

    schedule(levelTypeDto:any): any {
      return this.http
      .post<any>(this.schedulerMethod,levelTypeDto,{})
    }

    save(savelimittrans?:limitTransDto): Observable<any> {
      return this.http
      .post<limitTransDto[]>(this.savelimittransUrl,savelimittrans, {observe: 'response' })
    }

    getAmountAndLBC(req?: any): Observable<any> {
      return this.http
      .post<any[]>(this.getAmountAndLBCUrl,req,{ observe: 'response' });
    }

    getIAComponentLimitTransById(id:number): Observable<any> {
      return this.http
      .get<limitTransDto[]>(`${this.getIAComponentLimitTransUrl}?id=${id}`,{observe: 'response' })
    }
    getExcelDownload(data){
      return this.http.get(`${this.reportAsyncDownloadUrl}?reportName=${data}`,{observe:'response',responseType: 'text'})
    }
    reportAsyncStatusCheck(req:any){
      const options = createRequestOption(req);
      return this.http.get(this.reportAsyncStatusCheckUrl,{
        params: options,
        observe: 'response',
      });
    }
  
}