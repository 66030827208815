import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { LimitTransListService } from '../limit-translist/limit-translist.service';
import { TenderPageService } from '../vendor-mapping-page/tendor-page.service';
import { TenderMapping } from '../vendor-mapping-page/vendor-mapping-model';

@Component({
  selector: 'app-vendor-mapping-table',
  templateUrl: './vendor-mapping-table.component.html',
  styleUrls: ['./vendor-mapping-table.component.scss'],
})
export class VendorMappingTableComponent implements OnInit {
  userContextSubscription: Subscription;
  itemsPerPage: number = 10;
  user: any;
  page: number;
  searchResult: SearchResult<TenderMapping> = new SearchResult<TenderMapping>();
  cols:any;
  allowEdit: boolean;
  enableApprove: boolean;
  isChecker: boolean;
  workId: any;
  levelMasterId: number;

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    protected paymentService: PaymentService,
    private spinner: NgxSpinnerService,
    public fontService: FontService,
    private loginService: SigninService,
    private tenderPageService: TenderPageService,
    private userRoleService: UserRoleService,
    protected notificationService: NotificationService,
    private limitTransListService: LimitTransListService
    
  ) {}

  ngOnInit(): void {  
    this.cols = [
      {
        field: 'levelMaster',
        header: 'District',
        jhiTranslate: 'tableHead.district.name',
        isSortable: false,
        isFilterable: false,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'workId',
        header: 'Work Id',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'work',
        header: 'Administrative Sanction Amount',
        width: 13,
        isSelectcolumn: true,
        type: "dropDown_with_other_field",
        Dfield: "administrativeSanctionAmount",
        isFilterable: false,
      },
      {
        field: 'work',
        header: 'Construction Cost',
        width: 13,
        isSelectcolumn: true,
        type: "dropDown_with_other_field",
        Dfield: "constructionCost",
        isFilterable: false,
      },
      {
        field: 'work',
        header: 'Name Of Work',
        isSortable: true,
        isFilterable: false,
        width: 10,
        type: "dropDown_with_other_field",
        Dfield: "projectName",
        isSelectcolumn: true,
      },
      {
        field: 'tenderNo',
        header: 'Tender No',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
      },
      {
        field: 'tenderDate',
        header: 'Tender Date',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
        type:'date'
      }, 
      {
        field: 'workProcurementDate',
        header: 'Work Order Date',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
        type:'date'
      },
     
    ];
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      ...(this.user?.role?.code !== userRoles.roleState && {levelMasterId: this.user?.levelMaster?.id}),
     
    };
    if(this.workId) {
      Query['workId'] = this.workId;
    }
    
    this.tenderPageService.filter({ ...Query }).subscribe(
      (res: HttpResponse<TenderMapping[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        this.spinner.hide();
      },
      () => {
        this.onError();
        this.spinner.hide();
      }
    );
  }

  protected onSuccess(data:TenderMapping[]  | null, headers: HttpHeaders, page: number, navigate: boolean): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
   
  }

  protected onError(): void {}

  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    console.log('event',event.filterDefinitions[0]?.value);
    if(event.filterDefinitions[0]?.value) {
      this.workId = event.filterDefinitions[0]?.value;
    }
    else {
      this.workId = null;
    }
    this.userContextSubscription = this.userRoleService.getuserContext().subscribe(
      res => {
        this.user = res;
        this.levelMasterId = res.levelMaster.id;
        if (
          res.role.code == userRoles.roleStateMaker ||
          res.role.code == userRoles.roleMaker
        ) {
          this.allowEdit = true;
        } else if (
          res.role.code == userRoles.roleChecker ||
          res.role.code == userRoles.roleStateChecker
        ) {
          this.enableApprove = true;
        }
        if (
          res.role.code == userRoles.roleChecker ||
          res.role.code == userRoles.roleApprover ||
          res.role.code == userRoles.roleStateChecker ||
          res.role.code == userRoles.roleStateApprover
        ) {
          this.isChecker = true;
        }
        this.loadPage(event.page);
      },
      () => {}
    );
  }

  onSelectRow($event) {
    if ($event.type === 'select') {
      this.router.navigate(['/tender-page'], {
        queryParams: {
          id: $event.value.id,
        },
      });
    }
    else if ($event.type === "view") {
      this.router.navigate(["/tender-page"], {
        queryParams: {
          id: $event.value.id,
          view: true,
        },
      });
    }

    else if ($event.type === 'approve') {
      this.router.navigate(["/tender-page"], {
        queryParams: {
          id: $event.value.id,
          view: true,
          approve:true
        },
      });
    }
  }

  openNew() {
    this.router.navigate(['/tender-page']);
  }
  downloadData() {
    let data="Vendor Mapping Report";
    
    this.spinner.show();
    this.limitTransListService.getExcelDownload(data).subscribe((res: HttpResponse<any>) => 
      {
       console.log(res);
       this.notificationService.alertSuccess(res.body, '');
       this.spinner.hide();
     },
      (onError) => {
        this.notificationService.alertError(onError.error.errorMessage, '');
        this.spinner.hide();
      }
    );
  }

  checkStatus() {
    let filter = {
      reportName:'Vendor Mapping Report',
    };
    this.spinner.show();
    this.limitTransListService.reportAsyncStatusCheck({ ...filter }).subscribe(
      (res) => {
        console.log('res', res);
        let b: any = res.body;
        this.saveBlobToExcel(b.data,'Vendor Mapping Report');
        this.spinner.hide();
      },
      (onError) => {
        this.notificationService.alertError(onError.error.errorMessage, '');
        this.spinner.hide();
      }
    );
  }

  saveBlobToExcel(data, filename) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }
}
