import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'ngx-webstorage';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/alert/notification.service';
import { LevelMasterDto } from '../module/level-master/level-master';
import { LevelMasterService } from '../module/level-master/level-master.service';
import { FinYear } from '../module/limit-translist/limit-translist.module';
import { LimitTransListService } from '../module/limit-translist/limit-translist.service';
import { CustomFilterTableComponent } from '../shared/custom-filter-table/custom-filter-table.component';
import { SearchResult } from '../shared/models/search-result';
import { ReportService } from './dynamic-report-service';

@Component({
  selector: 'app-dynamic-report-table',
  templateUrl: './dynamic-report-table.component.html',
  styleUrls: ['./dynamic-report-table.component.scss'],
})
export class DynamicReportTableComponent implements OnInit {
  private routerEventsSubscription: Subscription;
  @ViewChild(CustomFilterTableComponent) customFilterTableComponent: CustomFilterTableComponent;
  searchResult: SearchResult<any> = new SearchResult<any>();
  reportData: any;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  currentElement: any;
  cols: any;
  districtLevelList: LevelMasterDto[];
  district: any;
  BlockLevelDto: null;
  blockLevelList: LevelMasterDto[];
  finYear: FinYear[];
  finYearDto: any;
  toDate: Date;
  fromDate: Date;
  reportId: number;

  statusList = [
    {
      name: 'Approved',
      value: 'Approved'
    },
    {
      name: 'Reject',
      value: 'Reject'
    },
    {
      name: 'Pending',
      value: 'Pending'
    }
  ];
  selectedStatus: any;
  isReload: boolean;

  constructor(
    protected activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService,
    private reportService: ReportService,
    private levelMasterService: LevelMasterService,
    private limitTransListService: LimitTransListService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService
  ) {
    this.routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url.startsWith('/dynamicReport')) {
        this.reloadComp();
        this.isReload = true;
      }
    });
  }

  ngOnInit(): void {
  }

  reloadComp() {
    if (this.isReload) {
      this.customFilterTableComponent.resetTablePagination();
    }
    this.httpClient
      .get('/assets/json/report.json')
      .subscribe((reportData: any) => {
        reportData.forEach((element) => {
          if (element.menu_id === Number(this.route.snapshot.queryParamMap.get('id'))) {
            this.currentElement = element;
            this.cols = element.tableHead;
            if (element.enable_District) {
              // this.levelMasterService.getLevelMaster(2).subscribe(
              //   (res: HttpResponse<any>) => {
              //     this.district = res.body;
              //   },
              //   () => {}
              // );
              this.onStateLevelChange();
            }

            if (element.enable_finyear) {
              this.limitTransListService.getFinYear().subscribe(
                (res: HttpResponse<FinYear[]>) => {
                  this.finYear = res.body;
                },
                () => { }
              );
            }
            this.loadPage(1);
          }
        });
      });
  }

  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => { }
        );
    }
  }

  onDistrictLevelChange($event?) {
    this.BlockLevelDto = null;
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.district.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.district.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let filterQuery = this.currentElement?.queryparms;
    filterQuery['page'] = pageToLoad - 1;
    filterQuery['size'] = this.itemsPerPage;
    if (this.district) {
      filterQuery[this.currentElement?.district_field] = this.district.id
    }
    if (this.finYearDto) {
      filterQuery[this.currentElement?.finyear_fields] = this.finYearDto.id
    }
    if (this.currentElement.enable_date && !this.fromDate) {
      filterQuery['fromDate'] = this.datePipe.transform(new Date("04-01-2023"), 'yyyy-MM-dd') + ' 00:00:00.000';
      filterQuery['toDate'] = this.datePipe.transform(new Date(), 'yyyy-MM-dd') + ' 23:59:59.999';
    }
    if (this.currentElement.enable_date && this.fromDate && this.toDate) {
      filterQuery = this.currentElement?.queryparms;
      filterQuery['fromDate'] = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd') + ' 00:00:00.000';
      filterQuery['toDate'] = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd') + ' 23:59:59.999';
    }
    if (this.selectedStatus) {
      filterQuery[this.currentElement?.status_field] = this.selectedStatus.value;
    }
    this.filterProps = this.currentElement?.payload;


    this.reportService
      .getData(
        this.currentElement.api_name,
        { ...filterQuery },
        this.filterProps
      )
      .subscribe(
        (res: HttpResponse<any[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
  }

  protected onSuccess(
    data: any[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {
    this.spinner.hide();
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  ngOnDestroy() {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }


  downloadData() {
    let data;
    const reportId = Number(this.route.snapshot.queryParamMap.get('id'));
    if (reportId == 18) {
      data = "Work List Report";
    }
    else if (reportId == 17) {
      data = "Vendor List Report";
    }
    else if (reportId == 19) {
      data = "Fund List Report";
    }
    else if (reportId == 21) {
      data = "Statutory Success Report";
    }
    else if (reportId == 22) {
      data = "Statutory Failed Report";
    }
    else if (reportId == 10) {
      data = "Vendor Success Report";
    }
    else if (reportId == 12) {
      data = "Vendor Failed Report";
    }
    else if (reportId == 24) {
      data = "Miscellaneous Success Report";
    }
    else if (reportId == 25) {
      data = "Miscellaneous Failed Report";
    }

    this.reportService.getExcelDownload(data).subscribe((res: HttpResponse<any>) => 
       {
        console.log(res);
        this.notificationService.alertSuccess(res.body, '');
        this.spinner.hide();
      },
    ),
    (onError) => {
      this.notificationService.alertError(onError.error.errorMessage, '');
      this.spinner.hide();
    }
  }

  checkStatus() {

    let filter: any = {};
    let reportName;
    const reportId = Number(this.route.snapshot.queryParamMap.get('id'));
    if (reportId == 18) {
      reportName = 'Work List Report';
    } else if (reportId == 17) {
      reportName = 'Vendor List Report';
    } else if (reportId == 19) {
      reportName = 'Fund List Report';
    } else if (reportId == 21) {
      reportName = 'Statutory Success Report';
    } else if (reportId == 22) {
      reportName = 'Statutory Failed Report';
    } else if (reportId == 10) {
      reportName = 'Vendor Success Report';
    } else if (reportId == 12) {
      reportName = 'Vendor Failed Report';
    } else if (reportId == 24) {
      reportName = 'Miscellaneous Success Report';
    } else if (reportId == 25) {
      reportName = 'Miscellaneous Failed Report';
    }

    this.spinner.show();
    this.reportService.reportAsyncStatusCheck(reportName).subscribe(
      (res) => {
        console.log('res', res);
        let b: any = res.body;
        this.saveBlobToExcel(b.data,reportName);
        this.spinner.hide();
      },
      (onError) => {
        console.log(onError);
        this.notificationService.alertError(onError.error.errorMessage, '');
        this.spinner.hide();
      }
    );
  }

  saveBlobToExcel(data, filename) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }
}