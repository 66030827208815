<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Vendor Mapping Table</h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <div class="p-toolbar-group-end">
                        <div class="row download_field">
                            <div class="col-sm">
                                <button pButton pRipple label="Download" (click)="downloadData()"
                                    class="p-button-success download-btn" styleClass="button"><br />
                                    <span class="button-label"></span>
                                </button>
                            </div>
                            <div class="col-sm">
                                <button pButton pRipple label="Check Download Status" (click)="checkStatus()"
                                    class="p-button-success download-status-btn" styleClass="button"><br />
                                    <span class="button-label"></span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <button *ngIf="allowEdit" pButton pRipple icon="pi pi-plus"
                        class="p-button-secondary custom-button  new-button" (click)="openNew()" styleClass="button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.newbutton"></span>
                    </button>

                </ng-template>

            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult"
                (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="!allowEdit"
                [enableViewIcon]="true" [enableApproveButton]="enableApprove" [levelMasterId]="levelMasterId"
                (emitRow)="onSelectRow($event)"></custom-filter-table>
        </div>
    </div>

</div>