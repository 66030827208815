<div class="container-fluid" style="background-color: #162a72">
    <div class="row header">
        <div class="col-lg-4 col-md-4 col-sm-6 d-flex">
            <img src="./assets/img/ib-rdp-tng-logo.svg" alt="Tamil Nadu Government" />
            <!-- <h1 [class.tamil-header-font]="selectedLanguage === 'nav.ta'" jhiTranslate="nav.header">Department of Rural Development
            and
            Panchayat Raj</h1> -->
            <h1 class="head" [ngClass]="{
            'tamil-header-font': selectedLanguage === 'nav.ta',
            'english-header-font': selectedLanguage === 'nav.en'
          }">
                Department Of Rural Development and <br /> Panchayat Raj
            </h1>
        </div>
        <div class="col-lg-4 col-md-5 col-sm-12">
            <div class="navbar-header">
                <div class="d-flex align-items-center" style="gap: 10px">
                    <div class="header-title d-flex align-items-center">
                        <h4>
                            Mudalvarin Grama Salaigal <br /> Membattu Thittam (MGSMT)
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-3 col-sm-4 col-4 top-buttons">
            <div class="full-screen">
                <button type="button" (click)="toggleFullScreen()" class="full-screen-button">
                    <i class="bi bi-fullscreen" *ngIf="!isFullScreen"></i>
                    <i class="bi bi-fullscreen-exit" *ngIf="isFullScreen"></i>
                </button>
            </div>
            <div class="dropdown language pr-2">
                <button class="btn dropdown-toggle big-screen" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ "nav." + selectedLanguage | translate }}
                </button>
                <button class="btn bi bi-translate small-scrren" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                <ul class="dropdown-menu">
                    <li>
                        <a class="dropdown-item" (click)="setLanguage('en')" jhiTranslate="nav.en">English</a>
                    </li>
                    <li>
                        <a class="dropdown-item" (click)="setLanguage('ta')" jhiTranslate="nav.ta">Tamil</a>
                    </li>
                </ul>
            </div>
            <div class="dropdown user">
                <!-- <div class="circle">
              <i class="pi pi-user p-text-light p-text-bold" style="font-size: 16px;"></i>
            </div> -->
                <p style="margin-top: 30px" class="uname">
                    {{ userName }}
                </p>
                <!-- <ul class="dropdown-menu">
              <li>
                <a routerLink="" class="dropdown-item" routerLink="/">
                  <i class="pi pi-user"></i>
                  {{'nav.myAccount' | translate}}</a>
              </li>
              <li>
                <a routerLink="" class="dropdown-item" routerLink="/">
                  <i class="pi pi-cog"></i>
                  {{'nav.settings' | translate}}</a>
              </li>
            </ul> -->
            </div>
            <div class="logout">
                <!-- <button  label="Log Out" pButton icon="pi pi-sign-out" (click)="logout()"></button> -->
                <button id="logout" pButton type="button" (click)="logout()">
                    <i class="pi pi-sign-out"></i>
                    <span [ngClass]="{
                'tamil-kavi': selectedLanguage === 'nav.ta',
                'english-reqular': selectedLanguage === 'nav.en'
              }" class="label">{{ "logout.name" | translate }}</span>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="!disableMenu" class="row nav-row">
        <nav class="navbar navbar-expand-lg navbar-dark bg-custom">
            <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon">
                        <svg width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M1.5 4.5A.5.5 0 0 1 2 4h12a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-1zm0 4A.5.5 0 0 1 2 8h12a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-1zm0 4a.5.5 0 0 1 .5-.5H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-1z" />
                        </svg>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">

                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" jhiTranslate="nav.dashboard" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                               Dashboard
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li *ngIf="roleCode!=='SMAKER' && roleCode!=='SC1' && roleCode!=='SC2' && roleCode!=='SM'">
                                    <a routerLink="dashBoard-Report" class="dropdown-item">DashBoard Report</a>
                                </li>
                                <li *ngIf="roleCode==='SA'">
                                    <a routerLink="state-dashboard" class="dropdown-item" jhiTranslate="nav.dashboard"></a>
                                </li>
                                <li *ngIf="roleCode!=='SA'">
                                    <a routerLink="district-dashboard" class="dropdown-item" jhiTranslate="nav.dashboard"></a>
                                </li>

                            </ul>
                        </li>
                        <li class="nav-item">
                            <a routerLink="profile" class="nav-link" routerLink="profile" [hidden]="hideMenu('PROFILE')" jhiTranslate="nav.profile">Profile</a>
                        </li>
                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" jhiTranslate="nav.user.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                User
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="user" class="dropdown-item" jhiTranslate="nav.user.list"></a>
                                </li>
                                <li *ngIf="roleCode!=='SM' && roleCode!=='DM'">
                                    <a routerLink="profile" class="dropdown-item" jhiTranslate="nav.user.profile"></a>
                                </li>
                            </ul>
                        </li>

                        <li [routerLinkActive]="['active']" class="nav-item">
                            <a routerLink="commercial-vendors" class="nav-link" [hidden]="hideMenu('VENDORREGISTRATION')" jhiTranslate="nav.vendor.name"></a>
                        </li>
                        <!-- <li [routerLinkActive]="['active']" [hidden]="hideMenu('VENDORREGISTRATION')" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" jhiTranslate="nav.vendor.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Vendor Registration
              </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="personal-vendors" class="dropdown-item" jhiTranslate="nav.vendor.personal"></a>
                                </li>
                                <li>
                                    <a routerLink="commercial-vendors" class="dropdown-item" jhiTranslate="nav.vendor.commercial"></a>
                                </li>
                                <li><a routerLink="employee"  class="dropdown-item" jhiTranslate="nav.vendor.employee" >Employee</a></li>
                <li><a routerLink="electedRepresentativeComponent"  class="dropdown-item" jhiTranslate="nav.vendor.electedRepresentative">Elected Representative</a></li>

                            </ul>
                        </li> -->
                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" [hidden]="hideMenu('ACCOUNT')" jhiTranslate="nav.account.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Account
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="account-sna" class="dropdown-item" [hidden]="hideMenu('ACCOUNT')" jhiTranslate="nav.account.sna"></a>
                                </li>
                            </ul>
                        </li>
                        <!-- <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" [hidden]="hideMenu('DEPARTMENT')" jhiTranslate="nav.department.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Department
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                               
                            </ul>
                        </li> -->
                        <li [routerLinkActive]="['active']" [hidden]="hideMenu('FUNDS')" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" jhiTranslate="nav.funds.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Funds
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="funds" class="dropdown-item" jhiTranslate="nav.funds.budgetAllocation"></a>
                                </li>
                                <!-- <li>
                                    <a routerLink="opening-balance" class="dropdown-item" jhiTranslate="nav.funds.openingBal"></a>
                                </li> -->
                                <li>
                                    <a routerLink="funds-transaction" class="dropdown-item" jhiTranslate="nav.funds.fundsReceipt"></a>
                                </li>
                            </ul>
                        </li>
                        <!-- <li [routerLinkActive]="['active']"  class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Maintenance Module 
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="funds" class="dropdown-item" ></a>
                                </li>
                                <li>
                                    <a routerLink="funds-transaction" class="dropdown-item" jhiTranslate="nav.funds.fundsReceipt"></a>
                                </li>
                            </ul>
                        </li> -->
                        <li [routerLinkActive]="['active']" [hidden]="hideMenu('PAYMENT')" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" jhiTranslate="nav.payments.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" routerLinkActive="active">
                                Payment
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li *ngIf="roleCode==='SA' || roleCode==='DMAKER' || roleCode==='DC1' || roleCode==='DC2' || roleCode === 'DM'">
                                    <a routerLink="WorkPayment" class="dropdown-item" jhiTranslate="nav.payments.workPayment">WorkPayment</a>
                                </li>
                                <!-- <li>
                                    <a routerLink="VendorPayment" class="dropdown-item" jhiTranslate="nav.payments.vendorPayment">VendorPayment</a>
                                </li> -->
                                <li *ngIf="roleCode==='SA' || roleCode==='DMAKER' || roleCode==='DC1' || roleCode==='DC2' || roleCode === 'DM'">
                                    <a routerLink="MigratePayment" class="dropdown-item" jhiTranslate="nav.payments.migrateWorkPayment">MigrateWork Payment</a>
                                </li>
                                <!-- <li>
                                    <a routerLink="MigrateVendorPayment" class="dropdown-item" jhiTranslate="nav.payments.migrateVendorPayment">Migrate VendorPayment</a>
                                </li> -->
                                <li>
                                    <a routerLink="StatutoryPaymentList" class="dropdown-item">Statutory Payment</a>
                                </li>
                                <li>
                                    <a routerLink="miscellaneous-payment-table" class="dropdown-item">Miscellaneous Payment</a>
                                </li>
                                <li>
                                    <a routerLink="miscellaneous-bulkpayment-table" class="dropdown-item">Miscellaneous BulkPayment</a>
                                </li>
                            </ul>
                        </li>
                        <!-- <li [routerLinkActive]="['active']" [hidden]="hideMenu('PAYMENT')" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" jhiTranslate="nav.payments.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" routerLinkActive="active">
                Payment
              </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a routerLink="payment-personal" class="dropdown-item" jhiTranslate="nav.payments.personal">Personal</a></li>
                                <li><a routerLink="payment" class="dropdown-item" jhiTranslate="nav.payments.commercial">Commercial</a></li>
                                <li><a routerLink="employeePaymentTable" jhiTranslate="nav.payments.employee" class="dropdown-item" >Employee</a></li>
                <li><a routerLink="electedRepresenativePaymentTableComponent" class="dropdown-item" jhiTranslate="nav.payments.electedRepresentative" >Elected Representative</a></li>
                <li><a [hidden]=hidden  routerLink="swapTable" class="dropdown-item" jhiTranslate="nav.payments.swapTable">Swap Table</a> </li>
                            </ul>
                        </li> -->
                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" [hidden]="hideMenu('LEVELTYPE')" jhiTranslate="nav.master.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Master
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="agency" class="dropdown-item" jhiTranslate="nav.agency"></a>
                                </li>
                                <li>
                                    <a routerLink="department-list" class="dropdown-item" jhiTranslate="nav.department.departList"></a>
                                </li>
                                <li>
                                    <a routerLink="scheme-list" class="dropdown-item" jhiTranslate="nav.department.schemeList"></a>
                                </li>
                                <li>
                                    <a routerLink="level-type" class="dropdown-item" jhiTranslate="nav.master.levelType"></a>
                                </li>
                                <li>
                                    <a routerLink="level-master" class="dropdown-item" jhiTranslate="nav.master.levelMaster"></a>
                                </li>
                                <li>
                                    <a routerLink="component-type" class="dropdown-item" jhiTranslate="nav.master.compoType"></a>
                                </li>
                            </ul>
                        </li>

                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" [hidden]="hideMenu('COMPONENTTYPE')" jhiTranslate="nav.iACompo.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                IA Component
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="limit-set" class="dropdown-item" jhiTranslate="nav.iACompo.limitset"></a>
                                </li>
                                <li>
                                    <a routerLink="limit-allocation" class="dropdown-item" jhiTranslate="nav.iACompo.districtAllocation"></a>
                                </li>
                                <li>
                                    <a routerLink="limit-translist" class="dropdown-item" jhiTranslate="nav.iACompo.districtAuthorisation"></a>
                                </li>
                            </ul>
                        </li>
                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Report
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <ng-container *ngFor="let menuItem of items">
                                    <li *ngIf="menuItem.roleList.includes(roleCode)">
                                        <a [routerLink]="['/dynamicReport']" [queryParams]="{ id: menuItem.id }" class="dropdown-item">{{menuItem.reportName}}</a>
                                    </li>
                                </ng-container>
                            </ul>
                        </li>
                        <li *ngIf="roleCode==='SA'" [routerLinkActive]="['active']" class="nav-item">
                            <a routerLink="wrong-transaction-page" class="nav-link">Wrong Transaction</a>
                        </li>
                        <li *ngIf="roleCode==='SA'" [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                               DrillDown Report
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="drilldownpaymentstatus" class="dropdown-item">Payment Report</a>
                                </li>
                                <li>
                                    <a routerLink="drilldownvoucherstatus" class="dropdown-item">Voucher Report</a>
                                </li>
                                <li>
                                    <a routerLink="drilldownvendorstatus" class="dropdown-item">Vendor Report</a>
                                </li>
                                <li>
                                    <a routerLink="drilldownlimitReport" class="dropdown-item">Limit Report</a>
                                </li>
                                <!-- <li>
                                    <a routerLink="level-master" class="dropdown-item"
                                        jhiTranslate="nav.master.levelMaster"></a>
                                </li>
                                <li>
                                    <a routerLink="component-type" class="dropdown-item"
                                        jhiTranslate="nav.master.compoType"></a>
                                </li> -->
                            </ul>
                        </li>
                        <!-- <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" [hidden]="hideMenu('COMPONENTMASTER')" jhiTranslate="nav.compoMaster.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Component Master
              </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="major-component" class="dropdown-item" jhiTranslate="nav.compoMaster.major"></a>
                                </li>
                                <li>
                                    <a routerLink="minor-component" class="dropdown-item" jhiTranslate="nav.compoMaster.minor"></a>
                                </li>
                                <li>
                                    <a routerLink="sub-component" class="dropdown-item" jhiTranslate="nav.compoMaster.sub"></a>
                                </li>
                            </ul>
                        </li> -->
                        <!-- <li [routerLinkActive]="['active']" class="nav-item">
                            <a routerLink="local-body-contribution" [hidden]="hideMenu('LBC')"  class="nav-link" jhiTranslate="nav.iACompo.lbc"></a>
                        </li> -->
                        <li [routerLinkActive]="['active']" class="nav-item">
                            <a routerLink="project" class="nav-link" [hidden]="hideMenu('PROJECT')" jhiTranslate="nav.project"></a>
                        </li>
                        <li [routerLinkActive]="['active']" class="nav-item">
                            <a routerLink="tender-table" class="nav-link" [hidden]="hideMenu('PROJECT')">Vendor Mapping</a>
                        </li>

                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Payment Status
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="DisputedPaymentList" class="dropdown-item">Disputed Payment</a>
                                </li>
                                <li>
                                    <a routerLink="failureTransaction" class="dropdown-item">Failed Transaction</a>
                                </li>
                            </ul>
                        </li>

                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Statutory Payment
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="StatutoryPayment" class="dropdown-item">List</a>
                                </li>
                                <li>
                                    <a routerLink="StatutoryAccount" class="dropdown-item">Account configuration</a>
                                </li>
                            </ul>
                        </li>
                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Deductions
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li *ngIf="roleCode==='SA' || roleCode==='DMAKER' || roleCode==='DC1' || roleCode==='DC2' || roleCode === 'DM'">
                                    <a routerLink="WorkDeductions" class="dropdown-item">Work Deduction List</a>
                                </li>
                                <li>
                                    <a routerLink="VendorDeductions" class="dropdown-item">Vendor Deduction List</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
    <!-- <div class="row">
    <div class="col-12">
      <div class="breadscrum">
        <p>Breadscrum Area</p>
      </div>
    </div>
  </div> -->
</div>