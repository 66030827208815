import { Component } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-enable-maintenance-payment',
  templateUrl: './enable-maintenance-payment.component.html',
  styleUrl: './enable-maintenance-payment.component.scss'
})
export class EnableMaintenancePaymentComponent {
  workId:string;
  workEnableList: any;
  uFilename: any;
  isFileUploaded: boolean;
  vFileExt: any;
  vFileUrl: string;
  viewDoc: boolean;
  view: boolean;
   constructor(
      public fontService: FontService,
      protected paymentService: PaymentService,
      protected notificationService: NotificationService,
      private spinner: NgxSpinnerService,
      private router: Router,
      private confirmationService: ConfirmationService,
      protected activatedRoute: ActivatedRoute,
    ) {}
  
    ngOnInit(): void {
      if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
        let Query = {
          id:Number((this.activatedRoute.snapshot.queryParamMap.get('id')))
        };
            this.spinner.show();
            this.paymentService
              .getReenableList(
                Query
              )
              .subscribe(
                (res: HttpResponse<any>) => {
                  console.log('resss',res.body);
                  this.spinner.hide();
                  this.workId = res.body [0].workId;
                  this.workEnableList = res.body;
                  this.view = true;
                },
                () => {
                  this.spinner.hide();
                }
              );
            }
    }
  getMaintenancePayment () {
    this.spinner.show();
        this.paymentService.getMaintenaceDetailsReenable(this.workId).subscribe(
          (res: any) => {
            this.spinner.hide();
            console.log('res', res.body);
            this.workEnableList = res.body;
          },
          (error) => {
            this.workId = null;
            this.workEnableList = null;
            this.spinner.hide();
            if (error.status == 400) {
              this.notificationService.alertError(error.error.message, '');
    
            } else {
              this.notificationService.alertError('An unexpected error occurred.', '');
            }
    
          }
        );

  }

   viewDocument(){
      this.paymentService.viewimage(this.uFilename)
      .subscribe((res)=>
      {
        const filename = res.body.fileName; 
        this.vFileExt  = filename.split('.').pop();
        this.vFileUrl = "data:image/"+this.vFileExt+";base64,"+res.body.fileType; // Replace with your Base64-encoded image string
        this.viewDoc=true;
    });
  }
    downloadImage(base64Data: string, filename: string) {
      const link = document.createElement('a');
      link.href = base64Data;
      link.download = filename;
      link.click();
    }
    downloadDocument(){
    this.paymentService.getimage(this.uFilename)
    .subscribe((res)=>
    {
      const filename = res.body.fileName; 
      const fileExtension = filename.split('.').pop();
  
      if(fileExtension=='pdf')
      {
      const base64Data = "data:application/pdf;base64,"+res.body.fileType; // Replace with your Base64-encoded image string
      this.downloadImage(base64Data, filename);
    }
      else{
      const base64Data = "data:image/"+fileExtension+";base64,"+res.body.fileType; // Replace with your Base64-encoded image string
      this.downloadImage(base64Data, filename);
      }
    },
    (onError)=>
    {
  
    })
    }
    removeDocument(){
      this.paymentService.deleteimage(this.uFilename)
      .subscribe((res)=>{
      this.notificationService.alertSuccess("File Deleted",'');
      this.isFileUploaded=false;
      this.uFilename=null;
      },
      (error)=>{
      this.notificationService.alertError("Error occured, try again",'');
      });
    }
  
    onUpload($event) {
      const formData = new FormData();
      const i=$event.files.length-1;
      const file = $event.files[i];
      const acceptedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
  
      if (file && !acceptedFormats.includes(file.type)) {
        this.notificationService.alertError('Invalid file format. Please upload a valid file.','');
        return;
      }
      const fileData = new Blob([$event.files[i]], {
        type: $event.files[i].type,
      });
      if ($event.files[i].type === 'application/pdf') {
        formData.append('file', fileData, `.pdf`);
      } else if ($event.files[i].type === 'image/png') {
        formData.append('file', fileData, `.png`);
      } else if ($event.files[i].type === 'image/jpeg') {
        formData.append('file', fileData, `.jpeg`);
      } else {
        formData.append('file', fileData, `.jpg`);
      }
  
      this.paymentService.uploadImage(formData).subscribe(
        (res: HttpResponse<any>) => {
            this.uFilename=res.body.fileName;
            this.isFileUploaded=true;
          this.notificationService.alertSuccess('File Uploaded Successfully', '');
        },
        () => {
          this.notificationService.alertError('Error, please make sure file size is within 5MB and in supported format','');
        }
      );
  
      // Call the backend endpoint to upload the file
      // Use HttpClient to make an HTTP POST request
    }

    save () {
      if(!this.uFilename) {
        this.notificationService.alertError('please Upload Letter','');
        return;
      }
      let payload = [];
      this.workEnableList.forEach(element => {
        if(element.isReenable)  {
          element.districtRequestLetter = this.uFilename;
          payload.push(element)
        }
      });
      console.log('djdjjd',this.workEnableList);
      this.spinner.show();
      if(payload.length === 0) {
        this.notificationService.alertError('please enable Payment','');
        return
      }
      this.paymentService.enablePayment(payload).subscribe(
        (response: any) => {
          this.spinner.hide();
          this.notificationService.alertSuccess('enabled Successfully', '');
          this.router.navigate(['/enable-maintenance-payment-list'], {});
          
          this.workId = null;
          this.workEnableList = null;
        },
        (error) => {
          this.spinner.hide();
          if (error.status == 400) {
  
            this.notificationService.alertError(error.error.message, '');
  
          } else {
            this.notificationService.alertError('An unexpected error occurred.', '');
          }
        }
      );
    }

    cancel() {
      this.router.navigate(['/enable-maintenance-payment-list'], {});
    }

}
