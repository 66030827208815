import { SigninService } from './../../auth/signin/signin.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../dashboard/dashboard.service';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { HttpResponse } from '@angular/common/http';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { FontService } from 'src/app/shared/font-service/font.service';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { environment } from 'src/environments/environment';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dash-board-report',
  templateUrl: './dash-board-report.component.html',
  styleUrls: ['./dash-board-report.component.scss']
})
export class DashBoardReportComponent implements OnInit {
  overAllDataCardDetails: any;
  vouchercreated: boolean;
  voucherDetails: any;
  district:any;
  @ViewChild('reportTable', { static: true }) reportTable!: any;
  @ViewChild('paidReportTable', { static: true }) paidReportTable!: any;
  @ViewChild('voucherBillReportTable',{ static: true }) voucherBillReportTable!: any;
  @ViewChild('finalBillReportTable',{ static: true }) finalBillReportTable!: any;
  @ViewChild('zeroBillReportTable',{ static: true }) zeroBillReportTable!: any;
  @ViewChild('lastThirtyReportTable',{ static: true }) lastThirtyReportTable!: any;

  districtLevelList: LevelMasterDto[];
  voucherpaid: boolean;
  voucherpaidDetails: any;
  loginWiseVoucherShow: boolean;
  loginWiseDetails: any;
  finalBillShow: boolean;
  finalBillDetails:any;
  zerobillPayment: boolean;
  zeroBillDetails: any;
  lastThirtyDaysExpenditure: boolean;
  lastThirtyDaysExpenditureDetails: any;
  finYear: FinYear[];
  finYearDto: FinYear;
  userContextSubscription: Subscription;
  fundData= []
  palette: string[] = ['#7459D9'];
  levelMasterId: number;
  userRoles=userRoles;
  customizeChartTooltip(arg: any){
    return {
      text: `${arg.argumentText}- ${arg.percentText}`,
    };
  }

  constructor(private dashboardService:DashboardService, private levelMasterService: LevelMasterService,private spinner: NgxSpinnerService, private limitTransListService: LimitTransListService,public fontService:FontService,public userRoleService:UserRoleService,public loginService:SigninService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.loginService.getuserDto().subscribe(
      (res)=>{
        console.log('res',res);
        if(res.body.role.code !== userRoles.roleState && res.body.role.code !== 'SY') {
          this.levelMasterId = res.body.levelMaster.id;
          console.log('levelmaster', this.levelMasterId);
        }
          this.limitTransListService.getFinYear().subscribe(
            (res: HttpResponse<FinYear[]>) => {
              this.finYear = res.body;
              this.finYearDto = this.finYear[0];
              this.onFinyearChange(this.levelMasterId);
              this.spinner.hide();
            },
            () => { }
          );
        
    },
    ()=>{
    
    })
    
    
    this.onStateLevelChange();
  }
  ngAfterViewInit() {
    console.log('table',this.reportTable);
    let table = this.reportTable.containerViewChild.nativeElement.querySelector('table');
    table.setAttribute('id', 'excelTable');
    table = this.paidReportTable.containerViewChild.nativeElement.querySelector('table');
    table.setAttribute('id', 'excelTable');
    table = this.voucherBillReportTable.containerViewChild.nativeElement.querySelector('table');
    table.setAttribute('id', 'excelTable');
    table = this.finalBillReportTable.containerViewChild.nativeElement.querySelector('table');
    table.setAttribute('id', 'excelTable');
    table = this.zeroBillReportTable.containerViewChild.nativeElement.querySelector('table');
    table.setAttribute('id', 'excelTable');
    table = this.lastThirtyReportTable.containerViewChild.nativeElement.querySelector('table');
    table.setAttribute('id', 'excelTable');
  }

  onFinyearChange(id?) {
   
    this.dashboardService.overAllDataCardDetails(this.finYearDto.id,id).subscribe(
      (res)=>{
        console.log('res',res);
        this.overAllDataCardDetails = res.body;
        this.fundData = [ { "name":"Allocation", "amount":this.overAllDataCardDetails.allocationAmount},
                          
                        ]
        this.spinner.hide();
      },
      (error)=>{
        this.spinner.hide();
      }
    )
  }

  formatCash = n => {
    return +(n / 1e7).toFixed(3);
  };

  voucherPendingPaid(id?){
    console.log('voucher');
    if(!id) {
      this.district = null;
    }
    this.spinner.show();
    this.dashboardService.voucherCreatedAndToBePaidAmount(this.finYearDto.id,this.levelMasterId?this.levelMasterId:id).subscribe(
      (res)=>{
        console.log('res',res.body);
        this.vouchercreated = true;
        this.voucherDetails = res.body;
        this.spinner.hide();
      },
      (error)=>{
        this.spinner.hide();
      }
    )
  }

  voucherPaidSuccess(id?){
    console.log('voucher');
    if(!id) {
      this.district = null;
    }
    this.spinner.show();
    this.dashboardService.voucherCreatedAndToSuccessAmount(this.finYearDto.id,this.levelMasterId?this.levelMasterId:id).subscribe(
      (res)=>{
        this.spinner.hide();
        console.log('res',res.body);
        this.voucherpaid = true;
        this.vouchercreated = false;
        this.voucherpaidDetails = res.body;
      },
      (error)=>{
        this.spinner.hide();
      }
    )
  }

  loginWiseVoucher(id?){
    console.log('voucher');
    if(!id) {
      this.district = null;
    }
    this.spinner.show();
    this.dashboardService.loginWisePendingBills(this.finYearDto.id,this.levelMasterId?this.levelMasterId:id).subscribe(
      (res)=>{
        this.spinner.hide();
        console.log('res',res.body);
        this.voucherpaid = false;
        this.vouchercreated = false;
        this.loginWiseDetails = res.body;
        this.loginWiseVoucherShow = true;
      },
      (error)=>{
        this.spinner.hide();
      }
    )
  }

  finalBillVoucher(id?){
    console.log('voucher');
    this.spinner.show();
    if(!id) {
      this.district = null;
    }
    this.dashboardService.finalBillsPaidWorkData(this.finYearDto.id,this.levelMasterId?this.levelMasterId:id).subscribe(
      (res)=>{
        console.log('res',res.body);
        this.voucherpaid = false;
        this.vouchercreated = false;
        this.loginWiseVoucherShow = false;
        this.finalBillShow = true;
        this.finalBillDetails = res.body;
        this.spinner.hide();
      },
      (error)=>{
        this.spinner.hide();
      }
    )
  }

  zeroBillVoucher(id?){
    console.log('voucher');
    if(!id) {
      this.district = null;
    }
    this.spinner.show();
    this.dashboardService.zeroBillsWorkData(this.finYearDto.id,this.levelMasterId?this.levelMasterId:id).subscribe(
      (res)=>{
        this.spinner.hide();
        console.log('res',res.body);
        this.voucherpaid = false;
        this.vouchercreated = false;
        this.loginWiseVoucherShow = false;
        this.finalBillShow = false;
        this.zerobillPayment = true;
        this.zeroBillDetails = res.body;
      },
      (error)=>{
        this.spinner.hide();
      }
    )
  }

  thirtyDaysExpenditure(id?){
    this.spinner.show();
    console.log('voucher');
    if(!id) {
      this.district = null;
    }
    this.dashboardService.lastThirtyDaysExpenditure(this.finYearDto.id,this.levelMasterId?this.levelMasterId:id).subscribe(
      (res)=>{
        this.spinner.hide();
        console.log('res',res.body);
        this.voucherpaid = false;
        this.vouchercreated = false;
        this.loginWiseVoucherShow = false;
        this.finalBillShow = false;
        this.zerobillPayment = false;
        this.lastThirtyDaysExpenditure = true;
        this.lastThirtyDaysExpenditureDetails = res.body;
      },
      (error)=>{
        this.spinner.hide();
      }
    )
  }


  exportExcel(type) {
   
    import('xlsx').then(xlsx => {
      let worksheet = xlsx.utils.table_to_book(document.getElementById('excelTable'))
      let fileName = ''
      switch (type) {
        case 'unpaid':
          fileName = 'VoucherPendingPayment.xlsx';
        break;
        case 'paid':
          fileName = 'VoucherSuccessPayment.xlsx';
        break;
        case 'voucherPendingBills':
          fileName = 'VoucherPendingBills.xlsx'
        break;
        case 'final':
          fileName = 'VoucherFinalBills.xlsx'
        break;
        case 'zero':
          fileName = 'VoucherZeroBills.xlsx'
        break;
        case 'lastThirty':
          fileName = 'LastThirtyExpenditure.xlsx'
      }
     
      console.log('logss',worksheet);
      var workbook = xlsx.utils.book_new();
      workbook.SheetNames = worksheet.SheetNames;
      workbook.Sheets = worksheet.Sheets;
      
      xlsx.writeFileXLSX(workbook, fileName);
    });
  }

  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

}
