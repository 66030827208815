import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { MenuItem } from 'primeng/api';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { TranslateService } from '@ngx-translate/core';
import screenfull from 'screenfull';
import { FontService } from 'src/app/shared/font-service/font.service';
import { NavbarService } from './navbar.service';
import { HttpResponse } from '@angular/common/http';
import { Report } from './report-model';
import { Location } from '@angular/common';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ib-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  inProduction?: boolean;
  isNavbarCollapsed = true;
  openAPIEnabled?: boolean;
  version = '';
  isFullScreenEnabled: boolean = false;
  hidden:boolean=true;
  headerItems: MenuItem[] = [
    {
      // label: 'usermenu.myAccount',
      label: this.translateService.instant('usermenu.myAccount'),
      icon: 'pi pi-user',
      routerLink: '/',
    },
    {
      // label: 'usermenu.settings',
      label: this.translateService.instant('usermenu.settings'),
      icon: 'pi pi-cog',
      routerLink: '/',
    },
    // {
    //   label: 'Logout',
    //   icon: 'pi pi-sign-out',
    //   routerLink: '/'
    // }
  ];

  items:Report[]

  public selectedLanguage: string = 'English';
  showMenu = false;
  menu: any;

  isFullScreen = false;
  userName: any;
  disableMenu: boolean;
  role: any;
  currentPath: string;
  userContextSubscription: Subscription;
  roleCode: string;
  scheme: any;
  constructor(
    private loginService: SigninService,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private renderer: Renderer2,
    private fontService: FontService,
    private navbarService: NavbarService,
    private userRoleService:UserRoleService,
    private location: Location
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.fontService.setFontClass(this.translateService.currentLang);
    });
  }

  ngOnInit() {
    let token = this.localStorageService.retrieve('authenticationToken');
    this.currentPath = this.location.path();
    this.scheme = this.localStorageService.retrieve('scheme');
    if(!token && !this.scheme) {
      this.localStorageService.clear();
    }
    if(this.currentPath === '/select-scheme' && !this.scheme) {
      this.disableMenu = true;
    }
    else if(token && this.scheme) {
      this.disableMenu = false;
      this.router.navigateByUrl('dashBoard-Report');
    }
    
    if(!this.disableMenu && this.scheme) {
      this.navbarService.getMenu().subscribe(
        (res: HttpResponse<any>) => {
          this.menu = res.body;
        },
        () => {}
      );
      this.navbarService.getReportMenu().subscribe(
        (res: HttpResponse<Report[]>) => {
          this.items = res.body;
          this.items.forEach(element => {
            element.roleList = element.roleList.split(",")
          });
          console.log('logs',this.items)
        },
        () => {}
      );
      this.loginService.getuserDto().subscribe(
        (res)=>{
          this.userName = res.body.userName;
          this.roleCode=res.body.role.code;
          if (res.body.profileStatus === 'No' && res.body.role.code !== 'DM') {
            this.localStorageService.store('refresh', 'Yes');
            this.disableMenu = true;
            this.router.navigate(['/profile']);
          } else {
            this.disableMenu = false;
          }
          // if (res.body.role.code == 'SA') {
          //   this.hidden=false;
          // }
        },
        (onError) => {}
      );
    }
    this.setLanguage('en');
  }

  login(): void {
    this.router.navigate(['/login']);
  }

  handleLinkClick(menuItem){
    this.currentPath = this.location.path();
    this.localStorageService.store('id', menuItem.id);
    if(this.currentPath === '/dynamicReport'){
      this.localStorageService.store('refresh', 'Yes');
      window.location.reload();
    }
  }

  hideMenu(menuName) {
    const index = this.menu?.findIndex((_) => _.menuTab === menuName);

    if (index >= 0) {
      return false;
    } else {
      return true;
    }
  }

  logout(): void {
    this.navbarService.logout().subscribe(
      (res: HttpResponse<any>) => {
      },
      (err) => {}
    );
    this.localStorageService.clear();
    this.sessionStorageService.clear();
    this.router.navigate(['']);
  }

  //   selectedLanguage: string = 'English';
  //   languageOptions = [  { label: 'English', value: 'en' },  { label: 'Tamil', value: 'ta' }];

  // clearTranslationCache(): void {
  //   this.translateService.resetLang(this.translateService.currentLang);
  // }

  setLanguage(language: string): void {
    this.translateService.use(language);
    this.translateService.reloadLang(language);

    if (language === 'en') {
      this.selectedLanguage = 'nav.en';
      this.renderer.removeClass(document.body, 'tamil-font');
    } else if (language === 'ta') {
      this.selectedLanguage = 'nav.ta';
      this.renderer.addClass(document.body, 'tamil-font');
    }

    // Update the text content of the button using translation
    this.translateService
      .get(this.selectedLanguage)
      .subscribe((translation: string) => {
        const button = document.querySelector('.dropdown-toggle');
        if (button instanceof HTMLElement) {
          button.textContent = translation;
        }
      });

    // Update the body and dialog fonts
    const bodyElement = document.getElementsByTagName('body')[0];
    if (language === 'ta') {
      bodyElement.classList.add('tamil-font');
    } else {
      bodyElement.classList.remove('tamil-font');
    }

    const dialogElement = document.querySelectorAll('.p-dialog-title');
    if (dialogElement) {
      dialogElement.forEach((element) => {
        if (language === 'en') {
          element.classList.add('english-heading');
          element.classList.remove('tamil-heading');
        } else {
          element.classList.add('tamil-heading');
          element.classList.remove('english-heading');
        }
      });
    }
    this.cdr.detectChanges();
  }

  // setLanguage(language: string): void {
  //   // this.clearTranslationCache();
  //   this.translateService.use(language);
  //   // this.translateService.reloadLang(language);
  //   if (language === "en") {
  //     this.selectedLanguage = "English";
  //     this.renderer.removeClass(document.body, 'tamil-font');

  //   }
  //   else if (language === "ta") {
  //     this.selectedLanguage = "Tamil";
  //     this.renderer.addClass(document.body, 'tamil-font');

  //   }

  //   const bodyElement = document.getElementsByTagName('body')[0];
  //   if (language === 'ta') {
  //     bodyElement.classList.add('tamil-font');
  //   } else {
  //     bodyElement.classList.remove('tamil-font');
  //   }

  //   const dialogElement = document.querySelector('.p-component-overlay');
  //   if (dialogElement) {
  //     if (language === 'ta') {
  //       dialogElement.classList.add('tamil-font');

  //     } else {
  //       dialogElement.classList.remove('tamil-font');
  //     }
  //   }
  //   // this.cdr.detectChanges();
  // }

  setLanguageOld(language: string): void {
    this.translateService.use(language);
    if (language === 'en') {
      this.selectedLanguage = 'English';
    } else if (language === 'ta') {
      this.selectedLanguage = 'Tamil';
    }
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  toggleFullScreen() {
    const sf = screenfull as any; // cast to any to avoid typescript errors
    if (sf.isEnabled) {
      sf.toggle();
      this.isFullScreen = !this.isFullScreen;
    }
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.userContextSubscription) {
      this.userContextSubscription.unsubscribe();
    }
  }

  changeScheme() {
    this.localStorageService.store("refresh", "Yes");
    this.disableMenu = true;
    this.localStorageService.clear('scheme');
    this.router.navigate(["/select-scheme"]);
  }
}
