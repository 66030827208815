<div class="container-fluid d-flex justify-content-center vh-100 mt-4">
    <div class="text-center">
        <h1 style="color: #404040; font-size: 40px;">Select Scheme</h1>
        <div style="
            width: 200px;
            height: 5px;
            margin: 10px auto;
            border-radius: 20px 0px 0px 0px;
            background: #162A72;">
        </div>
        <div class="row  mt-3">
            <div (click)="selectScheme(schemeList[0])" style="text-align: center;" class="col-md-3 hover-effect-col1">
                <span style="color: #0056CF;">{{schemeList[0]?.name}}</span>
            </div>
            <div (click)="selectScheme(schemeList[1])" style="text-align: center;" class="col-md-3 hover-effect-col2">
                <span style="color: #0056CF;">{{schemeList[1]?.name}}</span>
            </div>
        </div>

    </div>
</div>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>