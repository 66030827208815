<p-table #reportTable [value]="listItems | async" scrollDirection="both" styleClass="dyanamic-table" columnResizeMode='fit' [paginator]="paginator" (onLazyLoad)="loadFilterValues($event)" [rows]="itemsPerPage" [showCurrentPageReport]="true" [lazy]="true"
    [totalRecords]="totalCount" [lazyLoadOnInit]="lazyLoadOnInit" responsiveLayout="scroll" [columns]="selectedColumns" [tableStyle]="{ width: 'max-content'}" [reorderableColumns]="true" selectionMode="single" [rowsPerPageOptions]="[1,10,20,30,2000]" [sortOrder]="sortOrder"
    id="reportTable" [sortField]="sortField" [currentPageReportTemplate]="totalCount > 0 ? 'Showing {first} - {last} of {totalRecords} items' : 'No Items Found'" [first]="first">
    <ng-template pTemplate="caption">
        <p-multiSelect [options]="columnOptions" [(ngModel)]="selectedColumns" optionLabel="header" selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns">
        </p-multiSelect>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th>S.No</th>
            <ng-container *ngFor="let column of columns">
                <th pReorderableColumn [pSortableColumn]="column.field" scope="col">
                    <!-- <th *ngIf="column.isSortable && column.type !== 'dropDown'" pReorderableColumn [pSortableColumn]="column.field"
          scope="col"> -->
                    <div class="head-wrapper">
                        <span class="left" jhiTranslate={{column.jhiTranslate}}>{{column.header}}</span>
                        <span class="right"> <p-columnFilter *ngIf="column.isFilterable" type="text"
                                field={{column.field}} display="menu" [matchModeOptions]="matchModeOptions"
                                [showAddButton]="false" [hideOnClear]="true">
                            </p-columnFilter></span>
                    </div>


                </th>
                <!-- <th *ngIf="column.type === 'dropDown'" style="min-width:230px">
          <span jhiTranslate={{column.jhiTranslate}}>{{column.header}}</span>
          <p-columnFilter  field="{{column.field}}Id" [showMenu]="false" matchMode="equals">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown [ngModel]="value" [options]="column.option" (onChange)="filter($event.value)" placeholder="Select" >
                <ng-template let-option pTemplate="item">
                  <span [class]="'customer-badge status-' + option.value">{{option[0]}}</span>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th> -->

            </ng-container>
            <th alignFrozen="right" pFrozenColumn>
                <span *ngIf="enableViewIcon || !disableEditIcon || !disableDeleteIcon
        || showProfileButton || showRedirectButton || showTableButton || enableCheckTransaction || !disablePaymentEditIcon">Action</span>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-record let-columns="columns" let-rowData let-index="rowIndex">
        <!-- <tr (click)="onSelectRow(record, 'select')" > -->
        <tr>
            <td>{{ index + 1 }}</td>
            <ng-container *ngFor="let col of columns" [ngSwitch]="col.type">
                <td *ngSwitchCase="'date'">
                    {{rowData[col.field]| date:'dd/MMM/yy' }}
                </td>
                <td *ngSwitchCase="'dateTime'">
                    {{ rowData[col.field] | date : 'dd/MMM/yy HH:mm:ss' }}
                </td>
                <td *ngSwitchCase="'dropDown'">
                    {{(rowData[col.field])?.name}}
                </td>
                <td *ngSwitchCase="'dropDown_with_other_field'">
                    {{ rowData[col.field]?.[col.Dfield] }}
                </td>
                <td *ngSwitchCase="'dropDown_with_other_inner_field'">
                    {{ rowData[col.field]?.[col.Dfield]?.[col.innerField] }}
                </td>
                <td *ngSwitchCase="'dropDown_with_inner_field'">
                    {{ rowData[col.field]?.role?.name }}
                </td>
                <td *ngSwitchCase="'dropDown_with_levelmaster_field'">
                    {{ rowData[col.field]?.levelMaster?.name }}
                </td>
                <td *ngSwitchCase="'amount'">
                    {{ rowData[col.field] | currency:'INR':'':'1.2-2':'en-IN' }}
                </td>
                <td *ngSwitchCase="'type'">
                    {{(rowData[col.field])?.name}}
                </td>
                <td *ngSwitchCase="'crfield'">
                    <div *ngIf="rowData[col.cfield] === 'CR'"> {{(rowData[col.field])}}</div>
                    <div *ngIf="rowData[col.cfield] !== 'CR'"> -</div>
                </td>
                <td *ngSwitchCase="'dbfield'">
                    <div *ngIf="rowData[col.cfield] === 'DR'"> {{(rowData[col.field])}}</div>
                    <div *ngIf="rowData[col.cfield] !== 'DR'"> -</div>
                </td>
                <td *ngSwitchCase="'color'">
                    <div style="color:red">
                        {{rowData[col.field]}}
                    </div>

                </td>

                <td *ngIf="!col.toolTip && !col.type">
                    {{rowData[col.field]}}
                </td>
                <td *ngIf="col.toolTip && !col.type" [pTooltip]="rowData[col.toolTipText]">
                    {{rowData[col.field]}}
                </td>
            </ng-container>
            <td style=" width: 8" alignFrozen="right" pFrozenColumn class="action-btn">
                <button *ngIf="enableViewIcon" pButton pRipple type="button" label="View" class="p-button-text p-button-secondary" (click)="onSelectRow(record, 'view')">
                </button>
                <span *ngIf="enableApproveButton && enableViewIcon && (!record?.approve || record?.approve=='No')  && (!record?.reject || record?.reject!='Yes') && record?.levelMaster?.id==levelMasterId" pButton class="p-button-text p-split-color">|</span>
                <button *ngIf="(!record?.approve || record?.approve=='No')  && (!record?.reject || record?.reject!=='Yes') && record?.levelMaster?.id==levelMasterId && enableApproveButton" pButton pRipple type="button" label="Approve" class="p-button" (click)="onSelectRow(record, 'approve')">
                </button>
                <span *ngIf="record?.approve=='No' && record?.reject!='Yes' && enablePApproveButton && enableViewIcon" pButton class="p-button-text p-split-color">|</span>
                <button *ngIf="record?.approve=='No' && record?.reject!='Yes' && enablePApproveButton" pButton pRipple type="button" label="Approve" class="p-button" (click)="onSelectRow(record, 'approve')">
                </button>
                <span *ngIf="(!disableEditIcon || !disablePaymentEditIcon || (!disableVendorEdit && (!record?.approve || record?.approve=='No') && record?.levelMaster?.id==levelMasterId)) && enableViewIcon" pButton class="p-button-text p-split-color">|</span>
                <button *ngIf="!disableEditIcon" pButton pRipple type="button" label="Edit" class="p-button-text" (click)="onSelectRow(record, 'select')">
                </button>
                <button *ngIf="!disablePaymentEditIcon" pButton pRipple type="button" label="View/Edit" class="p-button-text" (click)="onSelectRow(record, 'select')">
                </button>
                <button *ngIf="!disableVendorEdit && (!record?.approve || record?.approve=='No') && record?.levelMaster?.id==levelMasterId" pButton pRipple type="button" label="Edit" class="p-button-text" (click)="onSelectRow(record, 'select')">
                </button>
                <!-- <button pButton pRipple type="button" icon="pi pi-eye"
          class="p-button-rounded p-button-text p-button-info">
        </button> -->
                <span *ngIf="!disableDeleteIcon || !disableVendorDelete  && (((userRoles.roleMaker === currentRole  || userRoles.roleStateMaker === currentRole) && record?.levelMaster?.id==levelMasterId && record?.approve=='No')||(userRoles.roleState === currentRole && record?.approve ==='Yes'))"
                    pButton class="p-button-text p-split-color">|</span>
                <button *ngIf="!disableDeleteIcon" pButton pRipple type="button" label="Delete" class="p-button-text p-button-danger" (click)="onSelectRow(record, 'delete')">
                </button>
                <button *ngIf="!disableVendorDelete  && (((userRoles.roleMaker === currentRole  || userRoles.roleStateMaker === currentRole) && record?.levelMaster?.id==levelMasterId && record?.approve=='No')||(userRoles.roleState === currentRole && record?.approve ==='Yes'))"
                    pButton pRipple type="button" label="Delete" class="p-button-text p-button-danger" (click)="onSelectRow(record, 'delete')">
                </button>
                <button *ngIf="showProfileButton" pButton pRipple type="button" icon="pi pi-user" class="p-button-rounded p-button-text p-button-info" (click)="navigateProfile()">
                </button>
                <button *ngIf="showRedirectButton" pButton pRipple type="button" icon="pi pi-arrow-circle-right" class="p-button-rounded p-button-text" (click)="navigatetoVoucherPage(record.id)">
                </button>
                <button *ngIf="showTableButton" pButton pRipple type="button" icon="pi pi-th-large" class="p-button-rounded p-button-text" (click)="onSelectshowTable(record?.transactionId)">
                </button>
                <span pButton *ngIf="enableActivateButton && (record?.profileStatus==='No'|| record?.profileStatus==='Yes')" class="p-button-text p-split-color">|</span>
                <button *ngIf="enableActivateButton && record?.profileStatus==='No'" pButton pRipple type="button" label="Activate" class="p-button-text" (click)="onSelectRow(record?.id,'activate')">
                </button>
                <button *ngIf="enableDeactivateButton && record?.profileStatus==='Yes'" pButton pRipple type="button" label="Deactivate" class="p-button-text p-button-danger" (click)="onSelectRow(record?.id,'deactivate')">
                </button>
                <span pButton *ngIf="enableActivateButton && (record?.activeFlag==='No'|| record?.activeFlag==='Yes')" class="p-button-text p-split-color">|</span>
                <button *ngIf="enableActivateButton && record?.activeFlag==='No'" pButton pRipple type="button" label="Enable" class="p-button-text" (click)="onSelectRow(record,'enable')">
                </button>
                <button *ngIf="enableDeactivateButton && record?.activeFlag==='Yes'" pButton pRipple type="button" label="Disable" class="p-button-text p-button-danger" (click)="onSelectRow(record,'disable')">
                </button>
                <span pButton *ngIf="enableuserUnlock && record?.loginAttempt >=5" class="p-button-text p-split-color">|</span>
                <button *ngIf="enableuserUnlock && record?.loginAttempt >=5" pButton pRipple type="button" label="Unlock" class="p-button-text p-button-danger" (click)="onSelectRow(record?.id,'unlock')">
                </button>

                <button *ngIf="enableCheckTransaction && record?.transactionStatus === 'Pending'" pButton pRipple type="button" label="Confirm" class="p-button p-button-success" (click)="onSelectRow(record,'true')">
                </button>
                <button *ngIf="enableCheckTransaction && record?.transactionStatus === 'Pending'" pButton pRipple type="button" label="Dispute" class="p-button p-button-danger" (click)="onSelectRow(record,'false')">
                </button>
                <button *ngIf="record.reInitialize" pButton pRipple type="button" label="Reinitiate Payment" class="p-button-text" (click)="onSelectRow(record, 'reinitialize')">
                </button>
                <button *ngIf="DownloadPdf" pButton pRipple type="button" label="Download Pdf" class="p-button p-button-success" (click)="downloadVoucherPdf(record)">
                </button>
            </td>
        </tr>
    </ng-template>
</p-table>

<div *ngIf="listItems" class="p-toolbar-group-start mb-4" style="padding-left: 10px">
    <p-button (click)="exportExcel()" styleClass="p-button-sm p-button-success  me-4 custom-button">
        <span class="button-label" jhiTranslate="common.exportexcel"></span>
    </p-button>
</div>