import {
  LevelMaster,
  LevelMasterDto,
} from 'src/app/module/level-master/level-master';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'ngx-webstorage';

export type EntityResponseType = HttpResponse<LevelMaster>;
export type EntityArrayResponseType = HttpResponse<LevelMaster[]>;

@Injectable()
export class LevelMasterService {
  protected resourceFliterUrl =
    environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getViewLevelMasterByFilter';
  protected resourceSaveUrl =
    environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/saveLevelMaster';
  protected resourceDeleteUrl =
    environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/deleteProfile';
  protected getLevelMasterDtoFliterUrl =
    environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getLevelMasterByFilter';
  protected resourcegetUrl = environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getLevelMaster';
  protected getVillageListBySchemeUrl =
  environment.SERVER_API_URL + 'api/'+ this.localStorageService.retrieve('scheme') + '/getVillageListByScheme';
  
  constructor(protected http: HttpClient,private localStorageService: LocalStorageService,) {}
  filter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<LevelMaster[]>(this.resourceFliterUrl, filter, {
      params: options,
      observe: 'response',
    });
  }
  LevelMasterDtoFilter(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<LevelMasterDto[]>(
      this.getLevelMasterDtoFliterUrl,
      filter,
      { params: options, observe: 'response' }
    );
  }
  villageListByScheme(req?: any): Observable<any> {
   
    return this.http.post<LevelMasterDto[]>(
      this.getVillageListBySchemeUrl,req,
      { observe: 'response' }
    );
  }
  save(levelMaster?: LevelMasterDto): Observable<any> {
    return this.http.post<LevelMasterDto>(this.resourceSaveUrl, levelMaster, {
      observe: 'response',
    });
  }

  getLevelMaster(id: number): Observable<HttpResponse<any>> {
    return this.http.get<LevelMasterDto>(`${this.resourcegetUrl}?id=${id}`, {
      observe: "response",
    });
  }

  // delete(id: number): Observable<HttpResponse<{}>> {
  //   return this.http.get(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
  // }
}
