import { DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { StatutoryAccountDetail, StatutoryDeductionType } from 'src/app/module/statutory-account-config/statutory-account-model';
import { StatutoryAccountService } from 'src/app/module/statutory-account-config/statutory-account.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { environment } from 'src/environments/environment';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { StatutoryDeductionDto, StatutoryTransaction, StatutoryVoucherGeneration } from '../statutory-payment-voucher/statutory-payment-voucher.model';
import { StatutoryPaymentVoucherService } from './statutory-payment-voucher.service';

@Component({
  selector: 'app-statutory-payment-voucher',
  templateUrl: './statutory-payment-voucher.component.html',
  styleUrls: ['./statutory-payment-voucher.component.scss']
})
export class StatutoryPaymentVoucherComponent implements OnInit {

  statutoryDeductionList:StatutoryDeductionType[];
  statutoryVoucherGeneration: StatutoryVoucherGeneration;
  date:Date;
  month:Date;
  enableRevertButton:boolean;
  enableSignButton:boolean;
  ngSelectInvalid:boolean;
  filterProps: any;
  statutoryDeductionDto:StatutoryDeductionDto;
  readonly: boolean;
  param1: any;
  param2: any;
  param3: any;
  signButtonName: string;
  enableApproved: boolean;
  checkAmount: boolean;
  checkstatusButton: boolean;
  downloadButton: boolean;
  enableZonalButton: boolean;
  requiredError:boolean;
  reasonDialog:boolean;
  rejectionReason:string;
  isEditable:boolean=true;
  paymentSucessDialog: boolean;
  paymentSucessText: any;
  voucherConfirmDialog: boolean=false;
  adminuserDto: any;
  maxSelectableDate: Date;
  enableBackButton:boolean=true;
  userRoles = userRoles;

  constructor(public fontService:FontService,
    public activatedRoute:ActivatedRoute,
    protected statutoryAccountService:StatutoryAccountService,
    protected statutoryPaymentVoucherService:StatutoryPaymentVoucherService,
    private router:Router,
    private localStorageService: LocalStorageService,
    private loginService:SigninService,
    private datePipe: DatePipe,
    private notificationService:NotificationService,
    private spinner:NgxSpinnerService,
    private paymentService:PaymentService) { }

  ngOnInit(): void {
    const today = new Date();
    this.maxSelectableDate = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999);
    console.log('month',this.maxSelectableDate);
    this.loadDeductionType();
    this.getRole().subscribe(
      (roleCode) => {
        if (roleCode) {
          this.adminuserDto=roleCode;
          this.paymentService.getByImplemeting(roleCode.implementingAgencyId)
          .subscribe(
            (res: HttpResponse<any>) => {
              this.statutoryVoucherGeneration.statutoryTransactionDto.implementingAgency =
                res.body;
            },
            ()=>{});
            if (
              roleCode.role.code === userRoles.roleMaker ||
              roleCode.role.code === userRoles.roleStateMaker ||
              roleCode.role.code === 'VMAKER' ||
              roleCode.role.code === 'BMAKER'
            ) {              
              this.signButtonName = 'Perform e-Sign Administrative Officer';
            } else if (
              
              roleCode.role.code === userRoles.roleChecker ||
              roleCode.role.code === userRoles.roleStateChecker ||
              roleCode.role.code === 'BC1' ||
              roleCode.role.code === 'VC1' ||
              roleCode.role.code === 'ZC1'
            ) {
              this.enableApproved=true;
              if (roleCode.role.code === 'BC1' || roleCode.role.code === 'ZC1') {
                this.checkAmount = true;
              }
              this.signButtonName = 'Perform e-Sign Project Director';
              this.enableRevertButton = true;
              this.readonly = true;
            } else if (
              roleCode.role.code === userRoles.roleApprover ||
              roleCode.role.code === userRoles.roleStateApprover ||
              roleCode.role.code === 'BC2' ||
              roleCode.role.code === 'VC2'
            ) {
              this.enableApproved=true;
              this.signButtonName = 'Perform e-Sign District Collector';
              this.enableRevertButton = true;
              this.downloadButton = true;
              this.readonly = true;
            }
            if (
              roleCode.role.code === 'BMAKER' ||
              roleCode.role.code === 'BC1' ||
              roleCode.role.code === 'BC2' ||
              roleCode.role.code === 'ZC1'
            ) {
              this.enableZonalButton = true;
            }
            if (this.activatedRoute.snapshot.queryParamMap.get('response')){
              this.paymentSucessDialog = true;
              this.paymentSucessText = this.activatedRoute.snapshot.queryParamMap.get('response');
            }
            if (this.activatedRoute.snapshot.queryParamMap.get('error')){
              this.notificationService.alertError('Error Occurred: '+this.activatedRoute.snapshot.queryParamMap.get('error'),'');
            }
            if (!this.activatedRoute.snapshot.queryParamMap.get('id')) {
              this.statutoryVoucherGeneration = new StatutoryVoucherGeneration();
              this.statutoryVoucherGeneration.statutoryTransactionDto =
                new StatutoryTransaction();
                this.statutoryVoucherGeneration.statutoryTransactionDto.levelMaster
                = roleCode.levelMaster;
              this.date = new Date();
              this.statutoryVoucherGeneration.statutoryTransactionDto.voucherNumber =
                this.activatedRoute.snapshot.queryParamMap.get('VoucherNo');
            }
            if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
              this.spinner.show();
              this.statutoryPaymentVoucherService.getStatutoryPayment(
                  Number((this.activatedRoute.snapshot.queryParamMap.get('id')))
                )
                .subscribe(
                  (res: HttpResponse<StatutoryVoucherGeneration>) => {
                    this.spinner.hide();
                    this.statutoryVoucherGeneration = res.body;
                    this.isEditable=this.statutoryVoucherGeneration.statutoryTransactionDto.isEditable;
                    const [monthString, yearString] = this.statutoryVoucherGeneration.statutoryTransactionDto.deductionMonth.split('/');
                    const date = new Date(Number(yearString), Number(monthString) - 1, 1);
                    this.month=date;
                    if (
                      this.checkAmount &&
                      res.body.statutoryTransactionDto.transactionAmount <= 500000 &&
                      res.body.statutoryTransactionDto.levelOneSign
                    ) {
                      res.body.statutoryTransactionDto.levelTwoSign = 'signed';
                    }
                    if (this.statutoryVoucherGeneration.statutoryTransactionDto)
                      if (this.statutoryVoucherGeneration.statutoryTransactionDto.signStatus) {
                        this.readonly = true;
                        this.enableSignButton = true;
                      }
                    this.date = new Date(
                      this.statutoryVoucherGeneration.statutoryTransactionDto.transactionDate
                    );
                  },
                  (error)=>{
                    this.spinner.hide();
                  }
            );
          }
        }
      });
   
}

  getRole(): Observable<any> {
    return this.loginService.getuserDto().pipe(
      map((res:any) => res.body),
      catchError((error) => {
        return of(null);
      })
    );
  }

  loadDeductionType(){
    let query = {
      page: 0,
      size: 10,
    };
    let filterProps = [];
    let filquery = new filterQuery();
    filquery.key = 'isMiscellaneous';
    filquery.operation = 'equals';
    filquery.value = false;
    filterProps.push(filquery);
    filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    filterProps.push(filquery);
    this.statutoryAccountService.getAllDeductionTypesByFilter(query,filterProps).subscribe(
      (res)=>{
        this.statutoryDeductionList=res.body;
        this.statutoryDeductionList= this.statutoryDeductionList.filter(item => !item.code.startsWith('HA'));
      },
      ()=>{}
    )
  }


  loadAccount(){
    let filter=[];
   filter = [ {
    key: 'activeFlag',
    operation: 'equals',
    value: 'YES',
  },{
    key: 'approve',
    operation: 'equals',
    value: true,
  },{
    key: 'deductionType.code',
    operation: 'equals',
    value:this.statutoryVoucherGeneration.statutoryTransactionDto.deductionType.code ,
  },
  {
    key: 'levelMaster.id',
    operation: 'equals',
    value:this.statutoryVoucherGeneration.statutoryTransactionDto.levelMaster.id,
  }
];
    this.statutoryAccountService.getAllStatutoryDetailsfilter({ page:0,size:1 }, filter)
      .subscribe(
        (res: HttpResponse<StatutoryAccountDetail[]>) => {
          this.statutoryVoucherGeneration.statutoryTransactionDto.statutoryAccountDetail=new StatutoryAccountDetail();
          if(res.body.length>0){
            this.statutoryVoucherGeneration.statutoryTransactionDto.statutoryAccountDetail=res.body[0];
          } else {
            this.notificationService.alertError('Please configure the account for the selected dection type to proceed','');
            if(this.statutoryVoucherGeneration.statutoryBillDescription){
              this.statutoryVoucherGeneration.statutoryBillDescription=[];
            }
            return;
          }
          this.loadStatutoryBillDescription();

        },
        ()=>{
          this.notificationService.alertError('Error in loading account details','');
        }
      );
  }

  loadStatutoryBillDescription(){
    console.log('this.month',this.month);
    let month=this.month.getMonth();
    let year=this.month.getFullYear();
    if(this.maxSelectableDate < new Date(year, month, 1) && this.maxSelectableDate < new Date(year, month+1, 0, 23, 59, 59, 999)) {
      this.notificationService.alertError('This Month ' + this.month.toLocaleString('default', { month: 'long' }) + ' Statutory Payments Will Allowed For Next Month' ,'');
      this.month = new Date();
      this.month = null;
      this.statutoryVoucherGeneration.statutoryTransactionDto.deductionType = null;
      this.statutoryVoucherGeneration.statutoryBillDescription = [];
     
      return
    }
    console.log('this.monthout',this.month);
    if(this.month && this.statutoryVoucherGeneration.statutoryTransactionDto.deductionType)
    {
      console.log(this.statutoryVoucherGeneration.statutoryTransactionDto.statutoryAccountDetail.deductionType);
     
      const lastDay = new Date(year, month+1, 0).getDate();
      let startDate=this.datePipe.transform(new Date(year, month, 1), 'yyyy-MM-dd HH:mm:ss.SSS');
      let endDate=this.datePipe.transform(new Date(year, month+1, 0, 23, 59, 59, 999), 'yyyy-MM-dd HH:mm:ss.SSS');
      console.log('this.month',this.maxSelectableDate);
      console.log('endDate',new Date(year, month+1, 0, 23, 59, 59, 999));
      
        console.log('this.month',this.maxSelectableDate)
        this.spinner.show();
        this.statutoryPaymentVoucherService.getStatutoryDeductionList
        (this.statutoryVoucherGeneration.statutoryTransactionDto.deductionType.code,
          startDate,endDate).subscribe(
          (response)=>{
            this.spinner.hide();
            this.statutoryVoucherGeneration.statutoryBillDescription=response.body.statutoryDeductionListDtos;
            this.statutoryVoucherGeneration.statutoryTransactionDto.totalAmount=response.body.totalAmount;
            this.statutoryVoucherGeneration.statutoryTransactionDto.transactionAmount=response.body.totalAmount;
          },
          (onError)=>{
            this.spinner.hide();
            this.statutoryVoucherGeneration.statutoryBillDescription=[];
            this.statutoryVoucherGeneration.statutoryTransactionDto.totalAmount=0;
            this.statutoryVoucherGeneration.statutoryTransactionDto.transactionAmount=0;
            if (onError.status == 400) {
                  this.notificationService.alertError(
                    onError.error.title,'');
            }else {
              this.notificationService.alertError('Error!','');
            }
          }
        )
    }
  }

  saveVoucher(){
    this.ngSelectInvalid = false;
    const isFormValid =
      this.month &&
      this.statutoryVoucherGeneration.statutoryTransactionDto.remarks &&
      this.statutoryVoucherGeneration.statutoryTransactionDto.deductionType &&
      this.statutoryVoucherGeneration.statutoryTransactionDto.transactionAmount;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      this.notificationService.alertError("Please fill all the mandotory fields marked with *",'');
      return;
    }
    if(!this.statutoryVoucherGeneration.statutoryTransactionDto.statutoryAccountDetail.deductionType){
      this.notificationService.alertError('Account details not loaded properly,please choose deduction type with configured account','');
      return;
    }
    // if(!this.statutoryVoucherGeneration.statutoryTransactionDto.totalAmount){
    //   this.notificationService.alertError('Total Amount is not generated','');
    //   return;
    // }
    // if(this.statutoryVoucherGeneration.statutoryTransactionDto.totalAmount<this.statutoryVoucherGeneration.statutoryTransactionDto.transactionAmount){
    //   this.notificationService.alertError('Transaction Amount should not exceed total Amount','');
    //   return;
    // }

    if(this.statutoryVoucherGeneration.statutoryTransactionDto.transactionAmount <= 0){
      this.notificationService.alertError('Transaction Amount should be greater than 0','');
      return;
    }
    console.log('this.month',(this.month.getMonth()+1).toString().length);
    let month = ''
    if ((this.month.getMonth()+1).toString().length === 1){
      month = '0'+(this.month.getMonth()+1).toString();
    }
    else{
      month = (this.month.getMonth()+1).toString();
    }
    this.statutoryVoucherGeneration.statutoryTransactionDto.deductionMonth=month+'/'+this.month.getFullYear().toString();
    this.statutoryVoucherGeneration.statutoryTransactionDto.year=this.month.getFullYear();
    
    if( this.adminuserDto.role.code === userRoles.roleMaker || this.adminuserDto.role.code === userRoles.roleStateMaker ){
      this.voucherConfirmDialog=true;
      return;
    }
    else{
      this.generateVoucher();
    }
    
  }

  generateVoucher(){
    this.voucherConfirmDialog=false;
    this.spinner.show();
    this.statutoryPaymentVoucherService.generateVoucher(this.statutoryVoucherGeneration).subscribe(
      (response: any) => {
        this.readonly = true;
        this.spinner.hide();
        this.enableSignButton = true;
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      (onError) => {
        this.spinner.hide();
        if (onError.status == 400) {
           this.notificationService.alertError(onError.error.title,'');
        } else {
          this.notificationService.alertError('An unexpected error occurred.','');
        }
      }
    );
  }

  submitForm(){
    this.spinner.show();
    this.statutoryPaymentVoucherService
      .statutoryCdacRequest(this.statutoryVoucherGeneration.statutoryTransactionDto.voucherNumber,'Statutory')
    .subscribe(
      (res: HttpResponse<any>) => {
        console.log('ress',res.body);
        this.spinner.hide();
        this.param1 = res.body.paramOne;
        this.param2 = res.body.paramTwo;
        this.param3 = res.body.paramThree;
        if(!this.statutoryVoucherGeneration.statutoryTransactionDto.id){
          this.router.navigate(['/StatutoryPaymentVoucher'], {
            queryParams: {
              id: res.body.paramFour
            },
          });
        }
        this.localStorageService.store('refresh', 'Yes');
        const formElement = document.createElement('form');
        formElement.method = 'POST';
        formElement.action = environment.CDAC_URL;
        let inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.name = "eSignRequest";
        inputElement.value = res.body.paramOne;
        formElement.appendChild(inputElement);
        inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.name = "aspTxnID";
        inputElement.value = res.body.paramTwo;
        formElement.appendChild(inputElement);
        inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.name = "Content-Type";
        inputElement.value = res.body.paramThree;
        formElement.appendChild(inputElement);
        document.body.appendChild(formElement);
        formElement.submit();
      },
      (error) => {
        this.spinner.hide();
        
      }
    );
    
  }

  getfile(){
    this.statutoryPaymentVoucherService
      .statutoryCdacRequest(this.statutoryVoucherGeneration.statutoryTransactionDto.voucherNumber,'Statutory')
      .subscribe(
        (res: HttpResponse<any>) => {
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          this.enableSignButton = true;
          if(!this.statutoryVoucherGeneration.statutoryTransactionDto.id){
            this.router.navigate(['/StatutoryPaymentVoucher'], {
              queryParams: {
                id: res.body.paramFour
              },
            });
          }
        },
        () => {}
      );
  }

  getActionUrl(){
    this.localStorageService.store('refresh', 'Yes');
    return environment.CDAC_URL;
  }

  getPdf() {
    this.paymentService
      .getPdfFile(this.statutoryVoucherGeneration.statutoryTransactionDto.voucherNumber,false)
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          window.open(url, '_blank');
          link.click();
          URL.revokeObjectURL(url);
        },
        (err) => {
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  revert() {
    this.spinner.show();
    this.statutoryPaymentVoucherService
      .revertTranaction(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/StatutoryPaymentList'], {});
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  rejectDialog(){
    if(!this.rejectionReason){
      this.requiredError=true;
      return;
    }
    else{
      this.reasonDialog=false;
      this.reject();
    }
  }

  reject() {
    this.spinner.show();
    this.statutoryPaymentVoucherService.rejectTranaction(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id')),this.rejectionReason)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/StatutoryPaymentList'], {});
        },
        () => {
          this.spinner.hide();
          this.notificationService.alertError('Unexpected error occured!!','');
        }
      );
  }

  redirect() {
    this.router.navigate(['/StatutoryPaymentVoucher'], {
      queryParams: {
        id:String(this.statutoryVoucherGeneration.statutoryTransactionDto.id),
      },
    });
  }
  BackButtonClick() {
    this.router.navigate(['/StatutoryPaymentList']); 
  }

}
