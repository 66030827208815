import { DatePipe } from '@angular/common';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { ReportService } from 'src/app/dynamic-report-table/dynamic-report-service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { StatutoryTransaction } from '../statutory-payment-voucher/statutory-payment-voucher.model';
import { StatutoryPaymentVoucherService } from '../statutory-payment-voucher/statutory-payment-voucher.service';
import { Statutory } from '../statutory-payment/statutory-payment.model';
import { StatutoryPaymentService } from '../statutory-payment/statutory-payment.service';

@Component({
  selector: 'app-statutory-payment-module',
  templateUrl: './statutory-payment-module.component.html',
  styleUrls: ['./statutory-payment-module.component.scss']
})
export class StatutoryPaymentModuleComponent implements OnInit {
  statutoryData:Statutory;
  cols:any;
  date: Date;
  roleCode: any;
  userRoles=userRoles;
  itemsPerPage: any = 10;
  filterProps: any;
  searchResult: SearchResult<StatutoryTransaction> =
    new SearchResult<StatutoryTransaction>();
  page: number;
  
  constructor(public fontService:FontService,
    public statutoryService:StatutoryPaymentService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    protected paymentService: PaymentService,
    protected statutoryPaymentService: StatutoryPaymentVoucherService,
    private router:Router,
    private loginService: SigninService,
    protected notificationService: NotificationService,
    private reportService: ReportService
    ) { }

  ngOnInit(): void {

    this.cols = [
      {
        field: 'levelMaster',
        header: 'District',
        isSelectcolumn: true,
        isFilterable: true,
        type: 'dropDown'
      },
      {
        field: 'voucherNumber',
        header: 'Voucher No',
        isFilterable: true,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no'
      },
      {
        field: 'deductionMonth',
        header: 'Deduction Month',
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'transactionDate',
        header: 'Voucher Date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'statutoryAccountDetail',
        header: 'Account Number',
        isFilterable: true,
        isSelectcolumn: true,
        type:'dropDown_with_other_field',
        Dfield: 'bankAccountNumber'
      },
      {
        field: 'statutoryAccountDetail',
        header: 'PAN Number',
        isFilterable: true,
        isSelectcolumn: true,
        type:'dropDown_with_other_field',
        Dfield: 'panNumber'
      },
      {
        field: 'statutoryAccountDetail',
        header: 'GST Number',
        isFilterable: true,
        isSelectcolumn: true,
        type:'dropDown_with_other_field',
        Dfield: 'gstNumber'
      },
      {
        field: 'paymentDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      
      {
        field: 'deductionType',
        header: 'Deduction Type',
        isSelectcolumn: true,
        isFilterable: true,
        type: 'dropDown'
      },
      {
        field: 'implementingAgency',
        header: 'Implementing Agency',
        isSelectcolumn: true,
        isFilterable: true,
        type: 'dropDown'
      },
      {
        field: 'transactionAmount',
        header: 'Total Transction Amount',
        jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        isFilterable: true,
        type: 'amount'
      },
      {
        field: 'signMessage',
        header: 'Status',
        isSelectcolumn: true,
        isFilterable: true
      }
    ];
    if (this.route.snapshot.queryParamMap.get('error')){
      this.notificationService.alertError('Error Occurred: '+this.route.snapshot.queryParamMap.get('error'),'');
    }
    this.loginService.getuserDto().subscribe(
      (res) => {
       this.roleCode = res.body.role.code
      },
      (onError) => {
        
      } 
     
    );
    }

    loadPage(page?: number, dontNavigate?: boolean): void {
      // this.spinner.show();
      const pageToLoad: number = page ?? this.page ?? 1;
      let query = new filterQuery();
    query.key = 'activeFlag';
      query.operation = 'equals';
      query.value = 'YES';
    this.filterProps.push(query);
    query = new filterQuery();
    query.key = 'workId';
    query.operation = 'null';
    this.filterProps.push(query);
      let Query = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
      };
      this.statutoryPaymentService.filter({ ...Query }, this.filterProps).subscribe(
        (res: HttpResponse<StatutoryTransaction[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    }
  
    protected onSuccess(
      data: StatutoryTransaction[] | null,
      headers: HttpHeaders,
      page: number,
      navigate: boolean
    ): void {
      this.searchResult.total = Number(headers.get('X-Total-Count'));
      // this.spinner.hide();
      this.page = page;
  
      this.searchResult.items = data ?? [];
      this.searchResult = { ...this.searchResult };
    }
  
    protected onError(): void {}
  
    onLazyLoad(event) {
      this.filterProps = event.filterDefinitions;
      this.itemsPerPage = event.size;
      this.loadPage(event.page);
    }

    openNew() {
      this.paymentService.getVoucher().subscribe(
        (res: HttpResponse<any>) => {
          this.router.navigate(['/StatutoryPaymentVoucher'], {
            relativeTo: this.route,
            queryParams: {
              VoucherNo: res.body,
            },
          });
        },
        () => {}
      );
    }

    onSelectRow($event) {
      if ($event.type === 'select') {
        this.router.navigate(['/StatutoryPaymentVoucher'], {
          queryParams: {
            id: $event.value.id,
          },
        });
      }
    else{
      this.statutoryPaymentService
        .rejectTranaction($event.value.id)
        .subscribe(
        (res: HttpResponse<any>) => {
          this.loadPage(this.page)
        },
        () => {
          this.onError();
        }
        );
    }
    }
    downloadData() {
      let data="Statutory Payment Report";
      
      this.spinner.show();
      this.reportService.getExcelDownload(data).subscribe((res: HttpResponse<any>) => 
        {
         console.log(res);
         this.notificationService.alertSuccess(res.body, '');
         this.spinner.hide();
       },
        (onError) => {
          this.notificationService.alertError(onError.error.errorMessage, '');
          this.spinner.hide();
        }
      );
    }
  
    checkStatus() {
      let  reportName='Statutory Payment Report';
      this.spinner.show();
      this.reportService.reportAsyncStatusCheck(reportName).subscribe(
        (res) => {
          console.log('res', res);
          let b: any = res.body;
          this.saveBlobToExcel(b.data,'Statutory Payment Report');
          this.spinner.hide();
        },
        (onError) => {
          this.notificationService.alertError(onError.error.errorMessage, '');
          this.spinner.hide();
        }
      );
    }
  
    saveBlobToExcel(data, filename) {
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, filename);
    }
  }
  
    
