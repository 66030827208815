import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/alert/notification.service';
import { AuthUser } from './authuser';
import { ResetService } from './reset.service';
import { NavbarService } from 'src/app/layout/navbar/navbar.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  login?: AuthUser;
keyCloakUuid:string;
 public pwdMismatch:boolean=false;
phide:boolean=true;
cphide:boolean=true;
  resetPwdForm = this.fb.group({
    password: [null, [Validators.required]],
    confirmPassword: [null, [Validators.required]],
  });
  passwordError: any;
  constructor( private fb: UntypedFormBuilder,private route: ActivatedRoute,private router: Router,
    private resetService: ResetService,private notificationService:NotificationService,
    private navbarService:NavbarService,private localStorageService:LocalStorageService,
    private sessionStorageService:SessionStorageService) { }



  ngOnInit(): void {
    this.localStorageService.store('auth', false);
  }
  get password(){
    return this.resetPwdForm.get('password')!.value;
  }
  get confirmPassword(){
    return this.resetPwdForm.get('confirmPassword')!.value;
  }
  navToLogin() {
    this.logout();
  }
  submit(){
    this.pwdMismatch=false;
    this.login = new AuthUser();
    if (this.resetPwdForm.invalid) {
    return;
    }
      if(this.password==this.confirmPassword){
    this.login.password= this.resetPwdForm.get('password')!.value ;
    this.resetService.change(this.login).subscribe(
      (res) => {
        this.notificationService.alertSuccess('Changed Successfully', '');
        this.logout();
      },
      (onError)=>{
        if (onError.status == 400)
        {
          this.passwordError = onError.error.errorKey;
        }
        else{
          this.passwordError='Password reset failed!';
        }
     
      }
    );
      }
      else{
        this.pwdMismatch=true;
      }  
 
  }
  logout(): void {
    this.navbarService.logout().subscribe(
      (res: HttpResponse<any>) => {},
      (err) => {}
    );
    this.localStorageService.clear();
    this.sessionStorageService.clear();
    this.router.navigate(['']);
  }
}
