import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { Dialog } from 'primeng/dialog';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { DashboardService } from 'src/app/dashBoards/dashboard/dashboard.service';
import { ReportService } from 'src/app/dynamic-report-table/dynamic-report-service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { VendorTransaction } from '../payment-voucher/payment-voucher.model';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { MaintenanceTransaction } from '../work-maintenance-payment-voucher/work-maintenance-payment.model';
import { MaintenanceService } from 'src/app/maintenance-view/maintenance.service';

@Component({
  selector: 'app-enable-maintenance-payment-list',
  templateUrl: './enable-maintenance-payment-list.component.html',
  styleUrl: './enable-maintenance-payment-list.component.scss'
})
export class EnableMaintenancePaymentListComponent {
 @ViewChild('dialog') dialog: Dialog;
  itemsPerPage: any = 10;
  filterProps: any;
  userRoles = userRoles;
  searchResult: SearchResult<MaintenanceTransaction> =
    new SearchResult<MaintenanceTransaction>();
  page: number;
  cols: any;
  roleCode: any;
  vouchercols: any;
  enableStatusVoucher: boolean;
  searchVoucherStatusResult: SearchResult<any> = new SearchResult<any>();
  userContextSubscription: Subscription;

  constructor(
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    protected router: Router,
    protected paymentService: PaymentService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    private loginService: SigninService,
    protected notificationService: NotificationService,
    private userRoleService: UserRoleService,
    private reportService: ReportService,
    private maintenanceService:MaintenanceService
  ) {
    this.translateService.onLangChange.subscribe(() => {
    });
  }

  message: string;

  ngOnInit(): void {
    if (this.route.snapshot.queryParamMap.get('error')) {
      this.notificationService.alertError(
        'Error Occurred: ' + this.route.snapshot.queryParamMap.get('error'),
        ''
      );
    }
    this.loginService.getuserDto().subscribe(
      (res) => {
        this.roleCode = res.body.role.code;
        this.cols = [
          {
            field: 'levelMaster',
            header: 'District',
            isFilterable: true,
            isSelectcolumn: true,
            type: 'dropDown',
          },
          {
            field: 'finYear',
            header: 'Fin Year',
            isSelectcolumn: true,
            type: 'dropDown_with_other_field',
            Dfield: 'name',
          },
          {
            field: 'workId',
            header: 'Work Id',
            isFilterable: true,
            isSelectcolumn: true,
          },
          {
            field: 'workName',
            header: 'Work Name',
            isSelectcolumn: true,
          },
          {
            field: 'totalMaintenanceAmount',
            header: 'Total Maintenance Amount',
            isSelectcolumn: true,
            type:'amount'
          },
          {
            field: 'administrativeSanctionAmount',
            header: 'AS Amount',
            isSelectcolumn: true,
            type:'amount'
          },
          {
            field: 'finalPaymentDate',
            header: 'Final Payment Date',
            isSortable: true,
            isSelectcolumn: true,
            type: 'date',
          },
          
          
        ];
      
      },
      (onError) => {}
    );
  }
  captureWSMessage(data: any) {
    throw new Error('Method not implemented.');
  }

  ngAfterViewInit() {
  }

  openNew() {
    this.paymentService.getVoucher().subscribe(
      (res: HttpResponse<any>) => {
        this.router.navigate(['/enable-maintenance-payment'], {
          relativeTo: this.route,
        });
      },
      () => {}
    );
  }

 

  loadPage(page?: number, dontNavigate?: boolean): void {
    // this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
   
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    let query = new filterQuery();
    query.key = 'maintenanceDescList.isReenable';
    query.operation = 'equals';
    query.value = 'true';
    this.filterProps.push(query);
    this.maintenanceService.filter({ ...Query }, this.filterProps).subscribe(
      (res: HttpResponse<MaintenanceTransaction[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );
    
  }

  protected onSuccess(
    data: MaintenanceTransaction[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  onLazyLoad(event) {
    this.filterProps = [];
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  onSelectRow($event) {
    if ($event.type === 'view') {
      this.router.navigate(['/enable-maintenance-payment'], {
        queryParams: {
          id: $event.value.id,
        },
      });
    } 
  }
  getSubTableDetails($event) {
    this.vouchercols = [
      {
        field: 'amount',
        header: 'Amount',
        isSortable: true,
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.amount',
        type: 'amount',
      },
      {
        field: 'gstAmount',
        header: 'Gst Amount',
        isSortable: true,
        isSelectcolumn: true,
      },
      {
        field: 'grossAmount',
        header: 'Gross Amount',
        isSortable: true,
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.grossTotal',
        type: 'amount',
      },
    ];
    this.dashboardService.getVoucherSubtableById('NO', $event).subscribe(
      (res: HttpResponse<any>) => {
        this.enableStatusVoucher = true;
        this.searchVoucherStatusResult.total = Number(
          res.headers.get('X-Total-Count')
        );
        this.searchVoucherStatusResult.items = res.body ?? [];
        this.searchVoucherStatusResult = { ...this.searchVoucherStatusResult };
      },
      () => {
        this.onError();
      }
    );
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.userContextSubscription) {
      this.userContextSubscription.unsubscribe();
    }
  }

  blukVoucherApproved(){
    this.router.navigate(['/blukpaymentTable'],  {
      queryParams: {
        type:'Work',
      },
    });
  }
  downloadData() {
    let data="Work Payment Report";
    
    this.spinner.show();
    this.reportService.getExcelDownload(data).subscribe((res: HttpResponse<any>) => 
      {
       console.log(res);
       this.notificationService.alertSuccess(res.body, '');
       this.spinner.hide();
     },
      (onError) => {
        this.notificationService.alertError(onError.error.errorMessage, '');
        this.spinner.hide();
      }
    );
  }

  checkStatus() {
      let reportName='Work Payment Report';
    this.spinner.show();
    this.reportService.reportAsyncStatusCheck(reportName).subscribe(
      (res) => {
        console.log('res', res);
        let b: any = res.body;
        this.saveBlobToExcel(b.data,'Work Payment Report');
        this.spinner.hide();
      },
      (onError) => {
        this.notificationService.alertError(onError.error.errorMessage, '');
        this.spinner.hide();
      }
    );
  }

  saveBlobToExcel(data, filename) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }
}
